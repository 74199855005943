import { RequestActionProps } from 'src/sections/request/types/RequestActionProps';

export const getCompleteWorkActionState = ({
  requestStateInformation,
}: RequestActionProps) => {
  const {
    isTechnicianWorking,
    isTechnician,
    isAssignedToMe,
    isServiceDispatcher,
  } = requestStateInformation;

  const isVisible = isTechnicianWorking;

  if (!isVisible) {
    return {
      isVisible: false,
      isEnabled: false,
      message: '',
    };
  }

  const acceptMessage = 'Complete Work';
  const canPerform = (isTechnician && isAssignedToMe) || isServiceDispatcher;

  return {
    isVisible: true,
    isEnabled: canPerform,
    message: canPerform ? acceptMessage : `Waiting On Technician`,
    confirmationTitle: acceptMessage,
    confirmationMessage:
      'Are you sure you are ready to sign off on the work being completed?',
  };
};
