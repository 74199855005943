import { createFileRoute } from '@tanstack/react-router';
import { PrivacyPolicyPage } from 'src/pages/privacy-policy';

export const Route = createFileRoute('/privacy-policy')({
  component: RouteComponent,
});

function RouteComponent() {
  return <PrivacyPolicyPage />;
}
