import { api } from '@cvx/api';
import { ConvexUserId } from '@cvx/types/entities/sharedIds';
import { createFileRoute, notFound } from '@tanstack/react-router';
import Page from 'src/pages/dashboard/driver/edit';
import {
  checkRouteAccess,
  routeAccessConfigs,
} from 'src/utils/checkRouteAccess';

export const Route = createFileRoute('/_auth/dashboard/drivers/$driverId')({
  beforeLoad: ({ context }) =>
    checkRouteAccess(context, routeAccessConfigs.fleetOnly),
  loader: async ({ context, params }: { context: any; params: any }) => {
    try {
      const user = await context.convex.query(api.functions.users.getUserById, {
        id: params.driverId as ConvexUserId,
      });

      return user;
    } catch (error) {
      // TODO: Come up with a better pattern all around for this
      // could be not found (deleted) or not authorized.. found but not authorized
      throw notFound();
    }
  },
  component: Page,
});
