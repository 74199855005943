import { api } from '@cvx/api';
import { Box, CardHeader, Dialog, IconButton, InputBase } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useMutation, useQuery } from 'convex/react';
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { toast } from 'sonner';
import { Doc } from 'src/convex/_generated/dataModel';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { Scrollbar } from 'src/minimal-theme/components/scrollbar';
import { useCollapseNav } from 'src/sections/chat/hooks/use-collapse-nav';
import { type RequestTabs } from 'src/sections/request/RequestTabs';

import { EnhancedRequest } from '@cvx/types/entities/requestsEntityTypes';
import { ConvexUser } from '@cvx/types/entities/usersEntityTypes';
import { InvoiceWithItems } from 'src/convex/functions/invoices';
import { EnhancedUser } from 'src/convex/functions/users';
import { getRequestStateInformation } from 'src/sections/request/actions/utils/getRequestStateInformation';
import {
  IChatAttachment,
  IChatMessage,
  IChatParticipant,
} from 'src/types/chat';
import { ChatMessageList } from '../chat-message-list';
import { Layout } from '../layout';

export interface RequestChatProps {
  isDialog?: boolean;
  onClose?: () => void;
  setCurrentTab?: Dispatch<SetStateAction<RequestTabs>>;
  request: EnhancedRequest;
  me: EnhancedUser;
  invoices: InvoiceWithItems[];
}

export function RequestChat({
  setCurrentTab,
  me,
  request,
  invoices,
}: RequestChatProps) {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isDialogOpen, setIsDialogOpen] = useState(isMobile ? true : false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    if (isMobile) {
      setCurrentTab?.('main' as RequestTabs);
    }
    setIsDialogOpen(false);
  };

  const messages = useQuery(
    api.functions.requestChats.getRequestMessages,
    request
      ? {
          requestId: request._id,
        }
      : 'skip'
  );

  const participants = useMemo(() => {
    if (!request) return [];

    return [
      mapUserToChatParticipant(request.activeDriver, 'Driver'),
      mapUserToChatParticipant(request.fleetDispatcher, 'Fleet Dispatcher'),
      mapUserToChatParticipant(request.serviceDispatcher, 'Service Dispatcher'),
      mapUserToChatParticipant(request.technician, 'Technician'),
      mapUserToChatParticipant(request.activeBroker, 'Third Party Dispatcher'),
    ].filter((p): p is IChatParticipant => p !== null);
  }, [request]);

  const sendMessage = useMutation(api.functions.requestChats.sendMessage);
  const [message, setMessage] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);

  const scrollToBottom = () => {
    if (scrollContainerRef.current) {
      const scrollContainer = scrollContainerRef.current;
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
  };

  // Check if user is near bottom of scroll
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const container = event.currentTarget;
    const { scrollHeight, scrollTop, clientHeight } = container;
    const isNearBottom = scrollHeight - scrollTop - clientHeight < 100;
    setShouldAutoScroll(isNearBottom);
  };

  // Scroll to bottom when new messages arrive, if we should
  useEffect(() => {
    if (shouldAutoScroll && messages?.length) {
      scrollToBottom();
    }
  }, [messages, shouldAutoScroll]);

  const handleSend = async () => {
    if (!message.trim() || !request) return;
    setMessage('');
    try {
      sendMessage({
        requestId: request._id,
        content: message,
        type: 'GENERAL',
      });

      setShouldAutoScroll(true);
      scrollToBottom();
    } catch (error) {
      console.error('Failed to send message:', error);
      toast.error('Problem occurred when sending message, please try again');
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleChangeMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const roomNav = useCollapseNav();

  const formattedMessages = formatChatMessages(messages ?? []);

  const requestStateInformation = getRequestStateInformation(
    request,
    me,
    invoices
  );
  const { canEditChatAndPhotos } = requestStateInformation;

  const chatContent = (
    <Layout
      sx={{
        height: isMobile ? 'calc(90vh - 72px)' : '50vh',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: isMobile && isDialogOpen ? 0 : 2,
        position: 'relative',
        bgcolor: 'background.paper',
        boxShadow: theme =>
          !isMobile || !isDialogOpen ? theme.customShadows.card : 'none',
      }}
      slots={{
        header: (
          <CardHeader
            title="Request Chat"
            sx={{ pl: '4px', pt: '4px', pb: 0, pr: 0, flexShrink: 0 }}
            action={
              isMobile &&
              isDialogOpen && (
                <IconButton onClick={handleCloseDialog}>
                  <Iconify icon="eva:close-fill" />
                </IconButton>
              )
            }
          />
        ),
        nav: null,
        main: (
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              overflow: 'hidden',
            }}
          >
            <Scrollbar
              ref={scrollContainerRef}
              onScroll={handleScroll}
              sx={{
                height: 200,
              }}
            >
              <ChatMessageList
                messages={formattedMessages}
                participants={participants}
                loading={!messages}
              />
              <div ref={messagesEndRef} />
            </Scrollbar>
            {/* <ChatMessageInput
              recipients={participants}
              onAddRecipients={() => {}}
              selectedConversationId={request?._id ?? ''}
              disabled={!participants.length}
            /> */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 2,
                borderTop: theme => `solid 1px ${theme.vars.palette.divider}`,
                px: 3,
                py: 2,
              }}
            >
              <InputBase
                name="chat-message"
                id="chat-message-input"
                value={message}
                onKeyDown={handleKeyPress}
                onChange={handleChangeMessage}
                placeholder={
                  canEditChatAndPhotos
                    ? 'Type a message'
                    : 'Cannot chat until you are an active participant'
                }
                multiline
                disabled={!canEditChatAndPhotos}
                fullWidth
                // fullWidth
                // startAdornment={
                //   <IconButton>
                //     <Iconify icon="eva:smiling-face-fill" />
                //   </IconButton>
                // }
                // endAdornment={
                //   <Stack direction="row" sx={{ flexShrink: 0 }}>
                //     <IconButton onClick={handleAttach}>
                //       <Iconify icon="solar:gallery-add-bold" />
                //     </IconButton>
                //     <IconButton onClick={handleAttach}>
                //       <Iconify icon="eva:attach-2-fill" />
                //     </IconButton>
                //     <IconButton>
                //       <Iconify icon="solar:microphone-bold" />
                //     </IconButton>
                //   </Stack>
                // }
              />

              <IconButton
                size="small"
                onClick={handleSend}
                sx={{
                  bgcolor: 'primary.main',
                  color: 'primary.contrastText',
                }}
              >
                <Iconify icon="mingcute:arrow-up-fill" />
              </IconButton>

              {/* <input type="file" ref={fileRef} style={{ display: 'none' }} /> */}
            </Box>
          </Box>
        ),
        // details: (
        //   <ChatRoom
        //     collapseNav={roomNav}
        //     participants={participants}
        //     loading={!messages}
        //     messages={formattedMessages}
        //   />
        // ),
        details: null,
      }}
    />
  );

  if (isMobile) {
    return (
      <Dialog
        fullScreen
        open={isDialogOpen}
        onClose={handleCloseDialog}
        sx={{
          '& .MuiDialog-paper': {
            margin: 0,
          },
        }}
      >
        {chatContent}
      </Dialog>
    );
  }

  return chatContent;
}

function mapUserToChatParticipant(
  user: ConvexUser | null,
  role: string
): IChatParticipant | null {
  if (!user) return null;

  return {
    id: user._id,
    name: user.clerkUser.fullName,
    role,
    email: user.clerkUser.primaryEmailAddress?.emailAddress ?? '',
    phoneNumber: user.clerkUser.primaryPhoneNumber?.phoneNumber ?? '',
    avatarUrl: user.clerkUser.imageUrl ?? '',
    lastActivity: new Date().toISOString(),
    status: 'offline', // Default to offline since we're not tracking presence yet
  };
}

/**
 * Formats raw request chat messages into standardized chat message format
 */
export function formatChatMessages(
  messages: Array<
    Doc<'requestChats'> & {
      user?: {
        name: string;
        imageUrl: string;
      };
    }
  >
): IChatMessage[] {
  return messages.map(message => {
    // Extract creation time components
    const creationDate = new Date(message._creationTime);

    // const attachments: IChatAttachment[] = message.attachments?.map(attachment => ({
    //   name: `File-${attachment.fileId}`, // Generate a name for the file
    //   size: 0, // Would need actual file size from storage
    //   type: attachment.type,
    //   path: `/api/files/${attachment.fileId}`,
    //   preview: `/api/files/${attachment.fileId}`,
    //   createdAt: message._creationTime,
    //   modifiedAt: message._creationTime
    // })) || [];

    const attachments: IChatAttachment[] = [];

    return {
      id: message._id,
      body: message.content,
      senderId: message.userId,
      contentType: message.type,
      createdAt: message._creationTime,
      attachments,
    };
  });
}
