import { ConvexUser } from '@cvx/types/entities/usersEntityTypes';
import { Vehicle } from '@cvx/types/entities/vehiclesEntityTypes';
import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import Map, { Layer, Source } from 'react-map-gl';
import { CONFIG } from 'src/config-global';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { useMapDirections } from 'src/utils/useMapDirections';
import { useMapViewState } from 'src/utils/useMapViewState';
import { MapControl } from '../../minimal-theme/components/map/map-control';
import { MapMarker } from '../../minimal-theme/components/map/map-marker';

type MapDisplayProps = {
  technician?: ConvexUser | null;
  driverVehicle?: Vehicle | null;
  canUseCurrentLocation?: boolean;
  onUpdateLocation?: () => void;
  isUpdatingLocation?: boolean;
  buttonText?: string;
  coordinatesOverride?: { latitude: number; longitude: number }; // For cases when we do not have a vehicle with a location (service provider initiated will usually just put an address)
};

const routeLayer = {
  id: 'route',
  type: 'line',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': '#3887be',
    'line-width': 5,
    'line-opacity': 0.75,
  },
};

export const RequestMapDisplay = ({
  technician,
  driverVehicle,
  canUseCurrentLocation,
  coordinatesOverride,
  onUpdateLocation,
  isUpdatingLocation,
  buttonText = 'Use Current Location',
}: MapDisplayProps) => {
  const { routeGeometry, travelTime, getDirections } = useMapDirections();

  const coordinates = useMemo(() => {
    if (
      driverVehicle?.location?.latitude != null &&
      driverVehicle?.location?.longitude != null
    ) {
      return {
        latitude: driverVehicle.location.latitude,
        longitude: driverVehicle.location.longitude,
      };
    }

    if (
      coordinatesOverride?.latitude != null &&
      coordinatesOverride?.longitude != null
    ) {
      return coordinatesOverride;
    }

    return null;
  }, [driverVehicle?.location, coordinatesOverride]);

  const { viewState, setViewState } = useMapViewState({
    coordinates,
  });

  // useEffect(() => {
  //   console.log('Map Debug:', {
  //     hasToken: !!CONFIG.mapboxApiKey,
  //     techLocation: technician?.location,
  //     vehicleLocation: driverVehicle?.location,
  //     coordinatesOverride,
  //     viewState,
  //   });
  // }, [
  //   technician?.location,
  //   driverVehicle?.location,
  //   coordinatesOverride,
  //   viewState,
  // ]);

  // Get directions when technician or target location changes
  useEffect(() => {
    if (technician?.location && coordinates) {
      getDirections(
        [technician.location.longitude, technician.location.latitude],
        [coordinates.longitude, coordinates.latitude]
      );
    }
  }, [technician?.location, coordinates, getDirections]);

  const currentLocationButton = (
    <>
      {canUseCurrentLocation && (
        <LoadingButton
          variant="contained"
          onClick={onUpdateLocation}
          loading={isUpdatingLocation}
          startIcon={<Iconify icon="f7:location-fill" />}
        >
          {buttonText}
        </LoadingButton>
      )}
    </>
  );
  if (!coordinates && !driverVehicle?.location) {
    return <>{currentLocationButton}</>;
  }

  return (
    <>
      <Box sx={{ borderRadius: 2 }}>
        <Map
          mapboxAccessToken={CONFIG.mapboxApiKey}
          mapStyle="mapbox://styles/mapbox/light-v10"
          {...viewState}
          onMove={evt => setViewState(evt.viewState)}
          style={{ width: '100%', height: 400, borderRadius: '16px' }}
        >
          <MapControl />
          {coordinates && (
            <MapMarker
              latitude={coordinates.latitude}
              longitude={coordinates.longitude}
              color="primary"
            />
          )}

          {technician?.location && (
            <>
              <MapMarker
                latitude={technician.location.latitude}
                longitude={technician.location.longitude}
                color="success"
              />
              {routeGeometry && (
                <Source type="geojson" data={routeGeometry}>
                  <Layer {...(routeLayer as any)} />
                </Source>
              )}
            </>
          )}
        </Map>
        {!!travelTime && (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            Estimated travel time: {travelTime} minutes
          </Typography>
        )}
      </Box>
      {currentLocationButton}
    </>
  );
};
