import { api } from '@cvx/api';
import { CompanyGroup } from '@cvx/types/entities/groupsEntityTypes';
import { CompanyId } from '@cvx/types/entities/sharedIds';
import { useQuery } from 'convex/react';
import { useState } from 'react';

export function useGroupSearch(forSpecificCompanyId?: CompanyId) {
  const [debouncedTerm, setDebouncedTerm] = useState('');
  const [selectedGroup, setSelectedGroup] = useState<CompanyGroup | null>(null);

  // Get search results based on text
  const groups = useQuery(api.functions.companies.searchLocationsByName, {
    searchTerm: debouncedTerm,
    forSpecificCompanyId,
  });

  const returnedGroups: CompanyGroup[] = groups || [];

  return {
    debouncedTerm,
    setDebouncedTerm,
    groups: returnedGroups,
    selectedGroup,
    setSelectedGroup,
  };
}
