import { VendorServiceId } from '@cvx/types/entities/sharedIds';
import { Stack, TextField } from '@mui/material';
import { useServiceSelection } from 'src/sections/location/view/hooks/useServiceSelection';
import { ServiceSelectField } from 'src/sections/location/view/ServiceSelectField';

export type ServiceFilterOptions = {
  splitOutTireBrandSelection?: boolean;
};

type ServiceSelectionProps = {
  value: VendorServiceId[];
  onChange: (value: VendorServiceId[]) => void;
  error?: boolean;
  helperText?: string;
  filterOptions?: ServiceFilterOptions;
  required?: boolean;
  nationalAccountNumber?: string;
  onNationalAccountNumberChange?: (value: string) => void;
  tireSize?: string;
  onTireSizeChange?: (value: string) => void;
};

export const ServiceSelection = ({
  value,
  onChange,
  error,
  helperText,
  filterOptions,
  required,
  nationalAccountNumber = '',
  onNationalAccountNumberChange,
  tireSize = '',
  onTireSizeChange,
}: ServiceSelectionProps) => {
  const {
    services,
    tireBrandsCategory,
    hasTireSalesSelected,
    hasBrandSelected,
    tireSalesCategory,
    mainServices,
  } = useServiceSelection(value);

  if (!services) return null;

  // TODO: This is pretty hacky, need to modify the data model to better support dynamic front-end customizations based on service type/category
  if (!filterOptions?.splitOutTireBrandSelection) {
    return (
      <ServiceSelectField
        services={services}
        value={value}
        onChange={onChange}
        error={error}
        helperText={helperText}
        label="Services Offered"
        required={required}
      />
    );
  }

  const handleMainServicesChange = (newValue: VendorServiceId[]) => {
    const safeNewValue = Array.isArray(newValue) ? newValue : [];

    const willHaveTireSales =
      tireSalesCategory?.services.some(service =>
        safeNewValue.includes(service._id)
      ) ?? false;

    if (!willHaveTireSales) {
      // Remove any tire brand selections if unselecting all tire sales
      const nonTireBrandSelections = safeNewValue.filter(
        id => !tireBrandsCategory?.services.some(s => s._id === id)
      );
      onChange(nonTireBrandSelections);
      return;
    }

    // Keep existing tire brand selections
    const existingTireBrands = value.filter(id =>
      tireBrandsCategory?.services.some(s => s._id === id)
    );

    onChange([...safeNewValue, ...existingTireBrands]);
  };

  const handleTireBrandChange = (newValue: VendorServiceId[]) => {
    // Keep existing main service selections
    const existingMainServices = value.filter(
      id => !tireBrandsCategory?.services.some(s => s._id === id)
    );

    onChange([...existingMainServices, ...newValue]);
  };

  return (
    <Stack spacing={3}>
      <ServiceSelectField
        services={mainServices}
        value={value.filter(
          id => !tireBrandsCategory?.services.some(s => s._id === id)
        )}
        onChange={handleMainServicesChange}
        label="Services Required"
        error={error}
        helperText={helperText}
        required={required}
      />

      {hasTireSalesSelected && tireBrandsCategory && (
        <>
          <ServiceSelectField
            services={[
              {
                category: tireBrandsCategory.category,
                services: tireBrandsCategory.services,
              },
            ]}
            value={value.filter(id =>
              tireBrandsCategory.services.some(s => s._id === id)
            )}
            error={!hasBrandSelected}
            onChange={handleTireBrandChange}
            label="Tire Brand Required"
            required={required}
          />

          {hasBrandSelected && (
            <Stack direction="column" spacing={3}>
              <TextField
                label="Tire Size"
                value={tireSize}
                onChange={e => onTireSizeChange?.(e.target.value)}
                fullWidth
                placeholder="Enter Tire Size"
              />
              <TextField
                label="National Account Number"
                value={nationalAccountNumber}
                onChange={e => onNationalAccountNumberChange?.(e.target.value)}
                fullWidth
                placeholder="Enter National Account Number"
              />
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
};
