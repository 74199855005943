import { api } from '@cvx/api';
import { EnhancedUser } from '@cvx/functions/users';
import { EnhancedRequest } from '@cvx/types/entities/requestsEntityTypes';
import {
  ConvexUserId,
  GroupId,
  RequestId,
} from '@cvx/types/entities/sharedIds';
import { getParticipantModificationPermissions } from '@cvx/utils/participants/participantPermissions';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Link,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useQuery } from 'convex/react';
import { useState } from 'react';
import { Label } from 'src/minimal-theme/components/label';
import { ParticipantModifyDialog } from 'src/sections/request/view/ParticipantModifyDialog';

type RequestParticipantsProps = {
  request: EnhancedRequest;
  me: EnhancedUser;
};
export function RequestParticipants({ me, request }: RequestParticipantsProps) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const permissions = getParticipantModificationPermissions({
    userRoles: me.roles.map(r => r.type),
    userLocationId: me.primaryLocationGroupId,
    requestFleetDispatchGroupId: request.fleetDispatchGroupId,
    requestMechanicDispatchGroupId: request.mechanicDispatchGroupId,
    requestBrokerageGroupId: request.brokerageGroupId,
  });

  const permission = me.primaryRoleType
    ? permissions[me.primaryRoleType]
    : undefined;

  const canModify =
    !!permission && permission.canModify && permission.participantType !== null;

  return (
    <Card>
      <CardHeader
        title="Participants"
        action={
          canModify && (
            <Button
              size="small"
              variant="contained"
              onClick={() => setDialogOpen(true)}
            >
              Modify
            </Button>
          )
        }
      />
      <Stack spacing={3} sx={{ p: 3 }}>
        {getParticipants(request).map(({ title, participant }) => (
          <Participant
            key={title}
            participant={participant}
            title={title}
            isCurrentAssignee={
              !!participant?._id &&
              participant?._id === request.currentAssignedToId
            }
            requestId={request._id}
            isDeleted={participant.isDeleted}
          />
        ))}
      </Stack>
      {canModify && (
        <ParticipantModifyDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          request={request}
          me={me}
          permission={permission}
        />
      )}
    </Card>
  );
}

type Participant = {
  _id?: ConvexUserId;
  fullName: string;
  phone: string;
  isDeleted?: boolean;
  imageUrl?: string;
  primaryLocationGroupId?: GroupId;
};

type ParticipantProps = {
  participant: Participant;
  title: string;
  isCurrentAssignee: boolean;
  isDeleted?: boolean;
  requestId: RequestId;
};

function Participant({
  participant,
  title,
  isCurrentAssignee,
  requestId,
}: ParticipantProps) {
  const theme = useTheme();

  const locationGroup = useQuery(
    api.functions.companies.getLocationById,
    participant.primaryLocationGroupId
      ? { id: participant.primaryLocationGroupId as GroupId }
      : 'skip'
  );

  const noParticipant = participant.fullName === '';

  if (noParticipant) {
    return null;
  }

  return (
    <>
      <ListItem
        sx={{
          cursor: 'default',
          '&:hover': { backgroundColor: 'transparent' },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ width: '100%' }}
        >
          <Box
            sx={{
              border: isCurrentAssignee
                ? `4px solid ${theme.vars.palette.primary.main}`
                : '4px solid transparent',
              borderRadius: '50%',
            }}
          >
            <Avatar src={participant.imageUrl ?? ''}>
              {participant.fullName[0]}
            </Avatar>
          </Box>
          <Box sx={{ flexGrow: 1, minWidth: 0 }}>
            {isCurrentAssignee && <Label color="primary">Assigned</Label>}
            <Box sx={{ display: 'flex' }}>
              <Typography variant="subtitle2" noWrap>
                {title}
                {locationGroup?.name ? ` - ${locationGroup.name}` : ''}
              </Typography>
            </Box>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {participant.fullName}
            </Typography>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              {participant.isDeleted ? (
                `Deleted User`
              ) : (
                <Link href={`tel:${participant.phone}`}>
                  {participant.phone}
                </Link>
              )}
            </Typography>
          </Box>
        </Stack>
      </ListItem>
    </>
  );
}
type Section = {
  title: string;
  participant: Participant;
};

function getParticipants(request: EnhancedRequest) {
  const {
    activeDriver,
    fleetDispatcher,
    serviceDispatcher,
    technician,
    activeBroker,
  } = request;

  const excludeServiceDispatcher = serviceDispatcher?._id === technician?._id;

  return [
    {
      title: 'Driver',
      participant: {
        _id: activeDriver?._id,
        fullName:
          activeDriver?.clerkUser.fullName ??
          `${request.tempDriverFirstName || ''} ${request.tempDriverLastName || ''}`.trim(),
        phone:
          activeDriver?.clerkUser.primaryPhoneNumber.phoneNumber ??
          (request.tempDriverPhone || ''),
        imageUrl: activeDriver?.clerkUser.imageUrl,
        primaryLocationGroupId: activeDriver?.primaryLocationGroupId,
        isDeleted: activeDriver?.isDeleted,
      },
    },
    {
      title: 'Fleet Dispatcher',
      participant: {
        _id: fleetDispatcher?._id,
        fullName:
          fleetDispatcher?.clerkUser.fullName ??
          `${request.tempFleetDispatchFirstName || ''} ${request.tempFleetDispatchLastName || ''}`.trim(),
        phone:
          fleetDispatcher?.clerkUser.primaryPhoneNumber.phoneNumber ??
          (request.tempFleetDispatchPhone || ''),
        imageUrl: fleetDispatcher?.clerkUser.imageUrl,
        primaryLocationGroupId: fleetDispatcher?.primaryLocationGroupId,
        isDeleted: fleetDispatcher?.isDeleted,
      },
    },
    {
      title: 'Third Party Dispatcher',
      participant: activeBroker
        ? {
            _id: activeBroker._id,
            fullName: activeBroker.clerkUser.fullName,
            phone: activeBroker.clerkUser.primaryPhoneNumber.phoneNumber,
            imageUrl: activeBroker?.clerkUser.imageUrl,
            primaryLocationGroupId: activeBroker?.primaryLocationGroupId,
            isDeleted: activeBroker?.isDeleted,
          }
        : null,
    },
    ...(excludeServiceDispatcher
      ? []
      : [
          {
            title: 'Service Dispatcher',
            participant: serviceDispatcher
              ? {
                  _id: serviceDispatcher._id,
                  fullName: serviceDispatcher.clerkUser.fullName,
                  phone:
                    serviceDispatcher.clerkUser.primaryPhoneNumber.phoneNumber,
                  imageUrl: serviceDispatcher?.clerkUser.imageUrl,
                  primaryLocationGroupId:
                    serviceDispatcher?.primaryLocationGroupId,
                  isDeleted: serviceDispatcher?.isDeleted,
                }
              : null,
          },
        ]),
    {
      title: 'Technician',
      participant: technician
        ? {
            _id: technician._id,
            fullName: technician.clerkUser.fullName,
            phone: technician.clerkUser.primaryPhoneNumber.phoneNumber,
            imageUrl: technician?.clerkUser.imageUrl,
            primaryLocationGroupId: technician?.primaryLocationGroupId,
            isDeleted: technician?.isDeleted,
          }
        : null,
    },
  ].filter(section => section.participant !== null) as Section[];
}
