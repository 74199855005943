import { CompanyGroup } from '@cvx/types/entities/groupsEntityTypes';
import { ConvexUser } from '@cvx/types/entities/usersEntityTypes';
import { ConvexUserZodId, VehicleZodId } from '@cvx/types/zod/commonZodId';
import { z } from 'zod';
import { baseSubmissionSchema } from './base';

export function getFleetDispatcherDraftInitiationFields(
  user: ConvexUser,
  primaryLocation: CompanyGroup
) {
  return {
    fleetDispatchGroupId: primaryLocation._id,
    activeFleetDispatcherId: user._id,
  };
}

export const fleetSubmissionSchema = baseSubmissionSchema.extend({
  vehicleId: VehicleZodId,
  activeDriverId: ConvexUserZodId,
});

export type FleetSubmissionInput = z.infer<typeof fleetSubmissionSchema>;
