import {
  CompanyId,
  ConvexUserId,
  GroupId,
  ScheduledFunctionId,
  VehicleId,
} from '@cvx/types/entities/sharedIds';
import { GroupZodId, VehicleZodId } from '@cvx/types/zod/commonZodId';
import {
  assignVehicleToDriverSchema,
  createSerialVehicleInput,
  createVinVehicleInput,
  getVehicleByIdInput,
  unassignVehicleFromDriverSchema,
  updateVehicleInput,
} from '@cvx/types/zod/vehiclesZod';
import { getManyFrom, getOneFrom } from 'convex-helpers/server/relationships';
import { internal } from '../_generated/api';
import { MutationCtx, query, QueryCtx } from '../_generated/server';
import {
  VehicleLocationUpdateInput,
  vehicleLocationUpdateInput,
} from '../types/locationSchema';
import { getUserContext } from '../utils/getUserContext';
import { driverHistoryMutations } from './driverHistory';
import { zInternalMutation, zMutation, zQuery } from './helpers/zodHelpers';
import { getActiveRequestsForVehicleHelper } from './requests';

interface VehicleData {
  vin: string;
  unitNumber: string;
  [key: string]: any;
}

interface VehicleResult {
  vin?: string;
  serialNumber?: string;
  vehicleId?: VehicleId;
  scheduleId?: ScheduledFunctionId;
  error?: string;
}
export const createVinVehicleMutation = zMutation({
  args: { vehiclesInput: createVinVehicleInput },
  handler: async (
    ctx,
    { vehiclesInput: { vehicles } }
  ): Promise<{
    success: boolean;
    message: string;
    results: VehicleResult[];
  }> => {
    const { user, company, primaryLocation } = await getUserContext(ctx);

    if (!user) {
      throw new Error('User not authenticated');
    }

    const results: VehicleResult[] = [];

    for (const vehicle of vehicles) {
      try {
        // Only check for existing VIN
        const existingVehicle = await getOneFrom(
          ctx.db,
          'vehicles',
          'by_vin',
          vehicle.vin,
          'vin'
        );

        if (existingVehicle) {
          results.push({
            vin: existingVehicle.vin,
            error: `Vehicle already exists with VIN`,
          });
          continue;
        }

        const vehicleData = {
          ...vehicle,
          companyId: company._id,
          addedById: user._id,
          updatedById: user._id,
          primaryLocationGroupId:
            vehicle.primaryLocationGroupId ?? primaryLocation._id,
          status: vehicle.status,
        };

        const vehicleId = await ctx.db.insert('vehicles', {
          ...vehicleData,
          typeStatus: 'FLEET_OWNED',
        });

        // Schedule VIN decoding
        const scheduleId = await ctx.scheduler.runAfter(
          0,
          internal.actions.addVehicles.getVehicleData,
          {
            vin: vehicle.vin,
            vehicleId,
          }
        );

        results.push({
          vin: vehicle.vin,
          vehicleId,
          scheduleId,
        });
      } catch (error) {
        results.push({
          vin: vehicle.vin,
          error:
            error instanceof Error ? error.message : 'Unknown error occurred',
        });
      }
    }

    const successCount = results.filter(r => !r.error).length;
    const failureCount = results.filter(r => r.error).length;

    return {
      success: successCount > 0,
      message: `${successCount} vehicles created, ${failureCount} failed. VIN decoding in progress.`,
      results,
    };
  },
});

// For serial-based vehicles (forklifts, moffetts)
export const createSerialVehicleMutation = zMutation({
  args: { vehiclesInput: createSerialVehicleInput },
  handler: async (
    ctx,
    { vehiclesInput: { vehicles } }
  ): Promise<{
    success: boolean;
    message: string;
    results: VehicleResult[];
  }> => {
    const { user, company, primaryLocation } = await getUserContext(ctx);

    if (!user) {
      throw new Error('User not authenticated');
    }

    const results: VehicleResult[] = [];

    for (const vehicle of vehicles) {
      try {
        if (!vehicle.serialNumber) {
          throw new Error('Serial number is required');
        }

        const existingVehicle = await getOneFrom(
          ctx.db,
          'vehicles',
          'by_serialNumber',
          vehicle.serialNumber,
          'serialNumber'
        );

        if (existingVehicle) {
          results.push({
            serialNumber: existingVehicle.serialNumber,
            error: `Vehicle already exists with serial number`,
          });
          continue;
        }

        const vehicleData = {
          ...vehicle,
          companyId: company._id,
          addedById: user._id,
          updatedById: user._id,
          primaryLocationGroupId:
            vehicle.primaryLocationGroupId ?? primaryLocation._id,
          status: vehicle.status,
        };

        const vehicleId = await ctx.db.insert('vehicles', {
          ...vehicleData,
          typeStatus: 'FLEET_OWNED',
        });

        results.push({
          serialNumber: vehicle.serialNumber,
          vehicleId,
        });
      } catch (error) {
        results.push({
          serialNumber: vehicle.serialNumber,
          error:
            error instanceof Error ? error.message : 'Unknown error occurred',
        });
      }
    }

    const successCount = results.filter(r => !r.error).length;
    const failureCount = results.filter(r => r.error).length;

    return {
      success: successCount > 0,
      message: `${successCount} vehicles created, ${failureCount} failed.`,
      results,
    };
  },
});

export const getVehiclesForLocation = zQuery({
  args: { locationGroupId: GroupZodId },
  handler: async (ctx, { locationGroupId }) => {
    const { user, primaryLocation, company, roles, isDispatcher } =
      await getUserContext(ctx);

    const location = await ctx.db.get(locationGroupId);

    const authorized = isDispatcher || user.clerkUser.isSuperAdmin;

    if (
      !user ||
      !location ||
      !authorized ||
      location.companyId !== company._id
    ) {
      throw new Error('User not authenticated');
    }

    const vehicles = await getManyFrom(
      ctx.db,
      'vehicles',
      'by_primaryLocationGroupId',
      locationGroupId
    );

    return vehicles.filter(v => v.isDeleted !== true);
  },
});

export const getVehiclesForCompany = zQuery({
  handler: async ctx => {
    const { user, company, roles, isDispatcher } = await getUserContext(ctx);

    // TODO: this is hacky, but needs to be in there now in case driver hasn't been assigned the vehicle explicitly yet
    const isDriver = roles.some(r => r.type === 'DRIVER_FLEET');
    const authorized = isDispatcher || user.clerkUser.isSuperAdmin || isDriver;

    if (!user || !authorized) {
      throw new Error('User not authenticated');
    }

    const vehicles = await getManyFrom(
      ctx.db,
      'vehicles',
      'by_companyId',
      company._id
    );

    return vehicles.filter(v => v.isDeleted !== true);
  },
});

export const getAllVehiclesForAdmin = query({
  handler: async ctx => {
    const { user } = await getUserContext(ctx);

    if (!user || !user.clerkUser.isSuperAdmin) {
      throw new Error('User not authenticated');
    }

    const allVehicles = await ctx.db.query('vehicles').collect();
    return allVehicles.filter(v => v.isDeleted !== true);
  },
});

export const updateVehicleMutation = zMutation({
  args: updateVehicleInput.shape,
  handler: async (ctx, { vehicleId, ...updates }) => {
    const { user, company } = await getUserContext(ctx);
    if (!user) {
      throw new Error('User not authenticated');
    }

    const vehicle = await ctx.db.get(vehicleId);
    if (!vehicle) {
      throw new Error(`Vehicle with ID ${vehicleId} not found`);
    }

    if (vehicle.companyId !== company._id) {
      throw new Error('Vehicle does not belong to your company');
    }

    if (vehicle.primaryLocationGroupId !== updates.primaryLocationGroupId) {
      // End all vehicle assignments at current location
      await driverHistoryMutations.endAllAssignmentsForVehicle(ctx, {
        vehicleId: vehicle._id,
        updatedBy: user._id,
      });
    }

    const updatedVehicle = await ctx.db.patch(vehicleId, updates);

    return {
      status: true,
      message: 'Vehicle updated successfully',
      vehicle: updatedVehicle,
    };
  },
});

export const deleteVehicle = zMutation({
  args: { vehicleId: VehicleZodId },
  handler: async (ctx, { vehicleId }) => {
    const { user, isDispatcher, company } = await getUserContext(ctx);

    const vehicleBeingDeleted = await ctx.db.get(vehicleId);

    if (!vehicleBeingDeleted) {
      throw new Error('Vehicle not found');
    }

    // TODO: Switching to simple authz for now, but we should probably add this back in
    // const isAuthororizedDispatcher =
    //   isDispatcher &&
    //   vehicleBeingDeleted?.defaultDispatchGroupId === primaryLocation._id;

    const isAuthororizedDispatcher =
      isDispatcher && company._id === vehicleBeingDeleted.companyId;

    if (
      (user.clerkUser.isSuperAdmin || isAuthororizedDispatcher) &&
      vehicleBeingDeleted
    ) {
      const { activeRequests, draftRequests } =
        await getActiveRequestsForVehicleHelper(ctx, vehicleId);

      if (activeRequests.length > 0) {
        return {
          success: false,
          message:
            'Vehicle is referenced in active requests and cannot be deleted',
        };
      }

      await Promise.all(
        draftRequests.map(request =>
          ctx.db.patch(request._id, { vehicleId: undefined })
        )
      );

      // End all vehicle assignments at current location
      await driverHistoryMutations.endAllAssignmentsForVehicle(ctx, {
        vehicleId: vehicleBeingDeleted._id,
        updatedBy: user._id,
      });

      const deletionTime = Date.now();

      await ctx.db.patch(vehicleId, {
        isDeleted: true,
        deletedAt: deletionTime,
        updatedById: user._id,
        searchQueryText: undefined,
        vin: `${vehicleBeingDeleted.vin}_DELETED_${deletionTime}`,
        serialNumber: `${vehicleBeingDeleted.serialNumber}_DELETED_${deletionTime}`,
        status: 'OUT_OF_SERVICE', // TODO: Let the user choose this
      });

      return {
        success: true,
        message: 'Vehicle deleted successfully',
      };
    } else {
      return {
        success: false,
        message: 'Not authorized to delete vehicle',
      };
    }
  },
});

export const updateVehicleLocation = zMutation({
  args: vehicleLocationUpdateInput.shape,
  handler: async (ctx, { vehicleId, location }) => {
    // Get user context and verify authentication
    const { user, company } = await getUserContext(ctx);

    const companyId = company._id;

    // Get vehicle and verify company ownership
    const vehicle = await ctx.db.get(vehicleId);
    if (!vehicle) {
      throw new Error('Vehicle not found');
    }
    if (vehicle.companyId !== companyId) {
      throw new Error('Vehicle does not belong to your company');
    }

    // Verify user is actively assigned to this vehicle
    // const activeAssignment = await ctx.db
    //   .query('driverHistory')
    //   .withIndex('by_vehicle_user_status', q =>
    //     q
    //       .eq('vehicleId', vehicleId)
    //       .eq('userId', user._id)
    //       .eq('status', 'ACTIVE')
    //   )
    //   .first();

    // if (!activeAssignment) {
    //   throw new Error('User is not currently assigned to this vehicle');
    // }

    // TODO: Had to remove authz check above because driver MIGHT NOT be assigned yet... need to figure out how to handle this
    // Maybe if they aren't assigned, we give them a dialog where they can add the assignment... then we notify dispatch about these for verification, I don't know

    await updateDriverAndVehicleLocation(
      ctx,
      { vehicleId, location },
      user._id
    );

    return {
      success: true,
      message: 'Vehicle location updated successfully',
    };
  },
});

export const updateDriverAndVehicleLocation = (
  ctx: MutationCtx,
  vehicleLocationUpdate: VehicleLocationUpdateInput,
  driverId: ConvexUserId
) => {
  return Promise.all([
    ctx.db.patch(vehicleLocationUpdate.vehicleId, {
      location: {
        ...vehicleLocationUpdate.location,
        lastUpdated: new Date().toISOString(),
      },
      city: vehicleLocationUpdate.city,
      state: vehicleLocationUpdate.state,
      postCode: vehicleLocationUpdate.postCode,
      country: vehicleLocationUpdate.country,
      stateShortCode: vehicleLocationUpdate.stateShortCode,
      timezone: vehicleLocationUpdate.timezone,
      updatedAt: new Date().toISOString(),
      updatedById: driverId,
    }),
    ctx.db.patch(driverId, {
      location: {
        ...vehicleLocationUpdate.location,
        lastUpdated: new Date().toISOString(),
      },
      city: vehicleLocationUpdate.city,
      state: vehicleLocationUpdate.state,
      postCode: vehicleLocationUpdate.postCode,
      country: vehicleLocationUpdate.country,
      stateShortCode: vehicleLocationUpdate.stateShortCode,
      timezone: vehicleLocationUpdate.timezone,
      updatedAt: new Date().toISOString(),
    }),
  ]);
};

/** Special internal vehicle mutation for updating vehicle after vin decoded with decoded info */
export const updateVehicleMutationInternal = zInternalMutation({
  args: updateVehicleInput.shape,
  handler: async (ctx, { vehicleId, ...updates }) => {
    const vehicle = await ctx.db.get(vehicleId);
    if (!vehicle) {
      throw new Error(`Vehicle with ID ${vehicleId} not found`);
    }

    await ctx.db.patch(vehicleId, updates);

    return {
      status: true,
      message: 'Vehicle updated successfully',
    };
  },
});

export const getVehicleById = zQuery({
  args: getVehicleByIdInput.shape,
  handler: async (ctx, { vehicleId }) => {
    const { user, company } = await getUserContext(ctx);

    const companyId = company._id;

    if (!user) {
      throw new Error('User not authenticated');
    }

    const vehicle = await ctx.db.get(vehicleId);

    if (!vehicle) {
      throw new Error(`Vehicle with ID ${vehicleId} not found`);
    }

    // TODO: removing this for now, but we should probably add it back in
    // if (vehicle.companyId !== companyId) {
    //   throw new Error('Vehicle does not belong to your company');
    // }

    return vehicle;
  },
});

export const deleteVehicleMutation = zMutation({
  args: getVehicleByIdInput.shape,
  handler: async (ctx, { vehicleId }) => {
    const { user, company } = await getUserContext(ctx);

    if (!user) {
      throw new Error('User not authenticated');
    }

    const vehicle = await ctx.db.get(vehicleId);

    if (!vehicle) {
      throw new Error(`Vehicle with ID ${vehicleId} not found`);
    }

    if (vehicle.companyId !== company._id) {
      throw new Error('Vehicle does not belong to your company');
    }

    await ctx.db.delete(vehicleId);

    return {
      status: true,
      message: 'Vehicle deleted successfully',
    };
  },
});

// todo: add photos to vehicle mutation

export const assignVehicleToDriver = zMutation({
  args: assignVehicleToDriverSchema.shape,
  async handler(ctx, { driverId, vehicleId }) {
    const { user, company } = await getUserContext(ctx);

    if (!user) {
      throw new Error('User not authenticated');
    }

    // Validate driver and vehicle existence and ownership
    const [driver, vehicle] = await Promise.all([
      ctx.db.get(driverId),
      ctx.db.get(vehicleId),
    ]);

    if (!driver) {
      throw new Error('Driver not found');
    }

    if (!vehicle) {
      throw new Error('Vehicle not found');
    }

    if (driver.companyId !== company._id) {
      throw new Error('Driver does not belong to your company');
    }

    if (vehicle.companyId !== company._id) {
      throw new Error('Vehicle does not belong to your company');
    }

    // Check if the driver is already assigned to the same vehicle with status
    const activeAssignment = await ctx.db
      .query('driverHistory')
      .withIndex('by_vehicle_user_status', q =>
        q
          .eq('vehicleId', vehicleId)
          .eq('userId', driverId)
          .eq('status', 'ACTIVE')
      )
      .first();

    if (activeAssignment) {
      throw new Error('Driver is already assigned to this vehicle');
    }

    // Create a new driver assignment in the `driverHistory` table
    await ctx.db.insert('driverHistory', {
      userId: driverId,
      vehicleId: vehicleId,
      started: new Date().toISOString(),
      status: 'ACTIVE',
      createdBy: user._id,
      updatedBy: user._id,
    });

    // Notify the driver of the new assignment
    await ctx.scheduler.runAfter(
      0,
      internal.actions.sendEmail.sendEmailUsingLocalTemplates,
      {
        to: driver.clerkUser.primaryEmailAddress?.emailAddress ?? '',
        emailType: 'NOTIFICATION',
        data: {
          userName: driver.clerkUser.firstName ?? '',
          subject: 'You Have Been Assigned A Vehicle',
          additionalContext: `You have been assigned a vehicle in the myMechanic App (Unit: ${vehicle.unitNumber})`,
          alternateRelativeUrl: '/',
          companyInitiatingNotification: company.name,
        },
      }
    );

    return {
      success: true,
      message: 'Truck assigned to driver successfully',
    };
  },
});

export const unassignVehicleFromDriver = zMutation({
  args: unassignVehicleFromDriverSchema.shape,
  async handler(ctx, { vehicleId, driverIds }) {
    const { user, company } = await getUserContext(ctx);

    if (!user) {
      throw new Error('User not authenticated');
    }

    // Check if the vehicle exists
    const vehicle = await ctx.db.get(vehicleId);
    if (!vehicle) {
      throw new Error('Vehicle not found');
    }

    if (vehicle.companyId !== company._id) {
      throw new Error('Vehicle does not belong to your company');
    }

    // Find active assignments for the specified drivers
    const activeAssignments = await Promise.all(
      driverIds.map(driverId =>
        ctx.db
          .query('driverHistory')
          .withIndex('by_vehicle_user_status', q =>
            q
              .eq('vehicleId', vehicleId)
              .eq('userId', driverId)
              .eq('status', 'ACTIVE')
          )
          .first()
      )
    );

    const validAssignments = activeAssignments.filter(
      (assignment): assignment is NonNullable<typeof assignment> =>
        assignment !== null
    );

    if (validAssignments.length === 0) {
      throw new Error('No active assignments found for the specified drivers');
    }

    // End assignments for specified drivers
    await Promise.all(
      validAssignments.map(async assignment => {
        await ctx.db.patch(assignment._id, {
          status: 'INACTIVE',
          stopped: new Date().toISOString(),
          updatedBy: user._id,
        });

        // Fetch the driver details and send notification
        const driver = await ctx.db.get(assignment.userId);
        if (driver) {
          await ctx.scheduler.runAfter(
            0,
            internal.actions.sendEmail.sendEmailUsingLocalTemplates,
            {
              to: driver.clerkUser.primaryEmailAddress?.emailAddress ?? '',
              emailType: 'NOTIFICATION',
              data: {
                userName: driver.clerkUser.firstName ?? '',
                subject: 'You Have Been Unassigned From A Vehicle',
                additionalContext: `You have been unassigned from a vehicle in the myMechanic App (Unit: ${vehicle.unitNumber})`,
                alternateRelativeUrl: '/',
                companyInitiatingNotification: company.name,
              },
            }
          );
        }
      })
    );

    return {
      success: true,
      message: `Truck unassigned from ${validAssignments.length} driver(s) successfully`,
    };
  },
});

// Helper functions for common vehicle location operations
export const vehicleLocationHelpers = {
  /**
   * Gets a vehicle's current primary location (HOME_TERMINAL)
   */
  async getCurrentHomeTerminal(ctx: QueryCtx, vehicleId: VehicleId) {
    return await ctx.db
      .query('vehicleLocationHistory')
      .withIndex('by_vehicleId', q => q.eq('vehicleId', vehicleId))
      .filter(q =>
        q.and(
          q.eq(q.field('assignmentType'), 'HOME_TERMINAL'),
          q.eq(q.field('endedAt'), undefined)
        )
      )
      .first();
  },

  /**
   * Gets all current locations for a vehicle (including temporary assignments)
   */
  async getAllCurrentLocations(ctx: QueryCtx, vehicleId: VehicleId) {
    return await ctx.db
      .query('vehicleLocationHistory')
      .withIndex('by_vehicleId', q => q.eq('vehicleId', vehicleId))
      .filter(q => q.eq(q.field('endedAt'), undefined))
      .collect();
  },

  /**
   * Gets all vehicles currently assigned to a location
   */
  async getLocationVehicles(ctx: QueryCtx, locationGroupId: GroupId) {
    return await ctx.db
      .query('vehicleLocationHistory')
      .withIndex('by_locationGroupId', q =>
        q.eq('locationGroupId', locationGroupId)
      )
      .filter(q => q.eq(q.field('endedAt'), undefined))
      .collect();
  },

  /**
   * Updates a vehicle's home terminal
   */
  async updateHomeTerminal(
    ctx: MutationCtx,
    vehicleId: VehicleId,
    newLocationGroupId: GroupId,
    companyId: CompanyId,
    userId: ConvexUserId
  ) {
    // End current home terminal assignment if exists
    const currentHome = await this.getCurrentHomeTerminal(ctx, vehicleId);
    if (currentHome) {
      await ctx.db.patch(currentHome._id, {
        endedAt: Date.now(),
      });
    }

    // Create new home terminal assignment
    await ctx.db.insert('vehicleLocationHistory', {
      vehicleId,
      locationGroupId: newLocationGroupId,
      locationCompanyId: companyId,
      startedAt: Date.now(),
      assignmentType: 'HOME_TERMINAL',
      assignedById: userId,
      companyId,
    });
  },

  /**
   * Creates a temporary location assignment
   */
  async createTemporaryAssignment(
    ctx: MutationCtx,
    vehicleId: VehicleId,
    locationGroupId: GroupId,
    companyId: CompanyId,
    userId: ConvexUserId,
    expectedDuration: {
      startDate: number;
      endDate: number;
      reason: string;
    }
  ) {
    await ctx.db.insert('vehicleLocationHistory', {
      vehicleId,
      locationGroupId,
      locationCompanyId: companyId,
      startedAt: Date.now(),
      assignmentType: 'TEMPORARY',
      expectedDuration,
      assignedById: userId,
      companyId,
    });
  },

  /**
   * Records a borrowed vehicle arrangement
   */
  async recordBorrowedVehicle(
    ctx: MutationCtx,
    vehicleId: VehicleId,
    borrowedFromGroupId: GroupId,
    borrowedFromCompanyId: CompanyId,
    borrowingGroupId: GroupId,
    borrowingCompanyId: CompanyId,
    userId: ConvexUserId,
    agreement: {
      agreementNumber: string;
      startDate: number;
      endDate: number;
      contactName: string;
      contactPhone?: string;
      contactEmail?: string;
      notes?: string;
    }
  ) {
    // Record at the lending location
    await ctx.db.insert('vehicleLocationHistory', {
      vehicleId,
      locationGroupId: borrowedFromGroupId,
      locationCompanyId: borrowedFromCompanyId,
      assignmentType: 'BORROWED_FROM',
      startedAt: Date.now(),
      agreementDetails: agreement,
      assignedById: userId,
      companyId: borrowedFromCompanyId,
    });

    // Record at the borrowing location
    await ctx.db.insert('vehicleLocationHistory', {
      vehicleId,
      locationGroupId: borrowingGroupId,
      locationCompanyId: borrowingCompanyId,
      originatingLocationGroupId: borrowedFromGroupId,
      originatingCompanyId: borrowedFromCompanyId,
      assignmentType: 'BORROWED_BY',
      startedAt: Date.now(),
      agreementDetails: agreement,
      assignedById: userId,
      companyId: borrowingCompanyId,
    });
  },
};
