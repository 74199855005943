import { Grid, Typography } from '@mui/material';
import { BillingMetricsValues } from 'src/convex/schema/entities/billing/billingMetrics';
import { CompanyType } from 'src/convex/schema/enums/companyType';
import { StatCard } from 'src/sections/admin/StatCard';

type MetricStatsProps = {
  metrics: BillingMetricsValues;
  variant?: 'card' | 'inline';
  companyType?: CompanyType;
};

type Stats = { label: string; value: number; show: boolean };

export function MetricStats({
  metrics,
  variant = 'card',
  companyType,
}: MetricStatsProps) {
  const stats: Stats[] = [
    {
      label: `${!companyType ? 'Total Unique System ' : ''}Transactions`,
      value: metrics.totalTransactions,
      show: true,
    },
    {
      label: 'Total Billable System Transactions',
      value: metrics.totalBillableSystemTransactions || 0,
      show: !companyType,
    },
    {
      label: 'Units',
      value: metrics.uniqueUnits,
      show: companyType === 'FLEET' || !companyType,
    },
    {
      label: 'Techs',
      value: metrics.uniqueTechs,
      show: companyType === 'REPAIR_SHOP' || !companyType,
    },
    {
      label: 'Dispatchers',
      value: metrics.uniqueCallCenterDispatchers,
      show: companyType === 'THIRD_PARTY' || !companyType,
    },
    {
      label: 'Directory Listings',
      value: metrics.uniqueServiceProviderLocationsInDirectory,
      show: companyType === 'REPAIR_SHOP' || !companyType,
    },
  ];

  const filteredStats = stats.filter(s => s.show);

  if (variant === 'inline') {
    return (
      <Typography variant="body2" color="text.secondary">
        {filteredStats
          .map(stat => `${stat.label}: ${stat.value.toLocaleString()}`)
          .join(' • ')}
      </Typography>
    );
  }

  return (
    <Grid container spacing={3}>
      {filteredStats.map(({ label, value }) => (
        <Grid item xs={12} sm={6} md={2.4} key={label}>
          <StatCard title={label} value={value} />
        </Grid>
      ))}
    </Grid>
  );
}
