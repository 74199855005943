import { api } from '@cvx/api';
import { EnhancedUser } from '@cvx/functions/users';
import { EnhancedRequest } from '@cvx/types/entities/requestsEntityTypes';
import { useQuery } from 'convex/react';
import { useMemo } from 'react';
import { ImageCategory } from 'src/convex/schema/entities/requests/requestImages';

type PhotoValidationResult = {
  isValid: boolean;
  missingCategories: ImageCategory[];
  existingCategories: ImageCategory[];
  isDotServiceRequired: boolean;
  isLoading: boolean;
};

export const useRequiredPhotosValidation = (
  request: EnhancedRequest,
  me: EnhancedUser
): PhotoValidationResult => {
  const { servicesRequiredByFleet } = request;
  const { myCompanySettings } = me;

  const services = useQuery(
    api.functions.serviceCategories.getServicesGroupedByCategory,
    {}
  );

  // Find the DOT service
  const dotService = services
    ?.find(group => group.category.name === 'Inspections')
    ?.services.find(
      service => service.name === 'Federal DOT Inspection (US ONLY)'
    );

  const isDotServiceRequired = servicesRequiredByFleet?.some(
    serviceId => serviceId === dotService?._id
  );

  const existingPhotos = useQuery(
    api.functions.requestImages.getAccessibleRequestPhotos,
    {
      requestId: request._id,
    }
  );

  const isLoading = services === undefined || existingPhotos === undefined;

  const confirmedPhotos = useMemo(
    () => existingPhotos?.filter(photo => !!photo.confirmed) || [],
    [existingPhotos]
  );

  return useMemo(() => {
    // If DOT service is not required or mandatory image requirements are disabled,
    // return valid with no missing categories
    if (
      !isDotServiceRequired ||
      !myCompanySettings.mandatoryImageRequirements
    ) {
      return {
        isValid: true,
        missingCategories: [],
        existingCategories: [],
        isDotServiceRequired: false,
        isLoading,
      };
    }

    // Determine which photo categories are required based on company settings
    const requiredCategories: ImageCategory[] = [];

    // Add categories based on company settings
    if (myCompanySettings.dotFormPhotoMandatory) {
      requiredCategories.push('WORK_ORDER');
    }

    if (myCompanySettings.dotUnitNumberPhotoMandatory) {
      requiredCategories.push('UNIT_NUMBER');
    }

    if (myCompanySettings.tagStickerPhotoMandatory) {
      requiredCategories.push('LICENSE_PLATE');
    }

    // If no photo categories are required by settings, return valid
    if (requiredCategories.length === 0) {
      return {
        isValid: true,
        missingCategories: [],
        existingCategories: [],
        isDotServiceRequired,
        isLoading,
      };
    }

    // Check which required categories already have confirmed photos
    const existingCategories: ImageCategory[] = [];
    requiredCategories.forEach(category => {
      const hasPhoto = confirmedPhotos.some(
        photo => photo.category === category
      );
      if (hasPhoto) {
        existingCategories.push(category);
      }
    });

    // Determine which categories are missing
    const missingCategories = requiredCategories.filter(
      category => !existingCategories.includes(category)
    );

    // Request is valid if there are no missing required categories
    const isValid = missingCategories.length === 0;

    return {
      isValid,
      missingCategories,
      existingCategories,
      isDotServiceRequired,
      isLoading,
    };
  }, [confirmedPhotos, isDotServiceRequired, isLoading, myCompanySettings]);
};
