import { api } from '@cvx/api';
import { Id } from '@cvx/dataModel';
import { createFileRoute } from '@tanstack/react-router';
import Page from 'src/pages/dashboard/invoice/edit';
import {
  checkRouteAccess,
  routeAccessConfigs,
} from 'src/utils/checkRouteAccess';
import { handleConvexRouteQuery } from 'src/utils/handleConvexRouteQuery';

export const Route = createFileRoute('/_auth/dashboard/invoices/$invoiceId')({
  beforeLoad: ({ context }) => {
    // throw notFound(); // TODO: temporary disablement of invoices to merge
    checkRouteAccess(context, {
      ...routeAccessConfigs.dispatcherGeneral,
      allowSoleProprietor: true,
    });
  },

  loader: async ({ context, params }) => {
    return handleConvexRouteQuery(
      () =>
        context.convex.query(api.functions.invoices.getInvoiceById, {
          invoiceId: params.invoiceId as Id<'invoices'>,
        }),
      { redirectTo: '/dashboard/invoices' }
    );
  },
  component: Page,
});
