import { redirect } from '@tanstack/react-router';
import { UserRole } from 'src/convex/schema/enums/userRole';
import { ValidPaths } from 'src/minimal-theme/layouts/config-nav-dashboard';
import { RouterContext } from 'src/routes/__root';

type AccessConfig = {
  allowSuperAdmin?: boolean;
  allowImpersonation?: boolean;
  allowedRoles?: UserRole[];
  allowSoleProprietor?: boolean;
  allowAllRoles?: boolean;
  allowCompanyPrimaryAdmin?: boolean;
  requireFleetOnly?: boolean;
  requireServiceProviderOnly?: boolean;
  redirectTo?: string;
};

export const checkRouteAccess = (
  context: RouterContext,
  config: AccessConfig = {},
  path?: ValidPaths
) => {
  const {
    allowSuperAdmin = false,
    allowCompanyPrimaryAdmin = false,
    allowImpersonation = true,
    allowedRoles = [],
    requireFleetOnly = false,
    requireServiceProviderOnly = false,
    allowAllRoles = false,
    redirectTo = '/dashboard',
    allowSoleProprietor = false,
  } = config;

  const {
    user,
    me,
    impersonatingFleet,
    impersonatingServiceProvider,
    impersonatingThirdParty,
  } = context;

  if (!user || !me) {
    throw redirect({ to: redirectTo });
  }

  const nonSubscribedUser =
    !me?.company.accountExecutiveId && !me?.clerkUser.isSuperAdmin;

  if (nonSubscribedUser && !path?.includes('/requests')) {
    throw redirect({ to: redirectTo });
  }

  // Handle super admin access
  const isSuperAdmin = user?.publicMetadata.isSuperAdmin;
  const isCompanyPrimaryAdmin = me?.isCompanyPrimaryAdmin;
  const isImpersonating =
    impersonatingFleet ||
    impersonatingServiceProvider ||
    impersonatingThirdParty;
  const currentRole = me?.primaryRoleType as UserRole;

  if (
    me.primaryLocation.noDispatchSoleProprietor &&
    path?.includes('dispatchers')
  ) {
    throw redirect({ to: redirectTo });
  }

  // Super admin checks
  if (isSuperAdmin) {
    if (!allowSuperAdmin || (!allowImpersonation && isImpersonating)) {
      throw redirect({ to: redirectTo });
    }

    if (requireFleetOnly && !impersonatingFleet) {
      throw redirect({ to: redirectTo });
    }
    if (requireServiceProviderOnly && !impersonatingServiceProvider) {
      throw redirect({ to: redirectTo });
    }

    return;
  }

  // Company primary admin checks
  if (isCompanyPrimaryAdmin && allowCompanyPrimaryAdmin) {
    return;
  }

  // Role-based access checks
  if (allowAllRoles) {
    return;
  }

  // Specific role requirement checks
  if (requireServiceProviderOnly) {
    if (currentRole !== 'SERVICE_DISPATCHER') {
      throw redirect({ to: redirectTo });
    }
    return;
  }

  if (requireFleetOnly) {
    // TODO: Better way to add conditional additional role access
    const driverAllowed =
      path?.includes('units') &&
      currentRole === 'DRIVER_FLEET' &&
      me.primaryLocation.noDispatchSoleProprietor;

    console.log(currentRole);
    console.log(me.primaryLocation);
    console.log(`driverAllowed`, driverAllowed);
    if (currentRole !== 'FLEET_DISPATCHER' && !driverAllowed) {
      throw redirect({ to: redirectTo });
    }
    return;
  }

  if (allowSoleProprietor) {
    return;
  }

  // Allowed roles check
  if (allowedRoles.length > 0) {
    const isRoleAllowed = allowedRoles.includes(currentRole);

    if (!isRoleAllowed) {
      throw redirect({ to: redirectTo });
    }
    return;
  }

  // Final check for configs that require either super admin or company primary admin
  if (
    (allowSuperAdmin && !isSuperAdmin) ||
    (allowCompanyPrimaryAdmin && !isCompanyPrimaryAdmin)
  ) {
    throw redirect({ to: redirectTo });
  }
};

type RouteConfigs = {
  allRolesExceptSuperAdmin: AccessConfig;
  dispatcherGeneral: AccessConfig;
  fleetOnly: AccessConfig;
  serviceProviderOnly: AccessConfig;
  noSuperAdmin: AccessConfig;
  onlySuperAdmin: AccessConfig;
  superAdminAndCompanyPrimaryAdmin: AccessConfig;
};

export const routeAccessConfigs: RouteConfigs = {
  allRolesExceptSuperAdmin: {
    allowAllRoles: true,
  },
  dispatcherGeneral: {
    allowSuperAdmin: true,
    allowImpersonation: true,
    allowedRoles: [
      'FLEET_DISPATCHER',
      'SERVICE_DISPATCHER',
      'THIRD_PARTY_DISPATCHER',
    ],
  },
  fleetOnly: {
    allowSuperAdmin: true,
    allowImpersonation: true,
    requireFleetOnly: true,
    allowedRoles: ['FLEET_DISPATCHER'],
  },
  serviceProviderOnly: {
    allowSuperAdmin: true,
    allowImpersonation: true,
    requireServiceProviderOnly: true,
    allowedRoles: ['SERVICE_DISPATCHER'],
  },
  noSuperAdmin: {
    allowSuperAdmin: false,
    allowedRoles: ['FLEET_DISPATCHER', 'SERVICE_DISPATCHER'],
  },
  onlySuperAdmin: {
    allowSuperAdmin: true,
  },
  superAdminAndCompanyPrimaryAdmin: {
    allowSuperAdmin: true,
    allowCompanyPrimaryAdmin: true,
  },
};
