import { api } from '@cvx/api';
import { useMutation, useQuery } from 'convex/react';
import { useEffect, useState } from 'react';
import { LoadingScreen } from 'src/minimal-theme/components/loading-screen';

export function StripeRefreshView() {
  const me = useQuery(api.functions.users.getMe);
  const location = useQuery(
    api.functions.companies.getLocationById,
    me?.primaryLocationGroupId ? { id: me.primaryLocationGroupId } : 'skip'
  );

  const [initialLink, setInitialLink] = useState<string | null>(null);

  const generateRefreshLink = useMutation(
    api.functions.invoices.generateNewAccountLink
  );

  useEffect(() => {
    const handleGenerateRefreshLink = async () => {
      if (
        location &&
        !initialLink &&
        location.stripeConnectedAccountOnboardingLink
      ) {
        setInitialLink(location.stripeConnectedAccountOnboardingLink);
        try {
          await generateRefreshLink({});
        } catch (error) {
          // TODO: RF - handle error
        }
      }
    };
    handleGenerateRefreshLink();
  }, [location]);

  useEffect(() => {
    if (
      location &&
      initialLink &&
      location.stripeConnectedAccountOnboardingLink !== initialLink
    ) {
      if (location.stripeConnectedAccountOnboardingLink) {
        // Once the new refresh link has been generated, we get here and can redirect to it
        window.location.href = location.stripeConnectedAccountOnboardingLink;
      }
    }
  }, [location]);

  return <LoadingScreen />;
}
