import {
  Alert,
  AlertColor,
  Box,
  Button,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Stack,
  Tooltip,
} from '@mui/material';

import { useAuth } from '@clerk/clerk-react';
import { api } from '@cvx/api';
import { EnhancedRequest } from '@cvx/types/entities/requestsEntityTypes';
import { useMutation } from 'convex/react';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { InvoiceWithItems } from 'src/convex/functions/invoices';
import { ServiceProviderResult } from 'src/convex/functions/providers';
import { EnhancedUser } from 'src/convex/functions/users';
import { getStepTypeDisplay } from 'src/convex/schema/entities/requests/requests';
import { useScheduledActions } from 'src/hooks/useScheduledActions';
import { usePopover } from 'src/minimal-theme/components/custom-popover';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { AcceptRequestAction } from 'src/sections/request/actions/AcceptRequestAction';
import { ArrivedAtLocationAction } from 'src/sections/request/actions/ArrivedAtLocationAction';
import { CountdownDisplay } from 'src/sections/request/actions/CountdownDisplay';
import { DeclineAssignmentAction } from 'src/sections/request/actions/DeclineAssignmentAction';
import { DeclineRequestAction } from 'src/sections/request/actions/DeclineRequestAction';
import { DriverConfirmLocationAction } from 'src/sections/request/actions/DriverConfirmLocationAction';
import { DriverStaticShareLocation } from 'src/sections/request/actions/DriverStaticShareLocation';
import { ElapsedDisplay } from 'src/sections/request/actions/ElapsedDisplay';
import { FindMechanicAction } from 'src/sections/request/actions/FindMechanicAction';
import { FindServiceProviderAction } from 'src/sections/request/actions/FindServiceProviderAction';
import { TechnicianCompleteWorkAction } from 'src/sections/request/actions/TechnicianCompleteWorkAction';
import { TechnicianConfirmEnRouteAction } from 'src/sections/request/actions/TechnicianConfirmEnRouteAction';
import { TechnicianStartWorkAction } from 'src/sections/request/actions/TechnicianStartWorkAction';
import { getRequestStateInformation } from 'src/sections/request/actions/utils/getRequestStateInformation';
import { VerifyWorkCompletionAction } from 'src/sections/request/actions/VerifyWorkCompletionAction';
import { RequestFormWrapper } from 'src/sections/request/RequestFormWrapper';
import { downloadServiceRequestPdf } from 'src/sections/request/utils/downloadServiceRequestPdf';
import { CancelRequestDialog } from 'src/sections/request/view/CancelRequestDialog';
import { getRequestStatusColor } from 'src/utils/helper';
import { NavigateToLocationAction } from '../actions/NavigateToLocationAction';

export type InvitingUser = {
  dialogOpen: boolean;
  selectedServiceProvider: ServiceProviderResult;
};

type RequestStepInformationProps = {
  request: EnhancedRequest;
  me: EnhancedUser;
  invoices: InvoiceWithItems[];
};

export const RequestStepInformation = ({
  me,
  request,
  invoices,
}: RequestStepInformationProps) => {
  const { getToken } = useAuth();

  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState<boolean>(false);

  // Set what the PDF ID is initially, so we can tell when we've finished generating a new one
  const [pdfId, setPdfId] = useState(request.serviceRequestPdfStorageId);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generated, setGenerated] = useState(false);

  const { watchIds } = useScheduledActions({
    hideSuccessMessage: true,
  });

  const popover = usePopover();

  const requestStateInformation = getRequestStateInformation(
    request,
    me,
    invoices
  );

  const handleDownloadPdf = useCallback(async () => {
    await downloadServiceRequestPdf({
      requestId: request._id,
      getToken,
    });

    setIsGenerating(false);
  }, [request._id, getToken]);

  useEffect(() => {
    const handleWatchPdfGeneration = async () => {
      // If we were generating a PDF and now have a new ID different from the initial one
      if (isGenerating && request.serviceRequestPdfStorageId !== pdfId) {
        setPdfId(request.serviceRequestPdfStorageId);

        await handleDownloadPdf();
      }
    };

    void handleWatchPdfGeneration();
  }, [
    request.serviceRequestPdfStorageId,
    pdfId,
    isGenerating,
    handleDownloadPdf,
  ]);

  const generatePdf = useMutation(
    api.functions.requests.generateServiceRequestPdf
  );

  const handleGeneratePdf = async () => {
    setIsGenerating(true);

    if (generated) {
      await handleDownloadPdf();
      return;
    }
    const resp = await generatePdf({ requestId: request._id });

    if (resp.success) {
      await watchIds([resp.scheduleId]);

      setGenerated(true);
    }
  };

  const {
    isFleetDispatcher,
    isServiceDispatcher,
    isThirdPartyDispatcher,
    isDriver,
    isTechnician,
  } = requestStateInformation;

  const canEdit =
    (isFleetDispatcher && me._id === request.activeFleetDispatcherId) ||
    (isServiceDispatcher && me._id === request.activeServiceDispatcherId) ||
    (isThirdPartyDispatcher &&
      me._id === request.activeBrokerageDispatcherId) ||
    (isDriver &&
      me._id === request.activeDriverId &&
      !request.activeFleetDispatcherId);

  const hasPermissionsToOtherActions =
    canEdit ||
    (isDriver && me._id === request.activeDriverId) ||
    (isTechnician && me._id === request.activeTechnicianId);

  const actionProps = { request, me, requestStateInformation, invoices };

  return (
    <Stack width="100%" spacing={2}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} width="100%">
        <Stack width={{ xs: '100%', md: '70%', lg: '60%' }} spacing={2}>
          {request.currentStepType && (
            <Alert
              sx={{ width: '100%', fontWeight: 600 }}
              variant="outlined"
              severity={getRequestStatusColor(request.status) as AlertColor}
              // action={
              //   (request.status === 'COMPLETED' ||
              //     request.status === 'CANCELLED') && (
              //     <LoadingButton
              //       variant="outlined"
              //       onClick={handleGeneratePdf}
              //       disabled={isGenerating}
              //       loading={isGenerating}
              //     >
              //       Generate PDF
              //     </LoadingButton>
              //   )
              // }
            >
              {getStepTypeDisplay(request.currentStepType)}
            </Alert>
          )}
        </Stack>
        <Stack
          width={{ xs: '100%', md: '30%', lg: '40%' }}
          spacing={1}
          direction="row"
          sx={{ justifyContent: 'center' }}
        >
          <Tooltip
            title={canEdit ? '' : 'Only participating dispatchers can edit'}
            placement="top"
            sx={{ fontWeight: 700, fontSize: '1rem' }}
          >
            <Box sx={{ width: '100%', height: '100%' }}>
              <Button
                variant="contained"
                fullWidth
                sx={{ width: '100%', height: '100%' }}
                size="large"
                disabled={!canEdit}
                onClick={() => setIsEditDialogOpen(true)}
                startIcon={
                  <Iconify icon="solar:pen-new-round-bold-duotone" width={24} />
                }
              >
                Edit Details
              </Button>
            </Box>
          </Tooltip>
          {hasPermissionsToOtherActions &&
            request.status !== 'COMPLETED' &&
            request.status !== 'CANCELLED' && (
              <Tooltip
                title={'Other Actions'}
                placement="top"
                sx={{ fontWeight: 700, fontSize: '1rem' }}
              >
                <Box sx={{ height: '100%' }}>
                  <Button
                    variant="contained"
                    onClick={popover.onOpen}
                    fullWidth
                    sx={{ width: '100%', height: '100%' }}
                  >
                    <Iconify icon="solar:menu-dots-square-broken" width={24} />
                  </Button>
                </Box>
              </Tooltip>
            )}
          <Popover
            open={popover.open}
            anchorEl={popover.anchorEl}
            onClose={() => {
              popover.onClose();
            }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <MenuList>
              <MenuItem
                onClick={() => {
                  popover.onClose();
                  setIsCancelDialogOpen(true);
                }}
              >
                <Iconify icon="mingcute:close-line" />
                <ListItemText
                  primary="Cancel Request"
                  primaryTypographyProps={{
                    noWrap: true,
                    typography: 'body2',
                  }}
                  secondaryTypographyProps={{ typography: 'caption' }}
                />
              </MenuItem>
            </MenuList>
          </Popover>
        </Stack>
      </Stack>
      {request.status !== 'COMPLETED' && request.status !== 'CANCELLED' && (
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} width="100%">
          {/* Primary Action Buttons */}
          <Box width={{ xs: '100%', md: '70%', lg: '60%' }}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              sx={{ height: '100%' }}
            >
              <AcceptRequestAction {...actionProps} />
              <DeclineRequestAction {...actionProps} />
              <DeclineAssignmentAction {...actionProps} />
              <DriverConfirmLocationAction {...actionProps} />
              <FindServiceProviderAction {...actionProps} />
              <FindMechanicAction {...actionProps} />
              <NavigateToLocationAction {...actionProps} />
              <ArrivedAtLocationAction {...actionProps} />
              <TechnicianConfirmEnRouteAction {...actionProps} />
              <TechnicianStartWorkAction {...actionProps} />
              <TechnicianCompleteWorkAction {...actionProps} />
              <VerifyWorkCompletionAction {...actionProps} />
              <DriverStaticShareLocation {...actionProps} />
            </Stack>
          </Box>

          <Stack width={{ xs: '100%', md: '30%', lg: '40%' }} direction="row">
            {requestStateInformation.isServiceProviderQueue ? (
              <CountdownDisplay
                heading="Time Until Returned To Customer"
                startTimestamp={dayjs(request.currentStepStartedAt).valueOf()}
              />
            ) : (
              <ElapsedDisplay
                heading="Time Since Last Update"
                startTimestamp={dayjs(request.currentStepStartedAt).valueOf()}
              />
            )}
          </Stack>
        </Stack>
      )}

      {/* {(request.status === 'COMPLETED' || request.status === 'CANCELLED') && (
        <Box
          width={{ xs: '100%', md: '70%', lg: '60%' }}
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <Stack direction="row" spacing={2}>
            <CreateInvoiceAction {...actionProps} />
            <PayInvoiceAction {...actionProps} />
          </Stack>
          <StripeSetupAlerts
            requestStateShortCode={request.stateShortCode}
            requestCountryCode={request.countryCode}
            me={me}
            inRequest
          />
        </Box>
      )} */}
      <RequestFormWrapper
        request={request}
        me={me}
        isDialog
        open={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
      />
      <CancelRequestDialog
        open={isCancelDialogOpen}
        onClose={() => setIsCancelDialogOpen(false)}
        request={request}
      />
    </Stack>
  );
};
