import { api } from '@cvx/api';
import { VehicleId } from '@cvx/types/entities/sharedIds';
import { createFileRoute } from '@tanstack/react-router';
import { ValidPaths } from 'src/minimal-theme/layouts/config-nav-dashboard';
import Page from 'src/pages/dashboard/unit/edit';
import {
  checkRouteAccess,
  routeAccessConfigs,
} from 'src/utils/checkRouteAccess';

export const Route = createFileRoute('/_auth/dashboard/units/$unitId')({
  beforeLoad: ({ context, location }) =>
    checkRouteAccess(
      context,
      routeAccessConfigs.fleetOnly,
      location.pathname as ValidPaths
    ),
  loader: async ({ context, params }) =>
    await context.convex.query(api.functions.vehicles.getVehicleById, {
      vehicleId: params.unitId as VehicleId,
    }),
  component: Page,
});
