import { SignInButton, useClerk, useUser } from '@clerk/clerk-react';
import { api } from '@cvx/api';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import type { IconButtonProps } from '@mui/material/IconButton';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { styled, useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useNavigate } from '@tanstack/react-router';
import { useConvexAuth, useMutation, useQuery } from 'convex/react';
import { useCallback, useState } from 'react';
import { toast } from 'sonner';
import { getUserRoleDisplay } from 'src/convex/schema/enums/userRole';
import { AnimateAvatar } from 'src/minimal-theme/components/animate';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { Scrollbar } from 'src/minimal-theme/components/scrollbar';
import { useBoolean } from 'src/minimal-theme/hooks/use-boolean';
import { AvatarUploadDialog } from 'src/minimal-theme/layouts/components/AvatarUploadDialog';
import { varAlpha } from 'src/minimal-theme/theme/styles';
import { accountNavData } from '../config-nav-account';
import { AccountButton } from './account-button';

// ----------------------------------------------------------------------

export const AvatarWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  cursor: 'pointer',
  '&:hover .overlay': {
    opacity: 1,
  },
}));

export const HoverOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#2c6dca',
  borderRadius: '50%',
  opacity: 0,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
  cursor: 'pointer',
  '&:hover': {
    cursor: 'pointer',
  },
  zIndex: 1,
}));

export type AccountDrawerProps = IconButtonProps & {
  data?: {
    label: string;
    href: string;
    icon?: React.ReactNode;
    info?: React.ReactNode;
  }[];
};

export function AccountDrawer({ data = [], sx, ...other }: AccountDrawerProps) {
  const theme = useTheme();

  const { isAuthenticated, isLoading } = useConvexAuth();

  const profileImageUploadDialog = useBoolean();

  const { user: clerkUser } = useUser();

  const user = useQuery(
    api.functions.users.getMe,
    isAuthenticated ? {} : 'skip'
  );

  const clearImpersonation = useMutation(
    api.functions.superAdmin.clearImpersonation
  );

  const [open, setOpen] = useState(false);

  const { client, setActive } = useClerk();

  const navigate = useNavigate();

  // You can get all the available sessions through the client
  const availableSessions = client.sessions;

  const handleOpenDrawer = useCallback(() => {
    setOpen(true);
  }, []);

  const handleCloseDrawer = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClickItem = (path: string) => {
    navigate({ to: path });
  };

  const renderAvatar = (
    <AvatarWrapper>
      <AnimateAvatar
        width={96}
        slotProps={{
          avatar: {
            src: clerkUser?.imageUrl,
            alt: user?.clerkUser?.fullName,
          },
          overlay: {
            border: 2,
            spacing: 3,
            color: `linear-gradient(135deg, ${varAlpha(theme.vars.palette.primary.mainChannel, 0)} 25%, ${theme.vars.palette.primary.main} 100%)`,
          },
        }}
      >
         {user?.clerkUser?.fullName?.charAt(0).toUpperCase()}
      </AnimateAvatar>
      <HoverOverlay
        className="overlay"
        onClick={profileImageUploadDialog.onTrue}
      >
        <Iconify
          icon="solar:camera-minimalistic-bold"
          color="white"
          width={32}
        />
      </HoverOverlay>
    </AvatarWrapper>
  );

  return (
    <>
      <AccountButton
        onClick={handleOpenDrawer}
        photoURL={clerkUser?.imageUrl ?? ''}
        displayName={user?.clerkUser?.fullName ?? ''}
        sx={sx}
        {...other}
      />

      <Drawer
        open={open}
        onClose={handleCloseDrawer}
        anchor="right"
        slotProps={{ backdrop: { invisible: true } }}
        PaperProps={{ sx: { width: 320 } }}
      >
        <IconButton
          onClick={handleCloseDrawer}
          sx={{ top: 12, left: 12, zIndex: 9, position: 'absolute' }}
        >
          <Iconify icon="mingcute:close-line" />
        </IconButton>

        <Scrollbar>
          <Stack alignItems="center" sx={{ pt: 8 }}>
            {renderAvatar}

            <Typography variant="subtitle1" noWrap sx={{ mt: 2 }}>
              {user?.clerkUser?.fullName}
            </Typography>
            <Typography variant="subtitle2" noWrap sx={{ mt: 2 }}>
              {getUserRoleDisplay(user?.primaryRoleType)}
            </Typography>

            <Typography
              variant="body2"
              sx={{ color: 'text.secondary', mt: 0.5 }}
              noWrap
            >
              {user?.clerkUser.primaryEmailAddress.emailAddress}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            spacing={1}
            flexWrap="wrap"
            justifyContent="center"
            sx={{ p: 3 }}
          >
            {availableSessions.map((session, index) => (
              <Tooltip
                key={session.id}
                title={`Switch to: ${session?.user?.fullName ?? ''}`}
                sx={{ cursor: 'pointer' }}
              >
                <Avatar
                  alt={session?.user?.fullName ?? ''}
                  src={session?.user?.fullName ?? ''}
                  // selected={session.id === client.lastActiveSessionId}
                  onClick={() => setActive({ session: session.id })}
                />
              </Tooltip>
            ))}

            {/* TODO : Fix Clerk SignInButton issue */}
            <SignInButton>
              <Tooltip title="Add account">
                <IconButton
                  sx={{
                    bgcolor: varAlpha(
                      theme.vars.palette.grey['500Channel'],
                      0.08
                    ),
                    border: `dashed 1px ${varAlpha(theme.vars.palette.grey['500Channel'], 0.32)}`,
                  }}
                >
                  <Iconify icon="material-symbols:add" />
                </IconButton>
              </Tooltip>
            </SignInButton>
          </Stack>

          <Stack
            sx={{
              py: 3,
              px: 2.5,
              borderTop: `dashed 1px ${theme.vars.palette.divider}`,
              borderBottom: `dashed 1px ${theme.vars.palette.divider}`,
            }}
          >
            {accountNavData.map(option => {
              return (
                <MenuItem
                  key={option.label}
                  onClick={() => handleClickItem(option.href)}
                  sx={{
                    py: 1,
                    color: 'text.secondary',
                    '& svg': { width: 24, height: 24 },
                    '&:hover': { color: 'text.primary' },
                  }}
                >
                  {option.icon}

                  <Box component="span" sx={{ ml: 2 }}>
                    {option.label}
                  </Box>
                  {/* 
                  {option.info && (
                    <Label color="error" sx={{ ml: 1 }}>
                      {option.info}
                    </Label>
                  )} */}
                </MenuItem>
              );
            })}
          </Stack>
        </Scrollbar>

        <Box sx={{ p: 2.5 }}>
          {user &&
            user.clerkUser.isSuperAdmin &&
            user.impersonatingLocationId && (
              <Button
                variant="contained"
                fullWidth
                size="large"
                color="secondary"
                sx={{ mb: 2 }}
                onClick={async () => {
                  try {
                    const resp = await clearImpersonation({});
                    navigate({ to: '/dashboard/locations' });
                    toast.success(resp.message);
                  } catch (e) {
                    // TODO: better reusable pattern for this stuff all over the place
                    toast.error('Something went wrong');
                  }
                }}
              >
                End Impersonation
              </Button>
            )}
          <Button
            variant="contained"
            fullWidth
            size="large"
            color="error"
            onClick={() => navigate({ to: '/sign-out' })}
          >
            Sign Out
          </Button>
        </Box>
        <AvatarUploadDialog
          open={profileImageUploadDialog.value}
          onClose={profileImageUploadDialog.onFalse}
        />
      </Drawer>
    </>
  );
}
