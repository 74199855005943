import { RequestId } from '@cvx/types/entities/sharedIds';
import { ConvexUser } from '@cvx/types/entities/usersEntityTypes';
import { ServiceRequestZodId } from '@cvx/types/zod/commonZodId';
import { servicesRequiredSchema } from '@cvx/types/zod/requestsZod';
import { z } from 'zod';
import { Doc } from '../../../_generated/dataModel';
import { MutationCtx } from '../../../_generated/server';
import {
  createHistoryEntry,
  messageTemplates,
} from '../../../schema/entities/requests/requestHistory';
import { paymentMethod } from '../../../schema/enums/paymentMethod';

export const baseSubmissionSchema = z.object({
  description: z.string(),
  timezone: z.string(),
  requestId: ServiceRequestZodId,
  fleetReferenceNumber: z.string().optional(),
  nationalAccountNumber: z.string().optional(),
  tireSize: z.string().optional(),
  paymentMethod: paymentMethod.optional(),
  servicesRequiredByFleet: servicesRequiredSchema,
  city: z.string().optional(),
  state: z.string().optional(),
  postCode: z.string().optional(),
  country: z.string().optional(),
  countryCode: z.string().optional(),
  stateShortCode: z.string().optional(),
});

export type BaseSubmissionInput = z.infer<typeof baseSubmissionSchema>;

type CreateRequestSubmissionHistoryProps = {
  ctx: MutationCtx;
  requestId: RequestId;
  user: ConvexUser;
  userRole: Doc<'roleDefinitions'>;
  dispatcherAssigned?: boolean;
  driver?: ConvexUser;
};

export async function createRequestSubmissionHistory({
  ctx,
  requestId,
  user,
  userRole,
  dispatcherAssigned,
  driver,
}: CreateRequestSubmissionHistoryProps) {
  if (dispatcherAssigned && driver) {
    await createHistoryEntry(ctx.db, {
      requestId,
      type: 'WORKFLOW_TRANSITION',
      userId: user._id,
      userRole: userRole._id,
      messageComponents:
        messageTemplates.fleetDispatcherAssignedToDriverToConfirmLocation({
          dispatcherName: user.clerkUser.fullName,
          dispatcherRole: userRole.type,
          driverName: driver.clerkUser.fullName,
          driverRole: driver.primaryRoleType!,
        }),
      visibleToRoles: [],
    });
  } else {
    await createHistoryEntry(ctx.db, {
      requestId,
      type: 'REQUEST_CREATED',
      userId: user._id,
      userRole: userRole._id,
      messageComponents: messageTemplates.requestCreated({
        creatorName: user.clerkUser.fullName,
        creatorRole: userRole.type,
      }),
      visibleToRoles: [],
    });
  }
}
