import { Box, Container, Typography } from '@mui/material';

export function EULAPage() {
  return (
    <Container maxWidth="lg" sx={{ height: '100vh' }}>
      <Box sx={{ height: '100%' }}>
        <Typography variant="body1" component="div" sx={{ height: '100%' }}>
          <iframe
            src="https://app.termly.io/policy-viewer/policy.html?policyUUID=de80277f-8ba2-42f1-9c22-053a24913d82"
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
              overflow: 'auto',
            }}
            title="End-User License Agreement"
          />
        </Typography>
      </Box>
    </Container>
  );
}
