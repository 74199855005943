import { MutationCtx, QueryCtx } from '../_generated/server';
import { CustomUserIdentity } from '../types/customUserIdentity';

export async function getCustomUserIdentity(
  ctx: QueryCtx | MutationCtx
): Promise<CustomUserIdentity> {
  const identity = await ctx.auth.getUserIdentity();

  if (!identity) {
    throw new Error('User not authenticated');
  }

  // Cast identity to CustomUserIdentity
  const customIdentity = identity as CustomUserIdentity;

  return customIdentity;
}
