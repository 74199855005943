import { api } from '@cvx/api';
import { EnhancedRequest } from '@cvx/types/entities/requestsEntityTypes';
import {
  Alert,
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useQuery } from 'convex/react';
import { useMemo, useState } from 'react';
import { EnhancedUser } from 'src/convex/functions/users';
import {
  ImageCategory,
  getImageCategoryColor,
  getImageCategoryLabel,
} from 'src/convex/schema/entities/requests/requestImages';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { Image } from 'src/minimal-theme/components/image';
import { Label } from 'src/minimal-theme/components/label';
import { useRequiredPhotosValidation } from 'src/sections/request/actions/hooks/useRequiredPhotosValidation';
import { AddPhotosDialog } from 'src/sections/request/AddPhotosDialog';
import { useRequestImages } from 'src/sections/request/hooks/useRequestImages';

type RequiredPhotosPromptProps = {
  request: EnhancedRequest;
  me: EnhancedUser;
};

export const RequiredPhotosPrompt = ({
  request,
  me,
}: RequiredPhotosPromptProps) => {
  const [addPhotoDialogOpen, setAddPhotoDialogOpen] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<ImageCategory>();

  const existingPhotos = useQuery(
    api.functions.requestImages.getAccessibleRequestPhotos,
    {
      requestId: request._id,
    }
  );

  const confirmedImages = useMemo(
    () => existingPhotos?.filter(i => !!i.confirmed) ?? [],
    [existingPhotos]
  );

  // Load image states (URLs) for confirmed images
  const imageStates = useRequestImages(
    request._id,
    confirmedImages.map(img => img._id)
  );

  const {
    missingCategories,
    isDotServiceRequired,
    isLoading,
    existingCategories,
  } = useRequiredPhotosValidation(request, me);

  const requiredCategories = useMemo(() => {
    if (!isDotServiceRequired || isLoading) {
      return [];
    }

    // Derive required categories from missing and existing categories
    const allRequiredCategories = [...missingCategories, ...existingCategories];

    return allRequiredCategories.map(category => ({
      category,
      label: getImageCategoryLabel(category),
    }));
  }, [isDotServiceRequired, isLoading, missingCategories, existingCategories]);

  const categoryStatus = useMemo(() => {
    return requiredCategories.map(req => {
      const photos = confirmedImages.filter(
        img => img.category === req.category
      );
      const hasPhotos = photos.length > 0;
      return {
        ...req,
        hasPhotos,
        photos,
        isMissing: missingCategories.includes(req.category),
      };
    });
  }, [requiredCategories, confirmedImages, missingCategories]);

  const handleAddPhoto = (category: ImageCategory) => {
    setSelectedCategory(category);
    setAddPhotoDialogOpen(true);
  };

  const missingCount = categoryStatus.filter(
    status => !status.hasPhotos
  ).length;

  if (!isDotServiceRequired || requiredCategories.length === 0 || isLoading) {
    return null;
  }

  return (
    <Box sx={{ mb: 3 }}>
      {missingCount > 0 && (
        <Alert
          severity="warning"
          variant="outlined"
          sx={{ mb: 2 }}
          icon={<Iconify icon="solar:camera-bold" width={24} />}
        >
          {missingCount === requiredCategories.length
            ? 'Required photos must be uploaded to complete this form'
            : `${missingCount} of ${requiredCategories.length} required photos still needed`}
        </Alert>
      )}

      <Grid container spacing={2}>
        {categoryStatus.map(status => {
          const gridSize =
            categoryStatus.length === 1
              ? 12
              : categoryStatus.length === 2
                ? 6
                : 4;

          return (
            <Grid item xs={12} sm={gridSize} key={status.category}>
              <Paper
                elevation={1}
                sx={{
                  p: 2,
                  height: '100%',
                  borderLeft: t =>
                    `4px solid ${
                      status.hasPhotos
                        ? getImageCategoryColor(status.category).main
                        : t.palette.grey[400]
                    }`,
                }}
              >
                <Stack spacing={1.5} height="100%">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Label
                      variant="soft"
                      sx={{
                        bgcolor: getImageCategoryColor(status.category).light,
                        color: getImageCategoryColor(status.category).main,
                      }}
                    >
                      {getImageCategoryLabel(status.category)}
                    </Label>

                    <Box>
                      {status.category === 'WORK_ORDER' && (
                        <Iconify
                          icon="solar:lock-bold"
                          sx={{
                            color: status.hasPhotos
                              ? 'success.main'
                              : 'error.main',
                            width: 24,
                            height: 24,
                          }}
                        />
                      )}
                      {status.hasPhotos ? (
                        <Iconify
                          icon="solar:check-circle-bold"
                          sx={{ color: 'success.main', width: 24, height: 24 }}
                        />
                      ) : (
                        <Iconify
                          icon="solar:close-circle-bold"
                          sx={{ color: 'error.main', width: 24, height: 24 }}
                        />
                      )}
                    </Box>
                  </Box>

                  {status.hasPhotos && status.photos.length > 0 ? (
                    <Box
                      sx={{
                        position: 'relative',
                        flexGrow: 1,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Image
                        src={imageStates[status.photos[0]._id]?.url || ''}
                        sx={{
                          borderRadius: 1,
                          width: '100%',
                          height: 120,
                          objectFit: 'cover',
                        }}
                      />
                      {status.photos.length > 1 && (
                        <Typography
                          variant="caption"
                          sx={{
                            position: 'absolute',
                            bottom: 8,
                            right: 8,
                            bgcolor: 'rgba(0,0,0,0.6)',
                            color: 'white',
                            px: 1,
                            py: 0.5,
                            borderRadius: 1,
                          }}
                        >
                          +{status.photos.length - 1} more
                        </Typography>
                      )}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        flexGrow: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'background.neutral',
                        borderRadius: 1,
                        height: 120,
                      }}
                    >
                      <Iconify
                        icon="solar:camera-minimalistic-broken"
                        sx={{
                          width: 48,
                          height: 48,
                          opacity: 0.5,
                          color: 'text.disabled',
                        }}
                      />
                    </Box>
                  )}

                  {!status.hasPhotos && (
                    <Button
                      fullWidth
                      variant={'contained'}
                      startIcon={<Iconify icon={'solar:camera-add-bold'} />}
                      onClick={() => handleAddPhoto(status.category)}
                      color={'primary'}
                    >
                      {'Upload Now'}
                    </Button>
                  )}
                </Stack>
              </Paper>
            </Grid>
          );
        })}
      </Grid>

      <AddPhotosDialog
        me={me}
        open={addPhotoDialogOpen}
        onClose={() => setAddPhotoDialogOpen(false)}
        requestId={request._id}
        defaultCategory={selectedCategory}
      />
    </Box>
  );
};
