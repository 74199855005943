import { phoneSchema } from '@cvx/types/zod/commonZod';
import {
  CompanyZodId,
  ConvexUserZodId,
  GroupZodId,
  RoleDefinitionZodId,
  ServiceRequestZodId,
  StorageZodId,
  VehicleZodId,
  VendorServiceZodId,
} from '@cvx/types/zod/commonZodId';
import { tempVehicleInfoSchema } from '@cvx/types/zod/vehiclesZod';
import { z } from 'zod';
import { paymentMethod } from '../../schema/enums/paymentMethod';
import { requestStatusType } from '../../schema/enums/requestStatusType';

export const searchComponentEnum = z.enum([
  'CASE_NUMBER',

  'FLEET_REFERENCE_NUMBER',
  'ADDRESS',
  'DESCRIPTION',
  'DRIVER_NAME',
  'FLEET_DISPATCHER_NAME',
  'SERVICE_DISPATCHER_NAME',
  'TECHNICIAN_NAME',
  'VEHICLE_VIN',
  'VEHICLE_MAKER',
  'VEHICLE_MODEL',
  'VEHICLE_UNIT_NUMBER',
  'REQUESTER_COMPANY_NAME',
  'REQUESTER_LOCATION_NAME',
  'TEMP_DRIVER_INFO',
  'TEMP_DISPATCHER_INFO',
]);

const technicianEnteredLineItemSchema = z.object({
  partNumber: z.string(),
  partDescription: z.string(),
  quantity: z.number(),
  unitPrice: z.number(),
  total: z.number(),
});

export const servicesRequiredSchema = z
  .array(VendorServiceZodId)
  .min(1, 'Please select at least one service');

export const searchComponentSchema = z.object({
  type: searchComponentEnum,
  text: z.string(),
  sourceId: ConvexUserZodId.or(VehicleZodId).optional(),
});

// Define service phase to track which group is actively handling the request
export const servicePhase = z.enum([
  'FLEET_DISPATCH', // Initial fleet company dispatch handling
  'TOW_DISPATCH', // Tow company dispatch handling
  'TOW_SERVICE', // Active tow service
  'MECHANIC_DISPATCH', // Service provider dispatch handling
  'MECHANIC_SERVICE', // Active mechanical service
  'VERIFICATION',
  'COMPLETED',
]);

export const extraRepairFieldsSchema = z.object({
  technicianEnteredPartsInfo: z
    .array(technicianEnteredLineItemSchema)
    .optional(),
  totalTechnicianHours: z.number().optional(),
});

export const repairDetailsSchema = z
  .object({
    cause: z.string().min(2, 'Cause is required'),
    correction: z.string().min(2, 'Cause is required'),
    wasATemporaryFix: z.boolean().optional(),
    notes: z.string().optional(),
    completedAt: z.string(), // When these details were submitted
    technicianId: ConvexUserZodId, // Who submitted them
  })
  .merge(extraRepairFieldsSchema);

export const repairDetailsFormSchema = repairDetailsSchema.omit({
  completedAt: true,
  technicianId: true,
});

export const submitRepairDetailsInput = repairDetailsSchema
  .omit({
    completedAt: true,
    technicianId: true,
  })
  .extend({
    requestId: ServiceRequestZodId,
    wasATemporaryFix: z.boolean(),
    tempVehicleInfo: tempVehicleInfoSchema
      .extend({ vin: z.string().optional() })
      .optional(),
  });

export const requestCreationContext = z.enum([
  // Fleet-initiated flows
  'FLEET_DISPATCHER_INITIATED', // Standard fleet dispatcher creates request
  'FLEET_DISPATCHER_WITH_LOCATION_CONFIRM', // Fleet dispatcher creates request that needs driver to confirm location
  'FLEET_DRIVER_INITIATED', // Driver creates request going to fleet dispatch
  'FLEET_DRIVER_SOLE_PROPRIETOR', // Driver creates request in sole proprietor mode (no dispatch)

  // Service provider-initiated flows
  'SERVICE_DISPATCHER_INITIATED', // Standard service dispatcher creates request
  'SERVICE_TECHNICIAN_SOLE_PROPRIETOR', // Technician creates request in sole proprietor mode
  'SERVICE_DISPATCHER_INITIATED_WITH_LOCATION_CONFIRM', // Service dispatcher creates request that needs driver to confirm location
  'SERVICE_TECHNICIAN_SOLE_PROPRIETOR_WITH_LOCATION_CONFIRM', // Technician creates request in sole proprietor mode that needs driver to confirm location

  // Third-party flows
  'THIRD_PARTY_DISPATCHER_INITIATED', // Broker/third-party initiates request
  'THIRD_PARTY_DISPATCHER_INITIATED_WITH_LOCATION_CONFIRM', // Broker/third-party initiates request that needs driver to confirm location
]);

export const caseNumberSchema = z
  .string()
  .regex(/^\d{6}-\d{7}$/, 'Invalid case number format')
  .describe('Case number in format YYMMDD-NNNNNNN');

export const stepState = z.enum([
  'UNASSIGNED', // No one assigned yet
  'ASSIGNED', // Someone specific is assigned
  'QUEUED', // Waiting in a dispatch/service queue
  'COMPLETED', // Step is done
  'CANCELED', // Step was canceled
]);

export const stepType = z.enum([
  'INTAKE',
  'DRIVER_CONFIRM_LOCATION',
  'DISPATCH_TRIAGE',
  'WITH_SERVICE_PROVIDER_DISPATCH',
  'TECHNICIAN_ASSIGNED',
  'TECHNICIAN_ACCEPTED_PRE_EN_ROUTE',
  'TECHNICIAN_ACCEPTED',
  'TECHNICIAN_ARRIVED',
  'TECHNICIAN_STARTED_WORK',
  'TECHNICIAN_COMPLETED_WORK',
  'COMPLETION_VERIFICATION',
  'COMPLETED',
]);

export const requestSchema = z.object({
  // workflowDefinitionId: zid('workflowDefinitions'),
  status: requestStatusType,
  // currentStepIndex: z.number(),

  currentPhase: servicePhase.optional(),
  currentStepType: stepType.optional(),
  currentStepState: stepState.optional(),

  requestCreationContext: requestCreationContext.optional(),

  caseNumber: caseNumberSchema,

  description: z.string().optional(),
  cancellationReason: z.string().optional(),

  // Location
  address: z.string().optional(), // To trap the initial location of accident in time... separate from where the vehicle is now, etc. can be used to geneerate heatmap of breakdown locations,
  longitude: z.number().optional(),
  latitude: z.number().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  postCode: z.string().optional(),
  country: z.string().optional(),
  countryCode: z.string().optional(),
  stateShortCode: z.string().optional(),
  timezone: z.string().optional(),
  route: z.string().optional(),

  // Group history - tracks all groups involved
  driverGroupId: GroupZodId.optional(),
  fleetDispatchGroupId: GroupZodId.optional(), // Fleet dispatch group
  towDispatchGroupId: GroupZodId.optional(), // Tow dispatcher group
  towServiceGroupId: GroupZodId.optional(), // Tow service group
  mechanicDispatchGroupId: GroupZodId.optional(), // Mechanic dispatcher group
  mechanicServiceGroupId: GroupZodId.optional(), // Mechanic service group
  brokerageGroupId: GroupZodId.optional(), // For third party elements, brokers or call centers, we'll use the same value for now TODO: we will be breaking out into a joiner table "participations", and can possibly eventually remove these

  // Current assignment
  currentAssignedToId: ConvexUserZodId.optional(),
  currentRequiredRoleId: RoleDefinitionZodId.optional(), // Role required for current step if it's queued, awaiting claim

  // Current active participants by role (for real-time coordination)
  activeDriverId: ConvexUserZodId.optional(),
  activeFleetDispatcherId: ConvexUserZodId.optional(),
  activeServiceDispatcherId: ConvexUserZodId.optional(),
  activeTechnicianId: ConvexUserZodId.optional(),
  activeBrokerageDispatcherId: ConvexUserZodId.optional(),
  activeTowOperatorId: ConvexUserZodId.optional(),

  // For technician when they have started their work
  repairDetails: repairDetailsSchema.optional(),

  // Companies involved
  requesterCompanyId: CompanyZodId,
  serviceProviderCompanyId: CompanyZodId.optional(),
  towingCompanyId: CompanyZodId.optional(),
  brokerageCompanyId: CompanyZodId.optional(),

  // For full text search/client side search in the meantime
  searchText: z.string().optional(), // Make existing optional, TODO: in the future we need to delete this..
  // Unfortunately we need two search indexes, maybe more in the future, because we need activeFleetDispatcherId for example to be in the filterFields to cut down the results, but we can't also include the mechanic one in the same index array
  fleetSearchText: z.string().optional(),
  serviceSearchText: z.string().optional(),
  searchComponents: z.array(searchComponentSchema).optional(),

  // In cases where dispatcher is creating on behalf of driver, and we require driver location ... determines the order of steps (confirm drivers location will show up and come before dispatch triage in these cases)
  requiresVehicleLocation: z.boolean().optional(),

  // Timing
  currentStepStartedAt: z.number().optional(),
  createdAt: z.number().optional(), // When draft is submitted
  completedAt: z.number().optional(),
  deletedAt: z.number().optional(),

  estimatedTimeOfArrival: z.string().optional(),

  driverRequiresTow: z.boolean().optional(),

  // TODO: RF ... would be more work but might be useful if this wasn't just a string, but pulling from a managed list fo relevant tools
  tools: z.string().optional(),

  // TODO: when workflows are figured out, this will apply to a step, not the whole request
  mapGeneratedEta: z.string().optional(),
  // TODO: RF - should probably not apply to the whole request but on the specific action/service being charged
  paymentMethod: paymentMethod.optional(),

  // Simple external reference - optional string that fleets can use to track their internal number
  fleetReferenceNumber: z.string().optional(),

  // TODO: I think get rid of these??
  externalReferenceNumber: z.string().optional(),
  nationalAccountNumber: z.string().optional(),
  tireSize: z.string().optional(),

  notes: z.string().optional(),

  // Relationships
  createdById: ConvexUserZodId,

  vehicleId: VehicleZodId.optional(), // Optionally initially for creating draft

  // Services the fleet believes are needed
  servicesRequiredByFleet: z.array(VendorServiceZodId).optional(),
  // Services the technician deems necessary after inspection
  servicesDeemedNecessaryByTech: z.array(VendorServiceZodId).optional(),
  // Services actually performed
  servicesPerformed: z.array(VendorServiceZodId).optional(),

  // Temporary fields for when driver is not yet in the system
  tempDriverEmail: z.string().email().optional(),
  tempDriverPhone: phoneSchema.optional(),
  tempFleetDispatchEmail: z.string().email().optional(),
  tempFleetDispatchPhone: phoneSchema.optional(),
  tempDriverFirstName: z.string().optional(),
  tempDriverLastName: z.string().optional(),
  tempFleetDispatchFirstName: z.string().optional(),
  tempFleetDispatchLastName: z.string().optional(),
  tempVehicleInfo: tempVehicleInfoSchema.optional(),
  tempVehicleUnitNumber: z.string().optional(),

  requesterCompanyName: z.string().optional(),
  requesterLocationName: z.string().optional(),

  // If there is no dispatcher involved in the request
  isDriverCalling: z.boolean().optional(),
  isNoActiveDriver: z.boolean().optional(),

  serviceRequestPdfStorageId: StorageZodId.optional(),
  serviceRequestPdfDocumentName: z.string().optional(),

  onHold: z.boolean().optional(),
});

export const submitExtraDetailsInput = extraRepairFieldsSchema.extend({
  requestId: ServiceRequestZodId,
  onHold: z.boolean().optional(), // TODO: Isn't on hold a status that can be applied at any stage?
});
