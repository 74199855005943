import {
  RequestSearchComponent,
  ServiceRequest,
} from '@cvx/types/entities/requestsEntityTypes';
import { internalMutation, MutationCtx } from '../_generated/server';

// To update existing requests
export const updateExistingRequestSearchFields = internalMutation({
  handler: async ctx => {
    const requestsToUpdate = await ctx.db.query('requests').collect();

    for (const request of requestsToUpdate) {
      const { searchComponents, searchText } = await generateSearchFields(
        ctx,
        request
      );

      await ctx.db.patch(request._id, {
        searchComponents,
        searchText,
      });
    }
  },
});

export async function generateSearchFields(
  ctx: MutationCtx,
  doc: ServiceRequest
) {
  const searchComponents: RequestSearchComponent[] = [];
  const searchTexts = [];

  // Always add case number
  searchComponents.push({
    type: 'CASE_NUMBER',
    text: doc.caseNumber,
  });

  searchTexts.push(doc.caseNumber ?? '');

  searchComponents.push({
    type: 'ADDRESS',
    text: doc.address ?? '',
  });

  searchTexts.push(doc.address ?? '');

  searchComponents.push({
    type: 'DESCRIPTION',
    text: doc.description ?? '',
  });

  searchTexts.push(doc.description ?? '');

  searchComponents.push({
    type: 'FLEET_REFERENCE_NUMBER',
    text: doc.fleetReferenceNumber ?? '',
  });

  searchTexts.push(doc.fleetReferenceNumber ?? '');

  searchComponents.push({
    type: 'REQUESTER_COMPANY_NAME',
    text: doc.requesterCompanyName ?? '',
  });

  searchTexts.push(doc.requesterCompanyName ?? '');

  searchComponents.push({
    type: 'REQUESTER_LOCATION_NAME',
    text: doc.requesterLocationName ?? '',
  });

  searchTexts.push(doc.requesterLocationName ?? '');

  // Fetch all related entities in parallel
  const [driver, dispatcher, serviceDispatcher, technician, vehicle] =
    await Promise.all([
      doc.activeDriverId ? ctx.db.get(doc.activeDriverId) : null,
      doc.activeFleetDispatcherId
        ? ctx.db.get(doc.activeFleetDispatcherId)
        : null,
      doc.activeServiceDispatcherId
        ? ctx.db.get(doc.activeServiceDispatcherId)
        : null,
      doc.activeTechnicianId ? ctx.db.get(doc.activeTechnicianId) : null,
      doc.vehicleId ? ctx.db.get(doc.vehicleId) : null,
    ]);

  if (driver) {
    searchComponents.push({
      type: 'DRIVER_NAME',
      text: driver.clerkUser.fullName,
      sourceId: driver._id,
    });
    searchTexts.push(driver.clerkUser.fullName);
  }

  if (dispatcher) {
    searchComponents.push({
      type: 'FLEET_DISPATCHER_NAME',
      text: dispatcher.clerkUser.fullName,
      sourceId: dispatcher._id,
    });
    searchTexts.push(dispatcher.clerkUser.fullName);
  }

  if (serviceDispatcher) {
    searchComponents.push({
      type: 'SERVICE_DISPATCHER_NAME',
      text: serviceDispatcher.clerkUser.fullName,
      sourceId: serviceDispatcher._id,
    });
    searchTexts.push(serviceDispatcher.clerkUser.fullName);
  }

  if (technician) {
    searchComponents.push({
      type: 'TECHNICIAN_NAME',
      text: technician.clerkUser.fullName,
      sourceId: technician._id,
    });
    searchTexts.push(technician.clerkUser.fullName);
  }

  if (vehicle) {
    if (vehicle.vin) {
      searchComponents.push({
        type: 'VEHICLE_VIN',
        text: vehicle.vin,
        sourceId: vehicle._id,
      });
      searchTexts.push(vehicle.vin);
    }

    if (vehicle.maker) {
      searchComponents.push({
        type: 'VEHICLE_MAKER',
        text: vehicle.maker,
        sourceId: vehicle._id,
      });
      searchTexts.push(vehicle.maker);
    }

    if (vehicle.model) {
      searchComponents.push({
        type: 'VEHICLE_MODEL',
        text: vehicle.model,
        sourceId: vehicle._id,
      });
      searchTexts.push(vehicle.model);
    }

    if (vehicle.unitNumber) {
      searchComponents.push({
        type: 'VEHICLE_UNIT_NUMBER',
        text: vehicle.unitNumber,
        sourceId: vehicle._id,
      });
      searchTexts.push(vehicle.unitNumber);
    }

    if (!doc.activeDriverId) {
      if (doc.tempDriverFirstName || doc.tempDriverLastName) {
        const tempDriverName = [doc.tempDriverFirstName, doc.tempDriverLastName]
          .filter(Boolean)
          .join(' ')
          .trim();

        if (tempDriverName) {
          searchComponents.push({
            type: 'DRIVER_NAME',
            text: tempDriverName,
          });
          searchTexts.push(tempDriverName);
        }
      }

      if (doc.tempDriverPhone) {
        searchComponents.push({
          type: 'TEMP_DRIVER_INFO',
          text: doc.tempDriverPhone,
        });
        searchTexts.push(doc.tempDriverPhone);
      }

      if (doc.tempDriverEmail) {
        searchComponents.push({
          type: 'TEMP_DRIVER_INFO',
          text: doc.tempDriverEmail,
        });
        searchTexts.push(doc.tempDriverEmail);
      }
    }

    if (!doc.activeFleetDispatcherId) {
      if (doc.tempFleetDispatchFirstName || doc.tempFleetDispatchLastName) {
        const tempDispatcherName = [
          doc.tempFleetDispatchFirstName,
          doc.tempFleetDispatchLastName,
        ]
          .filter(Boolean)
          .join(' ')
          .trim();

        if (tempDispatcherName) {
          searchComponents.push({
            type: 'FLEET_DISPATCHER_NAME',
            text: tempDispatcherName,
          });
          searchTexts.push(tempDispatcherName);
        }
      }

      if (doc.tempFleetDispatchPhone) {
        searchComponents.push({
          type: 'TEMP_DISPATCHER_INFO',
          text: doc.tempFleetDispatchPhone,
        });
        searchTexts.push(doc.tempFleetDispatchPhone);
      }

      if (doc.tempFleetDispatchEmail) {
        searchComponents.push({
          type: 'TEMP_DISPATCHER_INFO',
          text: doc.tempFleetDispatchEmail,
        });
        searchTexts.push(doc.tempFleetDispatchEmail);
      }
    }

    if (doc.tempVehicleInfo || doc.tempVehicleUnitNumber) {
      if (doc.tempVehicleInfo?.maker) {
        searchComponents.push({
          type: 'VEHICLE_MAKER',
          text: doc.tempVehicleInfo.maker,
        });
        searchTexts.push(doc.tempVehicleInfo.maker);
      }

      if (doc.tempVehicleInfo?.model) {
        searchComponents.push({
          type: 'VEHICLE_MODEL',
          text: doc.tempVehicleInfo.model,
        });
        searchTexts.push(doc.tempVehicleInfo.model);
      }

      if (doc.tempVehicleUnitNumber) {
        searchComponents.push({
          type: 'VEHICLE_UNIT_NUMBER',
          text: doc.tempVehicleUnitNumber,
        });
        searchTexts.push(doc.tempVehicleUnitNumber);
      }
    }
  }

  return {
    searchComponents,
    searchText: searchTexts.join(' ').toLowerCase().trim(),
  };
}
