import { api } from '@cvx/api';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import {
  ConnectAccountManagement,
  ConnectAccountOnboarding,
  ConnectTaxRegistrations,
} from '@stripe/react-connect-js';
import { useQuery } from 'convex/react';
import { useCallback, useEffect, useState } from 'react';
import { StripeSetupAlerts } from 'src/components/stripe/StripeSetupAlerts';
import { ConfirmDialog } from 'src/minimal-theme/components/custom-dialog';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { Label } from 'src/minimal-theme/components/label';
import { Scrollbar } from 'src/minimal-theme/components/scrollbar';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/minimal-theme/components/table';
import { useBoolean } from 'src/minimal-theme/hooks/use-boolean';
import { useSetState } from 'src/minimal-theme/hooks/use-set-state';
import { DashboardContent } from 'src/minimal-theme/layouts/dashboard';
import { varAlpha } from 'src/minimal-theme/theme/styles';
import { fIsAfter, fIsBetween } from 'src/minimal-theme/utils/format-time';
import { sumBy } from 'src/minimal-theme/utils/helper';
// import { StripeEmbedded } from 'src/sections/invoice/view/StripeEmbedded';
import { useTaxRegistrations } from 'src/components/stripe/hooks/useTaxRegistrations';
import { isOnboardedAndReadyToCharge } from 'src/components/stripe/utils/isStripeTestMode';
import { Doc } from 'src/convex/_generated/dataModel';
import { StripeEmbeddedWrapper } from 'src/sections/invoice/view/StripeEmbeddedWrapper';
import { InvoiceTableFilters } from 'src/types/invoice';
import { InvoiceAnalytic } from '../invoice-analytic';
import { InvoiceTableFiltersResult } from '../invoice-table-filters-result';
import { InvoiceTableRow } from '../invoice-table-row';
import { InvoiceTableToolbar } from '../invoice-table-toolbar';

const TABLE_HEAD = [
  { id: 'request', label: 'Case Number' },
  // { id: 'customer', label: 'Customer' },
  { id: 'createDate', label: 'Creation Date' },
  { id: 'dueDate', label: 'Due Date' },
  { id: 'price', label: 'Amount' },
  { id: 'status', label: 'Status' },
  { id: 'url', label: 'Invoice URL' },
  // { id: '' },
];

export function InvoiceListView() {
  const theme = useTheme();

  const user = useQuery(api.functions.users.getMe);

  const { checkRegistrations, isChecking } = useTaxRegistrations();

  const table = useTable({ defaultOrderBy: 'createDate' });

  const confirm = useBoolean();

  const [taxRegistrationDialogOpen, setTaxRegistrationDialogOpen] =
    useState(false);

  const [onboardingDialogOpen, setOnboardingDialogOpen] = useState(false);
  const [accountSettingsDialogOpen, setAccountSettingsDialogOpen] =
    useState(false);

  const tableData = useQuery(api.functions.invoices.getAllInvoicesForMe, {});

  const { invoicesIssued = [], invoicesReceived = [] } = tableData || {};

  const canIssueInvoices =
    user?.roles.some(r => r.type === 'SERVICE_DISPATCHER') ||
    user?.roles.some(r => r.type === 'THIRD_PARTY_DISPATCHER') ||
    (user?.roles?.some(r => r.type === 'TECHNICIAN_PROVIDER') &&
      user?.primaryLocation?.noDispatchSoleProprietor);

  const canPayInvoices =
    user?.primaryRoleType === 'FLEET_DISPATCHER' ||
    user?.primaryRoleType === 'THIRD_PARTY_DISPATCHER' ||
    (user?.roles?.some(r => r.type === 'DRIVER_FLEET') &&
      user?.primaryLocation?.noDispatchSoleProprietor);

  const filters = useSetState<any>({
    name: '',
    service: [],
    status: undefined,
    startDate: null,
    endDate: null,
  });

  const [initialized, setInitialized] = useState(false);

  const getInvoiceLength = useCallback(
    (status: string) => {
      if (status === 'TO_PAY') {
        return invoicesReceived.filter(
          inv => inv.status === 'SENT' || inv.status === 'OVERDUE'
        ).length;
      } else if (status === 'WAITING_PAYMENT') {
        return invoicesIssued.filter(
          inv => inv.status === 'SENT' || inv.status === 'OVERDUE'
        ).length;
      } else if (status === 'PAID') {
        return [...invoicesIssued, ...invoicesReceived].filter(
          inv => inv.status === 'PAID'
        ).length;
      } else if (status === 'DRAFT') {
        return invoicesIssued.filter(inv => inv.status === 'DRAFT').length;
      }
      return invoicesIssued.length + invoicesReceived.length;
    },
    [invoicesIssued, invoicesReceived]
  );

  const getTotalAmount = (status: string) => {
    if (status === 'TO_PAY') {
      return sumBy(
        invoicesReceived.filter(
          inv => inv.status === 'SENT' || inv.status === 'OVERDUE'
        ),
        invoice => (invoice.amount ?? 0) / 100
      );
    } else if (status === 'WAITING_PAYMENT') {
      return sumBy(
        invoicesIssued.filter(
          inv => inv.status === 'SENT' || inv.status === 'OVERDUE'
        ),
        invoice => (invoice.amount ?? 0) / 100
      );
    } else if (status === 'PAID') {
      return sumBy(
        [...invoicesIssued, ...invoicesReceived].filter(
          inv => inv.status === 'PAID'
        ),
        invoice => (invoice.amount ?? 0) / 100
      );
    } else if (status === 'DRAFT') {
      return sumBy(
        invoicesIssued.filter(inv => inv.status === 'DRAFT'),
        invoice => (invoice.amount ?? 0) / 100
      );
    }

    // 'all' case ... which doesn't really make sense so removed the all tab
    return sumBy(
      [...invoicesIssued, ...invoicesReceived],
      invoice => (invoice.amount ?? 0) / 100
    );
  };

  const getPercentByStatus = (status: string) => {
    const totalCount = invoicesIssued.length + invoicesReceived.length;
    if (totalCount === 0) return 0;
    return (getInvoiceLength(status) / totalCount) * 100;
  };

  const possibleTabs = [
    {
      value: 'TO_PAY',
      label: 'To Pay',
      color: 'warning',
      canView: canPayInvoices,
    },
    {
      value: 'WAITING_PAYMENT',
      label: 'Awaiting Payment',
      color: 'info',
      canView: canIssueInvoices,
    },
    {
      value: 'PAID',
      label: 'Paid',
      color: 'success',
      canView: true, // Everyone can see paid
    },
    {
      value: 'DRAFT',
      label: 'Draft',
      color: 'default',
      canView: canIssueInvoices,
    },
  ];

  const tabs = possibleTabs
    .filter(tab => tab.canView)
    .map(tab => ({
      value: tab.value,
      label: tab.label,
      color: tab.color,
      count: getInvoiceLength(tab.value),
    }));

  useEffect(() => {
    if (!initialized && user && tabs.length > 0) {
      // Find the first tab with invoices or default to first tab
      const tabWithInvoices = tabs.find(tab => getInvoiceLength(tab.value) > 0);
      // Set the initial status to the first tab with invoices, or the first available tab
      filters.setState({ status: tabWithInvoices?.value || tabs[0].value });
      setInitialized(true);
    }
  }, [user, initialized, tabs, filters, getInvoiceLength]);

  const dateError = fIsAfter(filters.state.startDate, filters.state.endDate);

  const dataFiltered = applyFilter({
    invoicesIssued,
    invoicesReceived,
    comparator: getComparator(table.order, table.orderBy),
    filters: filters.state,
    dateError,
  });

  const canReset =
    !!filters.state.name ||
    filters.state.service.length > 0 ||
    filters.state.status !== 'all' ||
    (!!filters.state.startDate && !!filters.state.endDate);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleEditRow = useCallback((id: string) => {
    console.log('edit');
  }, []);

  const handleViewRow = useCallback((id: string) => {
    console.log('view');
  }, []);

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      table.onResetPage();
      filters.setState({ status: newValue });
    },
    [filters, table]
  );

  const analyticsCards = [
    {
      title: 'Total',
      total: invoicesIssued.length + invoicesReceived.length,
      percent: 100,
      price: getTotalAmount('all'),
      icon: 'solar:bill-list-bold-duotone',
      color: theme.vars.palette.info.main,
    },
  ];

  // Add analytics cards for each tab
  tabs.forEach(tab => {
    const cardConfig = {
      TO_PAY: {
        title: 'To Pay',
        icon: 'solar:sort-by-time-bold-duotone',
        color: theme.vars.palette.warning.main,
      },
      WAITING_PAYMENT: {
        title: 'Awaiting Payment',
        icon: 'solar:bell-bing-bold-duotone',
        color: theme.vars.palette.info.main,
      },
      PAID: {
        title: 'Paid',
        icon: 'solar:file-check-bold-duotone',
        color: theme.vars.palette.success.main,
      },
      DRAFT: {
        title: 'Draft',
        icon: 'solar:file-corrupted-bold-duotone',
        color: theme.vars.palette.text.secondary,
      },
    }[tab.value];

    if (cardConfig) {
      analyticsCards.push({
        title: cardConfig.title,
        total: getInvoiceLength(tab.value),
        percent: getPercentByStatus(tab.value),
        price: getTotalAmount(tab.value),
        icon: cardConfig.icon,
        color: cardConfig.color,
      });
    }
  });

  if (!user) return null;

  const canOnboardConnectedAccount =
    user.isCompanyPrimaryAdmin &&
    user.primaryLocation._id === user.primaryLocation.defaultDispatchGroupId;

  return (
    <>
      <DashboardContent>
        <StripeSetupAlerts me={user} />

        <Card sx={{ mb: 3 }}>
          <Scrollbar sx={{ minHeight: 108 }}>
            <Stack
              direction="row"
              divider={
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderStyle: 'dashed' }}
                />
              }
              sx={{ py: 2 }}
            >
              {analyticsCards.map((card, index) => (
                <InvoiceAnalytic
                  key={index}
                  title={card.title}
                  total={card.total}
                  percent={card.percent}
                  price={card.price}
                  icon={card.icon}
                  color={card.color}
                />
              ))}
            </Stack>
          </Scrollbar>
        </Card>

        {canOnboardConnectedAccount && canIssueInvoices && (
          <Stack
            direction="row"
            spacing={2}
            sx={{ mb: 3 }}
            justifyContent={'flex-end'}
          >
            <Button
              onClick={() => setTaxRegistrationDialogOpen(true)}
              variant="contained"
              startIcon={<Iconify icon="solar:sale-bold-duotone" width={40} />}
            >
              Manage Tax Registrations
            </Button>
            <Button
              onClick={() => setAccountSettingsDialogOpen(true)}
              variant="contained"
              startIcon={
                <Iconify icon="solar:settings-bold-duotone" width={40} />
              }
            >
              Manage Stripe Account Settings
            </Button>
            {!isOnboardedAndReadyToCharge(user.primaryLocation) && (
              <Button
                onClick={() => setOnboardingDialogOpen(true)}
                variant="contained"
                startIcon={<Iconify icon="solar:settings-broken" width={40} />}
              >
                Stripe Onboarding
              </Button>
            )}
          </Stack>
        )}

        <Card>
          <Tabs
            value={filters.state.status || (tabs[0]?.value ?? '')}
            onChange={handleFilterStatus}
            sx={{
              px: 2.5,
              boxShadow: `inset 0 -2px 0 0 ${varAlpha(theme.vars.palette.grey['500Channel'], 0.08)}`,
            }}
          >
            {tabs.map(tab => (
              <Tab
                key={tab.value}
                value={tab.value}
                label={tab.label}
                iconPosition="end"
                icon={
                  <Label
                    variant={
                      (tab.value === filters.state.status && 'filled') || 'soft'
                    }
                  >
                    {tab.count}
                  </Label>
                }
              />
            ))}
          </Tabs>

          <InvoiceTableToolbar
            filters={filters}
            dateError={dateError}
            onResetPage={table.onResetPage}
            options={{
              services: [],
            }}
          />

          {canReset && (
            <InvoiceTableFiltersResult
              filters={filters}
              onResetPage={table.onResetPage}
              totalResults={dataFiltered.length}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <Box sx={{ position: 'relative' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected.length}
              rowCount={dataFiltered.length}
              onSelectAllRows={checked => {
                table.onSelectAllRows(
                  checked,
                  dataFiltered.map(row => row._id)
                );
              }}
              action={
                <Stack direction="row">
                  <Tooltip title="Sent">
                    <IconButton color="primary">
                      <Iconify icon="iconamoon:send-fill" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Download">
                    <IconButton color="primary">
                      <Iconify icon="eva:download-outline" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Print">
                    <IconButton color="primary">
                      <Iconify icon="solar:printer-minimalistic-bold" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Delete">
                    <IconButton color="primary" onClick={confirm.onTrue}>
                      <Iconify icon="solar:trash-bin-trash-bold" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              }
            />

            <Scrollbar sx={{ minHeight: 444 }}>
              <Table
                size={table.dense ? 'small' : 'medium'}
                sx={{ minWidth: 800 }}
              >
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={dataFiltered.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                  onSelectAllRows={checked =>
                    table.onSelectAllRows(
                      checked,
                      dataFiltered.map(row => row._id)
                    )
                  }
                />

                <TableBody>
                  {dataFiltered
                    .slice(
                      table.page * table.rowsPerPage,
                      table.page * table.rowsPerPage + table.rowsPerPage
                    )
                    .map(row => (
                      <InvoiceTableRow
                        key={row._id}
                        row={row}
                        selected={table.selected.includes(row._id)}
                        onSelectRow={() => table.onSelectRow(row._id)}
                        onViewRow={() => handleViewRow(row._id)}
                        onEditRow={() => handleEditRow(row._id)}
                      />
                    ))}

                  <TableEmptyRows
                    height={table.dense ? 56 : 56 + 20}
                    emptyRows={emptyRows(
                      table.page,
                      table.rowsPerPage,
                      dataFiltered.length
                    )}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </Box>

          <TablePaginationCustom
            page={table.page}
            dense={table.dense}
            count={dataFiltered.length}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onChangeDense={table.onChangeDense}
            onRowsPerPageChange={table.onChangeRowsPerPage}
          />
        </Card>
      </DashboardContent>

      <StripeEmbeddedWrapper
        dialogMode
        show={
          taxRegistrationDialogOpen ||
          accountSettingsDialogOpen ||
          onboardingDialogOpen
        }
        onClose={() => {
          setTaxRegistrationDialogOpen(false);
          setAccountSettingsDialogOpen(false);
          setOnboardingDialogOpen(false);
        }}
      >
        {taxRegistrationDialogOpen && (
          <ConnectTaxRegistrations
            onAfterTaxRegistrationAdded={checkRegistrations}
          />
        )}
        {onboardingDialogOpen && (
          <ConnectAccountOnboarding
            onExit={() => setOnboardingDialogOpen(false)}
          />
        )}
        {accountSettingsDialogOpen && <ConnectAccountManagement />}
      </StripeEmbeddedWrapper>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete{' '}
            <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

type ApplyFilterProps = {
  dateError: boolean;
  inputData: any[];
  filters: InvoiceTableFilters;
  comparator: (a: any, b: any) => number;
};

function applyFilter({
  invoicesIssued,
  invoicesReceived,
  comparator,
  filters,
  dateError,
}: {
  invoicesIssued: Doc<'invoices'>[];
  invoicesReceived: Doc<'invoices'>[];
  comparator: (a: any, b: any) => number;
  filters: InvoiceTableFilters;
  dateError: boolean;
}) {
  const { name, status, service, startDate, endDate } = filters;

  // If status is undefined, use all invoices as the default
  let inputData: Doc<'invoices'>[] = [];

  if (!status) {
    inputData = [...invoicesIssued, ...invoicesReceived];
  } else if (status === 'TO_PAY') {
    inputData = [...invoicesReceived].filter(
      invoice => invoice.status === 'SENT' || invoice.status === 'OVERDUE'
    );
  } else if (status === 'WAITING_PAYMENT') {
    inputData = [...invoicesIssued].filter(
      invoice => invoice.status === 'SENT' || invoice.status === 'OVERDUE'
    );
  } else if (status === 'PAID') {
    inputData = [...invoicesIssued, ...invoicesReceived].filter(
      invoice => invoice.status === 'PAID'
    );
  } else if (status === 'DRAFT') {
    inputData = [...invoicesIssued].filter(
      invoice => invoice.status === 'DRAFT'
    );
  }

  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map(el => el[0]);

  if (name) {
    inputData = inputData.filter(
      invoice =>
        invoice._id.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
        invoice.recipientUserId?.toLowerCase().indexOf(name.toLowerCase()) !==
          -1
    );
  }

  if (!dateError) {
    if (startDate && endDate) {
      inputData = inputData.filter(invoice =>
        fIsBetween(invoice._creationTime, startDate, endDate)
      );
    }
  }

  return inputData;
}

const statusLabels: Record<string, string> = {
  TO_PAY: 'To Pay',
  WAITING_PAYMENT: 'Awaiting Payment',
  PAID: 'Paid',
  DRAFT: 'Draft',
  SENT: 'Sent',
  OVERDUE: 'Overdue',
};

export const getFriendlyStatusLabel = (status: string): string => {
  return statusLabels[status] || status;
};
