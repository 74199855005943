import { VendorServiceId } from '@cvx/types/entities/sharedIds';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  ListItemText,
  ListSubheader,
  TextField,
} from '@mui/material';
import { useMemo } from 'react';
import { ServiceGroup } from 'src/convex/functions/serviceCategories';

type ServiceSelectFieldProps = {
  services: ServiceGroup[];
  value: VendorServiceId[];
  onChange: (value: VendorServiceId[]) => void;
  label: string;
  error?: boolean;
  helperText?: string;
  required?: boolean;
};

export const ServiceSelectField = ({
  services,
  value,
  onChange,
  label,
  error,
  helperText,
  required,
}: ServiceSelectFieldProps) => {
  // Create a flat list of services with their category names for the Autocomplete
  const options = useMemo(() => {
    return services.flatMap(group =>
      group.services.map(service => ({
        ...service,
        categoryName: group.category.name,
      }))
    );
  }, [services]);

  return (
    <FormControl fullWidth error={error} required={required}>
      <Autocomplete
        multiple
        options={options}
        value={options.filter(option => value.includes(option._id))}
        onChange={(_, newValue) => {
          onChange(newValue.map(item => item._id));
        }}
        getOptionLabel={option => option.name}
        groupBy={option => option.categoryName}
        filterOptions={(options, params) => {
          const filtered = options.filter(
            option =>
              option.name
                .toLowerCase()
                .includes(params.inputValue.toLowerCase()) ||
              option.categoryName
                .toLowerCase()
                .includes(params.inputValue.toLowerCase())
          );
          return filtered;
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            required={required}
            error={error}
            helperText={helperText}
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox checked={selected} />
            <ListItemText primary={option.name} />
          </li>
        )}
        renderGroup={params => (
          <li>
            <ListSubheader
              sx={{
                backgroundColor: 'background.paper',
                fontWeight: 'bold',
              }}
            >
              {params.group}
            </ListSubheader>
            {params.children}
          </li>
        )}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <span {...getTagProps({ index })}>{option.name}</span>
          ))
        }
      />
    </FormControl>
  );
};
