'use node';

import { MappedVinDecodedFields } from '@cvx/types/zod/vehiclesZod';
import axios from 'axios';

/**
 * Decodes a VIN (Vehicle Identification Number) using the NHTSA API.
 * @param vin - The VIN to decode. The vin can be partial as long as it has at least the first 8 characters.
 * @returns The decoded VIN data.
 * @throws Error if the VIN is invalid or the API request fails.
 */
export async function decodeVin(vin: string): Promise<VinDecoderResponse> {
  try {
    // Validate VIN format (basic validation)
    if (!vin || vin.length < 8) {
      throw new Error('Invalid VIN format');
    }

    const response = await axios.get(
      `https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/${vin}?format=json`
    );

    if (response.data.Results.length === 0) {
      throw new Error('No data found for this VIN');
    }

    return response.data.Results[0];
  } catch (error: any) {
    if (error.response) {
      // The API responded with a status code outside of the 2xx range
      console.error('API response error:', error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received:', error.request);
    } else {
      // Something else caused the request to fail
      console.error('Error:', error.message);
    }
    throw new Error('Failed to decode VIN');
  }
}

export type VinDecoderResponse = {
  Make: string;
  Model: string;
  BodyClass: string;
  BrakeSystemType: string;
  DisplacementL: string;
  DriveType: string;
  EngineConfiguration: string;
  EngineCylinders: string;
  EngineHP: string;
  EngineModel: string;
  EngineManufacturer: string;
  FuelTypePrimary: string;
  GVWR: string;
  Manufacturer: string;
  ModelID: string;
  Trim: string;
  // Add other fields as needed
};

export function mapVinData(
  vin: string,
  vinData?: VinDecoderResponse
): MappedVinDecodedFields {
  if (!vinData) {
    return {
      vin,
      isVinDecoded: false,
    };
  }

  return {
    vin,
    maker: vinData.Make,
    model: vinData.Model,
    bodyClass: vinData.BodyClass,
    brakeSystemType: vinData.BrakeSystemType,
    displacementInLiters: vinData.DisplacementL,
    driveType: vinData.DriveType,
    engineConfiguration: vinData.EngineConfiguration,
    engineCylinders: vinData.EngineCylinders,
    engineHP: vinData.EngineHP,
    engineModel: vinData.EngineModel,
    engineManufacturer: vinData.EngineManufacturer,
    fuelTypePrimary: vinData.FuelTypePrimary,
    gvwr: vinData.GVWR,
    manufacturer: vinData.Manufacturer,
    modelId: vinData.ModelID,
    trim: vinData.Trim,
    isVinDecoded: true,
  };
}
