import { z } from 'zod';

export const currencyCodeSchema = z.enum([
  'USD',
  'CAD',
  'EUR',
  'GBP',
  'JPY',
  'AUD',
  'CHF',
  'CNY',
  'INR',
  'BRL',
  // Just a start, add more as needed
]);

export type CurrencyCode = z.infer<typeof currencyCodeSchema>;
