import { api } from '@cvx/api';
import { CompanyId } from '@cvx/types/entities/sharedIds';
import { VendorServiceZodId } from '@cvx/types/zod/commonZodId';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  Button,
  CardActions,
  CardHeader,
  Divider,
  MenuItem,
  Tab,
  Typography,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useForm } from '@tanstack/react-form';
import { useParams } from '@tanstack/react-router';
import { useStore } from '@tanstack/react-store';
import { useMutation, useQuery } from 'convex/react';
import { useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input/input';
import { toast } from 'sonner';
import {
  CompanyType,
  companyType,
  getCompanyTypeDisplay,
} from 'src/convex/schema/enums/companyType';
import { AnimateAvatar } from 'src/minimal-theme/components/animate/animate-avatar';
import { CustomTabs } from 'src/minimal-theme/components/custom-tabs';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { PhoneInput } from 'src/minimal-theme/components/phone-input';
import { varAlpha } from 'src/minimal-theme/theme/styles';
import { schemaHelper } from 'src/minimal-theme/utils/schema-helper';
import { CompanyAdvancedSettings } from 'src/sections/company/CompanyAdvancedSettings';
import { ServiceSelection } from 'src/sections/location/view/ServiceSelection';
import { useAddressSearch } from 'src/utils/useAddressSearch';
import zod, { z } from 'zod';
import { AddressInput } from '../request/AddressInput';
import { CompanyUserList } from './CompanyUserList';

export const COMPANY_SETTINGS_TABS = {
  main: 'main',
  advanced: 'advanced',
} as const;

export type CompanySettingsTabs =
  (typeof COMPANY_SETTINGS_TABS)[keyof typeof COMPANY_SETTINGS_TABS];

export type NewVendorSchemaType = zod.infer<typeof NewVendorSchema>;

export const NewVendorSchema = zod.object({
  email: zod
    .string()
    .email('Invalid email address')
    .optional()
    .or(zod.literal('')),
  phone: schemaHelper.phoneNumber({ isValidPhoneNumber }),
  dotNo: zod
    .string()
    .regex(/^\d{6,7}$/, {
      message: 'DOT number must be a 6 or 7 digit number',
    })
    .optional(),
  serviceIds: z.array(VendorServiceZodId),
  companyName: zod
    .string()
    .min(2, 'Company name must be at least 2 characters'),
});

export function CompanyNewEditForm() {
  const theme = useTheme();
  const params = useParams({ strict: false });

  const [currentTab, setCurrentTab] = useState<CompanySettingsTabs>(
    COMPANY_SETTINGS_TABS.main
  );

  const me = useQuery(api.functions.users.getMe);

  const companySettings = useQuery(
    api.functions.companySettings.getCompanySettings,
    params.companyId
      ? {
          companyId: params.companyId as CompanyId,
        }
      : 'skip'
  );

  const currentCompany = useQuery(
    api.functions.companies.getCompany,
    params.companyId
      ? {
          companyId: params.companyId as CompanyId,
        }
      : 'skip'
  );

  const isSuperAdmin = me && me.clerkUser.isSuperAdmin;
  const isImpersonating = me && !!me.impersonatingCompanyId;

  const adminUsers = useQuery(
    api.functions.users.getMyMechanicUsers,
    isSuperAdmin && !isImpersonating ? {} : 'skip'
  );

  const hasAccountExecutive =
    currentCompany && currentCompany.accountExecutiveId;
  const hasCustomerSuccessRep =
    currentCompany && currentCompany.customerSuccessRepId;

  const accountReps = useQuery(
    api.functions.users.getCompanyReps,
    me?.isCompanyPrimaryAdmin || (isSuperAdmin && isImpersonating) ? {} : 'skip'
  );

  const existingCompanyServices = useQuery(
    api.functions.companyServices.getCompanyServices,
    params.companyId
      ? {
          companyId: params.companyId as CompanyId,
        }
      : 'skip'
  );

  const accountExecutive = accountReps?.find(
    r => r._id === currentCompany?.accountExecutiveId
  );
  const customerSuccessRep = accountReps?.find(
    r => r._id === currentCompany?.customerSuccessRepId
  );

  const createCompany = useMutation(api.functions.companies.createCompany);
  const editCompany = useMutation(api.functions.companies.editCompanyInfo);

  const companyTypeList = companyType.options;

  const { searchResults, coordinates, setCoordinates, searchAddress } =
    useAddressSearch();

  const { Field, Subscribe, handleSubmit, reset, store } = useForm({
    defaultValues: {
      name: currentCompany?.name || '',
      companyType: currentCompany?.companyType || '',
      ein: currentCompany?.ein || '',
      dotNo: currentCompany?.dotNo || '',
      about: currentCompany?.about || '',
      latitude: currentCompany?.location?.latitude || 0,
      longitude: currentCompany?.location?.longitude || 0,
      address: currentCompany?.location?.address || '',
      zip: currentCompany?.location?.zip || '',
      contactEmail: currentCompany?.contactEmail || '',
      contactPhoneNo: currentCompany?.contactPhoneNo || '',
      customerSuccessRepId: currentCompany?.customerSuccessRepId ?? null,
      accountExecutiveId: currentCompany?.accountExecutiveId ?? null,
      serviceIds: existingCompanyServices
        ? existingCompanyServices.map(s => s.serviceId)
        : [],
    },
    onSubmit: async ({ value }) => {
      try {
        if (currentCompany) {
          const result = await editCompany({
            companyId: currentCompany._id,
            name: value.name,
            ein: value.ein,
            dotNo: value.dotNo,
            about: value.about,
            location: {
              latitude: coordinates?.lat ?? 0,
              longitude: coordinates?.long ?? 0,
              address: value.address,
              lastUpdated: new Date().toISOString(),
            },
            contactEmail: value.contactEmail,
            contactPhoneNo: value.contactPhoneNo,
            customerSuccessRepId: value.customerSuccessRepId ?? null,
            accountExecutiveId: value.accountExecutiveId ?? null,
            serviceIds: value.serviceIds,
          });
          if (result.success) {
            toast.success(result.message);
          } else {
            toast.error(result.message);
          }
        } else {
          const result = await createCompany({
            name: value.name,
            companyType:
              value.companyType as (typeof companyType.options)[number],
            ein: value.ein,
            dotNo: value.dotNo,
            about: value.about,
            location: {
              latitude: coordinates?.lat ?? 0,
              longitude: coordinates?.long ?? 0,
              address: value.address,
              lastUpdated: new Date().toISOString(),
            },
            contactEmail: value.contactEmail,
            contactPhoneNo: value.contactPhoneNo,
            customerSuccessRepId: value.customerSuccessRepId ?? null,
            accountExecutiveId: value.accountExecutiveId ?? null,
            serviceIds: value.serviceIds,
          });
          if (result.success) {
            toast.success(result.message);
            reset();
            searchAddress('');
          } else {
            toast.error(result.message);
          }
        }
      } catch (error) {
        console.error('Failed to save company:', error);
        toast.error('Failed to save company. Please try again.');
      }
    },
  });

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success('Copied to clipboard', { position: 'bottom-center' });
  };

  const companyTypeValue = useStore(
    store,
    s => s.values.companyType
  ) as CompanyType;

  const isFleet = companyTypeValue === 'FLEET';
  const isService =
    companyTypeValue === 'REPAIR_SHOP' || companyTypeValue === 'TOWING';

  return (
    <>
      <Card>
        <CardHeader
          title={currentCompany ? 'Edit Company' : 'Create Company'}
          subheader={
            currentCompany
              ? 'Edit the details of the company'
              : "Enter the details of the company you'd like to invite"
          }
          sx={{ mb: 3 }}
        />
        <Divider />

        <CustomTabs
          value={currentTab}
          onChange={(e, val) => setCurrentTab(val)}
        >
          <Tab label="Main Details" value={COMPANY_SETTINGS_TABS.main} />
          <Tab label="Advanced" value={COMPANY_SETTINGS_TABS.advanced} />
        </CustomTabs>
        {currentTab === COMPANY_SETTINGS_TABS.advanced &&
          companySettings &&
          currentCompany && (
            <CompanyAdvancedSettings
              companySettings={companySettings}
              company={currentCompany}
            />
          )}

        {currentTab === COMPANY_SETTINGS_TABS.main && (
          <>
            <Stack spacing={3} sx={{ p: 3 }}>
              <form
                onSubmit={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSubmit();
                }}
                style={{ width: '100%' }}
              >
                <Typography variant="h6">Company Details</Typography>
                <Box
                  rowGap={3}
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                  }}
                  sx={{ my: 2 }}
                >
                  <Field
                    name="name"
                    children={({ state, handleChange, handleBlur }) => (
                      <TextField
                        label="Company Name"
                        variant="outlined"
                        fullWidth
                        required
                        value={state.value}
                        error={state.meta.errors.length > 0}
                        helperText={state.meta.errors[0]}
                        onChange={e => handleChange(e.target.value)}
                        onBlur={handleBlur}
                      />
                    )}
                  />
                  <Field
                    name="companyType"
                    listeners={{
                      onChange: ({ fieldApi }) =>
                        fieldApi.form.setFieldValue('serviceIds', []),
                    }}
                    children={({ state, handleChange, handleBlur }) => (
                      <TextField
                        disabled={!!currentCompany}
                        select
                        label="Company Type"
                        variant="outlined"
                        fullWidth
                        required
                        value={state.value}
                        onChange={e =>
                          handleChange(e.target.value as typeof state.value)
                        }
                        onBlur={handleBlur}
                        error={state.meta.errors.length > 0}
                        helperText={state.meta.errors[0]}
                      >
                        {companyTypeList
                          .filter(t => t !== 'SYSTEM_ADMIN' && t !== 'TOWING')
                          .map(type => (
                            <MenuItem key={type} value={type}>
                              {getCompanyTypeDisplay(type as CompanyType)}
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                  />
                  {isService && (
                    <Field
                      name="serviceIds"
                      children={({ state, handleChange }) => (
                        <ServiceSelection
                          value={state.value}
                          onChange={handleChange}
                          error={state.meta.errors.length > 0}
                          helperText={state.meta.errors[0] as string}
                        />
                      )}
                    />
                  )}
                  <Field
                    name="ein"
                    children={({ state, handleChange, handleBlur }) => (
                      <TextField
                        label="EIN"
                        variant="outlined"
                        fullWidth
                        value={state.value}
                        onChange={e => handleChange(e.target.value)}
                        onBlur={handleBlur}
                        error={state.meta.errors.length > 0}
                        helperText={state.meta.errors[0]}
                      />
                    )}
                  />
                  {isFleet && (
                    <Field
                      name="dotNo"
                      children={({ state, handleChange, handleBlur }) => (
                        <TextField
                          label="DOT #"
                          variant="outlined"
                          fullWidth
                          value={state.value}
                          onChange={e => handleChange(e.target.value)}
                          onBlur={handleBlur}
                          error={state.meta.errors.length > 0}
                          helperText={state.meta.errors[0]}
                        />
                      )}
                    />
                  )}
                  <Field
                    name="address"
                    children={({ state, handleChange, handleBlur }) => (
                      <AddressInput
                        value={state.value}
                        onChange={value => {
                          handleChange(value || '');
                        }}
                        error={state.meta.errors.length > 0}
                        helperText={state.meta.errors[0]}
                        searchResults={searchResults}
                        searchAddress={searchAddress}
                        onCoordinatesChange={coords => {
                          setCoordinates(
                            coords
                              ? { lat: coords.latitude, long: coords.longitude }
                              : null
                          );
                          // TODO: Should set this async, but need to think of a better pattern for clearing values on the backend
                        }}
                        required
                      />
                    )}
                  />
                  <Field
                    name="about"
                    children={({ state, handleChange, handleBlur }) => (
                      <TextField
                        multiline
                        label="About"
                        variant="outlined"
                        fullWidth
                        value={state.value}
                        onChange={e => handleChange(e.target.value)}
                        onBlur={handleBlur}
                        error={state.meta.errors.length > 0}
                        helperText={state.meta.errors[0]}
                      />
                    )}
                  />
                </Box>

                <Typography variant="h6">Contact Details</Typography>
                <Box
                  rowGap={3}
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                  }}
                  sx={{ my: 2 }}
                >
                  <Field
                    name="contactEmail"
                    children={({ state, handleChange, handleBlur }) => (
                      <TextField
                        label="Contact Email"
                        variant="outlined"
                        fullWidth
                        required
                        value={state.value}
                        error={state.meta.errors.length > 0}
                        helperText={state.meta.errors[0]}
                        onChange={e => handleChange(e.target.value)}
                        onBlur={handleBlur}
                      />
                    )}
                  />
                  <Field
                    name="contactPhoneNo"
                    children={({ state, handleChange, handleBlur }) => (
                      <PhoneInput
                        required
                        label="Contact Phone Number"
                        fullWidth
                        value={state.value}
                        onChange={newValue => handleChange(newValue || '')}
                        onBlur={handleBlur}
                        error={state.meta.errors.length > 0}
                        helperText={state.meta.errors[0]}
                      />
                    )}
                  />
                </Box>

                <Typography variant="h6">
                  Your myMechanic Support Team
                </Typography>

                <Box
                  rowGap={3}
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                  }}
                  sx={{ my: 2 }}
                >
                  {isSuperAdmin && !isImpersonating ? (
                    <>
                      <Field
                        name="customerSuccessRepId"
                        children={({ state, handleChange }) => (
                          <Autocomplete
                            fullWidth
                            options={adminUsers || []}
                            getOptionLabel={option => option.clerkUser.fullName}
                            value={
                              adminUsers?.find(l => state.value === l._id) ||
                              null
                            }
                            isOptionEqualToValue={(option, value) =>
                              option?._id === value?._id
                            }
                            onChange={(event, newValue) =>
                              handleChange(newValue?._id ?? null)
                            }
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="myMechanic Customer Success Representative"
                                error={state.meta.errors.length > 0}
                                helperText={state.meta.errors[0]}
                              />
                            )}
                            renderOption={(props, option) => (
                              <li {...props} key={option._id}>
                                {option.clerkUser.fullName}
                              </li>
                            )}
                          />
                        )}
                      />
                      <Field
                        name="accountExecutiveId"
                        children={({ state, handleChange }) => (
                          <Autocomplete
                            fullWidth
                            options={adminUsers || []}
                            getOptionLabel={option => option.clerkUser.fullName}
                            value={
                              adminUsers?.find(l => state.value === l._id) ||
                              null
                            }
                            isOptionEqualToValue={(option, value) =>
                              option?._id === value?._id
                            }
                            onChange={(event, newValue) =>
                              handleChange(newValue?._id ?? null)
                            }
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="myMechanic Account Executive"
                                error={state.meta.errors.length > 0}
                                helperText={state.meta.errors[0]}
                              />
                            )}
                            renderOption={(props, option) => (
                              <li {...props} key={option._id}>
                                {option.clerkUser.fullName}
                              </li>
                            )}
                          />
                        )}
                      />
                    </>
                  ) : (
                    <>
                      <Card>
                        <CardHeader
                          avatar={
                            hasCustomerSuccessRep && (
                              <AnimateAvatar
                                width={96}
                                slotProps={{
                                  avatar: {
                                    src: customerSuccessRep?.imageUrl,
                                    alt: customerSuccessRep?.fullName,
                                  },
                                  overlay: {
                                    border: 2,
                                    spacing: 3,
                                    color: `linear-gradient(135deg, ${varAlpha(theme.vars.palette.primary.mainChannel, 0)} 25%, ${theme.vars.palette.primary.main} 100%)`,
                                  },
                                }}
                              >
                                {customerSuccessRep?.fullName
                                  ?.charAt(0)
                                  .toUpperCase()}
                              </AnimateAvatar>
                            )
                          }
                          title={'Customer Success Representative'}
                          subheader={
                            hasCustomerSuccessRep
                              ? `${customerSuccessRep?.fullName} (${customerSuccessRep?.email})`
                              : 'No Customer Success Representative Assigned'
                          }
                        />

                        <CardActions>
                          {hasCustomerSuccessRep && (
                            <>
                              <Button
                                onClick={() =>
                                  customerSuccessRep
                                    ? handleCopy(customerSuccessRep?.email)
                                    : null
                                }
                                startIcon={
                                  <Iconify icon="solar:copy-bold-duotone" />
                                }
                              >
                                Copy Email Address
                              </Button>
                            </>
                          )}
                        </CardActions>
                      </Card>
                      <Card>
                        <CardHeader
                          avatar={
                            hasAccountExecutive && (
                              <AnimateAvatar
                                width={96}
                                slotProps={{
                                  avatar: {
                                    src: accountExecutive?.imageUrl,
                                    alt: accountExecutive?.fullName,
                                  },
                                  overlay: {
                                    border: 2,
                                    spacing: 3,
                                    color: `linear-gradient(135deg, ${varAlpha(theme.vars.palette.primary.mainChannel, 0)} 25%, ${theme.vars.palette.primary.main} 100%)`,
                                  },
                                }}
                              >
                                {accountExecutive?.fullName
                                  ?.charAt(0)
                                  .toUpperCase()}
                              </AnimateAvatar>
                            )
                          }
                          title={'Account Executive'}
                          subheader={
                            hasAccountExecutive
                              ? `${accountExecutive?.fullName} (${accountExecutive?.email})`
                              : 'No Account Executive Assigned'
                          }
                        />

                        <CardActions>
                          {hasAccountExecutive && (
                            <>
                              <Button
                                onClick={() =>
                                  accountExecutive
                                    ? handleCopy(accountExecutive?.email)
                                    : null
                                }
                                startIcon={
                                  <Iconify icon="solar:copy-bold-duotone" />
                                }
                              >
                                Copy Email Address
                              </Button>
                            </>
                          )}
                        </CardActions>
                      </Card>
                    </>
                  )}
                </Box>

                <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                  <Subscribe
                    selector={state => [state.canSubmit, state.isSubmitting]}
                    children={([canSubmit, isSubmitting]) => {
                      return (
                        <LoadingButton
                          type="submit"
                          color="primary"
                          variant="contained"
                          loading={isSubmitting}
                          disabled={!canSubmit}
                        >
                          {!currentCompany ? 'Invite company' : 'Save changes'}
                        </LoadingButton>
                      );
                    }}
                  />
                </Stack>
              </form>
            </Stack>
          </>
        )}
      </Card>
      {currentCompany && isSuperAdmin && !isImpersonating && (
        <CompanyUserList currentCompany={currentCompany} />
      )}
    </>
  );
}
