import { api } from '@cvx/api';
import { createFileRoute } from '@tanstack/react-router';
import { ValidPaths } from 'src/minimal-theme/layouts/config-nav-dashboard';
import { MyAssignmentsView } from 'src/sections/request/view/MyAssignmentsView';
import {
  checkRouteAccess,
  routeAccessConfigs,
} from 'src/utils/checkRouteAccess';

export const Route = createFileRoute(
  '/_auth/dashboard/requests/my-assignments'
)({
  beforeLoad: ({ context, location }) =>
    checkRouteAccess(
      context,
      routeAccessConfigs.allRolesExceptSuperAdmin,
      location.pathname as ValidPaths
    ),
  loader: async ({ context }) => {
    return await context.convex.query(
      api.functions.requests.getAssignedRequests,
      {}
    );
  },
  component: MyAssignmentsView,
});
