import { api } from '@cvx/api';
import { CompanyId } from '@cvx/types/entities/sharedIds';
import {
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useQuery } from 'convex/react';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { DashboardContent } from 'src/minimal-theme/layouts/dashboard';
import { MetricsDisplay } from 'src/sections/admin/MetricsDisplay';
import { MetricStats } from 'src/sections/admin/MetricStats';

export function AdminDashboardView() {
  const [showSystemWide, setShowSystemWide] = useState<boolean>(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState<CompanyId | null>(
    null
  );
  const [selectedDate, setSelectedDate] = useState<Dayjs>(
    dayjs(new Date()).startOf('month')
  );

  const me = useQuery(api.functions.users.getMe);

  const superAdmin = me && me.clerkUser.isSuperAdmin;

  const companies = useQuery(
    api.functions.companies.getCompanies,
    superAdmin ? {} : 'skip'
  );

  const actualCompanyId = superAdmin ? selectedCompanyId : me?.companyId;

  const actualCompanyType = superAdmin
    ? companies?.find(company => company._id === selectedCompanyId)?.companyType
    : me?.company.companyType;

  const hasPermissions = superAdmin || me?.isCompanyPrimaryAdmin;

  // Get metrics for selected company/month
  const metrics = useQuery(
    api.functions.billingMetrics.getBillingMetrics,
    actualCompanyId && hasPermissions
      ? {
          companyId: actualCompanyId,
          month: dayjs(selectedDate).format('YYYY-MM'),
        }
      : 'skip'
  );

  const systemWideMetrics = useQuery(
    api.functions.billingMetrics.getSystemBillingMetrics,
    superAdmin && showSystemWide
      ? {
          month: dayjs(selectedDate).format('YYYY-MM'),
        }
      : 'skip'
  );

  return (
    <DashboardContent>
      <Stack spacing={3}>
        <Card>
          <CardContent>
            <Stack direction="row" spacing={2} alignItems="center">
              {superAdmin && !showSystemWide && (
                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel>Company</InputLabel>
                  <Select
                    value={selectedCompanyId ?? ''}
                    onChange={e =>
                      setSelectedCompanyId(e.target.value as CompanyId)
                    }
                    label="Company"
                  >
                    {companies?.map(company => (
                      <MenuItem key={company._id} value={company._id}>
                        {company.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              <DatePicker
                label="Select Month"
                views={['month', 'year']}
                value={selectedDate}
                onChange={newDate =>
                  newDate && setSelectedDate(dayjs(newDate).startOf('month'))
                }
              />
              {superAdmin && (
                <FormControlLabel
                  label={'Show System Wide'}
                  control={
                    <Checkbox
                      size="small"
                      checked={showSystemWide}
                      onClick={() => setShowSystemWide(prev => !prev)}
                    />
                  }
                />
              )}
            </Stack>
          </CardContent>
        </Card>

        {actualCompanyId && metrics && actualCompanyType && !showSystemWide && (
          <MetricsDisplay metrics={metrics} companyType={actualCompanyType} />
        )}
        {showSystemWide && systemWideMetrics && (
          <MetricStats metrics={systemWideMetrics} />
        )}
      </Stack>
    </DashboardContent>
  );
}
