import { ConvexUserZodId, VehicleZodId } from '@cvx/types/zod/commonZodId';
import { zodToConvex } from 'convex-helpers/server/zod';
import { defineTable } from 'convex/server';
import { z } from 'zod';

// Status enum to track the state of a vehicle assignment
export const vehicleAssignmentStatus = z.enum([
  'ACTIVE', // Currently driving/using this vehicle
  'INACTIVE', // Not currently driving this vehicle
]);

export type VehicleAssignmentStatus = z.infer<typeof vehicleAssignmentStatus>;

export const driverHistorySchema = z.object({
  userId: ConvexUserZodId,
  vehicleId: VehicleZodId,
  started: z.string(),
  stopped: z.string().optional(),
  status: vehicleAssignmentStatus,
  createdBy: ConvexUserZodId,
  updatedBy: ConvexUserZodId,
});

export const driverHistory = defineTable(
  zodToConvex(driverHistorySchema).fields
)
  .index('by_vehicleId', ['vehicleId'])
  .index('by_userId', ['userId'])
  .index('by_vehicle_user_status', ['vehicleId', 'userId', 'status'])
  .index('by_vehicle_status', ['vehicleId', 'status'])
  .index('by_userId_status', ['userId', 'status']);
