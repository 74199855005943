import { api } from '@cvx/api';
import { RequestId } from '@cvx/types/entities/sharedIds';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import { useParams } from '@tanstack/react-router';
import { usePaginatedQuery, useQuery } from 'convex/react';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { FormattedHistoryEntry } from 'src/convex/schema/entities/requests/requestHistory';
import { getUserRoleDisplay } from 'src/convex/schema/enums/userRole';

export function RequestHistory() {
  const params = useParams({ from: '/_auth/dashboard/requests/$requestId' });
  const request = useQuery(api.functions.requests.getRequest, {
    requestId: params.requestId as RequestId,
  });

  const { results, status, loadMore } = usePaginatedQuery(
    api.functions.requests.getRequestHistory,
    request ? { requestId: request._id } : 'skip',
    { initialNumItems: 20 }
  );

  const groupedResults = useMemo(() => {
    if (!results) return [];
    return Object.values(groupEntriesByDay(results));
  }, [results]);

  const isLoading = status === 'LoadingFirstPage' || status === 'LoadingMore';
  return (
    <Card>
      <CardHeader title="Request History" />
      <CardContent>
        {groupedResults
          ?.slice()
          .reverse()
          .map(day => (
            <List key={day.date}>
              <ListSubheader>
                {dayjs(day.date).format('MMM D, YYYY')}
              </ListSubheader>

              {day.entries
                .slice()
                .reverse()
                .map(entry => (
                  <ListItem key={entry.id}>
                    <ListItemText
                      primary={dayjs(entry.timestamp).format(
                        'MMM D, YYYY h:mm A'
                      )}
                      secondary={
                        <MessageContent components={entry.messageComponents} />
                      }
                    />
                  </ListItem>
                ))}
            </List>
          ))}

        <CardActions>
          {status === 'CanLoadMore' && (
            <LoadingButton
              onClick={() => loadMore(20)}
              disabled={isLoading}
              loading={isLoading}
            >
              Load More
            </LoadingButton>
          )}
        </CardActions>
      </CardContent>
    </Card>
  );
}

// TODO: proper typing
function MessageContent({ components }: { components: any[] }) {
  return components.map((component, index) => {
    switch (component.type) {
      case 'USER_NAME':
        return (
          <Box component="span" key={index}>
            {component.value}
          </Box>
        );
      case 'USER_ROLE':
      case 'TARGET_ROLE':
        return (
          <Box component="span" key={index}>
            {getUserRoleDisplay(component.value)}
          </Box>
        );
      case 'ACTION_TEXT':
        return (
          <Box component="span" key={index}>
            {component.value}
          </Box>
        );
      case 'LOCATION':
        return (
          <Box component="span" key={index}>
            {component.value}
          </Box>
        );
      case 'DOCUMENT':
        return (
          <Box component="span" key={index}>
            {component.value}
          </Box>
        );
      case 'CUSTOM':
        return (
          <Box component="span" key={index}>
            {component.value}
          </Box>
        );
      default:
        return (
          <Box component="span" key={index}>
            {component.value}
          </Box>
        );
    }
  });
}

function groupEntriesByDay(entries: FormattedHistoryEntry[]) {
  return entries.reduce(
    (acc, entry) => {
      const day = dayjs(entry.timestamp).startOf('day').valueOf();

      if (!acc[day]) {
        acc[day] = {
          date: day,
          entries: [],
        };
      }

      acc[day].entries.push(entry);
      return acc;
    },
    {} as Record<number, { date: number; entries: FormattedHistoryEntry[] }>
  );
}
