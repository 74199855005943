import { VendorServiceId } from '@cvx/types/entities/sharedIds';

export function getServiceChanges<
  T extends { serviceId: VendorServiceId; isActive?: boolean },
>(
  existingServices: T[],
  serviceIds: VendorServiceId[]
): { servicesToAdd: VendorServiceId[]; servicesToDeactivate: T[] } {
  const existingServiceIds = new Set(existingServices.map(s => s.serviceId));

  const servicesToAdd = serviceIds.filter(id => !existingServiceIds.has(id));
  const servicesToDeactivate = existingServices.filter(
    s => !serviceIds.includes(s.serviceId) && s.isActive
  );

  return { servicesToAdd, servicesToDeactivate };
}
