import { Box, Container, Typography } from '@mui/material';

export function PrivacyPolicyPage() {
  return (
    <Container maxWidth="lg" sx={{ height: '100vh' }}>
      <Box sx={{ height: '100%' }}>
        <Typography variant="body1" component="div" sx={{ height: '100%' }}>
          <iframe
            src="https://app.termly.io/policy-viewer/policy.html?policyUUID=6fe47962-f161-4412-b229-961801a4f52f"
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
              overflow: 'auto',
            }}
            title="Privacy Policy"
          />
        </Typography>
      </Box>
    </Container>
  );
}
