import { api } from '@cvx/api';
import { RequestId } from '@cvx/types/entities/sharedIds';
import { createFileRoute } from '@tanstack/react-router';
import { ValidPaths } from 'src/minimal-theme/layouts/config-nav-dashboard';
import Page from 'src/pages/dashboard/request/details';
import {
  checkRouteAccess,
  routeAccessConfigs,
} from 'src/utils/checkRouteAccess';
import { handleConvexRouteQuery } from 'src/utils/handleConvexRouteQuery';

export const Route = createFileRoute('/_auth/dashboard/requests/$requestId')({
  beforeLoad: ({ context, location }) =>
    checkRouteAccess(
      context,
      routeAccessConfigs.allRolesExceptSuperAdmin,
      location.pathname as ValidPaths
    ),
  loader: async ({ context, params }) => {
    return handleConvexRouteQuery(
      () =>
        context.convex.query(api.functions.requests.getRequest, {
          requestId: params.requestId as RequestId,
        }),
      { redirectTo: '/dashboard/requests' }
    );
  },
  component: Page,
});
