import {
  Alert,
  Box,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { api } from '@cvx/api';
import { RequestId, RequestImageId } from '@cvx/types/entities/sharedIds';
import { useMutation } from 'convex/react';
import { useEffect, useState } from 'react';
import { EnhancedUser } from 'src/convex/functions/users';
import {
  getImageCategoryLabel,
  ImageCategory,
  imageCategoryGroups,
} from 'src/convex/schema/entities/requests/requestImages';
import { Image } from 'src/minimal-theme/components/image';
import { Upload } from 'src/minimal-theme/components/upload';
import { useAvailableCategories } from 'src/sections/request/hooks/useAvailableCategories';
import { resizeImage } from 'src/utils/resizeImage';

type AddPhotosDialogProps = {
  open: boolean;
  requestId: RequestId;
  onClose: () => void;
  me: EnhancedUser;
  defaultCategory?: ImageCategory;
};

export function AddPhotosDialog({
  open,
  requestId,
  onClose,
  me,
  defaultCategory = 'OTHER',
}: AddPhotosDialogProps) {
  const [optimisticPreviews, setOptimisticPreviews] = useState<string[]>([]);
  const [uploadingPhotos, setUploadingPhotos] = useState<boolean>(false);
  const [uploadedPhotos, setUploadedPhotos] = useState<RequestImageId[]>([]);

  const categoryOptions = useAvailableCategories(me);

  const [category, setCategory] = useState<ImageCategory>(defaultCategory);

  // Update category when defaultCategory changes
  useEffect(() => {
    setCategory(defaultCategory);
  }, [defaultCategory]);

  const generateUrls = useMutation(
    api.functions.requestImages.generatePhotoUploadUrl
  );
  const savePhotos = useMutation(api.functions.requestImages.saveRequestPhotos);
  const confirmPhotos = useMutation(
    api.functions.requestImages.confirmRequestPhotos
  );
  const deletePhotos = useMutation(
    api.functions.requestImages.deleteUnconfirmedRequestPhotos
  );

  const onDrop = async (files: File[]) => {
    setUploadingPhotos(true);

    const previews = files.map(file => URL.createObjectURL(file));
    setOptimisticPreviews(prev => [...prev, ...previews]);

    const resizedFiles = await Promise.all(
      files.map(file => resizeImage(file, 1920))
    );

    // Get multiple upload URLs
    const uploadUrls = await generateUrls({
      requestId,
      count: files.length,
    });

    // Upload each file to its own URL
    const uploads = await Promise.all(
      resizedFiles.map(async (file, i) => {
        const response = await fetch(uploadUrls[i], {
          method: 'POST',
          headers: { 'Content-Type': file.type },
          body: file,
        });
        return await response.json();
      })
    );

    // Save to database
    const photoIds = await savePhotos({
      photos: uploads.map(u => ({ storageId: u.storageId })),
      requestId,
    });

    setUploadedPhotos(prev => [...prev, ...photoIds]);
    setUploadingPhotos(false);
  };

  const confirmUpload = async () => {
    if (uploadedPhotos.length) {
      await confirmPhotos({ photoIds: uploadedPhotos.map(p => p), category });
    }
    setOptimisticPreviews([]);
    setUploadedPhotos([]);
    onClose();
  };

  const handleCancel = async () => {
    if (uploadedPhotos.length) {
      await deletePhotos({ photoIds: uploadedPhotos.map(p => p) });
    }
    setOptimisticPreviews([]);
    setUploadedPhotos([]);
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle sx={{ pb: 2 }}>Add Photos</DialogTitle>

      <DialogContent sx={{ typography: 'body2' }}>
        <Stack direction={'column'} spacing={2} sx={{ mt: 2 }}>
          <FormControl fullWidth>
            <InputLabel htmlFor="category-select">Photo Category</InputLabel>
            <Select
              labelId="category-select"
              label="Photo Category"
              value={category}
              onChange={e => setCategory(e.target.value as ImageCategory)}
            >
              {Object.entries(imageCategoryGroups).map(
                ([groupName, categories]) => [
                  <ListSubheader
                    key={groupName}
                    sx={{
                      borderBottom: '2px solid rgba(0,0,0,.1)',
                      fontWeight: 600,
                      fontSize: '1rem',
                    }}
                  >
                    {groupName}
                  </ListSubheader>,
                  ...categories
                    .filter(cat => categoryOptions.includes(cat))
                    .map((category: ImageCategory) => (
                      <MenuItem key={category} value={category}>
                        {getImageCategoryLabel(category as ImageCategory)}
                      </MenuItem>
                    )),
                ]
              )}
            </Select>
          </FormControl>
          {category === 'WORK_ORDER' && (
            <Alert severity="info">
              Work Order photos (and the category itself) are only visible to
              Service Provider participants.
            </Alert>
          )}
          <Upload
            multiple
            onDrop={onDrop}
            helperText={'Drag and drop or click to upload photos'}
            sx={{ mb: 2 }}
          />
        </Stack>
        <Box>
          {optimisticPreviews.map(i => (
            <Image
              key={i}
              src={i}
              sx={{
                borderRadius: 2,
                cursor: 'pointer',
                transition: theme => theme.transitions.create('opacity'),
                '&:hover': { opacity: 0.8 },
                mb: 2,
              }}
            />
          ))}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={confirmUpload}
          disabled={uploadingPhotos || uploadedPhotos.length === 0}
        >
          Confirm Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}
