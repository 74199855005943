import { api } from '@cvx/api';
import { ConvexUserId } from '@cvx/types/entities/sharedIds';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { usePaginatedQuery } from 'convex/react';
import { useState } from 'react';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { useResponsive } from 'src/minimal-theme/hooks/use-responsive';

type PreviousCustomerSelectionProps = {
  onSelectDriver: (driverId: ConvexUserId) => void;
  onSelectFleetDispatcher: (dispatcherId: ConvexUserId) => void;
  selectedDriverId?: ConvexUserId;
  selectedDispatcherId?: ConvexUserId;
  disableDriverSelection?: boolean;
  disableFleetDispatcherSelection?: boolean;
};

const customerPageSize = 5;

const title = 'Previous Customers';
const subheader =
  'Select a driver and/or fleet dispatcher from the lists below';

export const PreviousCustomerSelection = ({
  onSelectDriver,
  onSelectFleetDispatcher,
  selectedDriverId,
  selectedDispatcherId,
  disableDriverSelection,
  disableFleetDispatcherSelection,
}: PreviousCustomerSelectionProps) => {
  const [open, setOpen] = useState(false);

  const {
    results: drivers,
    status: driverStatus,
    loadMore: loadMoreDrivers,
  } = usePaginatedQuery(
    api.functions.previousCustomers.getPreviousCustomers,
    { roleType: 'DRIVER_FLEET' },
    { initialNumItems: customerPageSize }
  );

  const {
    results: dispatchers,
    status: dispatcherStatus,
    loadMore: loadMoreDispatchers,
  } = usePaginatedQuery(
    api.functions.previousCustomers.getPreviousCustomers,
    { roleType: 'FLEET_DISPATCHER' },
    { initialNumItems: customerPageSize }
  );

  const mdUp = useResponsive('up', 'md');

  const content = (
    <Box>
      <CardHeader title={'Recent Drivers'} sx={{ p: 0, mt: 2 }} />

      <List>
        {drivers
          .filter(d => !d.user.deletedAt)
          .map(c => (
            <ListItem
              key={c._id}
              sx={{
                mb: 1,
              }}
              disablePadding
            >
              <ListItemButton
                role={undefined}
                onClick={() => onSelectDriver(c.user._id)}
                disabled={
                  selectedDriverId === c.user._id || disableDriverSelection
                }
                sx={{
                  px: 2.5,
                  py: 2,
                  borderRadius: 2,
                  boxShadow:
                    'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
                }}
              >
                <ListItemText
                  primary={c.user.clerkUser.fullName}
                  secondary={`${c.locationName} - ${c.companyName} (${c.user.clerkUser.primaryPhoneNumber.phoneNumber})`}
                />
                <ListItemIcon>
                  <Checkbox
                    edge="end"
                    checked={selectedDriverId === c.user._id}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}

        {driverStatus === 'CanLoadMore' && (
          <LoadingButton
            onClick={() => loadMoreDrivers(customerPageSize)}
            variant="contained"
            fullWidth
          >
            Load More Drivers
          </LoadingButton>
        )}
      </List>
      <CardHeader title={'Recent Fleet Dispatchers'} sx={{ p: 0, mt: 2 }} />
      <List>
        {dispatchers
          .filter(d => !d.user.deletedAt)
          .map(c => (
            <ListItem
              key={c._id}
              disablePadding
              sx={{
                mb: 1,
              }}
            >
              <ListItemButton
                role={undefined}
                disabled={
                  selectedDispatcherId === c.user._id ||
                  disableFleetDispatcherSelection
                }
                onClick={() => onSelectFleetDispatcher(c.user._id)}
                sx={{
                  px: 2.5,
                  py: 2,
                  borderRadius: 2,
                  boxShadow:
                    'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
                }}
              >
                <ListItemText
                  primary={c.user.clerkUser.fullName}
                  secondary={`${c.locationName} - ${c.companyName} (${c.user.clerkUser.primaryPhoneNumber.phoneNumber})`}
                />
                <ListItemIcon>
                  <Checkbox
                    edge="end"
                    checked={selectedDispatcherId === c.user._id}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}
        {dispatcherStatus === 'CanLoadMore' && (
          <LoadingButton
            onClick={() => loadMoreDispatchers(customerPageSize)}
            fullWidth
            variant="contained"
          >
            Load More Dispatchers
          </LoadingButton>
        )}
      </List>
    </Box>
  );

  if (!mdUp) {
    return (
      <Box>
        <Button
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 2,
            mt: 0.5,
            mr: 0.5,
          }}
          startIcon={<Iconify icon="solar:history-bold-duotone" width={32} />}
          variant="contained"
          onClick={() => setOpen(true)}
        >
          {title}
        </Button>
        <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
          <DialogTitle>
            {title} <CardHeader subheader={subheader} sx={{ p: 0 }} />
          </DialogTitle>
          <DialogContent sx={{ py: 5, px: 3, display: 'flex' }}>
            {content}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
  return (
    <Card
      sx={{
        position: 'absolute',
        right: -345,
        top: 0,
        zIndex: 9,
        width: 330,
      }}
    >
      <CardHeader title={title} subheader={subheader} />
      <Box sx={{ pb: 2, px: 3 }}>{content}</Box>
    </Card>
  );
};
