import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useLocationTracking } from 'src/context/LocationTrackingProvider';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { useResponsive } from 'src/minimal-theme/hooks/use-responsive';
import { LocationTrackingInstructions } from 'src/minimal-theme/layouts/dashboard/LocationTrackingInstructions';

export const LocationTrackingAlert = () => {
  const {
    isTracking,
    isEnabled,
    enableTracking,
    shouldPromptForTracking,
    enabling,
    error,
    dismissError,
    disableTrackingAttemptForSession,
  } = useLocationTracking();

  const isMobile = useResponsive('down', 'md');

  if (disableTrackingAttemptForSession) {
    return null;
  }

  // Get content based on error state
  let title = 'Location Tracking';
  let message: string | React.ReactNode =
    'Please click the Allow button to enable location tracking for the active request you are involved in.';
  let actions = (
    <LoadingButton
      variant="contained"
      onClick={enableTracking}
      disabled={enabling}
      loading={enabling}
    >
      Allow
    </LoadingButton>
  );

  // Override content if there's an error
  if (error === 'blocked') {
    title = 'Location Access Required';
    message = <LocationTrackingInstructions />;
    actions = (
      <>
        <Button onClick={dismissError} color="inherit">
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          onClick={() => window.location.reload()}
        >
          Refresh Page
        </LoadingButton>{' '}
      </>
    );
  } else if (error === 'unavailable') {
    title = 'Location Unavailable';
    message =
      "Unable to determine your location. Please check that your device's location services are enabled and you have a GPS signal or network connection.";
    actions = (
      <>
        {' '}
        <Button onClick={dismissError} color="inherit">
          Cancel
        </Button>
        <LoadingButton
          onClick={enableTracking}
          disabled={enabling}
          loading={enabling}
        >
          Retry
        </LoadingButton>
      </>
    );
  } else if (error === 'timeout') {
    title = 'Location Timeout';
    message =
      "It's taking longer than expected to get your location. You may be indoors or have poor GPS signal.";
    actions = (
      <>
        <Button onClick={dismissError} color="inherit">
          Cancel
        </Button>
        <LoadingButton
          onClick={enableTracking}
          disabled={enabling}
          loading={enabling}
        >
          Retry
        </LoadingButton>
      </>
    );
  }

  if (!isEnabled && shouldPromptForTracking) {
    return (
      <Dialog open>
        <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>
        <DialogContent>{message}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    );
  }

  if (!isTracking) return null;

  return (
    <Alert
      severity="info"
      icon={<Iconify icon="solar:map-point-wave-bold" />}
      sx={{
        fontWeight: 700,
        alignItems: 'center',
        '.MuiAlert-message': { padding: 0 },
      }}
    >
      {isMobile ? '' : `Tracking Location`}
    </Alert>
  );
};
