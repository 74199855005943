import { CompanyGroup } from '@cvx/types/entities/groupsEntityTypes';
import { ConvexUser } from '@cvx/types/entities/usersEntityTypes';

export function getFleetDriverDraftInitiationFields(
  user: ConvexUser,
  primaryLocation: CompanyGroup
) {
  return {
    driverGroupId: primaryLocation._id,
    fleetDispatchGroupId: primaryLocation.defaultDispatchGroupId,
    activeDriverId: user._id,
  };
}
