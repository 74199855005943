import {
  imageCategories,
  ImageCategory,
} from '@cvx/schema/entities/requests/requestImages';
import { useMemo } from 'react';
import { EnhancedUser } from 'src/convex/functions/users';
import { UserRole } from 'src/convex/schema/enums/userRole';

export const useAvailableCategories = (user: EnhancedUser): ImageCategory[] => {
  return useMemo(() => {
    const allCategories = imageCategories.options;
    if (
      !['TECHNICIAN_PROVIDER', 'SERVICE_DISPATCHER'].includes(
        user.primaryRoleType as UserRole
      )
    ) {
      return allCategories.filter(category => category !== 'WORK_ORDER');
    }
    return allCategories;
  }, [user.primaryRoleType]);
};
