import { LoadingButton } from '@mui/lab';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { getDriverShareLocationActionState } from 'src/sections/request/actions/utils/getDriverShareLocationActionState';
import { RequestActionProps } from 'src/sections/request/types/RequestActionProps';
import { useRequestLocationUpdater } from 'src/utils/useRequestLocationUpdater';

export const DriverStaticShareLocation = ({
  request,
  me,
  requestStateInformation,
}: RequestActionProps) => {
  const { updateLocation, isUpdating } = useRequestLocationUpdater({
    vehicleId: request.vehicle?._id, // TODO: let invited drivers add their vehicle
    requestId: request._id,
  });

  const actionState = getDriverShareLocationActionState({
    request,
    me,
    requestStateInformation,
  });

  // When the DriverConfirmLocationAction is not visible, this can be visible for the driver
  const visible = !actionState.isVisible && requestStateInformation.isDriver;

  if (!visible) {
    return null;
  }

  return (
    <>
      <LoadingButton
        onClick={() => updateLocation()}
        fullWidth
        sx={{ height: '100%' }}
        color="primary"
        variant="contained"
        disabled={isUpdating}
        loading={isUpdating}
        startIcon={
          <Iconify icon="solar:point-on-map-bold-duotone" width={40} />
        }
      >
        Share Breakdown Location
      </LoadingButton>
    </>
  );
};
