import { api } from '@cvx/api';
import { useNavigate } from '@tanstack/react-router';
import { useMutation, useQuery } from 'convex/react';
import { useEffect } from 'react';
import { LoadingScreen } from 'src/minimal-theme/components/loading-screen';

export function StripeReturnView() {
  const navigate = useNavigate();
  const me = useQuery(api.functions.users.getMe);
  const location = useQuery(
    api.functions.companies.getLocationById,
    me?.primaryLocationGroupId ? { id: me.primaryLocationGroupId } : 'skip'
  );

  const completeStripeOnboarding = useMutation(
    api.functions.invoices.completeStripeOnboarding
  );

  useEffect(() => {
    const handleCompleteOnboarding = async () => {
      if (
        me?.primaryLocationGroupId === location?._id &&
        !location?.isStripeConnected
      ) {
        try {
          await completeStripeOnboarding({});
        } catch (error) {
          // TODO: RF - handle error
        }
      }
    };
    handleCompleteOnboarding();
  }, [me, location, completeStripeOnboarding]);

  useEffect(() => {
    if (location?.isStripeConnected) {
      navigate({ to: '/dashboard/invoices' });
    }
  }, [location]);
  return <LoadingScreen />;
}
