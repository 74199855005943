import { nanoid } from 'nanoid';
import { CONFIG } from 'src/config-global';

export interface SearchResult {
  place_name: string;
  center: [number, number];
  mapboxId: string;
}

interface RetrieveResponse {
  features: RetrieveResult[];
  type: 'FeatureCollection';
}
export interface RetrieveResult {
  type: string;
  geometry: {
    coordinates: [number, number];
    type: string;
  };
  properties: {
    name: string;
    name_preferred: string;
    mapbox_id: string;
    feature_type: string;
    address: string;
    full_address: string;
    place_formatted: string;
    context: {
      country: {
        id: string;
        name: string;
        country_code: string;
        country_code_alpha_3: string;
      };
      region: {
        id: string;
        name: string;
        region_code: string;
        region_code_full: string;
      };
      postcode: {
        id: string;
        name: string;
      };
      district: {
        id: string;
        name: string;
      };
      place: {
        id: string;
        name: string;
      };
      address: {
        id: string;
        name: string;
        address_number: string;
        street_name: string;
      };
      street: {
        id: string;
        name: string;
      };
    };
    coordinates: {
      latitude: number;
      longitude: number;
      accuracy: string;
      routable_points: Array<{
        name: string;
        latitude: number;
        longitude: number;
      }>;
    };
    language: string;
    maki: string;
    metadata: Record<string, any>;
  };
}

const SESSION_TOKEN_KEY = 'mapbox_session_token';

// Get or create a session token
const getSessionToken = (): string => {
  let token = window.sessionStorage.getItem(SESSION_TOKEN_KEY);
  if (!token) {
    token = nanoid();
    window.sessionStorage.setItem(SESSION_TOKEN_KEY, token);
  }
  return token;
};

export const searchAddresses = async (
  query: string
): Promise<SearchResult[]> => {
  if (!query) return [];

  const sessionToken = getSessionToken();

  try {
    const response = await fetch(
      `https://api.mapbox.com/search/searchbox/v1/suggest?` +
        `q=${encodeURIComponent(query)}` +
        `&session_token=${sessionToken}` +
        `&access_token=${CONFIG.mapboxApiKey}`
    );

    if (!response.ok) {
      throw new Error(
        `Mapbox API error: ${response.status} ${response.statusText}`
      );
    }

    const data = await response.json();

    return data.suggestions.map((suggestion: any) => ({
      place_name: suggestion.name + ', ' + suggestion.place_formatted,
      mapboxId: suggestion.mapbox_id,
      center: suggestion.center || [0, 0], // Some suggestions might not have coordinates at this stage
    }));
  } catch (error) {
    console.error('Search error:', error);
    return [];
  }
};

export const retrieveAddress = async (
  mapboxId: string
): Promise<RetrieveResult | null> => {
  if (!mapboxId || !CONFIG.mapboxApiKey) {
    console.warn('Missing required parameters for address retrieval');
    return null;
  }

  const sessionToken = getSessionToken();

  try {
    const response = await fetch(
      `https://api.mapbox.com/search/searchbox/v1/retrieve/` +
        `${encodeURIComponent(mapboxId)}` +
        `?session_token=${sessionToken}` +
        `&access_token=${CONFIG.mapboxApiKey}`
    );

    if (!response.ok) {
      throw new Error(
        `Mapbox API error: ${response.status} ${response.statusText}`
      );
    }

    const data = (await response.json()) as RetrieveResponse;

    if (!data.features?.[0]) {
      console.warn('No feature data found in Mapbox response');
      return null;
    }

    return data.features[0];
  } catch (error) {
    console.error('Address retrieval failed:', error);
    return null;
  }
};

// Reset session when needed (e.g., when starting a new search session)
export const resetSearchSession = () => {
  window.sessionStorage.removeItem(SESSION_TOKEN_KEY);
};
