export function formatTaxRegion(countryCode: string, regionCode: string) {
  return `${countryCode.toUpperCase()}-${regionCode.toUpperCase()}`;
}

type LocationRegistrationCheckParams = {
  requestCountryCode?: string;
  requestStateShortCode?: string;
  stateTaxRegistrations?: string[];
};

export function locationHasRequiredTaxRegistrations({
  requestCountryCode,
  requestStateShortCode,
  stateTaxRegistrations,
}: LocationRegistrationCheckParams) {
  if (!requestCountryCode || !requestStateShortCode) {
    return false;
  }
  const requestedTaxRegion = formatTaxRegion(
    requestCountryCode,
    requestStateShortCode
  );

  return stateTaxRegistrations?.some(
    registration => registration.toUpperCase() === requestedTaxRegion
  );
}
