import { CompanyGroup } from '@cvx/types/entities/groupsEntityTypes';
import { ServiceRequest } from '@cvx/types/entities/requestsEntityTypes';
import { ConvexUser } from '@cvx/types/entities/usersEntityTypes';
import { Doc } from '../../../_generated/dataModel';

export function getThirdPartyDispatcherDraftInitiationFields(
  user: ConvexUser,
  primaryLocation: CompanyGroup,
  company: Doc<'companies'>
): Partial<ServiceRequest> {
  return {
    brokerageGroupId: primaryLocation._id,
    activeBrokerageDispatcherId: user._id,
    brokerageCompanyId: company._id,
  };
}
