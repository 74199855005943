import { api } from '@cvx/api';
import { Doc } from '@cvx/dataModel';
import {
  CompanySettings,
  CompanySettingsUpdate,
} from '@cvx/types/entities/companySettingsEntityType';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useMutation } from 'convex/react';
import { useState } from 'react';
import { toast } from 'sonner';

type CompanyAdvancedSettingsProps = {
  company: Doc<'companies'>;
  companySettings: CompanySettings;
};

export function CompanyAdvancedSettings({
  companySettings,
  company,
}: CompanyAdvancedSettingsProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formState, setFormState] = useState<CompanySettingsUpdate>({
    doubleTechHoursOnPdf: companySettings.doubleTechHoursOnPdf,
    dotFormPhotoMandatory: companySettings.dotFormPhotoMandatory,
    dotUnitNumberPhotoMandatory: companySettings.dotUnitNumberPhotoMandatory,
    tagStickerPhotoMandatory: companySettings.tagStickerPhotoMandatory,
    allowTechniciansToEnterHoursWorked:
      companySettings.allowTechniciansToEnterHoursWorked,
    allowTechniciansToEnterPartLineItems:
      companySettings.allowTechniciansToEnterPartLineItems,
    allowTechniciansToViewInvoices:
      companySettings.allowTechniciansToViewInvoices,
  });

  const updateSettings = useMutation(
    api.functions.companySettings.updateCompanySettings
  );

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const resp = await updateSettings({
        companyId: company._id,
        settings: formState,
      });

      if (resp.success) {
        toast.success(resp.message);
      }
    } catch (error: any) {
      toast.error(`Could not update settings`);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (company.companyType !== 'REPAIR_SHOP') {
    return (
      <Stack spacing={3} sx={{ p: 3 }}>
        <Typography variant="h6">
          Advanced Settings For Your Company Type Coming Soon
        </Typography>
      </Stack>
    );
  }
  return (
    <Stack spacing={3} sx={{ p: 3 }}>
      <Typography variant="h6">PDF Settings</Typography>
      <Box
        rowGap={3}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
        }}
        sx={{ my: 2 }}
      >
        <FormControl>
          <FormControlLabel
            label={`Double Technician Hours (If Not Explicitly Set)`}
            control={
              <Checkbox
                size="small"
                checked={formState.doubleTechHoursOnPdf}
                onClick={() =>
                  setFormState(prev => ({
                    ...prev,
                    doubleTechHoursOnPdf: !prev.doubleTechHoursOnPdf,
                  }))
                }
              />
            }
          />
          <FormHelperText>
            If this setting is checked, if technician hours worked is derived
            from the timer tracking time between steps, it will be doubled when
            added to the PDF to include return time.
          </FormHelperText>
        </FormControl>
      </Box>
      <Typography variant="h6">DOT Photo Settings</Typography>
      <Box
        rowGap={3}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
        }}
        sx={{ my: 2 }}
      >
        <FormControl>
          <FormControlLabel
            label={`DOT Form Photo Upload Mandatory`}
            control={
              <Checkbox
                size="small"
                checked={formState.dotFormPhotoMandatory}
                onClick={() =>
                  setFormState(prev => ({
                    ...prev,
                    dotFormPhotoMandatory: !prev.dotFormPhotoMandatory,
                  }))
                }
              />
            }
          />
          <FormHelperText>
            Applies to requests that have the DOT service selected. The repair
            form will require this photo before the workflow can move on.
          </FormHelperText>
        </FormControl>
        <FormControl>
          <FormControlLabel
            label={`DOT Tag/Sticker Photo Upload Mandatory`}
            control={
              <Checkbox
                size="small"
                checked={formState.tagStickerPhotoMandatory}
                onClick={() =>
                  setFormState(prev => ({
                    ...prev,
                    tagStickerPhotoMandatory: !prev.tagStickerPhotoMandatory,
                  }))
                }
              />
            }
          />
          <FormHelperText>
            Applies to requests that have the DOT service selected. The repair
            form will require this photo before the workflow can move on.
          </FormHelperText>
        </FormControl>
        <FormControl>
          <FormControlLabel
            label={`DOT Unit Number Photo Upload Mandatory`}
            control={
              <Checkbox
                size="small"
                checked={formState.dotUnitNumberPhotoMandatory}
                onClick={() =>
                  setFormState(prev => ({
                    ...prev,
                    dotUnitNumberPhotoMandatory:
                      !prev.dotUnitNumberPhotoMandatory,
                  }))
                }
              />
            }
          />
          <FormHelperText>
            Applies to requests that have the DOT service selected. The repair
            form will require this photo before the workflow can move on.
          </FormHelperText>
        </FormControl>
      </Box>
      <Typography variant="h6">Repair Form Settings</Typography>
      <Box
        rowGap={3}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
        }}
        sx={{ my: 2 }}
      >
        <FormControl>
          <FormControlLabel
            label={`Allow technicians to upload part line items`}
            control={
              <Checkbox
                size="small"
                checked={formState.allowTechniciansToEnterPartLineItems}
                onClick={() =>
                  setFormState(prev => ({
                    ...prev,
                    allowTechniciansToEnterPartLineItems:
                      !prev.allowTechniciansToEnterPartLineItems,
                  }))
                }
              />
            }
          />
          <FormHelperText>
            In the repair form, technicians will be able to add part line items
            with descriptions/quanity/unit amounts/totals. IMPORTANT: These line
            items will only be visible by participants on the service provider
            side, not the fleet.
          </FormHelperText>
        </FormControl>
        <FormControl>
          <FormControlLabel
            label={`Allow technicians to enter their hours worked`}
            control={
              <Checkbox
                size="small"
                checked={formState.allowTechniciansToEnterHoursWorked}
                onClick={() =>
                  setFormState(prev => ({
                    ...prev,
                    allowTechniciansToEnterHoursWorked:
                      !prev.allowTechniciansToEnterHoursWorked,
                  }))
                }
              />
            }
          />
          <FormHelperText>
            Technicians will be able to enter the hours they worked on a repair
            in the repair form. IMPORTANT: This will only be visible by
            participants on the service provider side, not the fleet.
          </FormHelperText>
        </FormControl>
      </Box>
      <Typography variant="h6">Invoice Settings</Typography>
      <Box
        rowGap={3}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
        }}
        sx={{ my: 2 }}
      >
        <FormControl>
          <FormControlLabel
            label={`Allow technicians to view invoices`}
            control={
              <Checkbox
                size="small"
                checked={formState.allowTechniciansToViewInvoices}
                onClick={() =>
                  setFormState(prev => ({
                    ...prev,
                    allowTechniciansToViewInvoices:
                      !prev.allowTechniciansToViewInvoices,
                  }))
                }
              />
            }
          />
          <FormHelperText>
            This will allow technicians to view invoices and their statuses in
            the request view for requests they are participants in.
          </FormHelperText>
        </FormControl>
      </Box>
      <Stack alignItems="flex-end" sx={{ mt: 3 }}>
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          loading={isSubmitting}
          onClick={handleSubmit}
        >
          Save
        </LoadingButton>
      </Stack>
    </Stack>
  );
}
