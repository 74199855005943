import { useEffect, useState } from 'react';

export type LoginMethod = 'email' | 'phoneNumber';

// Custom hook to manage and persist login method preference
export function usePreferredLoginMethod() {
  const [loginMethod, setLoginMethod] = useState<LoginMethod>(() => {
    return (localStorage.getItem('loginMethod') ||
      'phoneNumber') as LoginMethod;
  });

  useEffect(() => {
    localStorage.setItem('loginMethod', loginMethod);
  }, [loginMethod]);

  return [loginMethod, setLoginMethod] as const;
}
