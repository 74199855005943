import type { Breakpoint, SxProps, Theme } from '@mui/material/styles';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Link } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { Logo } from 'src/minimal-theme/components/logo';
import { MuiTypeSafeLink } from 'src/minimal-theme/components/routes/components/mui-link';
import { HeaderSection } from '../core/header-section';
import { LayoutSection } from '../core/layout-section';
import { Content, Main } from './main';
import { Section } from './section';

// ----------------------------------------------------------------------

const FooterWrapper = styled('footer')(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  '& a': {
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export type AuthSplitLayoutProps = {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
  header?: {
    sx?: SxProps<Theme>;
  };
  section?: {
    title?: string;
    imgUrl?: string;
    subtitle?: string;
  };
};

export function SplitLayout({
  sx,
  section,
  children,
  header,
}: AuthSplitLayoutProps) {
  const layoutQuery: Breakpoint = 'md';

  return (
    <LayoutSection
      headerSection={
        /** **************************************
         * Header
         *************************************** */
        <HeaderSection
          disableElevation
          layoutQuery={layoutQuery}
          slotProps={{ container: { maxWidth: false } }}
          sx={{ position: { [layoutQuery]: 'fixed' }, ...header?.sx }}
          slots={{
            topArea: (
              <Alert severity="info" sx={{ display: 'none', borderRadius: 0 }}>
                This is an info Alert.
              </Alert>
            ),
            leftArea: (
              <>
                {/* -- Logo -- */}
                <Logo />
              </>
            ),
            rightArea: (
              <Box display="flex" alignItems="center" gap={{ xs: 1, sm: 1.5 }}>
                {/* -- Help link -- */}
                <Link
                  color="inherit"
                  underline="hover"
                  href="mailto:info@mymechanic.app"
                  sx={{ typography: 'subtitle2' }}
                >
                  Need help?
                </Link>
                {/* -- Settings button -- */}
                {/* <SettingsButton /> */}
              </Box>
            ),
          }}
        />
      }
      /** **************************************
       * Footer
       *************************************** */
      footerSection={null}
      /** **************************************
       * Style
       *************************************** */
      cssVars={{ '--layout-auth-content-width': '420px' }}
      sx={sx}
    >
      <Main layoutQuery={layoutQuery}>
        <Section
          title={section?.title}
          layoutQuery={layoutQuery}
          imgUrl={section?.imgUrl}
          subtitle={section?.subtitle}
        />
        <Content layoutQuery={layoutQuery}>{children}</Content>
      </Main>

      <FooterWrapper>
        <Typography variant="body2">
          Copyright © 2025 myMechanic, Inc™&nbsp;|&nbsp;
          <MuiTypeSafeLink to="/eula">
            End-User License Agreement
          </MuiTypeSafeLink>
          &nbsp;|&nbsp;
          <MuiTypeSafeLink to="/privacy-policy">Privacy Policy</MuiTypeSafeLink>
        </Typography>
      </FooterWrapper>
    </LayoutSection>
  );
}
