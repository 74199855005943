import { PhysicalLocationType } from '@cvx/types/enums/groupEnums';

export function isFleetType(type?: PhysicalLocationType): boolean {
  return type
    ? [
        'FLEET_TERMINAL',
        'FLEET_YARD',
        'FLEET_MAINTENANCE_SHOP',
        'FLEET_DISPATCH_CENTER',
      ].includes(type)
    : false;
}

export function isServiceType(type?: PhysicalLocationType): boolean {
  return type
    ? [
        'REPAIR_SHOP',
        'MOBILE_REPAIR_BASE',
        'TIRE_SHOP',
        'TOW_YARD',
        'SERVICE_DISPATCH_CENTER',
      ].includes(type)
    : false;
}

export const PHYSICAL_LOCATION_TYPE_DISPLAY: Record<
  PhysicalLocationType,
  string
> = {
  FLEET_TERMINAL: 'Fleet Terminal',
  FLEET_YARD: 'Fleet Yard',
  FLEET_MAINTENANCE_SHOP: 'Fleet Maintenance Shop',
  REPAIR_SHOP: 'Repair Shop',
  FLEET_DISPATCH_CENTER: 'Fleet Dispatch Center',
  SERVICE_DISPATCH_CENTER: 'Service Dispatch Center',
  MOBILE_REPAIR_BASE: 'Mobile Repair Base',
  TIRE_SHOP: 'Tire Shop',
  TOW_YARD: 'Tow Yard',
  PARTS_WAREHOUSE: 'Parts Warehouse',
  TRUCK_STOP: 'Truck Stop',
  ADMINISTRATIVE_OFFICE: 'Administrative Office',
  OTHER: 'Other',
  THIRD_PARTY_CALL_CENTER: 'Third Party Call Center',
};

export const getPhysicalLocationTypeDisplay = (type: PhysicalLocationType) =>
  PHYSICAL_LOCATION_TYPE_DISPLAY[type];
