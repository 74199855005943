import { api } from '@cvx/api';
import { useConvexAuth, useMutation, useQuery } from 'convex/react';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { getDistanceInMeters } from 'src/context/locationTracking';
import { useRequestLocationUpdater } from 'src/utils/useRequestLocationUpdater';

const LOCATION_UPDATE_THRESHOLD_METERS = 50;
const LOCATION_UPDATE_THROTTLE_MS = 30000;

type LocationErrorType = 'blocked' | 'unavailable' | 'timeout' | null;

type LocationStatus = {
  isTracking: boolean;
  isEnabled: boolean;
  enabling: boolean;
  shouldPromptForTracking: boolean;
  enableTracking: () => void;
  error: LocationErrorType;
  dismissError: () => void;
  disableTrackingAttemptForSession: boolean;
};

const LocationTrackingContext = createContext<LocationStatus>({
  isTracking: false,
  isEnabled: false,
  enabling: false,
  shouldPromptForTracking: false,
  enableTracking: () => {},
  dismissError: () => {},
  error: null,
  disableTrackingAttemptForSession: false,
});

export function LocationTrackingProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isEnabled, setIsEnabled] = useState(false);
  const [error, setError] = useState<LocationErrorType>(null);

  const [enabling, setEnabling] = useState(false);
  const [
    disableTrackingAttemptForSession,
    setDisableTrackingAttemptForSession,
  ] = useState(false);
  const { isAuthenticated } = useConvexAuth();
  const updateLocation = useMutation(
    api.functions.users.updateTechnicianLocation
  );

  // Get active requests for technician
  const me = useQuery(api.functions.users.getMe, isAuthenticated ? {} : 'skip');
  const isTechnician = me?.roles.find(r =>
    ['TECHNICIAN', 'TECHNICIAN_FLEET', 'TECHNICIAN_PROVIDER'].includes(r.type)
  );

  const isDriver = me?.roles.find(r => ['DRIVER_FLEET'].includes(r.type));

  const activeTechnicianRequests = useQuery(
    api.functions.requests.getAssignedRequests,
    isTechnician ? {} : 'skip'
  );

  const driverActiveRequests = useQuery(
    api.functions.requests.getActiveRequests,
    isDriver ? {} : 'skip'
  );

  const activeRequests = activeTechnicianRequests ?? driverActiveRequests;

  // Just track isTracking for UI feedback
  const [isTracking, setIsTracking] = useState(false);

  const watchId = useRef<number | null>(null);
  const lastLocation = useRef<{ latitude: number; longitude: number } | null>(
    null
  );

  const firstActiveRequest = activeRequests?.[0];

  const { updateLocation: updateDriverRequestLocation } =
    useRequestLocationUpdater({
      vehicleId: firstActiveRequest?.vehicleId ?? null,
      requestId: firstActiveRequest?._id ?? null,
    });

  const lastUpdateTime = useRef<number>(0);

  const dismissError = () => {
    setError(null);
    setIsEnabled(false);
    setDisableTrackingAttemptForSession(true);
  };

  const enableTracking = async () => {
    try {
      setEnabling(true);
      setError(null);

      const position = await new Promise<GeolocationPosition>(
        (resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0,
          });
        }
      );

      const newLocation = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };

      if (isTechnician) {
        await updateLocation(newLocation);
      } else if (isDriver) {
        await updateDriverRequestLocation();
      }

      lastLocation.current = newLocation;
      lastUpdateTime.current = Date.now();
      setIsEnabled(true);
      setIsTracking(true);

      startWatching();
    } catch (error) {
      if (error instanceof GeolocationPositionError) {
        switch (error.code) {
          case 1:
            setError('blocked');
            break;
          case 2:
            setError('unavailable');
            break;
          case 3:
            setError('timeout');
            break;
        }
      } else {
        console.error('Unexpected geolocation error:', error);
        setError('unavailable');
      }
      setIsTracking(false);
      setIsEnabled(false);
    } finally {
      setEnabling(false);
    }
  };

  const startWatching = () => {
    if (watchId.current) {
      navigator.geolocation.clearWatch(watchId.current);
      watchId.current = null;
    }
    try {
      watchId.current = navigator.geolocation.watchPosition(
        async position => {
          try {
            const now = Date.now();
            const newLocation = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };

            // Check thresholds
            if (
              lastLocation.current &&
              now - lastUpdateTime.current < LOCATION_UPDATE_THROTTLE_MS
            ) {
              const distance = getDistanceInMeters(
                lastLocation.current.latitude,
                lastLocation.current.longitude,
                newLocation.latitude,
                newLocation.longitude
              );

              if (distance < LOCATION_UPDATE_THRESHOLD_METERS) {
                return;
              }
            }

            if (isTechnician) {
              await updateLocation(newLocation);
            } else if (isDriver) {
              await updateDriverRequestLocation();
            }

            // Then update local state
            lastLocation.current = newLocation;
            lastUpdateTime.current = now;
            setIsTracking(true);
          } catch (e) {
            console.error('Failed to update location:', e);
            setIsTracking(false);
          }
        },
        error => {
          console.error('Geolocation error:', error);
          setIsTracking(false);
          setIsEnabled(false);
        },
        { enableHighAccuracy: true }
      );
    } catch (error) {
      console.error('Failed to start watching:', error);
      setIsTracking(false);
      setIsEnabled(false);
    }
  };

  // Use useEffect to stop tracking when conditions are no longer met
  useEffect(() => {
    const shouldTrack = Boolean(
      isAuthenticated &&
        (isTechnician || isDriver) &&
        activeRequests &&
        activeRequests.length > 0 &&
        isEnabled &&
        !disableTrackingAttemptForSession
    );

    if (!shouldTrack) {
      if (watchId.current) {
        navigator.geolocation.clearWatch(watchId.current);
        watchId.current = null;
        setIsTracking(false);
      }
    }
  }, [
    isAuthenticated,
    isTechnician,
    activeRequests,
    isEnabled,
    isDriver,
    disableTrackingAttemptForSession,
  ]);

  const shouldPromptForTracking = Boolean(
    isAuthenticated &&
      (isTechnician || isDriver) &&
      activeRequests &&
      activeRequests?.length > 0
  );

  return (
    <LocationTrackingContext.Provider
      value={{
        isTracking,
        isEnabled,
        enableTracking,
        shouldPromptForTracking,
        enabling,
        dismissError,
        error,
        disableTrackingAttemptForSession,
      }}
    >
      {children}
    </LocationTrackingContext.Provider>
  );
}

export const useLocationTracking = () => useContext(LocationTrackingContext);
