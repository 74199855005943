import { RetrieveResult } from '../../../utils/searchAddress';

export type StateTimezones = {
  [stateCode: string]: string | string[];
};

export type MapboxContext = {
  id: string;
  mapbox_id?: string;
  wikidata?: string;
  short_code?: string;
  text: string;
};

export type MapboxFeature = {
  id: string;
  type: 'Feature';
  place_type: string[];
  center: [number, number]; // Exactly two numbers for longitude and latitude
  properties: {
    mapbox_id?: string;
    wikidata?: string;
    short_code?: string;
  };
  text: string;
  place_name: string;
  context: MapboxContext[];
};

// In our case first feature should contain all the data we need if it is returned
export type GeocodingResponse = {
  type: 'FeatureCollection';
  query: [number, number];
  features: MapboxFeature[];
};

export const STATE_TIMEZONES: StateTimezones = {
  'US-AK': 'America/Anchorage',
  'US-AL': 'America/Chicago',
  'US-AR': 'America/Chicago',
  'US-AZ': 'America/Phoenix',
  'US-CA': 'America/Los_Angeles',
  'US-CO': 'America/Denver',
  'US-CT': 'America/New_York',
  'US-DC': 'America/New_York',
  'US-DE': 'America/New_York',
  'US-FL': ['America/New_York', 'America/Chicago'],
  'US-GA': 'America/New_York',
  'US-HI': 'Pacific/Honolulu',
  'US-IA': 'America/Chicago',
  'US-ID': ['America/Denver', 'America/Los_Angeles'],
  'US-IL': 'America/Chicago',
  'US-IN': 'America/Indiana/Indianapolis',
  'US-KS': ['America/Chicago', 'America/Denver'],
  'US-KY': ['America/New_York', 'America/Chicago'],
  'US-LA': 'America/Chicago',
  'US-MA': 'America/New_York',
  'US-MD': 'America/New_York',
  'US-ME': 'America/New_York',
  'US-MI': ['America/New_York', 'America/Chicago'],
  'US-MN': 'America/Chicago',
  'US-MO': 'America/Chicago',
  'US-MS': 'America/Chicago',
  'US-MT': 'America/Denver',
  'US-NC': 'America/New_York',
  'US-ND': ['America/Chicago', 'America/Denver'],
  'US-NE': ['America/Chicago', 'America/Denver'],
  'US-NH': 'America/New_York',
  'US-NJ': 'America/New_York',
  'US-NM': 'America/Denver',
  'US-NV': 'America/Los_Angeles',
  'US-NY': 'America/New_York',
  'US-OH': 'America/New_York',
  'US-OK': 'America/Chicago',
  'US-OR': ['America/Los_Angeles', 'America/Boise'],
  'US-PA': 'America/New_York',
  'US-RI': 'America/New_York',
  'US-SC': 'America/New_York',
  'US-SD': ['America/Chicago', 'America/Denver'],
  'US-TN': ['America/New_York', 'America/Chicago'],
  'US-TX': ['America/Chicago', 'America/Denver'],
  'US-UT': 'America/Denver',
  'US-VA': 'America/New_York',
  'US-VT': 'America/New_York',
  'US-WA': 'America/Los_Angeles',
  'US-WI': 'America/Chicago',
  'US-WV': 'America/New_York',
  'US-WY': 'America/Denver',

  // Canadian Provinces
  'CA-AB': 'America/Edmonton',
  'CA-BC': 'America/Vancouver',
  'CA-MB': 'America/Winnipeg',
  'CA-NB': 'America/Moncton',
  'CA-NL': 'America/St_Johns',
  'CA-NS': 'America/Halifax',
  'CA-NT': 'America/Yellowknife',
  'CA-NU': 'America/Iqaluit',
  'CA-ON': 'America/Toronto',
  'CA-PE': 'America/Halifax',
  'CA-QC': 'America/Montreal',
  'CA-SK': 'America/Regina',
  'CA-YT': 'America/Whitehorse',
};

export type MapBoxPlaceInformation = {
  city: string;
  state: string;
  stateShortCode: string;
  countryCode: string;
  postCode: string;
  country: string;
  timezone: string;
};

export function getMapboxPlaceInformation(
  feature: MapboxFeature,
  longitude: number
): MapBoxPlaceInformation | undefined {
  const city = feature.context.find(c => c.id.includes('place'));
  const state = feature.context.find(c => c.id.includes('region'));
  const postCode = feature.context.find(c => c.id.includes('postcode'));
  const country = feature.context.find(c => c.id.includes('country'));

  if (city && state && postCode && country) {
    const stateShortCode = (state.short_code || '').split('-')[1] || '';

    const timezone = STATE_TIMEZONES[state.short_code ?? ''];

    const info = {
      city: city.text,
      state: state.text,
      postCode: postCode.text,
      country: country.text,
      countryCode: country.short_code ?? '',
      stateShortCode,
    };

    if (timezone) {
      // Handle split timezone states
      if (Array.isArray(timezone)) {
        // For most split states, western timezone is second in array
        const zoneIndex = longitude < -100 ? 1 : 0;

        return {
          ...info,
          timezone: timezone[zoneIndex],
        };
      } else {
        return { ...info, timezone };
      }
    }

    return { ...info, timezone: '' };
  }
}

export function getRetrievePlaceInformation(
  feature: RetrieveResult
): MapBoxPlaceInformation | undefined {
  const longitude = feature.geometry.coordinates[0];
  const context = feature.properties.context;

  if (
    !context.place ||
    !context.region ||
    !context.postcode ||
    !context.country
  ) {
    return undefined;
  }

  const stateShortCode = context.region.region_code;
  const timezone =
    STATE_TIMEZONES[`${context.country.country_code}-${stateShortCode}`];

  const info = {
    city: context.place.name,
    state: context.region.name,
    postCode: context.postcode.name,
    country: context.country.name,
    countryCode: context.country.country_code,
    stateShortCode,
  };

  if (timezone) {
    if (Array.isArray(timezone)) {
      const zoneIndex = longitude < -100 ? 1 : 0;
      return {
        ...info,
        timezone: timezone[zoneIndex],
      };
    }
    return { ...info, timezone };
  }
}
