import { CompanyId, VendorServiceId } from '@cvx/types/entities/sharedIds';
import { CompanyZodId } from '@cvx/types/zod/commonZodId';
import { MutationCtx } from '../_generated/server';
import {
  listActiveCompanyServices,
  listAllCompanyServices,
} from '../model/companyServices/queries/companyServicePublicQueries';
import { getServiceChanges } from '../model/shared/helpers/serviceHelpers';
import { zQuery } from './helpers/zodHelpers';

export const getCompanyServices = zQuery({
  args: {
    companyId: CompanyZodId,
  },
  handler: (ctx, args) => {
    return listActiveCompanyServices(ctx, args.companyId);
  },
});

export async function updateCompanyServices(
  ctx: MutationCtx,
  serviceIds: VendorServiceId[],
  companyId: CompanyId
) {
  const existingServices = await listAllCompanyServices(ctx, companyId);

  const { servicesToAdd, servicesToDeactivate } = getServiceChanges(
    existingServices,
    serviceIds
  );

  for (const serviceId of servicesToAdd) {
    await ctx.db.insert('companyServices', {
      serviceId,
      companyId,
      isActive: true,
    });
  }

  for (const service of servicesToDeactivate) {
    await ctx.db.patch(service._id, {
      isActive: false,
    });
  }
}
