import { customCtx } from 'convex-helpers/server/customFunctions';
import { zCustomQuery } from 'convex-helpers/server/zod';
import { query } from '../../_generated/server';
import { getUserContext } from '../../utils/getUserContext';

export const zQueryWithUserContext = zCustomQuery(
  query,
  customCtx(async ctx => {
    const userContext = await getUserContext(ctx);
    return {
      userContext,
      ...ctx,
    };
  })
);
