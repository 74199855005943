import { createFileRoute } from '@tanstack/react-router';
import { ValidPaths } from 'src/minimal-theme/layouts/config-nav-dashboard';
import Page from 'src/pages/dashboard/mechanic/list';
import {
  checkRouteAccess,
  routeAccessConfigs,
} from 'src/utils/checkRouteAccess';

export const Route = createFileRoute('/_auth/dashboard/mechanics/')({
  beforeLoad: ({ context, location }) =>
    checkRouteAccess(
      context,
      routeAccessConfigs.serviceProviderOnly,
      location.pathname as ValidPaths
    ),
  component: Page,
});
