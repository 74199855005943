import { api } from '@cvx/api';
import { LoadingButton } from '@mui/lab';
import { useMutation } from 'convex/react';
import { toast } from 'sonner';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { ActionConfirmationDialog } from 'src/sections/request/actions/ActionConfirmationDialog';
import { useActionConfirmationDialog } from 'src/sections/request/actions/hooks/useActionConfirmationDialog';
import { getArrivedAtLocationActionState } from 'src/sections/request/actions/utils/getArrivedAtLocationActionState';
import { RequestActionProps } from 'src/sections/request/types/RequestActionProps';

export const ArrivedAtLocationAction = ({
  request,
  me,
  requestStateInformation,
}: RequestActionProps) => {
  const { confirmAction, isOpen, pendingAction, onClose } =
    useActionConfirmationDialog();

  const technicianArrivedAtLocation = useMutation(
    api.functions.requests.technicianArrivedAtLocation
  );

  const handleTechnicianArrived = async () => {
    const response = await technicianArrivedAtLocation({
      requestId: request._id,
    });
    if (response.success) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  const actionState = getArrivedAtLocationActionState({
    request,
    me,
    requestStateInformation,
  });

  if (!actionState.isVisible) {
    return null;
  }

  return (
    <>
      <LoadingButton
        onClick={() =>
          confirmAction({
            title: actionState.confirmationTitle!,
            message: actionState.confirmationMessage!,
            onConfirm: handleTechnicianArrived,
          })
        }
        fullWidth
        sx={{ height: '100%' }}
        color="primary"
        variant="contained"
        disabled={!actionState.isEnabled}
        startIcon={<Iconify icon="solar:hand-shake-bold-duotone" width={40} />}
      >
        {actionState.message}
      </LoadingButton>
      <ActionConfirmationDialog
        open={isOpen}
        onClose={onClose}
        {...pendingAction}
      />
    </>
  );
};
