import { api } from '@cvx/api';
import { useMutation } from 'convex/react';
import { useState } from 'react';
import { useScheduledActions } from 'src/hooks/useScheduledActions';
import { toast } from 'src/minimal-theme/components/snackbar';

export function useTaxRegistrations() {
  const [isChecking, setIsChecking] = useState(false);

  const checkAndUpdateTaxRegistrations = useMutation(
    api.functions.invoices.checkAndUpdateActiveTaxRegistrationsIfNeeded
  );

  const { watchIds } = useScheduledActions({
    successMessage: 'Updated tax registrations',
  });

  const checkRegistrations = async () => {
    setIsChecking(true);
    try {
      const response = await checkAndUpdateTaxRegistrations({});

      if (response.success && response.data.scheduleId) {
        await watchIds([response.data.scheduleId], {});
        return true;
      } else {
        toast.error(response.message);
        return false;
      }
    } catch (error) {
      toast.error('Error checking registrations');
      return false;
    } finally {
      setIsChecking(false);
    }
  };

  return {
    checkRegistrations,
    isChecking,
  };
}
