import { EnhancedRequest } from '@cvx/types/entities/requestsEntityTypes';
import { Dialog, DialogTitle } from '@mui/material';
import { EnhancedUser } from 'src/convex/functions/users';
import { RequestFormContent } from 'src/sections/request/RequestFormContent';

type RequestFormWrapperProps = {
  request: EnhancedRequest;
  me: EnhancedUser;
  isDialog?: boolean;
  open?: boolean;
  onClose?: () => void;
};

export const RequestFormWrapper = ({
  request,
  me,
  isDialog = false,
  open = false,
  onClose,
}: RequestFormWrapperProps) => {
  const formContent = (
    <RequestFormContent
      request={request}
      me={me}
      isDialog={isDialog}
      onClose={onClose}
    />
  );

  if (isDialog) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Edit Request Details</DialogTitle>
        {formContent}
      </Dialog>
    );
  }

  return <>{formContent}</>;
};
