import { customCtx, NoOp } from 'convex-helpers/server/customFunctions';
import {
  zCustomAction,
  zCustomMutation,
  zCustomQuery,
} from 'convex-helpers/server/zod';
import { v } from 'convex/values';
import { z } from 'zod';
import {
  action,
  internalAction,
  internalMutation,
  internalQuery,
  mutation,
  query,
} from '../../_generated/server';
import { getUserContext } from '../../utils/getUserContext';
import { triggers } from '../triggers';

export const zQuery = zCustomQuery(query, NoOp);
export const zInternalQuery = zCustomQuery(internalQuery, NoOp);

export const zMutation = zCustomMutation(mutation, customCtx(triggers.wrapDB));
export const zInternalMutation = zCustomMutation(
  internalMutation,
  customCtx(triggers.wrapDB)
);

export const zQueryWithUser = zCustomQuery(
  query,
  customCtx(async ctx => {
    const userContext = await getUserContext(ctx);

    return { ...ctx, userContext };
  })
);

export const zMutationWithUser = zCustomMutation(
  mutation,
  customCtx(async ctx => {
    const userContext = await getUserContext(ctx);

    return triggers.wrapDB({ ...ctx, userContext });
  })
);

export const zInternalMutationWithUser = zCustomMutation(internalMutation, {
  args: { userPerformingActionId: v.id('users') },
  input: async (ctx, args) => {
    const { userPerformingActionId, ...otherArgs } = args;

    const user = await ctx.db.get(userPerformingActionId);

    return {
      ctx: triggers.wrapDB({ ...ctx, user }),
      args: otherArgs,
    };
  },
});

export const zAction = zCustomAction(action, NoOp);
export const zInternalAction = zCustomAction(internalAction, NoOp);

export const zPaginationOptsValidator = z.object({
  numItems: z.number(),
  cursor: z.string().nullable(),
  endCursor: z.string().nullable().optional(),
  id: z.number().optional(),
  maximumRowsRead: z.number().optional(),
  maximumBytesRead: z.number().optional(),
});
