import { api } from '@cvx/api';
import { Doc } from '@cvx/dataModel';
import { InvoiceId } from '@cvx/types/entities/sharedIds';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useQuery } from 'convex/react';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { fCurrency } from 'src/minimal-theme/utils/format-number';
import { useInvoiceLineItems } from 'src/sections/invoice/hooks/useInvoiceLineItems';
import { InvoiceLineItem } from 'src/sections/invoice/InvoiceLineItem';

type InvoiceNewEditDetailsProps = {
  disabled?: boolean;
  invoiceId: InvoiceId;
  status?: string;
};

export const hasValidItems = (
  items: Doc<'invoiceItems'>[] | undefined
): boolean => {
  if (!items?.length) return false;

  if (!items.every(item => item.invoiceItemStripeId)) return false;

  return false;
};

export function InvoiceNewEditDetails({
  disabled,
  invoiceId,
  status,
}: InvoiceNewEditDetailsProps) {
  const { items, addItem, removeItem, updateItem } =
    useInvoiceLineItems(invoiceId);

  const invoice = useQuery(api.functions.invoices.getInvoiceById, {
    invoiceId,
  });

  const totalAmount = invoice?.invoice?.amount
    ? invoice.invoice.amount / 100
    : 0;

  const tax = invoice?.invoice?.totalTax ? invoice.invoice.totalTax / 100 : 0;

  const renderTotal = (
    <Stack
      spacing={2}
      alignItems="flex-end"
      sx={{ mt: 3, textAlign: 'right', typography: 'body2' }}
    >
      <Stack direction="row">
        <Box sx={{ color: 'text.secondary' }}>Tax</Box>
        <Box sx={{ width: 160, typography: 'subtitle2' }}>
          {fCurrency(tax, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
      </Stack>
      <Stack direction="row" sx={{ typography: 'subtitle1' }}>
        <div>Total</div>
        <Box sx={{ width: 160 }}>
          {fCurrency(totalAmount, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Box>
      </Stack>
    </Stack>
  );

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ color: 'text.disabled', mb: 3 }}>
        Details:
      </Typography>

      <Stack
        divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />}
        spacing={3}
      >
        {items.map(item => (
          <InvoiceLineItem
            key={item._id || item.clientId}
            item={item}
            onRemove={() => removeItem(item._id!)}
            onSave={updateItem}
            invoiceStatus={status}
            disabled={disabled}
          />
        ))}
      </Stack>

      <Divider sx={{ my: 3, borderStyle: 'dashed' }} />

      {!disabled && (
        <Button
          size="small"
          startIcon={<Iconify icon="mingcute:add-line" />}
          onClick={addItem}
          sx={{ flexShrink: 0 }}
        >
          Add Item
        </Button>
      )}

      {renderTotal}
    </Box>
  );
}

// TODO: kept commented for the discount/tax stuff... not sure where this is coming from?
// export function InvoiceNewEditDetails({
//   invoiceId,
// }: InvoiceNewEditDetailsProps) {
//   const { items } = useInvoiceLineItems(invoiceId);

//   const values = {
//     discount: 0,
//     taxes: 0,
//     items,
//     category: '',
//     totalAmount: 0,
//   };

//   const totalOnRow: number[] = values.items.map(
//     (item: any) => item.quantity * item.amount
//   );

//   const subtotal = totalOnRow.reduce((acc, num) => acc + num, 0);

//   const totalAmount = subtotal - values.discount + values.taxes;

//   useEffect(() => {
//     if (items?.length) {
//       items.forEach((item, index) => {
//         // Only update if the total is different from what it should be
//         const currentTotal = form.getFieldValue(`items[${index}].total`);
//         const expectedTotal = (item.quantity || 0) * (item.amount || 0);

//         if (currentTotal !== expectedTotal) {
//           form.setFieldValue(`items[${index}].total`, expectedTotal);
//         }
//       });
//     }
//   }, [items, form]);

//   const handleAdd = () => {
//     const currentItems = items;

//     const newItem: InvoiceItem = {
//       category: '',
//       description: '',
//       quantity: 0,
//       total: 0,
//       amount: 0,
//       taxCode: 'txcd_99999999',
//     };
//   };

//   const addItemsToInvoice = useMutation(
//     api.functions.invoices.addItemsToInvoice
//   );

//   const removeItemsFromInvoice = useMutation(
//     api.functions.invoices.removeItemsFromInvoice
//   );

//   const onItemSave = async (index: number) => {
//     // Get item and remove total field before saving
//     const item = form.getFieldValue(`items[${index}]`);
//     const { total, ...itemToSave } = item;

//     console.log('itemToSave', itemToSave);

//     const response = await addItemsToInvoice({
//       invoiceId: invoiceId as Id<'invoices'>,
//       items: [itemToSave], // Wrap the single item in an array
//     });

//     if (response.success && response.data.scheduleId) {
//       watchIds([response.data.scheduleId], {
//         onSuccess: () => {
//           toast.success('Items added successfully');
//         },
//         onError: failedIds => {
//           console.error('Failed save:', failedIds);
//           toast.error('Failed to add items');
//         },
//       });
//     } else {
//       toast.error(response.message);
//     }
//   };

//   const onItemRemove = async (index: number) => {
//     const item = form.getFieldValue(`items[${index}]`);

//     console.log('item', item._id);

//     const response = await removeItemsFromInvoice({
//       invoiceItemIds: [item._id],
//     });

//     if (response.success && response.data.scheduleId) {
//       watchIds([response.data.scheduleId], {
//         onSuccess: () => {
//           toast.success('Items removed successfully');
//         },
//         onError: failedIds => {
//           console.error('Failed remove:', failedIds);
//           toast.error('Failed to remove items');
//         },
//       });
//     } else {
//       toast.error(response.message);
//     }
//   };

//   const { watchIds } = useScheduledActions();

//   const renderTotal = (
//     <Stack
//       spacing={2}
//       alignItems="flex-end"
//       sx={{ mt: 3, textAlign: 'right', typography: 'body2' }}
//     >
//       <Stack direction="row">
//         <Box sx={{ color: 'text.secondary' }}>Subtotal</Box>
//         <Box sx={{ width: 160, typography: 'subtitle2' }}>
//           {fCurrency(subtotal) || '-'}
//         </Box>
//       </Stack>

//       <Stack direction="row">
//         <Box sx={{ color: 'text.secondary' }}>Discount</Box>
//         <Box
//           sx={{ width: 160, ...(values.discount && { color: 'error.main' }) }}
//         >
//           {values.discount ? `- ${fCurrency(values.discount)}` : '-'}
//         </Box>
//       </Stack>

//       <Stack direction="row">
//         <Box sx={{ color: 'text.secondary' }}>Taxes</Box>
//         <Box sx={{ width: 160 }}>
//           {values.taxes ? fCurrency(values.taxes) : '-'}
//         </Box>
//       </Stack>

//       <Stack direction="row" sx={{ typography: 'subtitle1' }}>
//         <div>Total</div>
//         <Box sx={{ width: 160 }}>{fCurrency(totalAmount) || '-'}</Box>
//       </Stack>
//     </Stack>
//   );

//   useEffect(() => {
//     const formItems = form.getFieldValue('items') || [];
//     const isValid = hasValidItems(formItems);
//     form.setFieldValue('isValid', isValid);
//   }, [form.getFieldValue('items')]);

//   return (
//     <Box sx={{ p: 3 }}>
//       <Typography variant="h6" sx={{ color: 'text.disabled', mb: 3 }}>
//         Details:
//       </Typography>

//       <Stack
//         divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />}
//         spacing={3}
//       >
//         {items.map(i => (
//           <InvoiceLineItem key={i.id} item={i} />
//         ))}
//       </Stack>

//       <Divider sx={{ my: 3, borderStyle: 'dashed' }} />

//       <Stack
//         spacing={3}
//         direction={{ xs: 'column', md: 'row' }}
//         alignItems={{ xs: 'flex-end', md: 'center' }}
//       >
//         <Button
//           size="small"
//           color="primary"
//           startIcon={<Iconify icon="mingcute:add-line" />}
//           onClick={handleAdd}
//           sx={{ flexShrink: 0 }}
//         >
//           Add Item
//         </Button>
//       </Stack>

//       {renderTotal}
//     </Box>
//   );
// }
