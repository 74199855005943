import { api } from '@cvx/api';
import { GroupZodId } from '@cvx/types/zod/commonZodId';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  CardHeader,
  FormControl,
  FormControlLabel,
  Switch,
} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useForm } from '@tanstack/react-form';
import { useLoaderData, useNavigate } from '@tanstack/react-router';
import { useMutation, useQuery } from 'convex/react';
import { useCallback, useRef } from 'react';
import { toast } from 'sonner';
import { baseInviteSchema } from 'src/convex/functions/invitations';
import { updateUserSchema } from 'src/convex/functions/users';
import { UserRole } from 'src/convex/schema/enums/userRole';
import { useScheduledActions } from 'src/hooks/useScheduledActions';
import { PhoneInput } from 'src/minimal-theme/components/phone-input';
import { ActionConfirmationDialog } from 'src/sections/request/actions/ActionConfirmationDialog';
import { useActionConfirmationDialog } from 'src/sections/request/actions/hooks/useActionConfirmationDialog.ts';
import { z } from 'zod';

export function DispatcherNewEditForm() {
  const selectedDispatcher = (() => {
    try {
      return useLoaderData({
        from: '/_auth/dashboard/dispatchers/$dispatcherId',
      });
    } catch {
      return null;
    }
  })();

  const navigate = useNavigate();

  const { confirmAction, isOpen, onClose, pendingAction } =
    useActionConfirmationDialog();

  const locations = useQuery(api.functions.companies.getLocations, {});

  const { watchIds } = useScheduledActions();

  const me = useQuery(api.functions.users.getMe, {});

  const company = useQuery(
    api.functions.companies.getCompany,
    me?.companyId ? { companyId: me.companyId } : 'skip'
  );

  const inviteDispatcher = useMutation(api.functions.invitations.inviteUser);
  const updateUser = useMutation(api.functions.users.updateUser);
  const phoneRef = useRef<HTMLInputElement>(null);

  const { Field, Subscribe, handleSubmit, reset } = useForm({
    defaultValues: {
      firstName: selectedDispatcher?.clerkUser.firstName ?? '',
      lastName: selectedDispatcher?.clerkUser.lastName ?? '',
      phone:
        selectedDispatcher?.clerkUser.primaryPhoneNumber?.phoneNumber ?? '',
      email: selectedDispatcher?.clerkUser.primaryEmailAddress?.emailAddress,
      skipInvitationEmail: !!me?.clerkUser.isSuperAdmin,
      knownLocationInvitationId: selectedDispatcher?.primaryLocationGroupId,
    },
    validators: {
      onChange: selectedDispatcher
        ? updateUserSchema.omit({ userId: true }).extend({
            skipInvitationEmail: z.boolean(),
            knownLocationInvitationId: GroupZodId.optional(),
            primaryLocationGroupId: GroupZodId.optional(),
          })
        : baseInviteSchema
            .omit({
              role: true,
            })
            .extend({
              skipInvitationEmail: z.boolean(),
            }),
    },
    onSubmit: async ({ value }) => {
      if (!selectedDispatcher) {
        let role: UserRole = 'FLEET_DISPATCHER';

        if (company?.companyType === 'REPAIR_SHOP') {
          role = 'SERVICE_DISPATCHER';
        }

        if (company?.companyType === 'THIRD_PARTY') {
          role = 'THIRD_PARTY_DISPATCHER';
        }

        const res = await inviteDispatcher({
          inviteInfo: {
            ...value,
            role,
          },
        });

        if (res.success && res.scheduleId) {
          await watchIds([res.scheduleId], {
            onSuccess: () => {
              navigate({ to: '/dashboard/dispatchers' });
            },
            onError: failedIds => {
              // Maybe show an error message or handle failed invites
              console.error('Failed invites:', failedIds);
            },
          });
        } else {
          toast.error(res.message);
        }
      } else {
        const {
          knownLocationInvitationId,
          skipInvitationEmail,
          ...restOfValues
        } = value;

        if (!knownLocationInvitationId) {
          return;
        }

        const res = await updateUser({
          userId: selectedDispatcher._id,
          ...restOfValues,
          primaryLocationGroupId: knownLocationInvitationId,
        });

        if (res.success && res.scheduleId) {
          await watchIds([res.scheduleId], {
            onSuccess: () => {
              // navigate({ to: '/dashboard/dispatchers' });
            },
            onError: failedIds => {
              // Maybe show an error message or handle failed invites
              console.error('Failed to update');
            },
          });
        } else {
          toast.error(res.message);
        }
      }
    },
  });

  const handleFormSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      e.stopPropagation();

      confirmAction({
        title: selectedDispatcher ? 'Update Dispatcher' : 'Add Dispatcher',
        message: selectedDispatcher
          ? 'Are you sure you want to update this dispatcher?'
          : 'Are you sure you want to add this dispatcher?',
        onConfirm: handleSubmit,
      });
    },
    [confirmAction, handleSubmit, selectedDispatcher]
  );

  return (
    <>
      <Card>
        <CardHeader
          title={!selectedDispatcher ? 'Add Dispatcher' : 'Edit Dispatcher'}
          subheader={selectedDispatcher?.clerkUser.fullName}
          sx={{ mb: 3 }}
        />
        {/* <Divider /> */}
        <Stack spacing={3} sx={{ p: 3 }}>
          <form onSubmit={handleFormSubmit} style={{ width: '100%' }}>
            {/* <Box sx={{ mb: 5 }}>
            <Field
              name="imageUrl"
              children={({ state, ...other }) => (
                <UploadAvatar
                  value={state.value}
                  maxSize={3145728}
                  helperText={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 3,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.disabled',
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, *.gif
                      <br /> max size of {fData(3145728)}
                    </Typography>
                  }
                  {...other}
                />
              )}
            />
          </Box> */}
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <Field
                name="firstName"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    autoFocus
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.value}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                  />
                )}
              />
              <Field
                name="lastName"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.value}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                  />
                )}
              />
              <Field
                name="phone"
                // validators={{
                //   onChange: phoneSchema,
                // }}
                children={({ state, handleChange, handleBlur }) => (
                  <PhoneInput
                    ref={phoneRef}
                    required
                    placeholder="(123) 456-7890"
                    fullWidth
                    value={state.value}
                    onChange={newValue => handleChange(newValue || '')}
                    onBlur={handleBlur}
                    helperText={state.meta.errors[0]}
                    label="Phone Number"
                  />
                )}
              />
              <Field
                name="email"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="Email Address"
                    variant="outlined"
                    fullWidth
                    value={state.value ?? ''}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    onChange={e => {
                      const value = e.target.value.trim();
                      handleChange(value || undefined);
                    }}
                    onBlur={handleBlur}
                  />
                )}
              />
              {locations && locations?.length > 1 && (
                <FormControl>
                  <Field
                    name="knownLocationInvitationId"
                    children={({ state, handleChange }) => (
                      <Autocomplete
                        fullWidth
                        options={locations || []}
                        getOptionLabel={option => option.name}
                        value={
                          locations?.find(l => state.value === l._id) || null
                        }
                        isOptionEqualToValue={(option, value) =>
                          option?._id === value?._id
                        }
                        onChange={(event, newValue) =>
                          handleChange(newValue ? newValue._id : undefined)
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Location"
                            required
                            error={state.meta.errors.length > 0}
                            helperText={state.meta.errors[0]}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option._id}>
                            {option.name}
                          </li>
                        )}
                      />
                    )}
                  />
                </FormControl>
              )}
              <Box>
                {!selectedDispatcher && (
                  <Field name="skipInvitationEmail">
                    {({ state, handleChange }) => (
                      <FormControlLabel
                        control={
                          <Switch
                            id="skipInvitationEmail"
                            checked={state.value}
                            onChange={e => handleChange(e.target.checked)}
                          />
                        }
                        label="Skip Invitation Email"
                      />
                    )}
                  </Field>
                )}
              </Box>
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <Subscribe
                selector={state => [state.canSubmit, state.isSubmitting]}
                children={([canSubmit, isSubmitting]) => {
                  return (
                    <LoadingButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      loading={isSubmitting}
                      disabled={!canSubmit}
                    >
                      {!selectedDispatcher ? 'Add Dispatcher' : 'Save changes'}
                    </LoadingButton>
                  );
                }}
              />
            </Stack>
          </form>
          <ActionConfirmationDialog
            open={isOpen}
            onClose={onClose}
            {...pendingAction}
          />
        </Stack>
      </Card>
    </>
  );
}
