import { createFileRoute } from '@tanstack/react-router';
import { EULAPage } from 'src/pages/eula';

export const Route = createFileRoute('/eula')({
  component: RouteComponent,
});

function RouteComponent() {
  return <EULAPage />;
}
