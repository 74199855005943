import { createFileRoute } from '@tanstack/react-router';
import { ValidPaths } from 'src/minimal-theme/layouts/config-nav-dashboard';
import Page from 'src/pages/dashboard/dispatcher/list';
import {
  checkRouteAccess,
  routeAccessConfigs,
} from 'src/utils/checkRouteAccess';

export const Route = createFileRoute('/_auth/dashboard/dispatchers/')({
  beforeLoad: ({ context, location }) =>
    checkRouteAccess(
      context,
      routeAccessConfigs.dispatcherGeneral,
      location.pathname as ValidPaths
    ),
  component: Page,
});

// TODO: Because of how we have routes structured, had to duplicate checkRouteAccess on the sub routes like /new, etc... if we structure things differently we won't have to do this
