import { RequestSearchComponent } from '@cvx/types/entities/requestsEntityTypes';
import {
  RequestSearchComponentItem,
  StepType,
} from '@cvx/types/enums/requestEnums';
import { requestSchema } from '@cvx/types/zod/requestsZod';
import { zodToConvex } from 'convex-helpers/server/zod';
import { defineTable } from 'convex/server';
import { customAlphabet } from 'nanoid';

export const REQUEST_SEARCH_COMPONENT_DISPLAY: Record<
  RequestSearchComponentItem,
  string
> = {
  CASE_NUMBER: 'Case Number',
  FLEET_REFERENCE_NUMBER: 'Fleet Reference Number',
  ADDRESS: 'Address',
  DESCRIPTION: 'Description',
  DRIVER_NAME: 'Driver',
  FLEET_DISPATCHER_NAME: 'Fleet Dispatcher',
  SERVICE_DISPATCHER_NAME: 'Service Dispatcher',
  TECHNICIAN_NAME: 'Technician',
  VEHICLE_VIN: 'VIN',
  VEHICLE_MAKER: 'Make',
  VEHICLE_MODEL: 'Model',
  VEHICLE_UNIT_NUMBER: 'Unit #',
  REQUESTER_COMPANY_NAME: 'Requester Company',
  REQUESTER_LOCATION_NAME: 'Requester Location',
  TEMP_DRIVER_INFO: 'Driver',
  TEMP_DISPATCHER_INFO: 'Fleet Dispatcher',
};

export function getOrganizedComponents(
  components: RequestSearchComponent[] | undefined
): RequestSearchComponent[] {
  if (!components?.length) return [];

  const byType = new Map<RequestSearchComponentItem, RequestSearchComponent>();

  components.forEach(component => {
    // For driver info, only use temp if we don't have a proper user
    if (component.type === 'TEMP_DRIVER_INFO' && byType.has('DRIVER_NAME')) {
      return;
    }
    if (component.type === 'DRIVER_NAME') {
      byType.delete('TEMP_DRIVER_INFO');
    }

    // For dispatcher info, only use temp if we don't have a proper user
    if (
      component.type === 'TEMP_DISPATCHER_INFO' &&
      byType.has('FLEET_DISPATCHER_NAME')
    ) {
      return;
    }
    if (component.type === 'FLEET_DISPATCHER_NAME') {
      byType.delete('TEMP_DISPATCHER_INFO');
    }

    byType.set(component.type, component);
  });

  return Array.from(byType.values());
}

export const getRequestSearchComponentDisplay = (
  component?: RequestSearchComponentItem
) => (component ? REQUEST_SEARCH_COMPONENT_DISPLAY[component] : 'N/A');

export const STEP_DISPLAY: Record<StepType, string> = {
  INTAKE: 'Request Created',
  DRIVER_CONFIRM_LOCATION: 'Driver Confirming Location',
  DISPATCH_TRIAGE: 'With Dispatch',
  WITH_SERVICE_PROVIDER_DISPATCH: 'Awaiting Service Provider',
  TECHNICIAN_ASSIGNED: 'Technician Assigned',
  TECHNICIAN_ACCEPTED_PRE_EN_ROUTE: 'Technician Accepted', // TODO: Weird naming because we had to add the step BEFORE and not mess with the existing one
  TECHNICIAN_ACCEPTED: 'Technician En Route',
  TECHNICIAN_ARRIVED: 'Technician Onsite',
  TECHNICIAN_STARTED_WORK: 'Technician Started Work',
  TECHNICIAN_COMPLETED_WORK: 'Technician Has Completed Work',
  COMPLETION_VERIFICATION: 'Awaiting Verification',
  COMPLETED: 'Request Completed',
};

export const getStepTypeDisplay = (role?: StepType): string =>
  role ? STEP_DISPLAY[role] : 'N/A';

export const requests = defineTable(zodToConvex(requestSchema).fields)
  .index('by_driverGroupId_and_status_and_createdAt', [
    'driverGroupId',
    'status',
  ])
  .index('by_fleetDispatchGroupId_and_status_and_createdAt', [
    'fleetDispatchGroupId',
    'status',
  ])
  .index('by_mechanicDispatchGroupId_and_status_and_createdAt', [
    'mechanicDispatchGroupId',
    'status',
  ])
  .index('by_mechanicServiceGroupId_and_status_and_createdAt', [
    'mechanicServiceGroupId',
    'status',
  ])

  .index('by_brokerageGroupId_and_status_and_createdAt', [
    'brokerageGroupId',
    'status',
  ])

  // Active participant indexes - all include status
  .index('by_activeDriverId_and_status', ['activeDriverId', 'status'])
  .index('by_activeFleetDispatcherId_and_status', [
    'activeFleetDispatcherId',
    'status',
  ])
  .index('by_activeServiceDispatcherId_and_status', [
    'activeServiceDispatcherId',
    'status',
  ])
  .index('by_activeTechnicianId_and_status', ['activeTechnicianId', 'status'])
  .index('by_activeBrokerageDispatcherId_and_status', [
    'activeBrokerageDispatcherId',
    'status',
  ])
  .index('by_vehicleId_and_status', ['vehicleId', 'status'])

  // Queue indexes (critical for dispatch functionality)
  .index('by_fleetQueue', [
    'currentRequiredRoleId',
    'fleetDispatchGroupId',
    'status',
    'currentStepState',
  ])
  .index('by_serviceQueue', [
    'currentRequiredRoleId',
    'mechanicDispatchGroupId',
    'status',
    'currentStepState',
  ])

  // Assignment indexes
  .index('by_currentAssignedToId_and_status', ['currentAssignedToId', 'status'])

  // Creation and management indexes
  .index('by_createdById_and_status', ['createdById', 'status'])
  .searchIndex('fleetDispatchSearch', {
    searchField: 'fleetSearchText',
    filterFields: ['fleetDispatchGroupId', 'status', 'createdAt'],
  })
  .searchIndex('serviceDispatchSearch', {
    searchField: 'serviceSearchText',
    filterFields: ['mechanicDispatchGroupId', 'status', 'createdAt'],
  });

const generateNumericId = customAlphabet('0123456789', 7);

export function generateCaseNumber(): string {
  const now = new Date();
  const datePart = now.toISOString().slice(2, 10).replace(/-/g, ''); // YYMMDD
  const randomPart = generateNumericId();

  return `${datePart}-${randomPart}`;
}
