import { Card, CardContent, Typography } from '@mui/material';

export const StatCard = ({
  title,
  value,
}: {
  title: string;
  value: number;
}) => (
  <Card>
    <CardContent>
      <Typography color="text.secondary" variant="subtitle2">
        {title}
      </Typography>
      <Typography variant="h4">{value.toLocaleString()}</Typography>
    </CardContent>
  </Card>
);
