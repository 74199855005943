import { EnhancedUser } from '@cvx/functions/users';
import { EnhancedRequest } from '@cvx/types/entities/requestsEntityTypes';
import { Button, Stack } from '@mui/material';
import { InvoiceWithItems } from 'src/convex/functions/invoices';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { Label } from 'src/minimal-theme/components/label';
import { RequestStateInformation } from 'src/sections/request/actions/utils/getRequestStateInformation';

export type PayInvoiceActionProps = {
  request: EnhancedRequest;
  me: EnhancedUser;
  requestStateInformation: RequestStateInformation;
  invoices?: InvoiceWithItems[];
  paidAndViewableInvoices: InvoiceWithItems[];
  payableInvoices: InvoiceWithItems[];
};

export const PayInvoiceAction = ({
  request,
  me,
  requestStateInformation,
  invoices,
  paidAndViewableInvoices,
  payableInvoices,
}: PayInvoiceActionProps & {
  paidAndViewableInvoices: InvoiceWithItems[];
  payableInvoices: InvoiceWithItems[];
}) => {
  return (
    <>
      {paidAndViewableInvoices?.map(invoice => (
        <Stack
          key={invoice.invoice._id}
          direction="row"
          spacing={2}
          alignItems="center"
        >
          <Button
            variant="contained"
            size="large"
            color="primary"
            fullWidth
            onClick={() => {
              window.open(invoice.invoice.publicInvoiceUrl, '_blank');
            }}
            startIcon={
              <Iconify icon="solar:file-text-bold-duotone" width={40} />
            }
          >
            View Invoice — $
            {invoice.invoice.amount ? invoice.invoice.amount / 100 : 0}
          </Button>
          <Label
            variant="soft"
            color={
              (invoice.invoice.status === 'PAID' && 'success') ||
              (invoice.invoice.status === 'SENT' && 'warning') ||
              (invoice.invoice.status === 'OVERDUE' && 'error') ||
              'default'
            }
          >
            {invoice.invoice.status}
          </Label>
        </Stack>
      ))}
      {payableInvoices?.map(invoice => (
        <Stack
          key={invoice.invoice._id}
          direction="row"
          spacing={2}
          alignItems="center"
        >
          <Button
            variant="contained"
            color="primary"
            sx={{ height: '100%' }}
            fullWidth
            onClick={() => {
              if (invoice.invoice.publicInvoiceUrl) {
                window.open(invoice.invoice.publicInvoiceUrl, '_blank');
              }
            }}
            startIcon={<Iconify icon="solar:card-bold-duotone" width={40} />}
          >
            Pay Invoice — $
            {invoice.invoice.amount ? invoice.invoice.amount / 100 : 0}
          </Button>
          <Label
            variant="soft"
            color={
              (invoice.invoice.status === 'PAID' && 'success') ||
              (invoice.invoice.status === 'SENT' && 'warning') ||
              (invoice.invoice.status === 'OVERDUE' && 'error') ||
              'default'
            }
          >
            {invoice.invoice.status}
          </Label>
        </Stack>
      ))}
    </>
  );
};
