import { api } from '@cvx/api';
import { ConvexUser } from '@cvx/types/entities/usersEntityTypes';
import { IconButton, Link, MenuItem, MenuList, Stack } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useRouter } from '@tanstack/react-router';
import { useQuery } from 'convex/react';
import {
  CustomPopover,
  usePopover,
} from 'src/minimal-theme/components/custom-popover';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { MuiTypeSafeLink } from 'src/minimal-theme/components/routes/components/mui-link';
import { useBoolean } from 'src/minimal-theme/hooks/use-boolean';

type Props = {
  selected: boolean;
  onEditRow: VoidFunction;
  row: ConvexUser;
  onSelectRow: VoidFunction;
  setUserToDelete: (user: ConvexUser) => void;
};

export function DispatcherTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  setUserToDelete,
}: Props) {
  const { _id, clerkUser, status } = row;

  const me = useQuery(api.functions.users.getMe);

  const confirm = useBoolean();

  const popover = usePopover();

  const router = useRouter();

  const location = useQuery(
    api.functions.companies.getLocationById,
    row.primaryLocationGroupId ? { id: row.primaryLocationGroupId } : 'skip'
  );

  const handleViewDetails = () => {
    router.navigate({
      to: '/dashboard/dispatchers/$dispatcherId',
      params: { dispatcherId: _id },
    });
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell>
          <MuiTypeSafeLink
            to="/dashboard/dispatchers/$dispatcherId"
            params={{ dispatcherId: _id }}
            color="inherit"
            sx={{ cursor: 'pointer' }}
          >
            {clerkUser.firstName} {clerkUser.lastName}
          </MuiTypeSafeLink>
        </TableCell>

        <TableCell>
          <Link
            href={`tel:${clerkUser.primaryPhoneNumber?.phoneNumber}`}
            underline="hover"
            color="inherit"
          >
            {clerkUser.primaryPhoneNumber?.phoneNumber}
          </Link>
        </TableCell>

        <TableCell>
          <Link
            href={`mailto:${clerkUser.primaryEmailAddress?.emailAddress}`}
            color="inherit"
            underline="hover"
          >
            {clerkUser.primaryEmailAddress?.emailAddress}
          </Link>
        </TableCell>

        <TableCell>{location?.name}</TableCell>

        <TableCell align="right">
          <Stack direction="row" spacing={1}>
            <IconButton
              color={popover.open ? 'primary' : 'default'}
              onClick={popover.onOpen}
            >
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        anchorEl={popover.anchorEl}
        onClose={popover.onClose}
        slotProps={{ arrow: { placement: 'right-top' } }}
      >
        <MenuList>
          <MenuItem
            component={MuiTypeSafeLink}
            to="/dashboard/dispatchers/$dispatcherId"
            params={{ dispatcherId: _id }}
            onClick={() => {
              confirm.onTrue();
              popover.onClose();
            }}
            sx={{ color: 'inherit' }}
          >
            <Iconify icon="solar:pen-bold" />
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              setUserToDelete(row);
              popover.onClose();
            }}
            sx={{ color: 'inherit' }}
            disabled={row._id === me?._id}
          >
            <Iconify icon="solar:trash-bin-trash-bold" />
            Delete
          </MenuItem>
        </MenuList>
      </CustomPopover>
    </>
  );
}
