import { z } from 'zod';

export const invoiceServiceType = z.enum([
  'DISPATCH_FEE',
  'TECHNICIAN_LABOR',
  'DIAGNOSTIC_FEE',
  'PART',
  'TIRE',
  'FET',
  'MILEAGE_CHARGE',
  'TOWING_CHARGE',
  'SHOP_SUPPLIES',
  'MISC',
]);

export type InvoiceServiceType = z.infer<typeof invoiceServiceType>;

export const INVOICE_SERVICE_TYPE_DISPLAY: Record<InvoiceServiceType, string> =
  {
    DISPATCH_FEE: 'Dispatch / Call Out Fee',
    TECHNICIAN_LABOR: 'Technician Labor',
    DIAGNOSTIC_FEE: 'Diagnostic Fee',
    PART: 'Part',
    TIRE: 'Tire',
    FET: 'Federal Excise Tax',
    MILEAGE_CHARGE: 'Mileage Charge',
    TOWING_CHARGE: 'Towing Charge',
    SHOP_SUPPLIES: 'Shop Supplies',
    MISC: 'Miscellaneous',
  };

export const getInvoiceServiceTypeDisplay = (type?: InvoiceServiceType) =>
  type ? INVOICE_SERVICE_TYPE_DISPLAY[type] : 'N/A';

export const INVOICE_SERVICE_TYPE_TAX_CODES: Record<
  InvoiceServiceType,
  string
> = {
  TECHNICIAN_LABOR: 'txcd_20080003', // General services
  DIAGNOSTIC_FEE: 'txcd_20080003', // General services
  DISPATCH_FEE: 'txcd_20030000', // General services
  PART: 'txcd_99999999', // Auto parts
  TIRE: 'txcd_99999999', // Tires specifically
  FET: 'txcd_00000000', // Zero tax code - FET is handled separately
  MILEAGE_CHARGE: 'txcd_20030000', // Transportation service
  TOWING_CHARGE: 'txcd_20030000', // Transportation service
  SHOP_SUPPLIES: 'txcd_99999999', // General merchandise
  MISC: 'txcd_99999999', // General merchandise - taxable
};

export const getInvoiceServiceTypeTaxCode = (type?: InvoiceServiceType) =>
  type ? INVOICE_SERVICE_TYPE_TAX_CODES[type] : 'txcd_99999999';
