import { api } from '@cvx/api';
import { EnhancedRequest } from '@cvx/types/entities/requestsEntityTypes';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useMutation } from 'convex/react';
import { useState } from 'react';
import { toast } from 'src/minimal-theme/components/snackbar';

type CancelRequestDialogProps = {
  onClose: () => void;
  request: EnhancedRequest;
  open: boolean;
};

export function CancelRequestDialog({
  onClose,
  request,
  open,
}: CancelRequestDialogProps) {
  const [isConfirming, setIsConfirming] = useState(false);
  const [reason, setReason] = useState<string>('');

  const cancelRequest = useMutation(api.functions.requests.cancelRequest);

  const onCancel = async () => {
    setIsConfirming(true);
    const res = await cancelRequest({
      requestId: request._id,
      reason,
    });

    if (res.success) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }

    setIsConfirming(false);
    onClose();
    setReason('');
  };

  return (
    <Dialog
      open={open}
      onClose={isConfirming ? () => {} : () => onClose}
      disableEscapeKeyDown={isConfirming}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{`Cancel Request`}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Are you sure you want to cancel this request?
        </DialogContentText>
        <TextField
          label="Reason for Cancellation"
          variant="outlined"
          fullWidth
          multiline
          disabled={isConfirming}
          rows={4}
          value={reason}
          helperText={
            'Provide a reason for why you are cancelling this request.'
          }
          onChange={e => setReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isConfirming}>
          Cancel
        </Button>
        <LoadingButton
          loading={isConfirming}
          disabled={isConfirming}
          onClick={onCancel}
          variant="contained"
        >
          Confirm Cancellation
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
