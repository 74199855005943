import { ScheduledFunctionId } from '@cvx/types/entities/sharedIds';
import { phoneSchema } from '@cvx/types/zod/commonZod';
import { GroupZodId, ServiceRequestZodId } from '@cvx/types/zod/commonZodId';
import { physicalLocationType } from '@cvx/types/zod/groupsZod';
import { getOneFrom } from 'convex-helpers/server/relationships';
import { ConvexError } from 'convex/values';
import { z } from 'zod';
import { internal } from '../_generated/api';
import { MutationCtx } from '../_generated/server';
import { notificationConfigSchema } from '../schema/entities/users';
import { userRole } from '../schema/enums/userRole';
import { getUserContext } from '../utils/getUserContext';
import {
  createCompanyViaInvitationHelper,
  createLocationViaInvitationHelper,
  getExistingUser,
} from './companies';
import { roleConfigs } from './helpers/userCapabilities';
import { zMutation } from './helpers/zodHelpers';

// Base invite schema remains the same
export const baseInviteSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email().optional(),
  phone: z.string(),
  role: userRole,
  requestId: ServiceRequestZodId.optional(),
  skipInvitationEmail: z.boolean().optional(),
  knownLocationInvitationId: GroupZodId.optional(), // If location/company exist already and we know it, we will either be assigning to an existing user or create just the user and assigning (if creating a new user we need to know the location/company ids)
  notificationSettings: notificationConfigSchema.optional(),
});

export type BaseInvite = z.infer<typeof baseInviteSchema>;

// Updated location info schema to match our helper expectations
export const locationInfoSchema = z
  .object({
    companyName: z.string(),
    locationName: z.string(),
    locationAddress: z.string(),
    locationLatitude: z.number(),
    locationLongitude: z.number(),
    companyHqAddress: z.string(),
    companyHqLatitude: z.number(),
    companyHqLongitude: z.number(),
    locationType: physicalLocationType,
    description: z.string().optional().default(''),
    companyContactPhone: phoneSchema,
    companyContactEmail: z.string().email().optional(),
    locationContactInfo: z
      .object({
        phone: phoneSchema.optional(),
        email: z.string().email().optional(),
        dispatchPhone: z.string().optional(),
        emergencyPhone: z.string().optional(),
      })
      .optional(),
  })
  .optional();

export const tempLocationInfoSchema = z
  .object({
    companyName: z.string(),
    locationName: z.string(),
    description: z.string().optional().default(''),
  })
  .optional();

export type TempLocationInfo = z.infer<typeof tempLocationInfoSchema>;

/**
 * Generic invitation handler, invitations occur during requests by users, or in existing locations, when a group wants to add drivers/dispatchers/technicians
 */
export const inviteUser = zMutation({
  args: z.object({
    inviteInfo: baseInviteSchema,
    locationInfo: locationInfoSchema,
    tempLocationInfo: tempLocationInfoSchema,
  }).shape,
  handler: async (ctx, { inviteInfo, locationInfo, tempLocationInfo }) => {
    const { roles, isSuperAdmin, company, primaryLocation, user } =
      await getUserContext(ctx);

    const { knownLocationInvitationId, ...inviteInfoForAction } = inviteInfo;

    const { email, phone, role, requestId } = inviteInfoForAction;

    // Regular auth check unless it's a request-based invite .. TODO: This isn't ideal because passing a valid request id is basically an admin invitation override...
    // But right now we need users to be able to invite people during a request..
    if (
      (!requestId && !roleConfigs[role].canInvite(roles, isSuperAdmin)) ||
      (role === 'SUPER_ADMIN' && !isSuperAdmin)
    ) {
      throw new ConvexError({
        code: 'FORBIDDEN',
        message: roleConfigs[role].errorMessage,
      });
    }

    // Check for existing user
    const existingUser = await getExistingUser({ ctx, email, phone });

    if (existingUser) {
      // TODO: Still need an audit of this entire function end to end, rushing

      // TODO: Also handle other role types...

      if (role !== existingUser.primaryRoleType && requestId) {
        throw new Error('User is not the correct role');
      }

      if (
        role === 'SERVICE_DISPATCHER' &&
        requestId &&
        existingUser.primaryLocationGroupId &&
        existingUser.companyId
      ) {
        await ctx.runMutation(
          internal.functions.requests.assignDirectlyToInvitedServiceDispatcher,
          {
            requestId,
            serviceDispatcherId: existingUser._id,
            locationGroupId: existingUser.primaryLocationGroupId,
            companyId: existingUser.companyId,
            roleOfInviterId: user.primaryRoleId!,
            userInvitingId: user._id,
            userBeingInvitedId: existingUser._id,
          }
        );

        return {
          success: true,
          message: `Invitation sent to existing ${role.toLowerCase().replace('_', ' ')}`,
        };
      } else if (
        role === 'TECHNICIAN_PROVIDER' &&
        requestId &&
        existingUser.primaryLocationGroupId &&
        existingUser.companyId
      ) {
        await ctx.runMutation(
          internal.functions.requests.assignDirectlyToInvitedProviderTechnician,
          {
            requestId,
            serviceDispatcherId: user._id,
            technicianId: existingUser._id,
            dispatcherRoleId: user.primaryRoleId!,
            dispatcherName: user.clerkUser.fullName!,
            companyName: company.name,
          }
        );

        return {
          success: true,
          message: `Invitation sent to existing ${role.toLowerCase().replace('_', ' ')}`,
        };
      }

      // For now, to avoid introducing blockers (i.e. assign to driver and they get stuck because they're new to the app, we keep it assigned to
      // the user who invited them, and if the driver does jump in, we make it really easy for them to share their location)
      else if (
        role === 'DRIVER_FLEET' &&
        requestId &&
        existingUser.primaryLocationGroupId &&
        existingUser.companyId
      ) {
        await ctx.runMutation(
          internal.functions.requests.addUserToRequestAsParticipant,
          {
            requestId,
            userId: existingUser._id,
            roleType: 'DRIVER_FLEET',
            inviterId: user._id,
            inviterLocationId: primaryLocation._id,
          }
        );

        return {
          success: true,
          message: `Invitation sent to existing ${role.toLowerCase().replace('_', ' ')}`,
        };
      } else if (
        role === 'FLEET_DISPATCHER' &&
        requestId &&
        existingUser.primaryLocationGroupId &&
        existingUser.companyId
      ) {
        await ctx.runMutation(
          internal.functions.requests.addUserToRequestAsParticipant,
          {
            requestId,
            userId: existingUser._id,
            roleType: 'FLEET_DISPATCHER',
            inviterId: user._id,
            inviterLocationId: primaryLocation._id,
          }
        );

        return {
          success: true,
          message: `Invitation sent to existing ${role.toLowerCase().replace('_', ' ')}`,
        };
      } else {
        return {
          success: false,
          message: `User with email or phone number already exists`,
        };
      }
    }

    let targetCompanyId = company._id;
    let targetLocationId = primaryLocation._id;

    if (knownLocationInvitationId) {
      const knownLocation = await ctx.db.get(knownLocationInvitationId);

      if (!knownLocation || !knownLocation.companyId) {
        throw new Error('Location does not exist or is malformed');
      }

      targetCompanyId = knownLocation.companyId;
      targetLocationId = knownLocation._id;
    } else if (locationInfo) {
      const { exactLocationMatch, associatedCompany, possibleCompanyMatches } =
        await findPotentialDuplicateLocations(
          ctx,
          locationInfo.locationAddress,
          locationInfo.companyName
        );

      if (exactLocationMatch && associatedCompany) {
        // We found the existing location - use it and its company
        targetLocationId = exactLocationMatch._id;
        targetCompanyId = associatedCompany._id;
      } else if (possibleCompanyMatches.length > 0) {
        // Use existing company but create new location
        targetCompanyId = possibleCompanyMatches[0]._id;
        const locationResult = await createLocationViaInvitationHelper(ctx, {
          companyId: targetCompanyId,
          name: locationInfo.locationName,
          description: locationInfo.description || '',
          location: {
            address: locationInfo.locationAddress,
            latitude: locationInfo.locationLatitude,
            longitude: locationInfo.locationLongitude,
            lastUpdated: new Date().toISOString(),
          },
          locationType: locationInfo.locationType,
          representsPhysicalLocation: true,
          showInProviderDirectory: !requestId,
          hasVerifiedDispatchTeam: false,
          hasVerifiedTechnicians: false,
          contactInfo: locationInfo.locationContactInfo,
          acceptedPaymentMethods: [],
          noDispatchSoleProprietor: false, // TODO: Handle this with questionnaire form on login for these invited users
          invitedByCompanyId: company._id,
          invitedByLocationId: primaryLocation._id,
          invitedByUserId: user._id,
          createdFromRequestId: requestId,
          createdById: user._id,
        });

        if (!locationResult.success) {
          throw new Error(locationResult.message);
        }
        targetLocationId = locationResult.locationId;
      } else {
        // Create new company and location
        const companyResult = await createCompanyViaInvitationHelper(ctx, {
          name: locationInfo.companyName,
          companyType: 'REPAIR_SHOP',
          invitedByCompanyId: company._id,
          invitedByLocationId: primaryLocation._id,
          invitedByUserId: user._id,
          createdFromRequestId: requestId,
          serviceIds: [],
          location: {
            address: locationInfo.companyHqAddress,
            longitude: locationInfo.companyHqLongitude,
            latitude: locationInfo.companyHqLatitude,
            lastUpdated: new Date().toISOString(),
          },
        });

        if (!companyResult.success) {
          throw new Error(companyResult.message);
        }
        targetCompanyId = companyResult.companyId;

        // Create new location for new company
        const locationResult = await createLocationViaInvitationHelper(ctx, {
          companyId: targetCompanyId,
          name: locationInfo.locationName,
          description: locationInfo.description || '',
          location: {
            address: locationInfo.locationAddress,
            latitude: locationInfo.locationLatitude,
            longitude: locationInfo.locationLongitude,
            lastUpdated: new Date().toISOString(),
          },
          locationType: locationInfo.locationType,
          representsPhysicalLocation: true,
          showInProviderDirectory: !requestId,
          hasVerifiedDispatchTeam: false,
          hasVerifiedTechnicians: false,
          contactInfo: locationInfo.locationContactInfo,
          acceptedPaymentMethods: [],
          noDispatchSoleProprietor: false,
          invitedByCompanyId: company._id,
          invitedByLocationId: primaryLocation._id,
          invitedByUserId: user._id,
          createdFromRequestId: requestId,
          createdById: user._id,
        });

        if (!locationResult.success) {
          throw new Error(locationResult.message);
        }
        targetLocationId = locationResult.locationId;
      }
    } else if (tempLocationInfo) {
      const companyResult = await createCompanyViaInvitationHelper(ctx, {
        name: tempLocationInfo.companyName,
        companyType: 'FLEET',
        invitedByCompanyId: company._id,
        invitedByLocationId: primaryLocation._id,
        invitedByUserId: user._id,
        createdFromRequestId: requestId,
        serviceIds: [],
      });

      if (!companyResult.success) {
        throw new Error(companyResult.message);
      }
      targetCompanyId = companyResult.companyId;

      // Create a minimal location
      const locationResult = await createLocationViaInvitationHelper(ctx, {
        companyId: targetCompanyId,
        description: tempLocationInfo.description || '',
        name: tempLocationInfo.locationName,
        locationType: 'FLEET_TERMINAL',
        invitedByLocationId: primaryLocation._id,
        invitedByUserId: user._id,
        createdFromRequestId: requestId,
        createdById: user._id,
        representsPhysicalLocation: true,
        noDispatchSoleProprietor: false,
        acceptedPaymentMethods: [],
      });

      if (!locationResult.success) {
        throw new Error(locationResult.message);
      }
      targetLocationId = locationResult.locationId;
    }

    try {
      const scheduleId: ScheduledFunctionId = await ctx.scheduler.runAfter(
        0,
        internal.actions.companies.inviteUserAction,
        {
          ...inviteInfoForAction,
          companyId: targetCompanyId,
          locationGroupId: targetLocationId,
          invitedByUserId: user._id,
          invitedByLocationId: primaryLocation._id,
          invitedByCompanyId: company._id,
          userInviting: user,
          companyName: company.name,
        }
      );

      return {
        success: true,
        message: `Inviting ${role.toLowerCase().replace('_', ' ')}`,
        scheduleId,
        targetLocationId,
      };
    } catch (error) {
      if (error instanceof ConvexError) throw error;
      console.error(`Failed to invite ${role}:`, error);
      throw new Error(`Failed to invite ${role.toLowerCase()}`);
    }
  },
});

async function findPotentialDuplicateLocations(
  ctx: MutationCtx,
  address: string,
  companyName: string
) {
  // Find location with exact address match - should only be one if any
  const exactLocationMatches = await getOneFrom(
    ctx.db,
    'groups',
    'by_location_address',
    address as any,
    'location.address'
  );

  // If we have an exact location match, get its company
  if (exactLocationMatches) {
    if (!exactLocationMatches.companyId) {
      throw new Error('Invalid data: Location exists without company ID');
    }

    const company = await ctx.db.get(exactLocationMatches.companyId);
    if (!company) {
      throw new Error(
        'Invalid data: Cannot find company for existing location'
      );
    }

    return {
      exactLocationMatch: exactLocationMatches,
      associatedCompany: company,
      possibleCompanyMatches: [],
    };
  }

  // If no location match, look for company matches
  const possibleCompanyMatches = await ctx.db
    .query('companies')
    .withIndex('by_name', q => q.eq('name', companyName))
    .collect();

  return {
    exactLocationMatch: null,
    associatedCompany: null,
    possibleCompanyMatches,
  };
}
