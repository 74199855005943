import { api } from '@cvx/api';
import { CompanyGroup } from '@cvx/types/entities/groupsEntityTypes';
import { CompanyId, GroupId } from '@cvx/types/entities/sharedIds';
import { PhysicalLocationType } from '@cvx/types/enums/groupEnums';
import {
  CompanyZodId,
  GroupZodId,
  VendorServiceZodId,
} from '@cvx/types/zod/commonZodId';
import {
  FLEET_LOCATION_TYPES,
  limitedLocationTypes,
  REPAIR_SHOP_LOCATION_TYPES,
  THIRD_PARTY_LOCATION_TYPES,
} from '@cvx/types/zod/groupsZod';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  CardHeader,
  Checkbox,
  debounce,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useForm } from '@tanstack/react-form';
import { useParams } from '@tanstack/react-router';
import { useStore } from '@tanstack/react-store';
import { useMutation, useQuery } from 'convex/react';
import { useEffect, useMemo, useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input/input';
import { toast } from 'sonner';
import { Doc } from 'src/convex/_generated/dataModel';
import {
  getFriendlyPaymentMethodName,
  paymentMethod,
  PaymentMethods,
} from 'src/convex/schema/enums/paymentMethod';
import {
  getPhysicalLocationTypeDisplay,
  isFleetType,
  isServiceType,
} from 'src/convex/types/utils/groupUtils';
import { PhoneInput } from 'src/minimal-theme/components/phone-input';
import { useCompanySearch } from 'src/minimal-theme/hooks/useCompanySearch';
import { useGroupSearch } from 'src/minimal-theme/hooks/useGroupSearch';
import { schemaHelper } from 'src/minimal-theme/utils/schema-helper';
import { ServiceSelection } from 'src/sections/location/view/ServiceSelection';
import { useAddressSearch } from 'src/utils/useAddressSearch';
import zod, { z } from 'zod';
import { AddressInput } from '../request/AddressInput';

// https://tanstack.com/form/latest/docs/framework/react/guides/async-initial-values
// TODO: We shouldn't have to keep creating new zod schema for forms, should be able to use the same ones on the front/backend which are extended from the base schema eventually
export type NewVendorSchemaType = zod.infer<typeof newVendorSchema>;

export const newVendorSchema = zod.object({
  email: zod.string().email('Invalid email address').optional(),
  phone: schemaHelper.phoneNumber({ isValidPhoneNumber }),
  dispatchPhone: schemaHelper.phoneNumber({ isValidPhoneNumber }),
  emergencyPhone: schemaHelper
    .phoneNumber({ isValidPhoneNumber })
    .optional()
    .or(zod.literal('')),
  address: zod.string().refine(val => !!val, 'Address is required'),
  noDispatchSoleProprietor: zod.boolean().optional(),
  locationType: zod.string().refine(val => !!val, 'Location type is required'),
  isRetail: zod.boolean().default(false),
  hasVerifiedDispatchTeam: zod.boolean().default(false),
  hasVerifiedTechnicians: zod.boolean().default(false),
  showInProviderDirectory: zod.boolean().default(false),
  companyId: CompanyZodId.refine(val => !!val, 'Parent company is required'),
  defaultDispatchGroupId: GroupZodId.optional(),
  name: zod.string().min(2, 'Name is required'),
  description: zod
    .string()
    .max(500, 'Description should be 500 characters or fewer'),
  notes: zod.string().max(500, 'Notes should be 500 characters or fewer'),
  serviceIds: z.array(VendorServiceZodId),
  // TODO: Gotta be a better way to do this
  simpleServiceAreaByMilesOut: z
    .string()
    .optional()
    .refine(
      val => {
        if (!val) return true;
        const num = Number(val);
        return !isNaN(num) && num > 0 && Number.isInteger(num);
      },
      {
        message: 'Must be a positive whole number',
      }
    ),
  acceptedPaymentMethods: z.array(paymentMethod),
});

interface AddressOption {
  place_name: string;
  mapboxId?: string;
}

export function LocationNewEditForm() {
  // TODO: need a better solution for this because we lose type safety
  const params = useParams({ strict: false });

  const me = useQuery(api.functions.users.getMe, {});

  const isDispatcher =
    me?.primaryRoleType === 'FLEET_DISPATCHER' ||
    me?.primaryRoleType === 'SERVICE_DISPATCHER';

  const currentLocation = useQuery(
    api.functions.companies.getLocationById,
    params.locationId
      ? {
          id: params.locationId as GroupId,
        }
      : 'skip'
  );

  let enableCurrentParentCompanyQuery = params.locationId && currentLocation;
  let companyId = currentLocation?.companyId;

  if (isDispatcher) {
    enableCurrentParentCompanyQuery = true;
    companyId = me?.companyId;
  }

  const currentParentCompany = useQuery(
    api.functions.companies.getCompany,
    enableCurrentParentCompanyQuery
      ? {
          companyId: companyId as CompanyId,
        }
      : 'skip'
  );

  const initialDispatchLocation = useQuery(
    api.functions.companies.getLocationById,
    currentLocation
      ? {
          id: currentLocation.defaultDispatchGroupId! as GroupId,
        }
      : 'skip'
  );

  const existingLocationServices = useQuery(
    api.functions.serviceCategories.getLocationServices,
    params.locationId
      ? {
          locationId: params.locationId as GroupId,
        }
      : 'skip'
  );

  const {
    setDebouncedTerm,
    companies,
    selectedCompany,
    setSelectedCompany,
    debouncedTerm,
  } = useCompanySearch();

  const {
    setDebouncedTerm: setDebouncedLocationTerm,
    groups: locations,
    setSelectedGroup: setSelectedLocation,
    selectedGroup: selectedLocation,
    debouncedTerm: debouncedLocationTerm,
  } = useGroupSearch(selectedCompany?._id);

  // Set initial data for autocompletes when available
  useEffect(() => {
    if (currentParentCompany) {
      setSelectedCompany(currentParentCompany);
    }
  }, [currentParentCompany]);

  useEffect(() => {
    if (initialDispatchLocation) {
      setSelectedLocation(initialDispatchLocation);
    }
  }, [initialDispatchLocation]);

  const debouncedSearch = useMemo(
    () =>
      debounce(async (query: string) => {
        if (!query) return;

        setDebouncedTerm(query);
      }, 200),
    []
  );

  const debouncedLocationSearch = useMemo(
    () =>
      debounce(async (query: string) => {
        if (!query) return;

        setDebouncedLocationTerm(query);
      }, 200),
    []
  );

  const createLocation = useMutation(api.functions.companies.createLocation);
  const updateLocation = useMutation(api.functions.companies.updateLocation);

  type LocationTypeEnum =
    | typeof limitedLocationTypes
    | typeof FLEET_LOCATION_TYPES
    | typeof REPAIR_SHOP_LOCATION_TYPES
    | typeof THIRD_PARTY_LOCATION_TYPES;

  let locationTypeList: LocationTypeEnum = limitedLocationTypes;

  if (selectedCompany?.companyType === 'FLEET') {
    locationTypeList = FLEET_LOCATION_TYPES;
  } else if (selectedCompany?.companyType === 'REPAIR_SHOP') {
    locationTypeList = REPAIR_SHOP_LOCATION_TYPES;
  } else if (selectedCompany?.companyType === 'THIRD_PARTY') {
    locationTypeList = THIRD_PARTY_LOCATION_TYPES;
  }

  const locationTypeOptions = locationTypeList.options;

  const { searchResults, coordinates, setCoordinates, searchAddress } =
    useAddressSearch();

  const { Field, Subscribe, handleSubmit, reset, store } = useForm({
    defaultValues: {
      name: currentLocation?.name || '',
      companyId: companyId || '',
      defaultDispatchGroupId: currentLocation?.defaultDispatchGroupId || '',
      description: currentLocation?.description || '',
      locationType: currentLocation?.locationType || '',
      address: currentLocation?.location?.address || '',
      showInProviderDirectory:
        currentLocation?.showInProviderDirectory || false,
      isRetail: currentLocation?.isRetail || false,
      noDispatchSoleProprietor:
        currentLocation?.noDispatchSoleProprietor || false,
      hasVerifiedDispatchTeam:
        currentLocation?.hasVerifiedDispatchTeam || false,
      hasVerifiedTechnicians: currentLocation?.hasVerifiedTechnicians || false,
      phone: currentLocation?.contactInfo?.phone || '',
      email: currentLocation?.contactInfo?.email || '',
      dispatchPhone: currentLocation?.contactInfo?.dispatchPhone || '',
      emergencyPhone: currentLocation?.contactInfo?.emergencyPhone || '',
      notes: currentLocation?.contactInfo?.notes || '',
      simpleServiceAreaByMilesOut: currentLocation?.simpleServiceAreaByMilesOut
        ? currentLocation?.simpleServiceAreaByMilesOut.toString()
        : undefined,
      serviceIds: existingLocationServices
        ? existingLocationServices.map(s => s.serviceId)
        : [],
      acceptedPaymentMethods: currentLocation?.acceptedPaymentMethods ?? [],
    } as NewVendorSchemaType,
    validators: {
      onSubmit: newVendorSchema,
    },
    onSubmit: async ({ value }) => {
      try {
        if (currentLocation) {
          const thisCompanyId = selectedCompany?._id;

          const result = await updateLocation({
            input: {
              locationId: currentLocation._id,
              name: value.name,
              location: {
                latitude:
                  coordinates?.lat ?? currentLocation.location?.latitude ?? 0,
                longitude:
                  coordinates?.long ?? currentLocation.location?.longitude ?? 0,
                address: value.address,
                lastUpdated: new Date().toISOString(),
              },
              isRetail: value.isRetail,
              simpleServiceAreaByMilesOut: value.simpleServiceAreaByMilesOut
                ? parseInt(value.simpleServiceAreaByMilesOut)
                : undefined,
              defaultDispatchGroupId:
                value.defaultDispatchGroupId === ''
                  ? undefined
                  : value.defaultDispatchGroupId,
              description: value.description,
              locationType: value.locationType as any,
              hasVerifiedDispatchTeam: value.hasVerifiedDispatchTeam,
              hasVerifiedTechnicians: value.hasVerifiedTechnicians,
              representsPhysicalLocation: true,
              showInProviderDirectory: value.showInProviderDirectory,
              contactInfo: {
                phone: value.phone,
                email: value.email === '' ? undefined : value.email,
                dispatchPhone: value.dispatchPhone,
                emergencyPhone: value.emergencyPhone,
                notes: value.notes,
              },
              acceptedPaymentMethods: value.acceptedPaymentMethods ?? [],
              noDispatchSoleProprietor: value.noDispatchSoleProprietor ?? false,
            },
            serviceIds: value.serviceIds,
          });
          if (result.success) {
            toast.success(result.message);
          } else {
            toast.error(result.message);
          }
        } else {
          const result = await createLocation({
            input: {
              name: value.name,
              companyId: value.companyId as CompanyId,
              location: {
                latitude: coordinates?.lat ?? 0,
                longitude: coordinates?.long ?? 0,
                address: value.address,
                lastUpdated: new Date().toISOString(),
              },
              isRetail: value.isRetail,
              hasVerifiedDispatchTeam: value.hasVerifiedDispatchTeam,
              hasVerifiedTechnicians: value.hasVerifiedTechnicians,
              description: value.description,
              locationType: value.locationType as any,
              representsPhysicalLocation: true,
              showInProviderDirectory: value.showInProviderDirectory,
              simpleServiceAreaByMilesOut: value.simpleServiceAreaByMilesOut
                ? parseInt(value.simpleServiceAreaByMilesOut)
                : undefined,
              defaultDispatchGroupId:
                value.defaultDispatchGroupId === ''
                  ? undefined
                  : value.defaultDispatchGroupId,
              contactInfo: {
                phone: value.phone,
                email: value.email === '' ? undefined : value.email,
                dispatchPhone: value.dispatchPhone,
                emergencyPhone: value.emergencyPhone,
                notes: value.notes,
              },
              acceptedPaymentMethods: value.acceptedPaymentMethods ?? [],
              noDispatchSoleProprietor: value.noDispatchSoleProprietor ?? false,
            },
            serviceIds: value.serviceIds,
          });

          if (result.success) {
            toast.success(result.message);
            reset();
            // Clear additional form state
            setSelectedCompany(null);
            setSelectedLocation(null);
            setCoordinates(null);
            searchAddress(''); // Clear the address search
          } else {
            toast.error(result.message);
          }
        }
      } catch (error) {
        console.error('Failed to save location:', error);
        toast.error('Failed to save location. Please try again.');
      }
    },
  });

  const locationTypeString = useStore(store, s => s.values.locationType);

  const locationType =
    locationTypeString === ''
      ? undefined
      : (locationTypeString as PhysicalLocationType);

  const fleetType = isFleetType(locationType);
  const serviceType = isServiceType(locationType);

  // Doesn't make sense to show the no dispatch option if it's a dispatch center
  const showSolePropOptions =
    (!!fleetType || !!serviceType) &&
    !(
      locationType === 'SERVICE_DISPATCH_CENTER' ||
      locationType === 'FLEET_DISPATCH_CENTER'
    );

  const defaultDispatchGroupId = useStore(
    store,
    s => s.values.defaultDispatchGroupId
  );

  const soleProp = useStore(store, s => s.values.noDispatchSoleProprietor);

  if (
    params.locationId &&
    (!currentLocation ||
      !currentParentCompany ||
      !initialDispatchLocation ||
      existingLocationServices === undefined)
  ) {
    return null;
  }

  // TODO: find a better way to do all of this
  // If there's a search term or search results, use those. Otherwise, fall back to current item if it exists
  const companyOptions =
    debouncedTerm || companies.length > 0
      ? companies
      : currentParentCompany
        ? [currentParentCompany]
        : [];

  const dispatchOptions =
    debouncedLocationTerm || locations.length > 0
      ? locations
      : initialDispatchLocation
        ? [initialDispatchLocation]
        : [];

  return (
    <>
      <Card>
        <CardHeader
          title={currentLocation ? 'Edit Location' : 'Create Location'}
          subheader={
            currentLocation
              ? 'Edit the details of the location'
              : "Enter the details of the location you'd like to create"
          }
          sx={{ mb: 3 }}
        />
        <Divider />
        <Stack spacing={3} sx={{ p: 3 }}>
          <form
            autoComplete="off" // TODO: need to revisit but autocomplete can blow things up for some reason
            noValidate
            onSubmit={e => {
              e.preventDefault();
              e.stopPropagation();
              handleSubmit();
            }}
            style={{ width: '100%' }}
          >
            <Typography variant="h6">Location Details</Typography>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
              sx={{ my: 2 }}
            >
              {!isDispatcher && (
                <Field
                  name="companyId"
                  listeners={{
                    onChange: ({ fieldApi }) =>
                      fieldApi.form.setFieldValue(
                        'defaultDispatchGroupId',
                        undefined
                      ),
                  }}
                >
                  {({ state, handleChange }) => (
                    <FormAutocomplete
                      options={companyOptions}
                      value={selectedCompany}
                      disabled={!!currentParentCompany}
                      getOptionLabel={(option: Doc<'companies'>) =>
                        option?.name || ''
                      }
                      isOptionEqualToValue={(option, value) =>
                        option?._id === value?._id
                      }
                      onInputChange={newValue => {
                        debouncedSearch(newValue);
                      }}
                      onChange={newValue => {
                        handleChange((newValue?._id || '') as CompanyId);
                        setSelectedCompany(newValue);
                      }}
                      label="Parent Company"
                      required
                      error={state.meta.errors.length > 0}
                      helperText={state.meta.errors[0] as string}
                    />
                  )}
                </Field>
              )}
              <Field
                name="name"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="Location Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.value}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                  />
                )}
              />
              <Field
                name="locationType"
                listeners={{
                  onChange: ({ value, fieldApi }) => {
                    // We want to ensure showInProviderDirectory is cleared if they choose a fleet type
                    if (isFleetType(value as PhysicalLocationType)) {
                      fieldApi.form.setFieldValue(
                        'showInProviderDirectory',
                        false
                      );
                    }
                    return undefined;
                  },
                }}
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    select
                    label="Location Type"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.value}
                    onChange={e =>
                      handleChange(e.target.value as typeof state.value)
                    }
                    onBlur={handleBlur}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                  >
                    {locationTypeOptions.map(type => (
                      <MenuItem key={type} value={type}>
                        {getPhysicalLocationTypeDisplay(
                          type as PhysicalLocationType
                        )}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Field
                name="description"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    multiline
                    label="About"
                    variant="outlined"
                    fullWidth
                    value={state.value}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                  />
                )}
              />

              <Field
                name="address"
                children={({ state, handleChange, handleBlur }) => (
                  <AddressInput
                    value={state.value}
                    onChange={value => {
                      handleChange(value || '');
                    }}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    searchResults={searchResults}
                    searchAddress={searchAddress}
                    onCoordinatesChange={coords => {
                      setCoordinates(
                        coords
                          ? { lat: coords.latitude, long: coords.longitude }
                          : null
                      );
                      // TODO: Should set this async, but need to think of a better pattern for clearing values on the backend
                    }}
                    required
                  />
                )}
              />
              {!soleProp && (
                <Field
                  name="defaultDispatchGroupId"
                  children={({ state, handleChange }) => (
                    <FormAutocomplete
                      options={dispatchOptions}
                      value={selectedLocation}
                      disabled={!selectedCompany}
                      getOptionLabel={(option: CompanyGroup) =>
                        option?.name
                          ? `${option?.name} (${getPhysicalLocationTypeDisplay(option.locationType!)})`
                          : ''
                      }
                      isOptionEqualToValue={(option, value) =>
                        option?._id === value?._id
                      }
                      onInputChange={newValue => {
                        debouncedLocationSearch(newValue);
                      }}
                      noOptionsText="Please type to search for a dispatch location"
                      onChange={newValue => {
                        handleChange((newValue?._id || '') as GroupId);
                        setSelectedLocation(newValue);
                      }}
                      label="Default Dispatch Location"
                      error={state.meta.errors.length > 0}
                      helperText={
                        !selectedCompany
                          ? 'Must select a parent company first'
                          : state.meta.errors[0] || undefined
                      }
                    />
                  )}
                />
              )}
              {serviceType && (
                <Field
                  name="serviceIds"
                  children={({ state, handleChange }) => (
                    <ServiceSelection
                      value={state.value}
                      onChange={handleChange}
                      error={state.meta.errors.length > 0}
                      helperText={state.meta.errors[0] as string}
                    />
                  )}
                />
              )}
              {serviceType && (
                <Field
                  name="acceptedPaymentMethods"
                  children={({ state, handleChange }) => (
                    <FormControl fullWidth error={state.meta.errors.length > 0}>
                      <InputLabel>Accepted Payment Methods</InputLabel>
                      <Select
                        multiple
                        value={state.value}
                        onChange={e =>
                          handleChange(e.target.value as PaymentMethods[])
                        }
                        label={'Accepted Payment Methods'}
                        renderValue={selected => {
                          const selectedItems = selected
                            .map(s => getFriendlyPaymentMethodName(s))
                            .join(', ');
                          return selectedItems;
                        }}
                      >
                        {paymentMethod.options.map(method => (
                          <MenuItem key={method} value={method}>
                            <Checkbox checked={state.value.includes(method)} />
                            <ListItemText
                              primary={getFriendlyPaymentMethodName(method)}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              )}
              {serviceType && (
                <Field
                  name="simpleServiceAreaByMilesOut"
                  children={({ state, handleChange, handleBlur }) => (
                    <TextField
                      label="Service Radius (Miles Out)"
                      variant="outlined"
                      fullWidth
                      error={state.meta.errors.length > 0}
                      helperText={state.meta.errors[0]}
                      value={state.value ?? ''}
                      onChange={e => handleChange(e.target.value)}
                      onBlur={handleBlur}
                    />
                  )}
                />
              )}
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              gap={2} // This adds consistent spacing between wrapped items
              sx={{ mb: 2 }}
            >
              {serviceType && (
                <>
                  <Field name="showInProviderDirectory">
                    {({ state, handleChange, handleBlur, store }) => (
                      <FormControlLabel
                        control={
                          <Switch
                            id="showInProviderDirectory"
                            checked={state.value}
                            onChange={e => handleChange(e.target.checked)}
                          />
                        }
                        label="Show in Provider Directory"
                      />
                    )}
                  </Field>
                  <Field name="hasVerifiedDispatchTeam">
                    {({ state, handleChange, handleBlur, store }) => (
                      <FormControlLabel
                        control={
                          <Switch
                            id="hasVerifiedDispatchTeam"
                            checked={state.value}
                            onChange={e => handleChange(e.target.checked)}
                          />
                        }
                        label="Has Verified Dispatch"
                      />
                    )}
                  </Field>
                  <Field name="hasVerifiedTechnicians">
                    {({ state, handleChange, handleBlur, store }) => (
                      <FormControlLabel
                        control={
                          <Switch
                            id="hasVerifiedTechnicians"
                            checked={state.value}
                            onChange={e => handleChange(e.target.checked)}
                          />
                        }
                        label="Has Verified Technicians"
                      />
                    )}
                  </Field>
                </>
              )}
              <Field name="isRetail">
                {({ state, handleChange }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        id="isRetail"
                        checked={Boolean(state.value)}
                        onChange={e => handleChange(Boolean(e.target.checked))}
                      />
                    }
                    label="Has Retail Component"
                  />
                )}
              </Field>
              {showSolePropOptions && (
                <Field name="noDispatchSoleProprietor">
                  {({ state, handleChange }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          id="noDispatchSoleProprietor"
                          checked={Boolean(state.value)}
                          onChange={e =>
                            handleChange(Boolean(e.target.checked))
                          }
                        />
                      }
                      label="No Dispatchers (Sole Proprietorship)"
                    />
                  )}
                </Field>
              )}
            </Box>

            <Typography variant="h6">Location Contact Details</Typography>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
              sx={{ my: 2 }}
            >
              <Field
                name="email"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="Contact Email"
                    variant="outlined"
                    fullWidth
                    value={state.value}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                  />
                )}
              />
              <Field
                name="phone"
                children={({ state, handleChange, handleBlur }) => (
                  <PhoneInput
                    required
                    label="Contact Phone Number"
                    fullWidth
                    value={state.value}
                    onChange={newValue => handleChange(newValue || '')}
                    onBlur={handleBlur}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                  />
                )}
              />
              <Field
                name="dispatchPhone"
                children={({ state, handleChange, handleBlur }) => (
                  <PhoneInput
                    label="Dispatch Phone Number"
                    fullWidth
                    value={state.value}
                    onChange={newValue => handleChange(newValue || '')}
                    onBlur={handleBlur}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    required
                  />
                )}
              />

              <Field
                name="emergencyPhone"
                children={({ state, handleChange, handleBlur }) => (
                  <PhoneInput
                    label="Emergency Phone Number"
                    fullWidth
                    value={state.value ?? ''}
                    onChange={newValue => handleChange(newValue || '')}
                    onBlur={handleBlur}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                  />
                )}
              />
            </Box>

            <Typography variant="h6">Additional Information</Typography>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
              sx={{ my: 2 }}
            >
              <Field
                name="notes"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    multiline
                    rows={4}
                    label="Additional Information"
                    variant="outlined"
                    fullWidth
                    value={state.value}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                  />
                )}
              />
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <Subscribe
                selector={state => [state.canSubmit, state.isSubmitting]}
                children={([canSubmit, isSubmitting]) => {
                  return (
                    <LoadingButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      loading={isSubmitting}
                      disabled={!canSubmit}
                    >
                      {!currentLocation ? 'Create location' : 'Save changes'}
                    </LoadingButton>
                  );
                }}
              />
            </Stack>
          </form>
        </Stack>
      </Card>
    </>
  );
}

interface FormAutocompleteProps<T> {
  options: T[];
  value: T | null;
  getOptionLabel: (option: T) => string;
  isOptionEqualToValue: (option: T, value: T) => boolean;
  onInputChange?: (value: string) => void;
  onChange: (value: T | null) => void;
  label: string;
  noOptionsText?: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: string | null;
}

function FormAutocomplete<T>({
  options,
  value,
  getOptionLabel,
  isOptionEqualToValue,
  onInputChange,
  onChange,
  noOptionsText,
  label,
  required,
  disabled,
  error,
  helperText,
}: FormAutocompleteProps<T>) {
  // Track the input value separately from the selected value
  const [inputValue, setInputValue] = useState('');

  return (
    <Autocomplete
      options={options}
      value={value}
      inputValue={inputValue}
      disabled={disabled}
      noOptionsText={noOptionsText}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
        onInputChange?.(newInputValue);
      }}
      onChange={(_event, newValue) => {
        onChange(newValue);
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          required={required}
          disabled={disabled}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
}
