import { EnhancedRequest } from '@cvx/types/entities/requestsEntityTypes';
import { EnhancedUser } from 'src/convex/functions/users';
import { DriverForm } from 'src/sections/request/DriverForm';
import { FleetDispatchForm } from 'src/sections/request/FleetDispatchForm';
import { NonFleetForm } from 'src/sections/request/NonFleetForm';

type RequestFormContentProps = {
  request: EnhancedRequest;
  me: EnhancedUser;
  isDialog?: boolean;
  onClose?: () => void;
};

export const RequestFormContent = ({
  request,
  me,
  isDialog,
  onClose,
}: RequestFormContentProps) => {
  if (me.primaryRoleType === 'DRIVER_FLEET') {
    return (
      <DriverForm
        request={request}
        me={me}
        isDialog={isDialog}
        onClose={onClose}
      />
    );
  } else if (me.primaryRoleType === 'FLEET_DISPATCHER') {
    return (
      <FleetDispatchForm
        request={request}
        me={me}
        isDialog={isDialog}
        onClose={onClose}
      />
    );
  } else if (
    me.primaryRoleType === 'THIRD_PARTY_DISPATCHER' ||
    me.primaryRoleType === 'SERVICE_DISPATCHER' ||
    me.primaryRoleType === 'TECHNICIAN_PROVIDER'
  ) {
    return (
      <NonFleetForm
        request={request}
        me={me}
        isDialog={isDialog}
        onClose={onClose}
      />
    );
  } else {
    return null;
  }
};
