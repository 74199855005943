import { api } from '@cvx/api';
import { Alert, Button } from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
import { useConvexAuth, useMutation, useQuery } from 'convex/react';
import { toast } from 'sonner';

export function ImpersonationAlert() {
  const { isAuthenticated, isLoading } = useConvexAuth();
  const navigate = useNavigate();
  const me = useQuery(api.functions.users.getMe, isAuthenticated ? {} : 'skip');

  const clearImpersonation = useMutation(
    api.functions.superAdmin.clearImpersonation
  );

  const location = useQuery(
    api.functions.companies.getLocationById,
    me?.impersonatingLocationId ? { id: me.impersonatingLocationId } : 'skip'
  );

  if (!me?.impersonatingLocationId) {
    return null;
  }

  return (
    <Alert
      severity="error"
      sx={{
        fontWeight: 700,
        alignItems: 'center',
        '.MuiAlert-message': { padding: 0 },
      }}
      action={
        <Button
          variant="contained"
          size="small"
          color="error"
          onClick={async () => {
            try {
              const resp = await clearImpersonation({});
              navigate({ to: '/dashboard/locations' });
              toast.success(resp.message);
            } catch (e) {
              // TODO: better reusable pattern for this stuff all over the place
              toast.error('Something went wrong');
            }
          }}
        >
          End Impersonation
        </Button>
      }
    >
      {`You are impersonating a location ${location?.name} as Super Admin`}
    </Alert>
  );
}
