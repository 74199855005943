import { Alert, Button, ListItemText, Menu, MenuItem } from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';
import { getStepTypeDisplay } from 'src/convex/schema/entities/requests/requests';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { useResponsive } from 'src/minimal-theme/hooks/use-responsive';
import { useActiveAssignments } from 'src/minimal-theme/hooks/useAssignmentTracking';

export const AssignmentTrackingAlert = () => {
  const { activeAssignments } = useActiveAssignments();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const isMobile = useResponsive('down', 'md');

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!activeAssignments || activeAssignments.length === 0) {
    return null;
  }

  const getDisplayText = () => {
    const count = activeAssignments.length;
    const suffix = count > 1 ? 's' : '';
    return `${count} ${isMobile ? `Case${suffix}` : `Active Assignment${suffix}`}`;
  };

  return (
    <Alert
      severity="warning"
      icon={<Iconify icon="solar:danger-circle-bold" />}
      sx={{
        fontWeight: 700,
        alignItems: 'center',
        '.MuiAlert-message': { padding: 0 },
        '.MuiAlert-action': {
          pt: 0,
        },
      }}
      action={
        <>
          <Button
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            size="small"
          >
            {isMobile ? (
              <Iconify icon="solar:alt-arrow-down-line-duotone" />
            ) : (
              'View'
            )}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {activeAssignments.map(a => (
              <MenuItem
                key={a._id}
                onClick={() => {
                  navigate({
                    to: `/dashboard/requests/$requestId`,
                    params: { requestId: a._id },
                  });
                  handleClose();
                }}
              >
                <ListItemText
                  primary={a.caseNumber}
                  secondary={getStepTypeDisplay(a.currentStepType)}
                  primaryTypographyProps={{
                    variant: 'body2',
                    fontWeight: 600,
                  }}
                  secondaryTypographyProps={{
                    variant: 'caption',
                  }}
                />
              </MenuItem>
            ))}
          </Menu>
        </>
      }
    >
      {getDisplayText()}
    </Alert>
  );
};
