import { ConvexUserId } from '@cvx/types/entities/sharedIds';
import { getOneFrom } from 'convex-helpers/server/relationships';
import { DatabaseWriter } from '../../_generated/server';
import { InviteUserInput } from '../../schema/entities/companies';
import { DEFAULT_NOTIFICATION_SETTINGS } from '../../schema/entities/users';

export type UserCreationResult = {
  clerkId: string;
  userId: ConvexUserId;
};

/**
 * Creates both Clerk and Convex user with proper role
 */
export async function createConvexUserAndRoleHelper(
  db: DatabaseWriter,
  input: Omit<InviteUserInput, 'companyName'>,
  clerkId: string
) {
  // Create Convex user first
  const userId = await db.insert('users', {
    companyId: input.companyId,
    invitedByUser: input.invitedByUserId,
    invitedByCompany: input.invitedByCompanyId,
    invitedByLocation: input.invitedByLocationId,
    createdByUserDuringRequest: !!input.requestId,
    createdFromRequestId: input.requestId,
    status: 'AVAILABLE',
    clerkUser: {
      id: clerkId,
      isSuperAdmin: input.role === 'SUPER_ADMIN',
      firstName: input.firstName,
      lastName: input.lastName,
      fullName: `${input.firstName} ${input.lastName}`,
      primaryEmailAddress: {
        emailAddress: input.email,
      },
      primaryPhoneNumber: {
        phoneNumber: input.phone,
      },
      imageUrl: '',
    },
    notificationSettings: DEFAULT_NOTIFICATION_SETTINGS,
  });

  const [roleDefinition, locationGroup] = await Promise.all([
    getOneFrom(db, 'roleDefinitions', 'by_type', input.role),
    db.get(input.locationGroupId),
  ]);

  if (!roleDefinition) {
    await db.delete(userId);
    throw new Error(`Role definition not found for ${input.role}`);
  }

  if (!locationGroup) {
    await db.delete(userId);
    throw new Error(`Location group not found for ${input.locationGroupId}`);
  }

  const promises: Promise<any>[] = [
    db.insert('userGroups', {
      userId: userId,
      groupId: input.locationGroupId,
    }),
    db.insert('userRoles', {
      userId,
      roleDefinitionId: roleDefinition._id,
      status: 'active',
      companyId: input.companyId,
    }),
  ];

  // Inviting a technician when the location is a sole prop, make them dispatcher AND technician TODO: Need to do something similar on fleet side
  // We've started to accrue enough use cases that it's time to refactor this into a more general solution (roles/capabilities, etc. more front-end configuration, less hard coding for cases)
  if (
    roleDefinition.type === 'TECHNICIAN_PROVIDER' &&
    locationGroup.noDispatchSoleProprietor
  ) {
    const serviceDispatcherRole = await getOneFrom(
      db,
      'roleDefinitions',
      'by_type',
      'SERVICE_DISPATCHER'
    );

    if (!serviceDispatcherRole) {
      throw new Error(`Problem getting service dispatcher role`);
    }

    promises.push(
      db.insert('userRoles', {
        userId,
        roleDefinitionId: serviceDispatcherRole._id,
        status: 'active',
        companyId: input.companyId,
      })
    );

    // Primary role will be the dispatcher role, as higher roles take precedence
    promises.push(
      db.patch(userId, {
        primaryRoleId: serviceDispatcherRole._id,
        primaryRoleType: serviceDispatcherRole.type,
        primaryLocationGroupId: input.locationGroupId,
        defaultDispatchGroupId: locationGroup.defaultDispatchGroupId,
      })
    );
  } else {
    promises.push(
      db.patch(userId, {
        primaryRoleId: roleDefinition._id,
        primaryRoleType: roleDefinition.type,
        primaryLocationGroupId: input.locationGroupId,
        defaultDispatchGroupId: locationGroup.defaultDispatchGroupId,
      })
    );
  }

  await Promise.all(promises);

  return { userId };
}
