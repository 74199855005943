import { api } from '@cvx/api';
import LoadingButton from '@mui/lab/LoadingButton';
import { Autocomplete, CardHeader } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useForm } from '@tanstack/react-form';
import { useQuery } from 'convex/react';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { CONFIG } from 'src/config-global';
import { PhoneInput } from 'src/minimal-theme/components/phone-input';

interface SearchResult {
  place_name: string;
  center: [number, number];
}

export function CompanyEditForm() {
  const me = useQuery(api.functions.users.getMe);

  if (!me || !me.companyId) return null;

  const company = useQuery(api.functions.companies.getCompany, {
    companyId: me.companyId,
  });

  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState<SearchResult | null>(null);

  useEffect(() => {
    const address = company?.location?.address || '';
    setInputValue(address);

    if (address) {
      setSelectedValue({
        place_name: address,
        center: [0, 0],
      });
    }
  }, [company]);

  const { Field, Subscribe, handleSubmit, reset } = useForm({
    defaultValues: {
      name: company?.name ?? '',
      address: company?.location?.address ?? '',
      contactEmail: company?.contactEmail ?? '',
      contactPhoneNo: company?.contactPhoneNo ?? '',
      about: company?.about ?? '',
    },
    onSubmit: ({ value }) => console.log(value),
  });

  const debouncedSearch = useMemo(
    () =>
      debounce(async (query: string) => {
        if (!query) return;

        try {
          const response = await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
              query
            )}.json?access_token=${CONFIG.mapboxApiKey}&types=address`
          );
          const data = await response.json();
          setSearchResults(
            data.features.map((feature: any) => ({
              place_name: feature.place_name,
              center: feature.center,
            }))
          );
        } catch (error) {
          console.error('Search error:', error);
          setSearchResults([]);
        }
      }, 100),
    []
  );

  const fetchAddressSuggestions = async (query: string) => {
    if (!query) {
      setSearchResults([]);
      return;
    }
    debouncedSearch(query);
  };

  return (
    <>
      {/* <Button
        component={RouterLink}
        href={'/dashboard/drivers'}
        startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
        sx={{ mr: 'auto', mb: 2 }}
      >
        Back
      </Button> */}
      <form
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit();
        }}
        style={{ width: '100%' }}
      >
        <Box display="flex" flexDirection="column" gap={3}>
          <Card>
            <CardHeader title="Company Details" />
            <Stack
              rowGap={3}
              columnGap={2}
              display="grid"
              sx={{ p: 3 }}
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <Field
                name="name"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="Company Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.value}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                  />
                )}
              />
              <Field
                name="address"
                children={({ state, handleChange, handleBlur }) => (
                  <Autocomplete<SearchResult, false, true, true>
                    fullWidth
                    options={searchResults}
                    value={selectedValue || undefined}
                    getOptionLabel={option => {
                      if (typeof option === 'string') return option;
                      return option?.place_name || '';
                    }}
                    inputValue={inputValue}
                    onInputChange={(_event, newValue) => {
                      setInputValue(newValue);
                      fetchAddressSuggestions(newValue);
                    }}
                    onChange={(_event, newValue) => {
                      if (newValue && typeof newValue !== 'string') {
                        setSelectedValue(newValue);
                        handleChange(newValue.place_name);
                      } else {
                        setSelectedValue(null);
                        handleChange('');
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Address"
                        variant="outlined"
                        fullWidth
                        required
                      />
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option.place_name === value.place_name
                    }
                    freeSolo
                  />
                )}
              />
            </Stack>
            <CardHeader title="Contact Information" />
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              sx={{ p: 3 }}
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <Field
                name="contactPhoneNo"
                children={({ state, handleChange, handleBlur }) => (
                  <PhoneInput
                    required
                    label="Phone Number"
                    fullWidth
                    value={state.value}
                    onChange={newValue => handleChange(newValue || '')}
                    onBlur={handleBlur}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                  />
                )}
              />
              <Field
                name="contactEmail"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="Email Address"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.value}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                  />
                )}
              />
            </Box>
          </Card>
          {/* <Card>
            <CardHeader title="Contact Information" />
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              sx={{ p: 3 }}
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <Field
                name="contactPhoneNo"
                children={({ state, handleChange, handleBlur }) => (
                  <PhoneInput
                    required
                    label="Phone Number"
                    fullWidth
                    value={state.value}
                    onChange={newValue => handleChange(newValue || '')}
                    onBlur={handleBlur}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                  />
                )}
              />
              <Field
                name="contactEmail"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="Email Address"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.value}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                  />
                )}
              />
            </Box>
          </Card> */}
        </Box>
        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
          <Subscribe
            selector={state => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit, isSubmitting]) => {
              return (
                <LoadingButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={!canSubmit}
                >
                  Save
                </LoadingButton>
              );
            }}
          />
        </Stack>
      </form>
    </>
  );
}
