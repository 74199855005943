import { api } from '@cvx/api';
import { EnhancedRequest } from '@cvx/types/entities/requestsEntityTypes';
import { ConvexUserId, VehicleId } from '@cvx/types/entities/sharedIds';
import { ConvexUserZodId, VehicleZodId } from '@cvx/types/zod/commonZodId';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useForm, useStore } from '@tanstack/react-form';
import { useMutation, useQuery } from 'convex/react';
import { toast } from 'sonner';
import { PaymentMethodField } from 'src/components/form/PaymentMethodField';
import { isLocationRecentlyUpdated } from 'src/convex/functions/requests';
import { EnhancedUser } from 'src/convex/functions/users';
import { getVehicleDisplayName } from 'src/convex/schema/entities/vehicles';
import { servicesRequiredSchema } from 'src/convex/types/zod/requestsZod';
import { useDebouncedMutation } from 'src/hooks/useDebouncedMutation';
import { useServiceSelection } from 'src/sections/location/view/hooks/useServiceSelection';
import { ServiceSelection } from 'src/sections/location/view/ServiceSelection';
import { RequestDetailsCard } from 'src/sections/request/RequestDetailsCard';
import { RequestLocationCard } from 'src/sections/request/RequestLocationCard';
import { RequestMapDisplay } from 'src/sections/request/RequestMapDisplay';

type FleetDispatchFormProps = {
  request: EnhancedRequest;
  me: EnhancedUser;
  isDialog?: boolean;
  onClose?: () => void;
};

export const FleetDispatchForm = ({
  request,
  me,
  isDialog,
  onClose,
}: FleetDispatchFormProps) => {
  const vehicles = useQuery(api.functions.vehicles.getVehiclesForCompany, {});

  const drivers = useQuery(
    api.functions.users.getUsersOfRoleForMultipleLocations,
    {
      roleType: 'DRIVER_FLEET',
    }
  );

  const submitRequest = useMutation(
    api.functions.requests.submitFleetDispatchRequest
  );

  const updateRequest = useMutation(api.functions.requests.updateRequest);

  const updateDraftRequest = useMutation(
    api.functions.requests.updateDraftRequest
  );

  const debouncedUpdateDraftRequest = useDebouncedMutation(updateDraftRequest);

  const form = useForm({
    defaultValues: {
      vehicleId: request.vehicleId ?? (null as VehicleId | null),
      activeDriverId: request.activeDriverId ?? (null as ConvexUserId | null),
      description: request.description || '',
      fleetReferenceNumber: request.fleetReferenceNumber || '',
      servicesRequiredByFleet: request.servicesRequiredByFleet || [],
      nationalAccountNumber: request.nationalAccountNumber || '',
      tireSize: '',
      paymentMethod: request.paymentMethod,
    },
    onSubmit: async ({ value }) => {
      try {
        if (isDialog) {
          const resp = await updateRequest({
            requestId: request._id,
            description: value.description,
            fleetReferenceNumber: value.fleetReferenceNumber,
            servicesRequiredByFleet: value.servicesRequiredByFleet,
            nationalAccountNumber: value.nationalAccountNumber,
            paymentMethod: value.paymentMethod,
            tireSize: value.tireSize,
          });

          if (resp.success) {
            toast.success(resp.message);
            onClose?.();
            return;
          }
        }
        if (!value.vehicleId || !value.activeDriverId) return;

        const resp = await submitRequest({
          input: {
            requestId: request?._id,
            ...value,
            vehicleId: value.vehicleId,
            activeDriverId: value.activeDriverId,
            // TODO: Submitting dispatch zone here... does it get overridden later when driver shares location?
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        });

        if (resp.success) {
          toast.success(resp.message);
        } else {
          toast.error(resp.error);
        }
      } catch (error) {
        toast.error("Couldn't submit request. Please try again.");
      }
    },
  });

  // using store to get values from form to avoid re-rendering
  const { Field, Subscribe, handleSubmit, store } = form;

  const selectedVehicleId = useStore(store, s => s.values.vehicleId);

  const driverVehicle = useQuery(
    api.functions.vehicles.getVehicleById,
    selectedVehicleId ? { vehicleId: selectedVehicleId } : 'skip'
  );

  const servicesRequiredByFleet = useStore(
    store,
    s => s.values.servicesRequiredByFleet
  );

  const nationalAccountNumber = useStore(
    store,
    s => s.values.nationalAccountNumber
  );

  const tireSize = useStore(store, s => s.values.tireSize);

  const { tireBrandsCategory, hasTireSalesSelected, hasBrandSelected } =
    useServiceSelection(servicesRequiredByFleet);

  let servicesValid = servicesRequiredByFleet.length > 0;

  if (hasTireSalesSelected && tireBrandsCategory) {
    servicesValid = hasBrandSelected;
  }

  const selectedVehicleLocationRecentlyUpdated =
    isLocationRecentlyUpdated(driverVehicle);

  const detailsContent = (
    <Box columnGap={2} rowGap={3} display="grid" sx={{ mt: 2 }}>
      <FormControl>
        <Field
          name="activeDriverId"
          validators={{
            onChange: ConvexUserZodId.nullable(),
          }}
          listeners={{
            onChange: ({ value }) =>
              debouncedUpdateDraftRequest({
                requestId: request._id,
                activeDriverId: value ?? undefined,
              }),
          }}
          children={({ state, handleChange }) => (
            <Autocomplete
              fullWidth
              disabled={isDialog}
              options={drivers || []}
              getOptionLabel={option =>
                `${option.clerkUser.firstName} ${option.clerkUser.lastName}`
              }
              value={
                request.activeDriver ||
                drivers?.find(d => d._id === state.value) ||
                null
              }
              isOptionEqualToValue={(option, value) =>
                option?._id === value?._id
              }
              onChange={(event, newValue) => {
                handleChange(newValue?._id || null);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Driver"
                  required
                  error={state.meta.errors.length > 0}
                  helperText={state.meta.errors[0]}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option._id}>
                  {option.clerkUser.firstName} {option.clerkUser.lastName}
                </li>
              )}
            />
          )}
        />
      </FormControl>
      <FormControl>
        <Field
          name="vehicleId"
          validators={{
            onChange: VehicleZodId.nullable(),
          }}
          listeners={{
            onChange: ({ value }) =>
              debouncedUpdateDraftRequest({
                requestId: request._id,
                vehicleId: value ?? undefined,
              }),
          }}
          children={({ state, handleChange }) => (
            <Autocomplete
              fullWidth
              options={vehicles || []}
              getOptionLabel={option => getVehicleDisplayName(option)}
              value={
                vehicles?.find(v => v?._id === state.value) ||
                request.vehicle ||
                null
              }
              disabled={isDialog}
              isOptionEqualToValue={(option, value) =>
                option?._id === value?._id
              }
              onChange={(event, newValue) => {
                handleChange(newValue?._id || null);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Vehicle"
                  required
                  error={state.meta.errors.length > 0}
                  helperText={state.meta.errors[0]}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option?._id}>
                  {getVehicleDisplayName(option)}
                </li>
              )}
            />
          )}
        />
      </FormControl>
      <FormControl>
        <Field
          name="fleetReferenceNumber"
          listeners={{
            onChange: ({ value }) => {
              if (!isDialog) {
                debouncedUpdateDraftRequest({
                  requestId: request._id,
                  fleetReferenceNumber: value,
                });
              }
            },
          }}
          children={({ state, handleChange, handleBlur }) => (
            <TextField
              label="Fleet Reference Number"
              placeholder="Optionally provide a fleet reference number"
              variant="outlined"
              fullWidth
              value={state.value}
              error={state.meta.errors.length > 0}
              helperText={state.meta.errors[0]}
              onChange={e => handleChange(e.target.value)}
              onBlur={handleBlur}
            />
          )}
        />
      </FormControl>
      <FormControl>
        <Field
          name="servicesRequiredByFleet"
          validators={{
            onChange: servicesRequiredSchema,
          }}
          listeners={{
            onChange: ({ value }) => {
              if (!isDialog) {
                debouncedUpdateDraftRequest({
                  requestId: request._id,
                  servicesRequiredByFleet: value,
                });
              }
            },
          }}
          children={({ state, handleChange }) => (
            <ServiceSelection
              value={state.value}
              onChange={handleChange}
              required
              filterOptions={{ splitOutTireBrandSelection: true }}
              error={state.meta.errors.length > 0}
              helperText={state.meta.errors[0] as string}
              nationalAccountNumber={nationalAccountNumber}
              onNationalAccountNumberChange={async value => {
                form.setFieldValue('nationalAccountNumber', value);
                if (!isDialog) {
                  await debouncedUpdateDraftRequest({
                    requestId: request._id,
                    nationalAccountNumber: value,
                  });
                }
              }}
              tireSize={tireSize}
              onTireSizeChange={async value => {
                form.setFieldValue('tireSize', value);
                if (!isDialog) {
                  await debouncedUpdateDraftRequest({
                    requestId: request._id,
                    tireSize: value,
                  });
                }
              }}
            />
          )}
        />
      </FormControl>
      <FormControl>
        <Field
          name="description"
          listeners={{
            onChange: ({ value }) => {
              if (!isDialog) {
                debouncedUpdateDraftRequest({
                  requestId: request._id,
                  description: value,
                });
              }
            },
          }}
          children={({ state, handleChange, handleBlur }) => (
            <TextField
              label="Additional Notes"
              placeholder="Optionally provide additional information"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              value={state.value}
              error={state.meta.errors.length > 0}
              helperText={state.meta.errors[0]}
              onChange={e => handleChange(e.target.value)}
              onBlur={handleBlur}
            />
          )}
        />
      </FormControl>
      <FormControl>
        <Field
          name="paymentMethod"
          listeners={{
            onChange: ({ value }) => {
              if (!isDialog) {
                debouncedUpdateDraftRequest({
                  requestId: request._id,
                  paymentMethod: value,
                });
              }
            },
          }}
          children={({ state, handleChange }) => (
            <PaymentMethodField value={state.value} onChange={handleChange} />
          )}
        />
      </FormControl>
    </Box>
  );
  const locationContent = (
    <Box columnGap={2} rowGap={3} display="grid">
      <Stack spacing={2}>
        <Typography>
          <strong>Address:</strong> {driverVehicle?.location?.address}
        </Typography>
        <Alert
          severity={
            selectedVehicleLocationRecentlyUpdated ? 'success' : 'warning'
          }
        >
          {selectedVehicleLocationRecentlyUpdated
            ? `Vehicle location has recently been collected.`
            : `Vehicle location not recently shared. Request will be sent to driver to confirm location.`}
        </Alert>
        <RequestMapDisplay driverVehicle={driverVehicle} />
      </Stack>
    </Box>
  );

  if (isDialog) {
    return (
      <>
        <DialogContent>{detailsContent}</DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Subscribe
            selector={state => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit, isSubmitting]) => (
              <LoadingButton
                variant="contained"
                loading={isSubmitting}
                disabled={!canSubmit || !servicesValid}
                onClick={() => handleSubmit()}
              >
                Save
              </LoadingButton>
            )}
          />
        </DialogActions>
      </>
    );
  }

  return (
    <>
      <RequestDetailsCard>{detailsContent}</RequestDetailsCard>
      <RequestLocationCard
        subheader={`Your selected vehicle's location information`}
      >
        {driverVehicle && locationContent}
      </RequestLocationCard>
      {request.status === 'DRAFT' && (
        <Box width="100%" display="flex" justifyContent="center">
          <Subscribe
            selector={state => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit, isSubmitting]) => {
              return (
                <LoadingButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  size="large"
                  fullWidth
                  loading={isSubmitting}
                  disabled={!canSubmit || !servicesValid}
                  onClick={() => handleSubmit()}
                >
                  {selectedVehicleLocationRecentlyUpdated
                    ? `Submit And Find Provider`
                    : `Submit And Send To Driver To Confirm Location`}
                </LoadingButton>
              );
            }}
          />
        </Box>
      )}
    </>
  );
};
