import { GetToken } from '@clerk/types';
import { RequestId, RequestImageId } from '@cvx/types/entities/sharedIds';
import { openDB } from 'idb';
import JSZip from 'jszip';

export const CACHE_DB_NAME = 'request-images-cache';
export const CACHE_VERSION = 1;

type ImageCache = {
  id: RequestImageId;
  requestId: RequestId;
  blob: Blob;
  timestamp: number;
};

export const dbPromise = openDB<ImageCache>(CACHE_DB_NAME, CACHE_VERSION, {
  upgrade(db) {
    const store = db.createObjectStore('images', { keyPath: 'id' });
    store.createIndex('timestamp', 'timestamp');
    store.createIndex('requestId', 'requestId');
  },
});

function getFileExtensionFromBlob(blob: Blob): string {
  const mimeToExt: Record<string, string> = {
    'image/jpeg': '.jpg',
    'image/png': '.png',
    'image/webp': '.webp',
    'image/heic': '.heic',
  };
  return mimeToExt[blob.type] || '.jpg'; // Fallback
}

export async function fetchImageBlob({
  imageId,
  getToken,
  requestId,
}: {
  imageId: RequestImageId;
  getToken: GetToken;
  requestId?: RequestId; // Optional for download-only cases
}) {
  const db = await dbPromise;

  // Try cache first
  const cached = await db.get('images', imageId);
  if (cached?.blob) {
    return cached.blob;
  }

  // Fetch if not cached
  const token = await getToken();
  const url = new URL(`${import.meta.env.VITE_CONVEX_SITE_URL}/photo/request`);
  url.searchParams.set('photoId', imageId);

  const response = await fetch(url.href, {
    credentials: 'include',
    headers: { Authorization: `Bearer ${token}` },
  });

  if (!response.ok) throw new Error('Failed to load image');

  const blob = await response.blob();

  // Cache the new image if requestId is provided
  if (requestId) {
    await db.put('images', {
      id: imageId,
      requestId,
      blob,
      timestamp: Date.now(),
    });
  }

  return blob;
}

type DownloadImageParams = {
  imageId: RequestImageId;
  baseFilename: string;
  getToken: GetToken;
};

export async function downloadImage({
  imageId,
  baseFilename,
  getToken,
}: DownloadImageParams) {
  const blob = await fetchImageBlob({ imageId, getToken });

  const ext = getFileExtensionFromBlob(blob);
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = `${baseFilename}${ext}`;
  link.click();
  URL.revokeObjectURL(blobUrl);
}

export async function downloadImagesAsZip(
  images: { id: RequestImageId; baseFilename: string }[],
  caseNumber: string,
  getToken: GetToken
) {
  const zip = new JSZip();

  await Promise.all(
    images.map(async ({ id, baseFilename }, index) => {
      const blob = await fetchImageBlob({ imageId: id, getToken });
      const ext = getFileExtensionFromBlob(blob);
      const filename = `${baseFilename}-${id}${ext}`;
      zip.file(filename, blob);
    })
  );

  const content = await zip.generateAsync({ type: 'blob' });
  const zipUrl = URL.createObjectURL(content);
  const link = document.createElement('a');
  link.href = zipUrl;
  link.download = `myMechanic-${caseNumber}-photos.zip`;
  link.click();
  URL.revokeObjectURL(zipUrl);
}
