import { api } from '@cvx/api';
import { useLoaderData } from '@tanstack/react-router';
import { useQuery } from 'convex/react';
import { RequestListView } from 'src/sections/request/view';

export function AllRequestsView() {
  const loaderData = useLoaderData({
    from: '/_auth/dashboard/requests/',
  });
  const requests = useQuery(api.functions.requests.getAllRequests, {});

  return (
    <RequestListView title="All Requests" requests={requests ?? loaderData} />
  );
}
