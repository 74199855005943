import { Vehicle } from '@cvx/types/entities/vehiclesEntityTypes';
import { vehicleSchema } from '@cvx/types/zod/vehiclesZod';
import { zodToConvex } from 'convex-helpers/server/zod';
import { defineTable } from 'convex/server';

export const vehicles = defineTable(zodToConvex(vehicleSchema).fields)
  .index('by_primaryLocationGroupId', ['primaryLocationGroupId'])
  .index('by_defaultDispatchGroupId', ['defaultDispatchGroupId'])
  .index('by_vin', ['vin'])
  .index('by_companyId', ['companyId'])
  .index('by_serialNumber', ['serialNumber'])
  .searchIndex('searchQueryText_defaultDispatchGroupId_primaryRoleType', {
    searchField: 'searchQueryText',
    filterFields: ['defaultDispatchGroupId'],
  });

export const getVehicleDisplayName = (vehicle?: Vehicle | null) => {
  return `Unit # ${vehicle?.unitNumber ?? ''} - ${vehicle?.modelYear ?? ''} ${vehicle?.maker ?? ''} ${vehicle?.model ?? ''}`;
};
