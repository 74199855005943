import { EnhancedRequest } from '@cvx/types/entities/requestsEntityTypes';
import { InvoiceWithItems } from 'src/convex/functions/invoices';
import { EnhancedUser } from 'src/convex/functions/users';
import { isOnboardedAndReadyToCharge } from '../../../../components/stripe/utils/isStripeTestMode';

export const getRequestStateInformation = (
  request: EnhancedRequest,
  me: EnhancedUser,
  invoices: InvoiceWithItems[]
): RequestStateInformation => {
  // Basic role checks
  const isAssignedToMe = request.currentAssignedToId === me._id;
  const isFleetDispatcher = me.roles.some(r => r?.type === 'FLEET_DISPATCHER');
  const isServiceDispatcher = me.roles.some(
    r => r?.type === 'SERVICE_DISPATCHER'
  );
  const isTechnician = me.roles.some(r => r?.type === 'TECHNICIAN_PROVIDER');
  const isThirdPartyDispatcher = me.roles.some(
    r => r?.type === 'THIRD_PARTY_DISPATCHER'
  );

  const isDriver = me.roles.some(r => r?.type === 'DRIVER_FLEET');

  const fleetInitiated = !!request.requestCreationContext?.startsWith('FLEET_');
  const serviceInitiated =
    !!request.requestCreationContext?.startsWith('SERVICE_');
  const thirdPartyInitiated =
    !!request.requestCreationContext?.startsWith('THIRD_PARTY');

  const canEditChatAndPhotos =
    (isFleetDispatcher && me._id === request.activeFleetDispatcherId) ||
    (isServiceDispatcher && me._id === request.activeServiceDispatcherId) ||
    (isThirdPartyDispatcher &&
      me._id === request.activeBrokerageDispatcherId) ||
    (isDriver && me._id === request.activeDriverId) ||
    (isTechnician && me._id === request.activeTechnicianId);

  const isAtSameLocationAsActiveFleetDispatcher =
    me.primaryLocationGroupId === request.fleetDispatchGroupId;
  const isAtSameLocationAsActiveServiceDispatcher =
    me.primaryLocationGroupId === request.mechanicDispatchGroupId;

  const canViewAndDownloadPhotos =
    canEditChatAndPhotos ||
    (isFleetDispatcher && isAtSameLocationAsActiveFleetDispatcher) ||
    (isServiceDispatcher && isAtSameLocationAsActiveServiceDispatcher);

  // Queue states
  const isFleetDispatchQueue =
    request.currentStepState === 'QUEUED' &&
    request.currentStepType === 'DISPATCH_TRIAGE';

  const isServiceProviderQueue =
    request.currentStepState === 'QUEUED' &&
    request.currentStepType === 'WITH_SERVICE_PROVIDER_DISPATCH';

  const isWaitingForTechnician =
    request.currentStepState === 'ASSIGNED' &&
    request.currentStepType === 'TECHNICIAN_ASSIGNED';

  // Action states
  const needsServiceProvider =
    request.currentStepState === 'ASSIGNED' &&
    request.currentStepType === 'DISPATCH_TRIAGE';

  const needsMechanic =
    request.currentStepState === 'ASSIGNED' &&
    request.currentStepType === 'WITH_SERVICE_PROVIDER_DISPATCH';

  const needsLocationConfirmation =
    request.currentStepState === 'ASSIGNED' &&
    request.currentStepType === 'DRIVER_CONFIRM_LOCATION';

  // Technician workflow states
  const isTechnicianAccepted =
    request.currentStepType === 'TECHNICIAN_ACCEPTED_PRE_EN_ROUTE';

  const isTechnicianEnRoute = request.currentStepType === 'TECHNICIAN_ACCEPTED';

  const isTechnicianArrived = request.currentStepType === 'TECHNICIAN_ARRIVED';

  const isTechnicianWorking =
    request.currentStepType === 'TECHNICIAN_STARTED_WORK';

  const needsVerification =
    request.currentStepType === 'COMPLETION_VERIFICATION';

  const isCompleted = request.currentStepType === 'COMPLETED';

  const isCancelled = request.status === 'CANCELLED';

  const invoiceRelevantToCurrentUser = invoices.find(
    i => i.invoice.serviceDispatchLocationId === me.primaryLocationGroupId
  );

  const isInvoiceCreated =
    isServiceDispatcher && !!invoiceRelevantToCurrentUser;

  const stripeOnboardingCompleted = isOnboardedAndReadyToCharge(
    me.primaryLocation
  );

  return {
    // Role states
    isAssignedToMe,
    isFleetDispatcher,
    isServiceDispatcher,
    isTechnician,
    isThirdPartyDispatcher,
    isDriver,
    fleetInitiated,
    serviceInitiated,
    thirdPartyInitiated,

    canEditChatAndPhotos,
    canViewAndDownloadPhotos,

    // Queue states
    isFleetDispatchQueue,
    isServiceProviderQueue,

    // Assigned and awaiting acceptance
    isWaitingForTechnician,

    // Action states
    needsServiceProvider,
    needsMechanic,
    needsLocationConfirmation,

    // Technician states
    isTechnicianAccepted,
    isTechnicianArrived,
    isTechnicianEnRoute,
    isTechnicianWorking,
    needsVerification,

    // Completion state
    isCompleted,
    isCancelled,
    isInvoiceCreated,

    // Stripe states
    stripeOnboardingCompleted,
  };
};

export type RequestStateInformation = {
  // Role states
  isAssignedToMe: boolean;
  isFleetDispatcher: boolean;
  isServiceDispatcher: boolean;
  isTechnician: boolean;
  isDriver: boolean;
  isThirdPartyDispatcher: boolean;

  fleetInitiated: boolean;
  serviceInitiated: boolean;
  thirdPartyInitiated: boolean;

  canEditChatAndPhotos: boolean;
  canViewAndDownloadPhotos: boolean;

  // Queue states
  isFleetDispatchQueue: boolean;
  isServiceProviderQueue: boolean;

  // Assigned and awaiting acceptance
  isWaitingForTechnician: boolean;

  // Action states
  needsServiceProvider: boolean;
  needsMechanic: boolean;
  needsLocationConfirmation: boolean;

  // Technician states
  isTechnicianAccepted: boolean;
  isTechnicianArrived: boolean;
  isTechnicianEnRoute: boolean;
  isTechnicianWorking: boolean;
  needsVerification: boolean;

  // Completion state
  isCompleted: boolean;
  isCancelled: boolean;
  isInvoiceCreated: boolean;

  // Stripe states
  stripeOnboardingCompleted: boolean;
};
