import { api } from '@cvx/api';
import { VendorServiceId } from '@cvx/types/entities/sharedIds';
import { useQuery } from 'convex/react';
import { ServiceGroup } from 'src/convex/functions/serviceCategories';

type ServiceSelectionReturnType = {
  services: ServiceGroup[];
  mainServices: ServiceGroup[];
  tireSalesCategory?: ServiceGroup;
  tireBrandsCategory?: ServiceGroup;
  hasTireSalesSelected: boolean;
  hasBrandSelected: boolean;
};

export const useServiceSelection = (
  value: VendorServiceId[]
): ServiceSelectionReturnType => {
  const services = useQuery(
    api.functions.serviceCategories.getServicesGroupedByCategory,
    {}
  );

  // Split mode behavior
  const tireSalesCategory = services?.find(
    s => s.category.name === 'Tire Sales and Service'
  );
  const tireBrandsCategory = services?.find(
    s => s.category.name === 'Tire Brands'
  );

  const hasTireSalesSelected =
    tireSalesCategory?.services.some(service => value.includes(service._id)) ??
    false;

  const hasBrandSelected =
    tireBrandsCategory?.services.some(service => value.includes(service._id)) ??
    false;

  const mainServices =
    services?.filter(group => group.category.name !== 'Tire Brands') ?? [];

  return {
    services: services ?? [],
    mainServices,
    tireBrandsCategory,
    hasTireSalesSelected,
    hasBrandSelected,
    tireSalesCategory,
  };
};
