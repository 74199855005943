import { api } from '@cvx/api';
import { CompanyGroup } from '@cvx/types/entities/groupsEntityTypes';
import { getPhysicalLocationTypeDisplay } from '@cvx/types/utils/groupUtils';
import { Link, Tooltip } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useMutation, useQuery } from 'convex/react';
import { toast } from 'sonner';
import { usePopover } from 'src/minimal-theme/components/custom-popover';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { MuiTypeSafeLink } from 'src/minimal-theme/components/routes/components/mui-link';

type LocationTableRowProps = {
  row: CompanyGroup;
  selected: boolean;
  onEditRow: () => void;
  onSelectRow: () => void;
  onDeleteRow: () => void;
};

export function LocationTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: LocationTableRowProps) {
  // TODO: We have to check userGroups probably to see what users exist at this location where location_manager is their role or something... not sure... can also denormalize on the group to make it easier in future
  // const companyContact = useQuery(
  //   api.functions.users.getUserById,
  //   // CreatedbbyId just to satisfy typings... not working
  //   row.createdById ? { id: row.createdById as ConvexUserId } : 'skip'
  // );

  const me = useQuery(api.functions.users.getMe);

  const canImpersonate = me?.clerkUser.isSuperAdmin;

  const parentCompany = useQuery(
    api.functions.companies.getCompany,
    row.companyId ? { companyId: row.companyId } : 'skip'
  );

  const isImpersonatingThisLocation = me?.impersonatingLocationId === row._id;

  const impersonateCompany = useMutation(
    api.functions.superAdmin.impersonateCompany
  );

  const clearImpersonation = useMutation(
    api.functions.superAdmin.clearImpersonation
  );

  const popover = usePopover();

  return (
    <>
      <TableRow hover selected={selected} aria-checked={selected} tabIndex={-1}>
        <TableCell padding="checkbox">
          <Checkbox id={row._id} checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <MuiTypeSafeLink
            to="/dashboard/locations/$locationId"
            params={{ locationId: row._id }}
            color="inherit"
            sx={{ cursor: 'pointer' }}
          >
            {row.name}
          </MuiTypeSafeLink>
        </TableCell>

        <TableCell>
          <MuiTypeSafeLink
            to="/dashboard/companies/$companyId"
            params={{ companyId: parentCompany?._id }}
            color="inherit"
            sx={{ cursor: 'pointer' }}
          >
            {parentCompany?.name}
          </MuiTypeSafeLink>
        </TableCell>

        <TableCell>
          {row.locationType &&
            getPhysicalLocationTypeDisplay(row.locationType as any)}
        </TableCell>

        <TableCell>
          <Link href={`mailto:${row.contactInfo?.email}`}>
            {row.contactInfo?.email}
          </Link>
        </TableCell>

        <TableCell>
          <Link href={`tel:${row.contactInfo?.phone}`}>
            {row.contactInfo?.phone}
          </Link>
        </TableCell>

        {/* <TableCell>
          <Label
            variant="soft"
            color={(row.isNetworkMember === true && 'success') || 'default'}
          >
            {row.isNetworkMember ? 'Yes' : 'No'}
          </Label>
        </TableCell> */}

        <TableCell>
          {canImpersonate && (
            <>
              {/* <IconButton
                color={popover.open ? 'inherit' : 'default'}
                onClick={popover.onOpen}
              >
                <Iconify icon="eva:more-vertical-fill" />
              </IconButton>
              <CustomPopover
                open={popover.open}
                anchorEl={popover.anchorEl}
                onClose={popover.onClose}
                slotProps={{ arrow: { placement: 'right-top' } }}
              >
                <MenuList>
                  <MenuItem
                    onClick={async () => {
                      if (isImpersonatingThisLocation) {
                        await clearImpersonation({});
                      } else {
                        if (row.companyId) {
                          try {
                            const response = await impersonateCompany({
                              locationId: row._id,
                              companyId: row.companyId,
                            });

                            if (response.success) {
                              toast.success(response.message);
                            }
                          } catch (e) {
                            toast.error('Something went wrong');
                          }
                        }
                      }
                      popover.onClose();
                    }}
                    sx={{ color: 'secondary.main' }}
                  >
                    <Iconify icon="solar:sim-cards-bold-duotone" />
                    {isImpersonatingThisLocation
                      ? 'End Impersonation'
                      : 'Impersonate Location'}
                  </MenuItem>
                </MenuList>
              </CustomPopover> */}
              <Tooltip
                title={
                  isImpersonatingThisLocation
                    ? 'End Impersonation'
                    : 'Impersonate Location'
                }
                placement="top"
                arrow
              >
                <IconButton
                  onClick={async () => {
                    if (isImpersonatingThisLocation) {
                      await clearImpersonation({});
                    } else {
                      if (row.companyId) {
                        try {
                          const response = await impersonateCompany({
                            locationId: row._id,
                            companyId: row.companyId,
                          });

                          if (response.success) {
                            toast.success(response.message);
                          }
                        } catch (e) {
                          toast.error('Something went wrong');
                        }
                      }
                    }
                  }}
                >
                  <Iconify
                    icon={
                      isImpersonatingThisLocation
                        ? 'material-symbols:person-cancel'
                        : 'material-symbols:person-alert'
                    }
                  />
                </IconButton>
              </Tooltip>
            </>
          )}
        </TableCell>
      </TableRow>
    </>
  );
}
