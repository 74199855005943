import { api } from '@cvx/api';
import { ScheduledFunctionId } from '@cvx/types/entities/sharedIds';
import { GroupZodId } from '@cvx/types/zod/commonZodId';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  Box,
  Card,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { useForm } from '@tanstack/react-form';
import { useLoaderData, useNavigate } from '@tanstack/react-router';
import { useStore } from '@tanstack/react-store';
import { useMutation, useQuery } from 'convex/react';
import { useCallback } from 'react';
import {
  getFriendlyVehicleCategoryName,
  getFriendlyVehicleTypeName,
  requiresSerial,
  requiresVIN,
  trailerType,
  truckType,
  VehicleCategories,
  vehicleCategory,
  VehicleType,
  vehicleType,
  yardEquipmentType,
} from 'src/convex/schema/enums/vehicleType';
import { useScheduledActions } from 'src/hooks/useScheduledActions';
import { toast } from 'src/minimal-theme/components/snackbar';
import { ActionConfirmationDialog } from 'src/sections/request/actions/ActionConfirmationDialog';
import { useActionConfirmationDialog } from 'src/sections/request/actions/hooks/useActionConfirmationDialog.ts';
import { z } from 'zod';

const makerOptions = [
  'Freightliner',
  'Peterbilt',
  'Kenworth',
  'Volvo',
  'Mack',
  'International',
  'Western Star',
];

export const UnitNewEditForm = () => {
  const vehicle = (() => {
    try {
      return useLoaderData({
        from: '/_auth/dashboard/units/$unitId',
      });
    } catch {
      return null;
    }
  })();

  const navigate = useNavigate();

  const createVinVehicle = useMutation(
    api.functions.vehicles.createVinVehicleMutation
  );

  const createSerialVehicle = useMutation(
    api.functions.vehicles.createSerialVehicleMutation
  );

  const updateVehicle = useMutation(
    api.functions.vehicles.updateVehicleMutation
  );

  const { watchIds } = useScheduledActions();

  const { confirmAction, isOpen, onClose, pendingAction } =
    useActionConfirmationDialog();
  const locations = useQuery(api.functions.companies.getLocations, {});

  // const drivers = useQuery(api.functions.users.getUsersOfRoleForMultipleLocations, {
  //   roleType: 'DRIVER_FLEET',
  // });

  const { Field, Subscribe, handleSubmit, reset, store } = useForm({
    defaultValues: {
      vin: vehicle?.vin || '',
      serialNumber: vehicle?.serialNumber || '',
      category: vehicle?.category || '',
      type: vehicle?.type || '',
      maker: vehicle?.maker || '',
      model: vehicle?.model || '',
      modelYear: vehicle?.modelYear || '',
      color: vehicle?.color || '',
      unitNumber: vehicle?.unitNumber || '',
      status: vehicle?.status,
      primaryLocationGroupId: vehicle?.primaryLocationGroupId,
      // assignedDriverId: vehicle?.userId || null,
    },
    onSubmit: async ({ value }) => {
      try {
        if (vehicle) {
          const { type, category, ...restOfValues } = value;
          // Update existing vehicle
          const result = await updateVehicle({
            ...restOfValues,
            category: category as VehicleCategories,
            type: type as VehicleType,
            vehicleId: vehicle._id,
          });

          if (!result.status) {
            toast.error(result.message);
            return;
          }

          toast.success('Truck updated successfully');
        } else {
          const { type, category, ...restOfValues } = value;

          if (
            requiresSerial({
              category: category as VehicleCategories,
              type: type as VehicleType,
            })
          ) {
            const result = await createSerialVehicle({
              vehiclesInput: {
                vehicles: [
                  {
                    ...restOfValues,
                    category: category as VehicleCategories,
                    type: type as VehicleType,
                  },
                ],
              },
            });

            if (!result.success) {
              toast.error(result.message);
              return;
            } else {
              toast.success('Vehicle created successfully');
            }
          } else {
            const result = await createVinVehicle({
              vehiclesInput: {
                vehicles: [
                  {
                    ...restOfValues,
                    category: category as VehicleCategories,
                    type: type as VehicleType,
                  },
                ],
              },
            });

            if (!result.success) {
              toast.error(result.message);
              return;
            } else {
              await watchIds(
                result.results
                  .filter(
                    (
                      r
                    ): r is typeof r & {
                      scheduleId: ScheduledFunctionId;
                    } => !!r.scheduleId
                  )
                  .map(r => r.scheduleId),
                {
                  onSuccess: () => {
                    navigate({ to: '/dashboard/units' });
                  },
                  onError: failedIds => {
                    console.error('Failed invites:', failedIds);
                  },
                }
              );
            }
          }

          reset();
        }
      } catch (error) {
        console.error('Failed to save vehicle:', error);
        toast.error('Failed to save vehicle. Please try again.');
      }
    },
  });

  const handleFormSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      e.stopPropagation();

      confirmAction({
        title: vehicle ? 'Update Unit' : 'Add Unit',
        message: vehicle
          ? 'Are you sure you want to update this unit?'
          : 'Are you sure you want to add this unit?',
        onConfirm: handleSubmit,
      });
    },
    [confirmAction, handleSubmit, vehicle]
  );

  const category = useStore(store, s => s.values.category) as
    | VehicleCategories
    | undefined;
  const type = useStore(store, s => s.values.type) as VehicleType | undefined;

  const vinRequired = requiresVIN({ category, type });
  const serialRequired = requiresSerial({ category, type });

  let typeOptions: VehicleType[] = [];

  if (category === 'TRAILER') {
    typeOptions = trailerType.options;
  } else if (category === 'TRUCK') {
    typeOptions = truckType.options;
  } else if (category === 'YARD_EQUIPMENT') {
    typeOptions = yardEquipmentType.options;
  }

  return (
    <Card>
      <CardHeader
        title={vehicle ? 'Edit Unit' : 'Create Unit'}
        subheader={
          vehicle
            ? 'Edit the details of the unit'
            : 'Enter the details of the unit'
        }
        sx={{ mb: 3 }}
      />
      <Divider />
      <Stack spacing={3} sx={{ p: 3 }}>
        <form onSubmit={handleFormSubmit} style={{ width: '100%' }}>
          <Box
            columnGap={2}
            rowGap={3}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              md: 'repeat(2, 1fr)',
            }}
          >
            <Field
              name="category"
              validators={{
                onChange: vehicleCategory,
              }}
              listeners={{
                onChange: ({ value, fieldApi }) => {
                  fieldApi.form.setFieldValue('type', '');
                },
              }}
            >
              {({ state, handleChange, handleBlur }) => (
                <FormControl fullWidth error={!!state.meta.errors[0]} required>
                  <InputLabel id="category-label">Unit Category</InputLabel>
                  <Select
                    required
                    labelId="category-label"
                    label="Unit Category"
                    value={state.value}
                    onChange={e =>
                      handleChange(e.target.value as VehicleCategories)
                    }
                    onBlur={handleBlur}
                  >
                    {vehicleCategory.options.map(cat => (
                      <MenuItem key={cat} value={cat}>
                        {getFriendlyVehicleCategoryName(cat)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Field>
            <Field
              name="type"
              validators={{
                onChange: vehicleType,
              }}
            >
              {({ state, handleChange, handleBlur }) => (
                <FormControl fullWidth error={!!state.meta.errors[0]} required>
                  <InputLabel id="type-label">Unit Type</InputLabel>
                  <Select
                    required
                    labelId="type-label"
                    label="Unit Type"
                    disabled={typeOptions.length === 0}
                    value={state.value}
                    onChange={e => handleChange(e.target.value as VehicleType)}
                    onBlur={handleBlur}
                  >
                    {typeOptions.map(t => (
                      <MenuItem key={t} value={t}>
                        {getFriendlyVehicleTypeName(t)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Field>
            {vinRequired && (
              <Field
                name="vin"
                validators={{
                  onChange: z
                    .string()
                    .length(17, { message: 'VIN must be 17 characters long' }),
                }}
              >
                {({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="VIN"
                    placeholder="12345678901234567"
                    variant="outlined"
                    required
                    fullWidth
                    value={state.value}
                    helperText={state.meta.errors[0]}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                    type="text"
                  />
                )}
              </Field>
            )}
            {serialRequired && (
              <Field
                name="serialNumber"
                validators={{
                  onChange: z
                    .string()
                    .refine(val => !!val, 'Serial is required'),
                }}
              >
                {({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="Serial Number"
                    placeholder="Serial Number"
                    variant="outlined"
                    required
                    fullWidth
                    value={state.value}
                    helperText={state.meta.errors[0]}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                    type="text"
                  />
                )}
              </Field>
            )}
            <Field
              name="maker"
              validators={{
                onChange: z.string(),
              }}
            >
              {({ state, handleChange, handleBlur }) => (
                <FormControl fullWidth error={!!state.meta.errors[0]}>
                  <Autocomplete
                    fullWidth
                    freeSolo
                    options={makerOptions}
                    getOptionLabel={option => option}
                    value={state.value}
                    onChange={(event, newValue) => {
                      handleChange(newValue || '');
                    }}
                    inputValue={state.value}
                    onInputChange={(event, newInputValue) => {
                      handleChange(newInputValue || '');
                    }}
                    renderInput={params => (
                      <TextField {...params} label="Make" />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                  />
                </FormControl>
              )}
            </Field>
            <Field
              name="model"
              validators={{
                onChange: z.string(),
              }}
            >
              {({ state, handleChange, handleBlur }) => (
                <TextField
                  label="Model"
                  variant="outlined"
                  fullWidth
                  value={state.value}
                  helperText={state.meta.errors[0]}
                  onChange={e => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  type="text"
                />
              )}
            </Field>

            <Field
              name="modelYear"
              validators={{
                onChange: z.string(),
              }}
            >
              {({ state, handleChange, handleBlur }) => (
                <TextField
                  label="Model Year"
                  variant="outlined"
                  fullWidth
                  value={state.value}
                  helperText={state.meta.errors[0]}
                  onChange={e => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  type="text"
                />
              )}
            </Field>

            <Field
              name="color"
              validators={{
                onChange: z.string(),
              }}
            >
              {({ state, handleChange, handleBlur }) => (
                <TextField
                  label="Color"
                  variant="outlined"
                  fullWidth
                  value={state.value}
                  helperText={state.meta.errors[0]}
                  onChange={e => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  type="text"
                />
              )}
            </Field>
            <Field
              name="unitNumber"
              validators={{
                onChange: z.string(),
              }}
            >
              {({ state, handleChange, handleBlur }) => (
                <TextField
                  label="Unit Number"
                  variant="outlined"
                  fullWidth
                  value={state.value}
                  helperText={state.meta.errors[0]}
                  onChange={e => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  type="text"
                />
              )}
            </Field>
            {locations && locations?.length > 1 && (
              <FormControl>
                <Field
                  name="primaryLocationGroupId"
                  validators={{
                    onChange: GroupZodId.optional(),
                    onSubmit: GroupZodId,
                  }}
                  children={({ state, handleChange }) => (
                    <Autocomplete
                      fullWidth
                      options={locations || []}
                      getOptionLabel={option => option.name}
                      value={
                        locations?.find(l => state.value === l._id) || null
                      }
                      isOptionEqualToValue={(option, value) =>
                        option?._id === value?._id
                      }
                      onChange={(event, newValue) =>
                        handleChange(newValue ? newValue._id : undefined)
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Location"
                          required
                          error={state.meta.errors.length > 0}
                          helperText={state.meta.errors[0]}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option._id}>
                          {option.name}
                        </li>
                      )}
                    />
                  )}
                />
              </FormControl>
            )}
            <Field name="status">
              {({ state, handleChange, handleBlur }) => (
                <FormControlLabel
                  sx={{ mt: 2 }}
                  control={
                    <Switch
                      id="status"
                      checked={state.value === 'OUT_OF_SERVICE'}
                      onChange={e =>
                        handleChange(
                          e.target.checked ? 'OUT_OF_SERVICE' : 'ACTIVE'
                        )
                      }
                    />
                  }
                  label="Out of Service"
                />
              )}
            </Field>
          </Box>

          <Stack alignItems="flex-end" sx={{ mt: 3 }}>
            <Subscribe
              selector={state => [state.canSubmit, state.isSubmitting]}
              children={([canSubmit, isSubmitting]) => {
                return (
                  <LoadingButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={!canSubmit}
                  >
                    {vehicle ? 'Save' : 'Create'}
                  </LoadingButton>
                );
              }}
            />
          </Stack>
        </form>
        <ActionConfirmationDialog
          open={isOpen}
          onClose={onClose}
          {...pendingAction}
        />
      </Stack>
    </Card>
  );
};
