import LoadingButton from '@mui/lab/LoadingButton';
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useForm } from '@tanstack/react-form';
import { isValidPhoneNumber } from 'react-phone-number-input/input';
import { FormHead } from 'src/components/form/form-head';
import { Logo } from 'src/minimal-theme/components/logo';
import { PhoneInput } from 'src/minimal-theme/components/phone-input';
import { SplitLayout } from 'src/minimal-theme/layouts/split';
import { schemaHelper } from 'src/minimal-theme/utils/schema-helper';
import { AddressInput } from 'src/sections/request/AddressInput';
import { useAddressSearch } from 'src/utils/useAddressSearch';
import zod from 'zod';

export const JoinSchema = zod.object({
  phone: schemaHelper.phoneNumber({ isValidPhoneNumber }),
  name: zod.string().min(2, 'Company name must be at least 2 characters'),
  address: zod.string().min(2, 'Address must be at least 2 characters'),
  contactEmail: zod
    .string()
    .email('Invalid email address')
    .optional()
    .or(zod.literal('')),
  contactPhoneNo: schemaHelper.phoneNumber({ isValidPhoneNumber }),
  contactFirstName: zod
    .string()
    .min(2, 'First name must be at least 2 characters'),
  contactLastName: zod
    .string()
    .min(2, 'Last name must be at least 2 characters'),
  totalNumberOfTechnicians: zod
    .number()
    .min(1, 'Total number of technicians must be at least 1'),
  servicesOffered: zod
    .array(zod.string())
    .min(1, 'At least one service must be selected'),
});

export function JoinView() {
  const form = useForm({
    defaultValues: {
      phone: '',
      name: '',
      address: '',
      contactEmail: '',
      contactPhoneNo: '',
      contactFirstName: '',
      contactLastName: '',
      totalNumberOfTechnicians: 0,
      servicesOffered: [] as string[],
    },
    onSubmit: async ({ value }) => {},
    // validators: {
    //   onBlur: JoinSchema,
    // },
  });

  const { Field, Subscribe, handleSubmit, reset, store } = form;

  const servicesOffered = [
    { label: 'Roadside', value: 'ROADSIDE' },
    { label: 'Towing', value: 'TOWING' },
    { label: 'Shop Repairs', value: 'SHOP_REPAIRS' },
    { label: 'Parts', value: 'PARTS' },
  ];

  const { searchResults, coordinates, setCoordinates, searchAddress } =
    useAddressSearch();

  const renderForm = (
    <Stack spacing={3} sx={{ p: 3 }}>
      <form
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit();
        }}
        style={{ width: '100%' }}
      >
        <Typography variant="h6">Company Details</Typography>
        <Box
          rowGap={3}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
          }}
          sx={{ my: 2 }}
        >
          <Field
            name="name"
            validators={{
              onChangeAsyncDebounceMs: 500,
              onChangeAsync: JoinSchema.shape.name,
            }}
            children={({ state, handleChange, handleBlur }) => (
              <TextField
                label="Company Legal Name"
                variant="outlined"
                fullWidth
                required
                value={state.value}
                error={state.meta.errors.length > 0}
                helperText={state.meta.errors[0]}
                onChange={e => handleChange(e.target.value)}
                onBlur={handleBlur}
              />
            )}
          />
          <Field
            name="address"
            children={({ state, handleChange, handleBlur }) => (
              <AddressInput
                value={state.value}
                onChange={value => {
                  handleChange(value || '');
                }}
                error={state.meta.errors.length > 0}
                helperText={state.meta.errors[0]}
                searchResults={searchResults}
                searchAddress={searchAddress}
                onCoordinatesChange={coords => {
                  setCoordinates(
                    coords
                      ? { lat: coords.latitude, long: coords.longitude }
                      : null
                  );
                  // TODO: Should set this async, but need to think of a better pattern for clearing values on the backend
                }}
                required
              />
            )}
          />
        </Box>

        <Typography variant="h6">Primary Contact</Typography>
        <Box
          rowGap={3}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
          }}
          sx={{ my: 2 }}
        >
          <Field
            name="contactFirstName"
            children={({ state, handleChange, handleBlur }) => (
              <TextField
                label="Contact First Name"
                required
                variant="outlined"
                fullWidth
                value={state.value}
                onChange={e => handleChange(e.target.value)}
                onBlur={handleBlur}
              />
            )}
          />

          <Field
            name="contactLastName"
            children={({ state, handleChange, handleBlur }) => (
              <TextField
                label="Contact Last Name"
                required
                variant="outlined"
                fullWidth
                value={state.value}
                onChange={e => handleChange(e.target.value)}
                onBlur={handleBlur}
              />
            )}
          />

          <Field
            name="contactEmail"
            children={({ state, handleChange, handleBlur }) => (
              <TextField
                label="Contact Email"
                variant="outlined"
                fullWidth
                required
                value={state.value}
                error={state.meta.errors.length > 0}
                helperText={state.meta.errors[0]}
                onChange={e => handleChange(e.target.value)}
                onBlur={handleBlur}
              />
            )}
          />
          <Field
            name="contactPhoneNo"
            children={({ state, handleChange, handleBlur }) => (
              <PhoneInput
                required
                label="Contact Phone Number"
                fullWidth
                value={state.value}
                onChange={newValue => handleChange(newValue || '')}
                onBlur={handleBlur}
                error={state.meta.errors.length > 0}
                helperText={state.meta.errors[0]}
              />
            )}
          />
        </Box>

        <Typography variant="h6">Additional Information</Typography>

        <Box
          rowGap={3}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
          }}
          sx={{ my: 2 }}
        >
          <Field
            name="totalNumberOfTechnicians"
            children={({ state, handleChange }) => (
              <TextField
                label="Total Number of Technicians"
                type="number"
                required
                error={state.meta.errors.length > 0}
                helperText={state.meta.errors[0]}
              />
            )}
          />

          <FormControl fullWidth>
            <InputLabel htmlFor="services-offered-select-label">
              Services Offered
            </InputLabel>
            <Field
              name="servicesOffered"
              children={({ state, handleChange }) => (
                <Select
                  label="Services Offered"
                  required
                  multiple
                  value={state.value}
                  onChange={e => handleChange(e.target.value as string[])}
                  renderValue={value =>
                    value
                      .map(
                        val => servicesOffered.find(o => o.value === val)?.label
                      )
                      .join(', ')
                  }
                >
                  {servicesOffered.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      <Checkbox checked={state.value.includes(option.value)} />
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Box>

        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
          <Subscribe
            selector={state => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit, isSubmitting]) => {
              return (
                <LoadingButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={!canSubmit}
                >
                  Submit
                </LoadingButton>
              );
            }}
          />
        </Stack>
      </form>
    </Stack>
  );

  let content = (
    <SplitLayout sx={{ '--layout-auth-content-width': '800px' }}>
      <Logo sx={{ mx: 'auto', width: '64px', height: '64px' }} />

      <FormHead title="Join myMechanic today!" />
      {renderForm}
    </SplitLayout>
  );

  return <>{content}</>;
}
