import { phoneSchema } from '@cvx/types/zod/commonZod';
import {
  CompanyZodId,
  ConvexUserZodId,
  GroupZodId,
  ServiceRequestZodId,
} from '@cvx/types/zod/commonZodId';
import {
  polygonServiceArea,
  radiusServiceArea,
  rectangleServiceArea,
} from '@cvx/types/zod/serviceAreaShapes';
import { z } from 'zod';
import { stripeSettingsSchema } from '../../schema/entities/stripeSettings';
import { groupSubscriptionInfoSchema } from '../../schema/entities/subscriptionHistory';
import { paymentMethod } from '../../schema/enums/paymentMethod';
import {
  locationReferencesSchema,
  locationSchema,
} from '../../types/locationSchema';

export const physicalLocationType = z.enum([
  // Fleet company locations
  'FLEET_TERMINAL',
  'FLEET_YARD',
  'FLEET_MAINTENANCE_SHOP',

  // Service provider locations
  'REPAIR_SHOP',
  'MOBILE_REPAIR_BASE',
  'TIRE_SHOP',
  'TOW_YARD',

  'PARTS_WAREHOUSE',
  'TRUCK_STOP',

  // Dispatch-only location
  'FLEET_DISPATCH_CENTER',
  'SERVICE_DISPATCH_CENTER',
  'THIRD_PARTY_CALL_CENTER',

  // Shared/Generic
  'ADMINISTRATIVE_OFFICE',
  'OTHER',
]);

export const limitedLocationTypes = z.enum([
  // Fleet company locations
  'FLEET_TERMINAL',
  'FLEET_YARD',
  // 'FLEET_MAINTENANCE_SHOP',

  // Service provider locations
  'REPAIR_SHOP',
  'MOBILE_REPAIR_BASE',

  // Dispatch-only location
  'FLEET_DISPATCH_CENTER',
  'SERVICE_DISPATCH_CENTER',
]);

export const serviceAreaSchema = z.discriminatedUnion('type', [
  radiusServiceArea,
  polygonServiceArea,
  rectangleServiceArea,
]);

export const FLEET_LOCATION_TYPES = z.enum([
  'FLEET_TERMINAL',
  'FLEET_YARD',
  'FLEET_DISPATCH_CENTER',
]);

export const REPAIR_SHOP_LOCATION_TYPES = z.enum([
  'REPAIR_SHOP',
  'MOBILE_REPAIR_BASE',
  'SERVICE_DISPATCH_CENTER',
]);

export const THIRD_PARTY_LOCATION_TYPES = z.enum(['THIRD_PARTY_CALL_CENTER']);

export const stripeRequirementSchema = z.object({
  alternatives: z
    .array(
      z.object({
        alternative_fields_due: z.array(z.string()),
        original_fields_due: z.array(z.string()),
      })
    )
    .nullable(),
  current_deadline: z.number().nullable(),
  currently_due: z.array(z.string()).nullable(),
  disabled_reason: z.string().nullable(),
});

export const stripeAccountInfoSchema = z.object({
  charges_enabled: z.boolean(),
  payouts_enabled: z.boolean(),
  future_requirements: stripeRequirementSchema.nullable(),
  requirements: stripeRequirementSchema.nullable(),
  details_submitted: z.boolean().optional(),
  capabilities: z.any().optional(), // TODO: This is a complex object, need to define schema, keeping it simple now as we can just assert the type from stripe
});

/** Entity for grouping users directly or nesting child groups, roles and direct permissions can be assigned to groups */
export const groupSchema = z
  .object({
    name: z.string(),
    description: z.string(),

    parentId: GroupZodId.optional(), // reference to parent group
    path: z.string().optional(),

    allowDriversSelfRequest: z.boolean().optional(),
    // Physical location groups
    representsPhysicalLocation: z.boolean(), // For service vendors (or fleets) with physical locations, we will enforce that users can only belong to one of these groups
    location: locationSchema.optional(),
    locationType: physicalLocationType.optional(),
    serviceArea: serviceAreaSchema.optional(),

    isRetail: z.boolean().optional(),

    isSuperAdmin: z.boolean().optional(),

    simpleServiceAreaByMilesOut: z.number().optional(),

    // Verification/onboarding status
    /** This governs whether or not a request can directly flow to the dispatch queue pertaining to this group
     * it would apply when a fleet dispatch/owner operator driver is submitting a request to an SP dispatch (not possible if false, must call)
     */
    hasVerifiedDispatchTeam: z.boolean().optional(),
    hasVerifiedTechnicians: z.boolean().optional(),

    // TODO: Need to think of a better way to do this, current need for "sole proprietor" where there is no dispatch involved on one side or the other or both
    noDispatchSoleProprietor: z.boolean().optional(), // Skip dispatch steps for this group, gives driver/technician elevated roles at this location
    acceptedPaymentMethods: z.array(paymentMethod).optional(),

    // Operational details for physical locations, TODO: .. could also maybe link into google business listings for this info... but fallback if they don't have one?
    operationalHours: z
      .object({
        timezone: z.string(),
        schedule: z.array(
          z.object({
            dayOfWeek: z.number(), // 0-6, Sunday = 0
            openTime: z.string(), // "HH:mm" format
            closeTime: z.string(), // "HH:mm" format
            is24Hours: z.boolean(),
          })
        ),
        holidaySchedule: z
          .array(
            z.object({
              date: z.string(), // "YYYY-MM-DD"
              isClosed: z.boolean(),
              openTime: z.string().optional(),
              closeTime: z.string().optional(),
            })
          )
          .optional(),
      })
      .optional(),

    // Contact info for physical locations
    contactInfo: z
      .object({
        phone: phoneSchema.optional(),
        email: z.string().email().optional(),
        dispatchPhone: phoneSchema.optional(), // 24/7 dispatch contact
        emergencyPhone: phoneSchema.optional(), // After hours emergency
        notes: z.string().optional(),
      })
      .optional(),

    isActive: z.boolean(),
    systemGenerated: z.boolean(),

    createdByUserDuringRequest: z.boolean().optional(),
    createdFromRequestId: ServiceRequestZodId.optional(),
    createdByLocationGroupId: GroupZodId.optional(),

    invitedByUser: ConvexUserZodId.optional(),
    invitedByLocation: GroupZodId.optional(),
    invitedByCompany: CompanyZodId.optional(),

    // Admin verification progress
    isAdminVerified: z.boolean().optional(), // Only true when our team verifies
    adminVerifiedAt: z.string().optional(),
    adminVerifiedById: ConvexUserZodId.optional(),

    // If service provider has completed profile/onboarding
    hasCompletedOnboarding: z.boolean().optional(),

    // Whether to show when searching for providers
    showInProviderDirectory: z.boolean().optional(),

    // For dispatchers if they cover multiple location groups
    dispatchCoverageGroupIds: z.array(GroupZodId).optional(),

    // For fleet driver locations, and service repair locations, allows requests to flow to the appropriate dispatch group
    defaultDispatchGroupId: GroupZodId.optional(),

    createdById: ConvexUserZodId.optional(),
    companyId: CompanyZodId.optional(),

    stripeAccountId: z.string().optional(),
    // TODO: we will be removing the next field in the future in favor of a more robust solution using stripe's requirements/charged_enabled status, etc.
    isStripeConnected: z.boolean().optional(),

    // Payment processing settings
    stripeSettings: stripeSettingsSchema.optional(),
    stripeConnectedAccountOnboardingLink: z.string().optional(),

    stateTaxRegistrations: z.array(z.string()).optional(),
    stripeAccountInfo: stripeAccountInfoSchema.optional(),
  })
  .merge(locationReferencesSchema)
  .merge(groupSubscriptionInfoSchema);
