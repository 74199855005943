import { api } from '@cvx/api';
import { useLoaderData } from '@tanstack/react-router';
import { useQuery } from 'convex/react';
import { RequestListView } from 'src/sections/request/view';

export function MyParticipationsView() {
  const loaderData = useLoaderData({
    from: '/_auth/dashboard/requests/my-participations',
  });
  const requests = useQuery(
    api.functions.requests.getRequestsParticipatingIn,
    {}
  );

  return (
    <RequestListView
      title="My Participations"
      requests={requests ?? loaderData}
    />
  );
}
