import { RequestActionProps } from 'src/sections/request/types/RequestActionProps';

export const getConfirmEnRouteActionState = ({
  requestStateInformation,
}: RequestActionProps) => {
  const { isTechnicianAccepted, isTechnician, isAssignedToMe } =
    requestStateInformation;

  const isVisible = isTechnicianAccepted;

  if (!isVisible) {
    return {
      isVisible: false,
      isEnabled: false,
      message: '',
    };
  }

  const acceptMessage = 'Confirm En Route';
  const canPerform = isTechnician && isAssignedToMe;

  return {
    isVisible: true,
    isEnabled: canPerform,
    message: canPerform ? acceptMessage : `Waiting On Technician`,
    confirmationTitle: acceptMessage,
    confirmationMessage:
      'Are you sure you are ready to navigate to the request?',
  };
};
