import {
  ConvexUserId,
  RequestHistoryId,
  RequestId,
  RoleDefinitionId,
} from '@cvx/types/entities/sharedIds';
import {
  CompanyZodId,
  ConvexUserZodId,
  GroupZodId,
  RoleDefinitionZodId,
  ServiceRequestZodId,
} from '@cvx/types/zod/commonZodId';
import { stepType } from '@cvx/types/zod/requestsZod';
import { zodToConvex } from 'convex-helpers/server/zod';
import { defineTable } from 'convex/server';
import { z } from 'zod';
import { Doc } from '../../../_generated/dataModel';
import { DatabaseWriter } from '../../../_generated/server';
import { userRole, UserRole } from '../../enums/userRole';

export const historyActionType = z.enum([
  // Request lifecycle events that represent major state changes
  'REQUEST_CREATED',
  'REQUEST_COMPLETED',
  'REQUEST_CANCELED',

  // Workflow progression events
  'WORKFLOW_TRANSITION',
  'DOCUMENT_ADDED', // Important for tracking uploaded files/photos
  'DOCUMENT_DELETED',

  // Operational events
  'LOCATION_UPDATE', // When vehicle/tech location is confirmed/updated
  'PING_REMINDER', // Manual ping sent to assignee
  'UPDATED_DETAILS',
  'PARTICIPANT_SWAPPED', // When a participant is swapped out
  'PARTICIPANT_ADDED', // When a participant is added
  'PARTICIPANT_REMOVED', // When a participant is removed

  // System events
  'SYSTEM_TIMEOUT', // When system takes automated action due to timeout
  'SYSTEM_ERROR', // For capturing system-level issues
]);

export type HistoryActionType = z.infer<typeof historyActionType>;

export const messageComponent = z.object({
  type: z.enum([
    'USER_NAME', // Actor's name
    'USER_ROLE', // Actor's role
    'TARGET_USER', // Target user's name (for assignments etc)
    'TARGET_ROLE', // Target role
    'ACTION_TEXT', // Static text describing the action
    'LOCATION', // Location details
    'DOCUMENT', // Document/photo details
    'CUSTOM', // Any additional text/data
  ]),
  value: z.string(),
  // Optional metadata about this component
  metadata: z.record(z.any()).optional(),
});

export type MessageComponent = z.infer<typeof messageComponent>;

// For tracking significant events/changes
export const requestHistorySchema = z.object({
  requestId: ServiceRequestZodId,
  type: historyActionType,

  userId: ConvexUserZodId,
  userRole: RoleDefinitionZodId,
  visibleToRoles: z.array(userRole), // For sensitive actions if we need this (I can think of whatever back and forth occurs between service manager and mechanics (i.e. reject, reassign, reject, etc.))

  messageComponents: z.array(messageComponent),

  // TODO: Not currently using but will be useful for metrics
  details: z
    .object({
      // For workflow transitions
      fromPhase: z.string().optional(),
      toPhase: z.string().optional(),
      fromStep: stepType.optional(),
      toStep: stepType.optional(),
      fromState: z.string().optional(),
      toState: z.string().optional(),

      // For assignments
      targetUserId: ConvexUserZodId.optional(),
      targetRoleId: RoleDefinitionZodId.optional(),
      targetCompanyId: CompanyZodId.optional(),
      targetLocationId: GroupZodId.optional(),

      // For locations
      latitude: z.number().optional(),
      longitude: z.number().optional(),

      // For document tracking
      documentIds: z.array(z.string()).optional(),

      // Flexible additional data
      additionalData: z.record(z.any()).optional(),
    })
    .optional(),
});

export const requestHistory = defineTable(
  zodToConvex(requestHistorySchema).fields
).index('by_requestId', ['requestId']);

// TODO: Figure out a good way to (for the Chat nav route if we need it) to pull in these chats to that view in some useful way

export type HistoryEntry = {
  type: HistoryActionType;
  userId: ConvexUserId;
  userRole: RoleDefinitionId;
  messageComponents: MessageComponent[];
  details?: z.infer<typeof requestHistorySchema>['details'];
  visibleToRoles: UserRole[];
};

export const messageTemplates = {
  // Assignment related
  fleetDispatcherAssignedToServiceProvider: (params: {
    dispatcherName: string;
    dispatcherRole: UserRole;
    serviceProviderName: string;
    // Direct assign
    serviceDispatcherName?: string;
    serviceDispatcherRole?: UserRole;
  }): MessageComponent[] => [
    { type: 'USER_NAME', value: params.dispatcherName },
    { type: 'ACTION_TEXT', value: ' [as ' },
    { type: 'USER_ROLE', value: params.dispatcherRole },
    {
      type: 'ACTION_TEXT',
      value: '] assigned the request to service dispatch with ',
    },
    { type: 'LOCATION', value: params.serviceProviderName },
    ...(params.serviceDispatcherName && params.serviceDispatcherRole
      ? [
          { type: 'ACTION_TEXT' as const, value: ' Directly assigned to ' },
          { type: 'TARGET_USER' as const, value: params.serviceDispatcherName },
          { type: 'ACTION_TEXT' as const, value: ' [as ' },
          { type: 'TARGET_ROLE' as const, value: params.serviceDispatcherRole },
          { type: 'ACTION_TEXT' as const, value: ']' },
        ]
      : []),
  ],

  technicianAssignedToRequest: (params: {
    dispatcherName: string;
    dispatcherRole: UserRole;
    technicianName: string;
    technicianRole: UserRole;
  }): MessageComponent[] => [
    { type: 'USER_NAME', value: params.dispatcherName },
    { type: 'ACTION_TEXT', value: ' [as ' },
    { type: 'USER_ROLE', value: params.dispatcherRole },
    { type: 'ACTION_TEXT', value: '] assigned ' },
    { type: 'TARGET_USER', value: params.technicianName },
    { type: 'ACTION_TEXT', value: ' [' },
    { type: 'TARGET_ROLE', value: params.technicianRole },
    { type: 'ACTION_TEXT', value: '] to the request' },
  ],

  participantSwapped: (params: {
    operatorName: string;
    operatorRole: UserRole;
    removedName: string;
    removedRole: UserRole;
    addedName: string;
    addedRole: UserRole;
  }): MessageComponent[] => [
    { type: 'USER_NAME', value: params.operatorName },
    { type: 'ACTION_TEXT', value: ' [as ' },
    { type: 'USER_ROLE', value: params.operatorRole },
    { type: 'ACTION_TEXT', value: '] swapped participant ' },
    { type: 'TARGET_USER', value: params.removedName },
    { type: 'ACTION_TEXT', value: ' [' },
    { type: 'TARGET_ROLE', value: params.removedRole },
    { type: 'ACTION_TEXT', value: '] with ' },
    { type: 'TARGET_USER', value: params.addedName },
    { type: 'ACTION_TEXT', value: ' [' },
    { type: 'TARGET_ROLE', value: params.addedRole },
    { type: 'ACTION_TEXT', value: ']' },
  ],

  requestCreated: (params: {
    creatorName: string;
    creatorRole: UserRole;
  }): MessageComponent[] => [
    { type: 'USER_NAME', value: params.creatorName },
    { type: 'ACTION_TEXT', value: ' [as ' },
    { type: 'USER_ROLE', value: params.creatorRole },
    { type: 'ACTION_TEXT', value: '] created the request' },
  ],

  requestCompleted: (params: {
    completedByName: string;
    completedByRole: UserRole;
  }): MessageComponent[] => [
    { type: 'USER_NAME', value: params.completedByName },
    { type: 'ACTION_TEXT', value: ' [as ' },
    { type: 'USER_ROLE', value: params.completedByRole },
    { type: 'ACTION_TEXT', value: '] marked the request as completed' },
  ],

  requestCancelled: (params: {
    cancelledByName: string;
    cancelledByRole: UserRole;
    reason?: string;
  }): MessageComponent[] => [
    { type: 'USER_NAME', value: params.cancelledByName },
    { type: 'ACTION_TEXT', value: ' [as ' },
    { type: 'USER_ROLE', value: params.cancelledByRole },
    {
      type: 'ACTION_TEXT',
      value: `] cancelled the request${params.reason ? ` for the following reason: ${params.reason}` : ''}`,
    },
  ],

  // Assignment Management
  fleetDispatcherClaimed: (params: {
    dispatcherName: string;
    dispatcherRole: UserRole;
  }): MessageComponent[] => [
    { type: 'USER_NAME', value: params.dispatcherName },
    { type: 'ACTION_TEXT', value: ' [as ' },
    { type: 'USER_ROLE', value: params.dispatcherRole },
    { type: 'ACTION_TEXT', value: '] claimed the request' },
  ],

  serviceDispatcherClaimed: (params: {
    dispatcherName: string;
    dispatcherRole: UserRole;
  }): MessageComponent[] => [
    { type: 'USER_NAME', value: params.dispatcherName },
    { type: 'ACTION_TEXT', value: ' [as ' },
    { type: 'USER_ROLE', value: params.dispatcherRole },
    {
      type: 'ACTION_TEXT',
      value: '] claimed the request from service provider queue',
    },
  ],

  // Workflow transitions
  technicianAction: (params: {
    technicianName: string;
    technicianRole: UserRole;
    action: string;
  }): MessageComponent[] => [
    { type: 'USER_NAME', value: params.technicianName },
    { type: 'ACTION_TEXT', value: ' [as ' },
    { type: 'USER_ROLE', value: params.technicianRole },
    { type: 'ACTION_TEXT', value: `] ${params.action}` },
  ],

  updatedDetails: (params: {
    name: string;
    role: UserRole;
    action: string;
  }): MessageComponent[] => [
    { type: 'USER_NAME', value: params.name },
    { type: 'ACTION_TEXT', value: ' [as ' },
    { type: 'USER_ROLE', value: params.role },
    { type: 'ACTION_TEXT', value: `] ${params.action}` },
  ],

  fleetDispatcherAssignedToDriverToConfirmLocation: (params: {
    dispatcherName: string;
    dispatcherRole: UserRole;
    driverName: string;
    driverRole: UserRole;
  }): MessageComponent[] => [
    { type: 'USER_NAME', value: params.dispatcherName },
    { type: 'ACTION_TEXT', value: ' [as ' },
    { type: 'USER_ROLE', value: params.dispatcherRole },
    {
      type: 'ACTION_TEXT',
      value: '] assigned the task of sharing vehicle location to driver ',
    },
    { type: 'USER_NAME', value: params.driverName },
    { type: 'ACTION_TEXT', value: ' [as ' },
    { type: 'USER_ROLE', value: params.driverRole },
    { type: 'ACTION_TEXT', value: ' ]' },
  ],

  driverConfirmsLocation: (params: {
    driverName: string;
    driverRole: UserRole;
    dispatcherName: string;
    dispatcherRole: UserRole;
  }): MessageComponent[] => [
    { type: 'USER_NAME', value: params.driverName },
    { type: 'ACTION_TEXT', value: ' [as ' },
    { type: 'USER_ROLE', value: params.driverRole },
    {
      type: 'ACTION_TEXT',
      value: '] has confirmed their location and assigned the request back to ',
    },
    { type: 'USER_NAME', value: params.dispatcherName },
    { type: 'ACTION_TEXT', value: ' [as ' },
    { type: 'USER_ROLE', value: params.dispatcherRole },
    { type: 'ACTION_TEXT', value: ' ]' },
  ],

  // Location & Verification
  locationUpdated: (params: {
    userName: string;
    userRole: UserRole;
    locationDescription: string;
  }): MessageComponent[] => [
    { type: 'USER_NAME', value: params.userName },
    { type: 'ACTION_TEXT', value: ' [as ' },
    { type: 'USER_ROLE', value: params.userRole },
    { type: 'ACTION_TEXT', value: '] updated location to: ' },
    { type: 'LOCATION', value: params.locationDescription },
  ],

  // Assignment Rejections/Changes
  assignmentRejected: (params: {
    rejecterName: string;
    rejecterRole: UserRole;
    reason?: string;
  }): MessageComponent[] => [
    { type: 'USER_NAME', value: params.rejecterName },
    { type: 'ACTION_TEXT', value: ' [as ' },
    { type: 'USER_ROLE', value: params.rejecterRole },
    { type: 'ACTION_TEXT', value: '] rejected the assignment' },
    ...(params.reason
      ? [
          { type: 'ACTION_TEXT' as const, value: ' Reason: ' },
          { type: 'CUSTOM' as const, value: params.reason },
        ]
      : []),
  ],

  // System Actions
  systemTimeout: (params: {
    entityName: string;
    timeoutDescription: string;
  }): MessageComponent[] => [
    { type: 'LOCATION', value: params.entityName },
    { type: 'ACTION_TEXT', value: ` ${params.timeoutDescription}` },
  ],

  // Documents & Photos
  documentAdded: (params: {
    userName: string;
    userRole: UserRole;
    documentType: string;
    documentDescription?: string;
  }): MessageComponent[] => [
    { type: 'USER_NAME', value: params.userName },
    { type: 'ACTION_TEXT', value: ' [as ' },
    { type: 'USER_ROLE', value: params.userRole },
    { type: 'ACTION_TEXT', value: `] added ${params.documentType}` },
    ...(params.documentDescription
      ? [
          { type: 'ACTION_TEXT' as const, value: ': ' },
          { type: 'DOCUMENT' as const, value: params.documentDescription },
        ]
      : []),
  ],

  // ETA Updates
  etaUpdated: (params: {
    userName: string;
    userRole: UserRole;
    estimatedTime: string;
  }): MessageComponent[] => [
    { type: 'USER_NAME', value: params.userName },
    { type: 'ACTION_TEXT', value: ' [as ' },
    { type: 'USER_ROLE', value: params.userRole },
    { type: 'ACTION_TEXT', value: '] updated ETA to: ' },
    { type: 'CUSTOM', value: params.estimatedTime },
  ],
};

export async function createHistoryEntry(
  db: DatabaseWriter,
  params: {
    requestId: RequestId;
    type: z.infer<typeof historyActionType>;
    userId: ConvexUserId;
    userRole: RoleDefinitionId;
    messageComponents: MessageComponent[];
    details?: z.infer<typeof requestHistorySchema>['details'];
    visibleToRoles: UserRole[];
  }
) {
  return await db.insert('requestHistory', {
    ...params,
  });
}

// Helper to format history for display
export type FormattedHistoryEntry = {
  id: RequestHistoryId;
  timestamp: number;
  type: z.infer<typeof historyActionType>;
  messageComponents: MessageComponent[];
  details?: z.infer<typeof requestHistorySchema>['details'];
};

export function formatHistoryEntry(
  entry: Doc<'requestHistory'>
): FormattedHistoryEntry {
  return {
    id: entry._id,
    timestamp: entry._creationTime,
    type: entry.type,
    messageComponents: entry.messageComponents,
    details: entry.details,
  };
}
