import { RequestActionProps } from 'src/sections/request/types/RequestActionProps';

export const getCreateInvoiceActionState = ({
  requestStateInformation,
}: RequestActionProps) => {
  const {
    isServiceDispatcher,
    isThirdPartyDispatcher,
    stripeOnboardingCompleted,
  } = requestStateInformation;

  const canPerform =
    (isServiceDispatcher || isThirdPartyDispatcher) &&
    stripeOnboardingCompleted;

  const isVisible = canPerform;

  if (!isVisible) {
    return {
      isVisible: false,
      isEnabled: false,
      message: '',
    };
  }

  const actionMessage = 'Create Invoice';

  return {
    isVisible: true,
    isEnabled: canPerform,
    message: actionMessage,
    confirmationTitle: actionMessage,
    confirmationMessage: stripeOnboardingCompleted
      ? 'Are you ready to create an invoice for this request?'
      : 'Please setup your Stripe account to create invoices',
    stripeOnboardingCompleted,
  };
};
