import { createRouter as createTanStackRouter } from '@tanstack/react-router';
import { NotFoundPage } from 'src/pages/error/404';
import { convex } from './convexClient';
import { routeTree } from './routeTree.gen';

export function createRouter() {
  const router = createTanStackRouter({
    routeTree,
    defaultPreload: 'intent',
    scrollRestoration: true,
    context: {
      convex,
      isAuthenticated: false,
      isAuthenticating: false,
      impersonatingServiceProvider: false,
      impersonatingFleet: false,
      impersonatingThirdParty: false,
    },
    // defaultErrorComponent: DefaultCatchBoundary,
    defaultNotFoundComponent: NotFoundPage,
    defaultPreloadStaleTime: 5000,
  });

  return router;
}

declare module '@tanstack/react-router' {
  interface Register {
    // This infers the type of our router and registers it across your entire project
    router: ReturnType<typeof createRouter>;
  }
}
