import { Doc } from '@cvx/dataModel';
import {
  CompanyZodId,
  ConvexUserZodId,
  GroupZodId,
  ServiceRequestZodId,
} from '@cvx/types/zod/commonZodId';
import { zid, zodToConvex } from 'convex-helpers/server/zod';
import { defineTable } from 'convex/server';
import { z } from 'zod';
import { userRole } from '../../enums/userRole';

export const imageCategories = z.enum([
  'PHOTO_OF_FAILURE',
  'VIN',
  'LICENSE_PLATE',
  'UNIT_NUMBER',
  'WORK_SCENE',
  'FAILURE_DETAIL',
  'TOWING_ACCIDENT_SCENE',
  'REPAIR_PHOTO',
  'FAILED_TIRE',
  'NEW_TIRE',
  'WORK_ORDER',
  'INVOICE',
  'OTHER',
]);

export const imageCategoryGroups = {
  'Driver Photos': ['PHOTO_OF_FAILURE'],
  'Unit Photos': ['VIN', 'LICENSE_PLATE', 'UNIT_NUMBER'],
  'Before Work': ['WORK_SCENE', 'FAILURE_DETAIL', 'TOWING_ACCIDENT_SCENE'],
  'After Work': ['REPAIR_PHOTO'],
  'Tire Photos': ['FAILED_TIRE', 'NEW_TIRE'],
  Documentation: ['INVOICE', 'WORK_ORDER', 'OTHER'],
} as const;

export const getImageCategoryLabel = (category: ImageCategory) => {
  const labels: Record<ImageCategory, string> = {
    PHOTO_OF_FAILURE: 'Photo of Failure',
    VIN: 'VIN',
    LICENSE_PLATE: 'License Plate',
    UNIT_NUMBER: 'Unit Number',
    WORK_SCENE: 'Work Scene',
    FAILURE_DETAIL: 'Picture of Failure',
    REPAIR_PHOTO: 'Picture of Repair',
    FAILED_TIRE: 'Failed Tire',
    NEW_TIRE: 'New Tire',
    WORK_ORDER: 'Work Order',
    INVOICE: 'Invoice',
    TOWING_ACCIDENT_SCENE: 'Towing / Accident Scene',
    OTHER: 'Other',
  };
  return labels[category];
};

export const categoryColorMap: Record<
  ImageCategory,
  { light: string; main: string }
> = {
  PHOTO_OF_FAILURE: { light: '#FFE5E5', main: '#E05151' }, // Red
  VIN: { light: '#E5F6FF', main: '#4A99D0' }, // Blue
  LICENSE_PLATE: { light: '#E5FFE8', main: '#45A854' }, // Green
  UNIT_NUMBER: { light: '#FFF3E5', main: '#D07B2F' }, // Orange
  WORK_SCENE: { light: '#FFE5F3', main: '#D0498B' }, // Pink
  FAILURE_DETAIL: { light: '#E5FFF6', main: '#3BA885' }, // Teal
  REPAIR_PHOTO: { light: '#F3E5FF', main: '#9244D0' }, // Purple
  FAILED_TIRE: { light: '#FFFFE5', main: '#998A16' }, // Yellow-Brown
  NEW_TIRE: { light: '#E5FFFD', main: '#288F88' }, // Sea Green
  WORK_ORDER: { light: '#FFF9E5', main: '#D0A030' }, // Gold
  INVOICE: { light: '#E5E5FF', main: '#4A4AD0' }, // Indigo
  TOWING_ACCIDENT_SCENE: { light: '#fbeae5', main: '#d64318' }, // Indigo
  OTHER: { light: '#F5F5F5', main: '#666666' }, // Dark Gray
};

export const getImageCategoryColor = (category: ImageCategory) => {
  return categoryColorMap[category] || categoryColorMap.OTHER;
};

export type ImageCategory = z.infer<typeof imageCategories>;

export const requestImageSchema = z.object({
  description: z.string().optional(),
  alt: z.string().optional(),
  imageId: zid('_storage'), // References id of image in convex storage
  deletedAt: z.number().optional(),

  // Handles whether or not user has "confirmed" that they want to upload the images
  // This allows us to optimistically save it and not show it in the list yet, and if they close the dialog/choose not to upload, we can delete them
  confirmed: z.boolean().optional(),

  // Relationships
  // User who took the photo
  createdById: ConvexUserZodId,

  requestId: ServiceRequestZodId,

  // Company of the user who took the photo
  companyId: CompanyZodId,

  category: imageCategories.optional(), // Optional for existing images
  technicianGroupId: GroupZodId.optional(), // SP tech group who should see the image, if user belongs to this group
  serviceDispatchGroupId: GroupZodId.optional(), // SP dispatch, who should see image, if user belongs to this group
  viewableByRoles: z.array(userRole).optional(), // Role types that can view, if other conditions met
});

export const requestImages = defineTable(zodToConvex(requestImageSchema).fields)
  .index('by_createdById', ['createdById'])
  .index('by_requestId', ['requestId'])
  .index('by_companyId', ['companyId']);

export type RequestImageWithUrl = Doc<'requestImages'> & { url: string };

export type RequestImageWithBlob = Doc<'requestImages'> & {
  blob: Blob;
  contentType: string;
};
