import { InvoiceId, InvoiceItemId } from '@cvx/types/entities/sharedIds';
import { useMutation, useQuery } from 'convex/react';
import { nanoid } from 'nanoid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from 'src/convex/_generated/api';
import { Doc, Id } from 'src/convex/_generated/dataModel';
import { useScheduledActions } from 'src/hooks/useScheduledActions';
import { toast } from 'src/minimal-theme/components/snackbar';

export type LocalInvoiceItem = Omit<
  Doc<'invoiceItems'>,
  '_id' | 'invoiceItemStripeId'
> & {
  clientId: string;
  _id?: InvoiceItemId;
  invoiceItemStripeId?: string;
};

const createNewItem = (
  invoiceId: InvoiceId,
  order: number
): LocalInvoiceItem => ({
  clientId: nanoid(),
  description: '',
  category: 'MISC',
  amount: 0,
  quantity: 1,
  taxCode: 'txcd_99999999',
  invoiceId,
  _creationTime: Date.now(),
  order,
});

const isItemValid = (item: LocalInvoiceItem): boolean => {
  return Boolean(
    item.category &&
      typeof item.quantity === 'number' &&
      item.quantity > 0 &&
      typeof item.amount === 'number' &&
      item.amount > 0
  );
};

const prepareItemForServer = (item: LocalInvoiceItem) => {
  const {
    _creationTime,
    invoiceId,
    _id,
    invoiceItemStripeId,
    taxCode,
    taxAmount,
    ...serverItem
  } = item;
  return serverItem;
};

export function useInvoiceLineItems(invoiceId: InvoiceId | undefined | 'skip') {
  const [localItems, setLocalItems] = useState<LocalInvoiceItem[]>([]);
  const [processedItems, setProcessedItems] = useState<LocalInvoiceItem[]>([]);
  const [pendingAdditions, setPendingAdditions] = useState<Set<string>>(
    new Set()
  );
  const [beingEdited, setBeingEdited] = useState<Set<Id<'invoiceItems'>>>(
    new Set()
  );
  const [isSyncing, setIsSyncing] = useState(false);

  const { watchIds } = useScheduledActions({
    successMessage: '',
    hideSuccessMessage: true,
    hidePendingMessage: true,
  });

  const invoiceData = useQuery(
    api.functions.invoices.getInvoiceById,
    invoiceId && invoiceId !== 'skip' ? { invoiceId } : 'skip'
  );

  const invoiceItems = useMemo(
    () => invoiceData?.invoiceItems || [],
    [invoiceData]
  );

  useEffect(() => {
    if (!invoiceId || invoiceId === 'skip' || invoiceItems.length === 0) return;

    // Get replacing item IDs directly in this effect
    const replacingIds = new Set();
    invoiceItems.forEach(item => {
      if (item.replacingItemId) {
        replacingIds.add(item.replacingItemId);
      }
    });

    setLocalItems(prev => {
      // Filter out any items that have been replaced
      const filteredItems = prev.filter(
        item => !(item._id && replacingIds.has(item._id))
      );

      // Create a map of existing items by ID for quick lookup
      const existingMap = new Map(
        filteredItems.filter(item => item._id).map(item => [item._id, item])
      );

      // Create a set of IDs for quick lookup
      const existingIds = new Set(existingMap.keys());

      // Add server items that aren't already in the local state
      const newItems = invoiceItems
        .filter(item => !existingIds.has(item._id))
        .map(item => ({
          ...item,
          clientId: nanoid(),
        }));

      // Update existing items with latest server data
      const updatedItems = filteredItems.map(item => {
        if (item._id) {
          const serverItem = invoiceItems.find(si => si._id === item._id);
          if (serverItem) {
            return { ...item, ...serverItem };
          }
        }
        return item;
      });

      return [...updatedItems, ...newItems];
    });
  }, [invoiceItems, invoiceId]);

  useEffect(() => {
    // Early exit for invalid state
    if (!invoiceItems || localItems.length === 0) return;

    // Get replacing item IDs
    const replacingIds = new Set();
    invoiceItems.forEach(item => {
      if (item.replacingItemId) {
        replacingIds.add(item.replacingItemId);
      }
    });

    const finalItems = localItems
      .filter(item => !(item._id && replacingIds.has(item._id)))
      .sort((a, b) => a.order - b.order);

    setProcessedItems(finalItems);
  }, [invoiceItems, localItems]);

  const addItemMutation = useMutation(api.functions.invoices.addItemToInvoice);
  const updateItemMutation = useMutation(
    api.functions.invoices.updateInvoiceLineItem
  );
  const removeItemMutation = useMutation(
    api.functions.invoices.removeItemFromInvoice
  );

  const handleAddItem = async () => {
    if (!invoiceId || invoiceId === 'skip') {
      toast.error('Please select a request first');
      return;
    }

    const lastItem = processedItems[processedItems.length - 1];
    const newItem = createNewItem(invoiceId, (lastItem?.order || 0) + 1);

    setPendingAdditions(prev => {
      const next = new Set(prev);
      next.add(newItem.clientId);
      return next;
    });

    setLocalItems(prev => [...prev, newItem]);

    try {
      const prepared = prepareItemForServer(newItem);
      const response = await addItemMutation({
        invoiceId,
        item: {
          ...prepared,
          category: newItem.category || 'MISC',
          description: newItem.description || '',
        },
      });

      if (response.success) {
        setLocalItems(prev =>
          prev.map(item =>
            item.clientId === newItem.clientId
              ? { ...item, _id: response.data.newItemId }
              : item
          )
        );

        setPendingAdditions(prev => {
          const next = new Set(prev);
          next.delete(newItem.clientId);
          return next;
        });
      } else {
        throw new Error(response.message || 'Failed to add item');
      }
    } catch (error) {
      console.error('Failed to add item:', error);

      setLocalItems(prev =>
        prev.filter(item => item.clientId !== newItem.clientId)
      );

      setPendingAdditions(prev => {
        const next = new Set(prev);
        next.delete(newItem.clientId);
        return next;
      });

      toast.error('Failed to add item');
    }
  };

  const handleUpdateItem = useCallback(
    async (itemId: Id<'invoiceItems'>, updates: Partial<LocalInvoiceItem>) => {
      if (!invoiceId || invoiceId === 'skip') return;

      setIsSyncing(true);

      try {
        // Mark as being edited
        setBeingEdited(prev => {
          const next = new Set(prev);
          next.add(itemId);
          return next;
        });

        // Update locally
        setLocalItems(prev =>
          prev.map(item =>
            item._id === itemId ? { ...item, ...updates } : item
          )
        );

        const itemToUpdate = localItems.find(item => item._id === itemId);
        if (!itemToUpdate) {
          throw new Error('Item not found');
        }

        const updatedItem = { ...itemToUpdate, ...updates };
        const prepared = prepareItemForServer(updatedItem);
        const { clientId, replacingItemId, ...withoutClientId } = prepared;

        // Call the update and pass replacingItemId
        const resp = await updateItemMutation({
          invoiceId,
          item: {
            ...withoutClientId,
            _id: itemId,
            description: updatedItem.description || '',
            category: updatedItem.category || 'MISC',
          },
        });

        if (resp.success && resp.data.scheduleId) {
          // Wait for completion
          await watchIds([resp.data.scheduleId], {
            onSuccess: () => {
              // Done editing
              setBeingEdited(prev => {
                const next = new Set(prev);
                next.delete(itemId);
                return next;
              });
            },
            onError: () => {
              // Revert
              setLocalItems(prev =>
                prev.map(item =>
                  item._id === itemId
                    ? { ...localItems.find(i => i._id === itemId)! }
                    : item
                )
              );

              setBeingEdited(prev => {
                const next = new Set(prev);
                next.delete(itemId);
                return next;
              });

              toast.error('Update failed');
            },
          });
        } else {
          throw new Error(resp.message || 'Update failed');
        }
      } catch (error) {
        console.error('Failed to update item:', error);

        // Revert
        setLocalItems(prev =>
          prev.map(item =>
            item._id === itemId
              ? { ...localItems.find(i => i._id === itemId)! }
              : item
          )
        );

        setBeingEdited(prev => {
          const next = new Set(prev);
          next.delete(itemId);
          return next;
        });

        toast.error('Failed to update item');
      } finally {
        setIsSyncing(false);
      }
    },
    [invoiceId, localItems, updateItemMutation, watchIds]
  );

  // Remove an item
  const handleRemoveItem = async (itemId: InvoiceItemId) => {
    if (!invoiceId || invoiceId === 'skip') return;

    setIsSyncing(true);

    // Remove optimistically
    setLocalItems(prev => prev.filter(item => item._id !== itemId));

    try {
      const resp = await removeItemMutation({
        invoiceId,
        invoiceItemId: itemId,
      });

      if (resp.success && resp.data?.scheduleId) {
        await watchIds([resp.data.scheduleId], {});
      }
    } catch (error) {
      console.error('Failed to remove item:', error);

      // Restore
      const itemToRestore = localItems.find(item => item._id === itemId);
      if (itemToRestore) {
        setLocalItems(prev => [...prev, itemToRestore]);
      }

      toast.error('Failed to remove item');
    } finally {
      setIsSyncing(false);
    }
  };

  // Check for pending operations
  const hasPendingOperations =
    pendingAdditions.size > 0 || beingEdited.size > 0;

  // Check all items are valid
  const allItemsValid = useMemo(() => {
    return localItems.every(isItemValid);
  }, [localItems]);

  return {
    items: processedItems,
    isDirty: hasPendingOperations,
    isSyncing,
    hasValidItems:
      localItems.length > 0 &&
      allItemsValid &&
      !hasPendingOperations &&
      !isSyncing,
    addItem: handleAddItem,
    updateItem: handleUpdateItem,
    removeItem: handleRemoveItem,
  };
}
