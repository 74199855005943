import { api } from '@cvx/api';
import { useQuery } from 'convex/react';
import { Doc } from 'src/convex/_generated/dataModel';
import { UserRole } from 'src/convex/schema/enums/userRole';
import { useImpersonationInfo } from 'src/hooks/useImpersonationInfo';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { FileRoutesByFullPath } from 'src/routeTree.gen';
import { Label } from '../components/label';

export type ValidPaths = keyof FileRoutesByFullPath;

export type NavItem = {
  title: string;
  path: ValidPaths;
  icon?: React.ReactNode;
  children?: NavItem[];
  info?: React.ReactNode;
  roles?: UserRole[];
  defaultExpanded?: boolean;
  allowCompanyAdmin?: boolean;
  disabled?: boolean;
};

export type NavSection = {
  subheader: string;
  items: NavItem[];
  roles?: UserRole[];
  allowCompanyAdmin?: boolean;
};

const hasRequiredRole = (
  userRoles: Doc<'roleDefinitions'>[],
  requiredRoles?: UserRole[],
  isCompanyAdmin?: boolean,
  allowCompanyAdmin?: boolean
) => {
  if (!requiredRoles || requiredRoles.length === 0) return true;

  // Allow company admins if the route specifically allows it
  if (isCompanyAdmin && allowCompanyAdmin) {
    return true;
  }

  return userRoles.some(
    role => role?.type && requiredRoles.includes(role.type)
  );
};

export function useNavConfig() {
  const {
    me,
    impersonatingFleet,
    impersonatingServiceProvider,
    impersonatingThirdParty,
  } = useImpersonationInfo();

  // TODO: Change this to reflect the different contexts... the sub routes under /Requests
  const activeRequestsAll = useQuery(
    api.functions.requests.getActiveRequests,
    {}
  );

  const activeAssignments = activeRequestsAll?.filter(
    r => r.currentAssignedToId === me?._id
  );
  const activeParticipations = activeRequestsAll?.filter(
    r =>
      r.activeFleetDispatcherId === me?._id ||
      r.activeServiceDispatcherId === me?._id
  );

  let activeQueue = activeRequestsAll?.filter(
    r => !r.currentAssignedToId && r.currentStepState === 'QUEUED'
  );

  if (me?.primaryRoleType === 'FLEET_DISPATCHER') {
    activeQueue = activeQueue?.filter(
      r => r.currentStepType === 'DISPATCH_TRIAGE'
    );
  } else if (me?.primaryRoleType === 'SERVICE_DISPATCHER') {
    activeQueue = activeQueue?.filter(
      r => r.currentStepType === 'WITH_SERVICE_PROVIDER_DISPATCH'
    );
  }

  const soleProprietorDriver =
    me?.primaryRoleType === 'DRIVER_FLEET' &&
    me.primaryLocation.noDispatchSoleProprietor;

  const nonSubscribedUser =
    !me?.company.accountExecutiveId && !me?.clerkUser.isSuperAdmin;

  const navData: NavSection[] = [
    {
      subheader: 'General',
      items: [
        {
          title: 'Map',
          path: '/dashboard/map',
          disabled: nonSubscribedUser,
          icon: <Iconify icon="mdi:map-marker-radius-outline" />,
          roles: [
            'FLEET_DISPATCHER',
            'SERVICE_DISPATCHER',
            'THIRD_PARTY_DISPATCHER',
            'FLEET_MANAGER',
            'SUPER_ADMIN',
          ],
        },
        {
          title: 'Requests',
          path: '/dashboard/requests',
          roles: [
            'FLEET_DISPATCHER',
            'SERVICE_DISPATCHER',
            'THIRD_PARTY_DISPATCHER',
            'FLEET_MANAGER',
            'DRIVER',
            'DRIVER_FLEET',
            'TECHNICIAN_PROVIDER',
            'TECHNICIAN',
            'TECHNICIAN_FLEET',
          ],
          icon: <Iconify icon="f7:exclamationmark-bubble" />,
          info: activeRequestsAll && activeRequestsAll.length > 0 && (
            <Label color="secondary">{activeRequestsAll.length}</Label>
          ),
          defaultExpanded: true,
          children: [
            {
              title: 'All',
              path: '/dashboard/requests',
            },
            {
              title: 'Assignments',
              path: '/dashboard/requests/my-assignments',
              info: activeAssignments && activeAssignments.length > 0 && (
                <Label color="success">{activeAssignments.length}</Label>
              ),
            },
            {
              title: 'Queue',
              path: '/dashboard/requests/queue',
              roles: ['FLEET_DISPATCHER', 'SERVICE_DISPATCHER', 'SUPER_ADMIN'],
              info: activeQueue && activeQueue.length > 0 && (
                <Label color="warning">{activeQueue.length}</Label>
              ),
            },
            {
              title: 'Participations',
              path: '/dashboard/requests/my-participations',
              roles: ['FLEET_DISPATCHER', 'SERVICE_DISPATCHER', 'SUPER_ADMIN'],
              info: activeParticipations && activeParticipations.length > 0 && (
                <Label color="secondary">{activeParticipations.length}</Label>
              ),
            },
          ],
        },
        // TODO: chat route not working now, hiding
        // {
        //   title: 'Chat',
        //   path: '/dashboard/chat',
        //   roles: [
        //     'FLEET_DISPATCHER',
        //     'SERVICE_DISPATCHER',
        //     'FLEET_MANAGER',
        //     'DRIVER',
        //     'DRIVER_FLEET',
        //     'TECHNICIAN_PROVIDER',
        //     'TECHNICIAN',
        //     'TECHNICIAN_FLEET',
        //   ],
        //   icon: <Iconify icon="material-symbols:chat" />,
        // },
      ],
    },
    {
      subheader: 'Management',
      items: [
        {
          disabled: nonSubscribedUser,
          title: 'Dispatchers',
          path: '/dashboard/dispatchers',
          icon: <Iconify icon="ic:baseline-send" />,
          roles: [
            ...(me?.primaryLocation.noDispatchSoleProprietor
              ? []
              : (['FLEET_DISPATCHER', 'SERVICE_DISPATCHER'] as UserRole[])),
            'FLEET_MANAGER',
            'THIRD_PARTY_DISPATCHER',
            ...(impersonatingFleet ||
            impersonatingServiceProvider ||
            impersonatingThirdParty
              ? ['SUPER_ADMIN' as UserRole]
              : []),
          ],
        },
        {
          disabled: nonSubscribedUser,
          title: 'Drivers',
          path: '/dashboard/drivers',
          icon: <Iconify icon="mdi:people-outline" />,
          roles: [
            'FLEET_DISPATCHER',
            'FLEET_MANAGER',
            ...(impersonatingFleet ? ['SUPER_ADMIN' as UserRole] : []),
          ],
        },
        {
          disabled: nonSubscribedUser,
          title: 'Units',
          path: '/dashboard/units',
          icon: <Iconify icon="ph:truck" />,
          roles: [
            'FLEET_DISPATCHER',
            'FLEET_MANAGER',
            ...(soleProprietorDriver ? ['DRIVER_FLEET' as UserRole] : []),
            ...(impersonatingFleet ? ['SUPER_ADMIN' as UserRole] : []),
          ],
        },
        {
          disabled: nonSubscribedUser,
          title: 'Mechanics',
          path: '/dashboard/mechanics',
          icon: <Iconify icon="heroicons:wrench-screwdriver" />,
          roles: [
            ...(me?.primaryLocation.noDispatchSoleProprietor
              ? ['FLEET_DISPATCHER' as UserRole] // TODO: Hacky fix to avoid empty array which is treated as "everyone"
              : ['SERVICE_DISPATCHER' as UserRole]),
            ...(impersonatingServiceProvider
              ? ['SUPER_ADMIN' as UserRole]
              : []),
          ],
        },
        {
          disabled: nonSubscribedUser,
          title: 'Organizations',
          path: '/dashboard/companies',
          icon: <Iconify icon="carbon:location-company" />,
          roles: [
            'SUPER_ADMIN',
            'FLEET_DISPATCHER',
            'SERVICE_DISPATCHER',
            'THIRD_PARTY_DISPATCHER',
          ],
          defaultExpanded: true,
          allowCompanyAdmin: true,
          children: [
            {
              disabled: nonSubscribedUser,
              title: 'Companies',
              path: '/dashboard/companies',
              roles: ['SUPER_ADMIN'],
              allowCompanyAdmin: true,
            },
            {
              disabled: nonSubscribedUser,
              title: 'Locations & Groups',
              path: '/dashboard/locations',
              roles: [
                'SUPER_ADMIN',
                'FLEET_DISPATCHER',
                'SERVICE_DISPATCHER',
                'THIRD_PARTY_DISPATCHER',
              ],
            },
          ],
        },
        {
          disabled: nonSubscribedUser,
          title: 'Admin Dashboard',
          path: '/dashboard/admin-dashboard',
          icon: <Iconify icon="solar:chart-2-bold-duotone" />,
          roles: ['SUPER_ADMIN'],
          defaultExpanded: true,
          allowCompanyAdmin: true,
        },
        {
          title: 'Invoices',
          disabled: nonSubscribedUser,
          path: '/dashboard/invoices',
          icon: <Iconify icon="hugeicons:invoice" />,
          roles: [
            'SERVICE_DISPATCHER',
            'THIRD_PARTY_DISPATCHER',
            'FLEET_DISPATCHER',
            ...(soleProprietorDriver ? ['DRIVER_FLEET' as UserRole] : []),
            ...(impersonatingServiceProvider
              ? ['SUPER_ADMIN' as UserRole]
              : []),
          ],
        },
      ],
    },
    {
      subheader: 'Settings',
      items: [
        {
          title: 'Profile',
          path: '/dashboard/settings/profile',
          icon: <Iconify icon="mdi:account" />,
          roles: [],
        },
      ],
    },
  ];

  // Filter nav items based on user roles
  const filteredNav = me?.roles
    ? navData
        .filter(section =>
          hasRequiredRole(
            me.roles,
            section.roles,
            me?.isCompanyPrimaryAdmin,
            section.allowCompanyAdmin
          )
        )
        .map(section => ({
          ...section,
          items: section.items
            .filter(item =>
              hasRequiredRole(
                me.roles,
                item.roles,
                me?.isCompanyPrimaryAdmin,
                item.allowCompanyAdmin
              )
            )
            .map(item => ({
              ...item,
              children: item.children?.filter(child =>
                hasRequiredRole(
                  me.roles,
                  child.roles,
                  me?.isCompanyPrimaryAdmin,
                  item.allowCompanyAdmin
                )
              ),
            })),
        }))
        .filter(section => section.items.length > 0)
    : [];

  return { items: filteredNav };
}
