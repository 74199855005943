import { api } from '@cvx/api';
import { RequestId } from '@cvx/types/entities/sharedIds';
import { StepType } from '@cvx/types/enums/requestEnums';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { keyframes } from '@mui/system';
import { useParams } from '@tanstack/react-router';
import { useQuery } from 'convex/react';
import { STEP_DISPLAY } from 'src/convex/schema/entities/requests/requests';

type TRequestWorkflowStepsProps = {
  currentStep: StepType;
};

const steps = STEP_DISPLAY;

export function RequestWorkflowSteps({
  currentStep,
}: TRequestWorkflowStepsProps) {
  const params = useParams({ from: '/_auth/dashboard/requests/$requestId' });

  const request = useQuery(api.functions.requests.getRequest, {
    requestId: params.requestId as RequestId,
  });

  const getRelevantSteps = () => {
    let actualSteps = { ...steps };

    if (!request?.requiresVehicleLocation) {
      const { DRIVER_CONFIRM_LOCATION: _, ...remainingSteps } = actualSteps;
      actualSteps = remainingSteps as any;
    }
    return Object.entries(actualSteps).map(([key, value]) => ({
      key,
      name: value,
    }));
  };

  const relevantSteps = getRelevantSteps();

  if (!relevantSteps.length) return null;

  const glow = keyframes`
    0% {
      box-shadow: 0 0 4px 2px rgba(25, 118, 210, 0.2),
                  0 0 8px 4px rgba(25, 118, 210, 0.2);
    }
    50% {
      box-shadow: 0 0 8px 4px rgba(25, 118, 210, 0.4),
                  0 0 16px 8px rgba(25, 118, 210, 0.3);
    }
    100% {
      box-shadow: 0 0 4px 2px rgba(25, 118, 210, 0.2),
                  0 0 8px 4px rgba(25, 118, 210, 0.2);
    }
  `;

  return (
    <Card>
      <CardHeader title="Service Request Progress" />
      <Box sx={{ p: 3 }}>
        <Timeline
          sx={{
            p: 0,
            m: 0,
            [`& .${timelineItemClasses.root}:before`]: { flex: 0, padding: 0 },
          }}
        >
          {relevantSteps.map((step, index) => {
            const isLast = index === relevantSteps.length - 1;
            const isCurrent = step.key === currentStep;

            return (
              <TimelineItem key={step.key}>
                <TimelineSeparator>
                  <TimelineDot
                    color={isCurrent ? 'primary' : 'grey'}
                    sx={
                      isCurrent
                        ? {
                            animation: `${glow} 1.5s ease-in-out infinite`,
                            position: 'relative',
                            zIndex: 1,
                          }
                        : undefined
                    }
                  />
                  {!isLast && <TimelineConnector />}
                </TimelineSeparator>

                <TimelineContent>
                  <Typography
                    variant="subtitle2"
                    color={isCurrent ? 'primary.main' : 'text.primary'}
                    fontWeight={isCurrent ? 600 : 400}
                  >
                    {step.name}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      </Box>
    </Card>
  );
}
