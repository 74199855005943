import { useSignIn } from '@clerk/clerk-react';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Stack,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { useForm } from '@tanstack/react-form';
import { useSearch } from '@tanstack/react-router';
import { useState } from 'react';
import { IMaskMixin } from 'react-imask';
import { FormHead } from 'src/components/form/form-head';
import { signUpSchema } from 'src/convex/schema/entities/companies';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { Logo } from 'src/minimal-theme/components/logo';
import { PhoneInput } from 'src/minimal-theme/components/phone-input';
import { MuiTypeSafeLink } from 'src/minimal-theme/components/routes/components/mui-link';
import { CenteredLayout } from 'src/minimal-theme/layouts/centered';
import { z } from 'zod';

// TODO: Gotta fix the validation on this one since moving to the latest tanstack forms.... we gotta figure out better patterns for all of this
type FormData = z.infer<typeof signUpSchema>;

export function SignUpView() {
  const { signIn, isLoaded } = useSignIn();
  const [companyOwner, setCompanyOwner] = useState<boolean | null>(null);
  // const signUp = useAction(api.functions.companies.initialSignUp);
  const [activeStep, setActiveStep] = useState(0);
  const { notSignedUp } = useSearch({
    from: '/sign-up',
  });

  const MaskedStyledInput = IMaskMixin(({ inputRef, ...props }: any) => (
    <TextField {...props} inputRef={inputRef} />
  ));

  const { Field, Subscribe, handleSubmit } = useForm<FormData>({
    defaultValues: {
      ein: '',
      name: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
    onSubmit: async ({ value }) => {
      setActiveStep(2);

      // const success = await signUp(value);

      // if (success) {
      //   signIn?.create({
      //     strategy: 'email_link',
      //     identifier: value.email,
      //     redirectUrl: getAbsoluteLink(signUpFinalLinkOptions.to),
      //   });
      // }
    },
  });

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const STEPS = [
    {
      label:
        companyOwner === null
          ? 'Are you a company owner?'
          : 'Please contact your company owner to create an account',
      description: !notSignedUp && (
        <>
          {`Already have an account? `}
          <MuiTypeSafeLink to="/sign-in" variant="subtitle2">
            Sign In
          </MuiTypeSafeLink>
        </>
      ),
    },

    {
      label: 'Personal Information',
      description:
        'Please provide some information about yourself so that we can create your account.',
    },
    {
      label: 'Please Check Your Email',
      description: 'We sent a link you can use to log in!',
    },
  ];

  const QontoStepIconRoot = styled('div')<{
    ownerState: {
      active?: boolean;
    };
  }>(({ theme, ownerState }) => ({
    height: 22,
    display: 'flex',
    alignItems: 'center',
    color: theme.vars.palette.text.disabled,
    ...(ownerState.active && { color: theme.vars.palette.primary.main }),
    '& .QontoStepIcon-completedIcon': {
      zIndex: 1,
      fontSize: 18,
      color: theme.vars.palette.primary.main,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }));

  function QontoStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    // Force completed state for last step when activeStep is 3
    const isCompleted = completed || (props.icon === 4 && activeStep === 3);

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {isCompleted ? (
          <Iconify
            icon="eva:checkmark-fill"
            className="QontoStepIcon-completedIcon"
            width={24}
            height={24}
          />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.vars.palette.primary.main,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.vars.palette.primary.main,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderRadius: 1,
      borderTopWidth: 3,
      borderColor: theme.vars.palette.divider,
    },
  }));

  const renderForm = (
    <form
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit();
      }}
      style={{ width: '100%' }}
    >
      <Stack spacing={2}>
        {activeStep === 0 && (
          <>
            <Field
              name="name"
              children={({ state, handleChange, handleBlur }) => (
                <TextField
                  label="Company Name"
                  variant="outlined"
                  fullWidth
                  value={state.value}
                  error={!!(state.meta.errors.length && state.meta.isTouched)}
                  helperText="Company name is required and must be greater than 3
                    characters"
                  onChange={e => handleChange(e.target.value)}
                  onBlur={handleBlur}
                />
              )}
            />
            <Field
              name="ein"
              children={({ state, handleChange, handleBlur }) => (
                <MaskedStyledInput
                  label="EIN"
                  mask="00-0000000"
                  value={state.value}
                  error={!!(state.meta.errors.length && state.meta.isTouched)}
                  helperText="EIN should be in the format XX-XXXXXXX (e.g., 12-3456789)"
                  onAccept={(value: string) => handleChange(value)}
                />
              )}
            />
          </>
        )}

        {activeStep === 1 && (
          <>
            <Field
              name="firstName"
              children={({ state, handleChange, handleBlur }) => (
                <TextField
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  value={state.value}
                  error={!!(state.meta.errors.length && state.meta.isTouched)}
                  helperText="First name is required"
                  onChange={e => handleChange(e.target.value)}
                  onBlur={handleBlur}
                />
              )}
            />
            <Field
              name="lastName"
              children={({ state, handleChange, handleBlur }) => (
                <>
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    value={state.value}
                    error={!!(state.meta.errors.length && state.meta.isTouched)}
                    helperText="Last name is required"
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                  />
                </>
              )}
            />
            <Field
              name="email"
              children={({ state, handleChange, handleBlur }) => (
                <TextField
                  label="Email Address"
                  variant="outlined"
                  fullWidth
                  value={state.value}
                  error={!!(state.meta.errors.length && state.meta.isTouched)}
                  helperText="Please provide a valid email address"
                  onChange={e => handleChange(e.target.value)}
                  onBlur={handleBlur}
                />
              )}
            />
            <Field
              name="phone"
              children={({ state, handleChange, handleBlur }) => (
                <PhoneInput
                  required
                  placeholder="(233) 331-2331"
                  fullWidth
                  value={state.value}
                  onChange={newValue => handleChange(newValue || '')}
                  onBlur={handleBlur}
                  error={!!(state.meta.errors.length && state.meta.isTouched)}
                  helperText="Please provide a valid phone number"
                />
              )}
            />
          </>
        )}

        {activeStep === 0 && (
          <Subscribe
            selector={state => [state.canSubmit, state.isTouched]}
            children={([canSubmit, isTouched]) => (
              <>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={handleNext}
                  disabled={!canSubmit || !isTouched}
                >
                  Next
                </Button>
              </>
            )}
          />
        )}

        {/* {activeStep === 1 && (
          <Button variant="contained" size="large" onClick={handleBack}>
            Back
          </Button>
        )} */}

        {activeStep === 1 && (
          <Subscribe
            selector={state => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit, isSubmitting]) => {
              return (
                <LoadingButton
                  size="large"
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={!canSubmit}
                >
                  Sign Up
                </LoadingButton>
              );
            }}
          />
        )}
      </Stack>
    </form>
  );

  if (!isLoaded) {
    return null;
  }

  return (
    <CenteredLayout>
      <Logo sx={{ mx: 'auto', width: '64px', height: '64px' }} />

      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
        sx={{ mb: 3 }}
      >
        {STEPS.map((_, index) => (
          <Step key={index}>
            <StepLabel StepIconComponent={QontoStepIcon}></StepLabel>
          </Step>
        ))}
      </Stepper>

      <FormHead
        title={STEPS[activeStep].label}
        description={STEPS[activeStep].description}
      />

      {companyOwner === null && (
        <>
          <Box display="flex" gap={2} justifyContent="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setCompanyOwner(true)}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setCompanyOwner(false)}
            >
              No
            </Button>
          </Box>
        </>
      )}

      {companyOwner && renderForm}
    </CenteredLayout>
  );
}
