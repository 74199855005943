import { QueryCtx } from '@cvx/server';
import { CompanyId } from '@cvx/types/entities/sharedIds';

export function listAllCompanyServices(ctx: QueryCtx, companyId: CompanyId) {
  return ctx.db
    .query('companyServices')
    .withIndex('by_companyId_and_isActive', q => q.eq('companyId', companyId))
    .collect();
}

export function listActiveCompanyServices(ctx: QueryCtx, companyId: CompanyId) {
  return ctx.db
    .query('companyServices')
    .withIndex('by_companyId_and_isActive', q =>
      q.eq('companyId', companyId).eq('isActive', true)
    )
    .collect();
}
