import { CompanyGroup } from '@cvx/types/entities/groupsEntityTypes';
import { ConvexUser } from '@cvx/types/entities/usersEntityTypes';
import { getManyVia, getOneFrom } from 'convex-helpers/server/relationships';
import { ConvexError } from 'convex/values';
import { Doc } from '../_generated/dataModel';
import { QueryCtx } from '../_generated/server';
import { isUserADispatcher } from '../schema/entities/users';
import { getCustomUserIdentity } from './getCustomUserIdentity';

export type UserContextType = {
  user: ConvexUser;
  primaryLocation: CompanyGroup;
  company: Doc<'companies'>;
  roles: Doc<'roleDefinitions'>[];
  isSuperAdmin: boolean;
  companyOwner: boolean;
  isDispatcher: boolean;
};

export async function getUserContext(ctx: QueryCtx): Promise<UserContextType> {
  const identity = await getCustomUserIdentity(ctx);
  if (!identity) throw new Error('User not authenticated');

  const { isSuperAdmin, companyOwner, subject } = identity;

  const user = await userQueryByClerkId(ctx, subject);

  if (!user) throw new ConvexError('User not found');

  let companyId = user.companyId!;
  let primaryLocationGroupId = user.primaryLocationGroupId!;

  if (isSuperAdmin) {
    if (user.impersonatingCompanyId) {
      companyId = user.impersonatingCompanyId;
    }
    if (user.impersonatingLocationId) {
      primaryLocationGroupId = user.impersonatingLocationId;
    }
  }

  const [roles, company, primaryLocation] = await Promise.all([
    getManyVia(ctx.db, 'userRoles', 'roleDefinitionId', 'by_userId', user._id),
    ctx.db.get(companyId),
    ctx.db.get(primaryLocationGroupId),
  ]);

  const nonNullRoles = roles.filter(
    (s): s is NonNullable<typeof s> => s !== null
  );
  const isDispatcher = isUserADispatcher(nonNullRoles);

  if (!company || !primaryLocation) {
    throw new ConvexError('User must belong to a company and primary location');
  }

  return {
    user,
    primaryLocation,
    company,
    roles: nonNullRoles,
    isSuperAdmin,
    companyOwner,
    isDispatcher,
  };
}

// TODO: Dumb temporary fix to get around circular deps when trying to access user context in triggers
// getUser is in a file important zQuery, etc..
export async function userQueryByClerkId(ctx: QueryCtx, subject: string) {
  return await getOneFrom(
    ctx.db,
    'users',
    'by_clerkId',
    subject,
    'clerkUser.id'
  );
}
