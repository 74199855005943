import {
  CompanyId,
  GroupId,
  ServiceCategoryId,
  VendorServiceId,
} from '@cvx/types/entities/sharedIds';
import { GroupZodId } from '@cvx/types/zod/commonZodId';
import { Doc } from '../_generated/dataModel';
import { MutationCtx } from '../_generated/server';
import { listServiceCategories } from '../model/serviceCategories/queries/serviceCategoryPublicQueries';
import { getServiceChanges } from '../model/shared/helpers/serviceHelpers';
import { getUserContext } from '../utils/getUserContext';
import { zQuery } from './helpers/zodHelpers';

export const getServiceCategories = zQuery({
  handler: ctx => {
    return listServiceCategories(ctx);
  },
});

// TODO: This shouldn't really be in here, will require planning to move to a more appropriate location
export const getLocationServices = zQuery({
  args: {
    locationId: GroupZodId,
  },
  handler: async (ctx, args) => {
    return await ctx.db
      .query('locationServices')
      .withIndex('by_groupId_and_isActive', q =>
        q.eq('groupId', args.locationId).eq('isActive', true)
      )
      .collect();
  },
});

// Query to get services organized by categories
export const getServicesGroupedByCategory = zQuery({
  handler: async (ctx): Promise<ServiceGroup[]> => {
    await getUserContext(ctx);

    // First get all categories
    const categories = await ctx.db
      .query('serviceCategories')
      .filter(q => q.eq(q.field('deletedAt'), undefined))
      .collect();

    // Then get all services
    const services = await ctx.db
      .query('services')
      .filter(q => q.eq(q.field('deletedAt'), undefined))
      .collect();

    // Split services into categorized and standalone
    const categorizedServices = services.filter(
      service => service.serviceCategoryId
    );
    const standaloneServices = services.filter(
      service => !service.serviceCategoryId
    );

    // Group categorized services
    const servicesGroupedByCategory = [
      // Add standalone services as first "category"
      {
        category: {
          _id: 'standalone' as ServiceCategoryId,
          name: 'General Services',
          description: 'Standalone services',
          systemGenerated: true,
        },
        services: standaloneServices,
      },
      // Add all regular categories with their services
      ...categories.map(category => ({
        category,
        services: categorizedServices.filter(
          service => service.serviceCategoryId === category._id
        ),
      })),
    ];

    return servicesGroupedByCategory;
  },
});

export type ServiceGroup = {
  category: {
    _id: ServiceCategoryId;
    name: string;
    description: string;
    systemGenerated?: boolean;
  };
  services: Doc<'services'>[];
};

export async function updateLocationServices(
  ctx: MutationCtx,
  locationId: GroupId,
  serviceIds: VendorServiceId[],
  companyId: CompanyId
) {
  const existingServices = await ctx.db
    .query('locationServices')
    .withIndex('by_groupId', q => q.eq('groupId', locationId))
    .collect();

  const { servicesToAdd, servicesToDeactivate } = getServiceChanges(
    existingServices,
    serviceIds
  );

  for (const serviceId of servicesToAdd) {
    await ctx.db.insert('locationServices', {
      serviceId,
      groupId: locationId,
      companyId,
      isActive: true,
    });
  }

  for (const service of servicesToDeactivate) {
    await ctx.db.patch(service._id, {
      isActive: false,
    });
  }
}
