import { CompanyZodId } from '@cvx/types/zod/commonZodId';
import { zodToConvex } from 'convex-helpers/server/zod';
import { defineTable } from 'convex/server';
import { z } from 'zod';

export const subscriptionPlanSchema = z
  .enum(['basic', 'premium', 'enterprise', 'none'])
  .default('none');

export const subscriptionStatusSchema = z
  .enum(['active', 'inactive', 'trial', 'expired', 'cancelled', 'none'])
  .default('none');

export const subscriptionRateSchema = z.object({
  perUnit: z.number(),
  perTech: z.number(),
  perTransaction: z.number(),

  perCallCenterDispatcher: z.number(),
  perServiceProviderLocationsInDirectory: z.number(),
});

export const subscriptionInfoSchema = z.object({
  subscriptionPlan: subscriptionPlanSchema,
  subscriptionStatus: subscriptionStatusSchema, // TODO: I don't want to make these optional, but it solves the type discrepency where the zod schema says they are one of the values but the return from use query says otherwise...
  subscriptionStartDate: z.number(),
  subscriptionEndDate: z.number().nullable(),

  rates: subscriptionRateSchema,
});

// TODO: We need to get rid of this, purely for backwards compatibility, switching from group level to company leve
export const groupSubscriptionInfoSchema = z.object({
  subscriptionPlan: subscriptionPlanSchema.optional(),
  subscriptionStatus: subscriptionStatusSchema.optional(), // TODO: I don't want to make these optional, but it solves the type discrepency where the zod schema says they are one of the values but the return from use query says otherwise...
  subscriptionStartDate: z.number().optional(),
  subscriptionEndDate: z.number().optional(),
});

const subscriptionHistorySchema = subscriptionInfoSchema.extend({
  companyId: CompanyZodId,
});

export const subscriptionsHistory = defineTable(
  zodToConvex(subscriptionHistorySchema).fields
).index('by_companyId', ['companyId']);
