import { createFileRoute } from '@tanstack/react-router';
import StripeRefreshPage from 'src/pages/dashboard/invoice/refresh';
import {
  checkRouteAccess,
  routeAccessConfigs,
} from 'src/utils/checkRouteAccess';

export const Route = createFileRoute('/_auth/dashboard/invoices/stripe-reauth')(
  {
    beforeLoad: ({ context }) =>
      checkRouteAccess(context, routeAccessConfigs.dispatcherGeneral),
    component: StripeRefreshPage,
  }
);
