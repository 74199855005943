import { CompanyGroup } from '@cvx/types/entities/groupsEntityTypes';
import { ServiceRequest } from '@cvx/types/entities/requestsEntityTypes';
import { ConvexUser } from '@cvx/types/entities/usersEntityTypes';
import { phoneSchema } from '@cvx/types/zod/commonZod';
import { ConvexUserZodId } from '@cvx/types/zod/commonZodId';
import { servicesRequiredSchema } from '@cvx/types/zod/requestsZod';
import { tempVehicleInfoSchema } from '@cvx/types/zod/vehiclesZod';
import { z } from 'zod';
import { Doc } from '../../../_generated/dataModel';
import { baseSubmissionSchema } from './base';

export function getServiceDispatcherDraftInitiationFields(
  user: ConvexUser,
  company: Doc<'companies'>,
  primaryLocation: CompanyGroup
): Partial<ServiceRequest> {
  return {
    mechanicDispatchGroupId: primaryLocation._id,
    activeServiceDispatcherId: user._id,
    serviceProviderCompanyId: company._id,
  };
}

export function getServiceTechnicianDraftInitiationFields(
  user: ConvexUser,
  company: Doc<'companies'>,
  primaryLocation: CompanyGroup
): Partial<ServiceRequest> {
  return {
    mechanicServiceGroupId: primaryLocation._id,
    activeTechnicianId: user._id,
    mechanicDispatchGroupId: primaryLocation._id,
    activeServiceDispatcherId: user._id,
    serviceProviderCompanyId: company._id,
  };
}

const serviceSubmissionBaseInput = z.object({
  // For active driver selection
  activeDriverId: ConvexUserZodId.optional(),

  // Driver info - conditionally required
  tempDriverFirstName: z.string().optional(),
  tempDriverLastName: z.string().optional(),
  tempDriverPhone: phoneSchema.optional(),
  tempDriverEmail: z.string().email().optional(),
  isDriverCalling: z.boolean(),
  isNoActiveDriver: z.boolean().optional(),
  // For active dispatcher selection
  activeFleetDispatcherId: ConvexUserZodId.optional(),

  // Dispatcher info - conditionally required
  tempFleetDispatchFirstName: z.string().optional(),
  tempFleetDispatchLastName: z.string().optional(),
  tempFleetDispatchPhone: phoneSchema.optional(),
  tempFleetDispatchEmail: z.string().email().optional(),

  // Location info
  address: z.string().optional(),
  longitude: z.number().optional(),
  latitude: z.number().optional(),

  description: z.string(),
  fleetReferenceNumber: z.string().optional(),
  tempVehicleUnitNumber: z.string().optional(),

  servicesRequiredByFleet: servicesRequiredSchema,

  // Vehicle info
  tempVehicleInfo: tempVehicleInfoSchema,
});

export const serviceSubmissionSchema = baseSubmissionSchema.merge(
  serviceSubmissionBaseInput
);

export type ServiceSubmissionInput = z.infer<typeof serviceSubmissionBaseInput>;

// Then apply refinements after the merge
export const refinedServiceSubmissionSchema = serviceSubmissionSchema
  .refine(
    data => {
      if (!data.isNoActiveDriver) {
        // If we have an activeDriverId, no need to validate temp driver fields
        if (data.activeDriverId) {
          return true;
        }

        // Otherwise, ensure temp driver fields are filled
        return (
          !!data.tempDriverFirstName &&
          data.tempDriverFirstName.length > 0 &&
          !!data.tempDriverLastName &&
          data.tempDriverLastName.length > 0 &&
          !!data.tempDriverPhone
        );
      }
      return true;
    },
    {
      message: 'Driver information is required',
      path: ['tempDriverFirstName'],
    }
  )
  .refine(
    data => {
      // If driver is calling directly, no need to validate dispatcher fields
      if (data.isDriverCalling) {
        return true;
      }

      // If we have an activeFleetDispatcherId, no need to validate temp dispatcher fields
      if (data.activeFleetDispatcherId) {
        return true;
      }

      // Otherwise, ensure temp dispatcher fields are filled
      return (
        !!data.tempFleetDispatchFirstName &&
        data.tempFleetDispatchFirstName.length > 0 &&
        !!data.tempFleetDispatchLastName &&
        data.tempFleetDispatchLastName.length > 0 &&
        !!data.tempFleetDispatchPhone
      );
    },
    {
      message: 'Dispatcher information is required',
      path: ['tempFleetDispatchFirstName'],
    }
  );

export type RefinedServiceSubmissionInput = z.infer<
  typeof refinedServiceSubmissionSchema
>;
