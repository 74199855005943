import { createFileRoute } from '@tanstack/react-router';
import { ValidPaths } from 'src/minimal-theme/layouts/config-nav-dashboard';
import Page from 'src/pages/dashboard/unit/list';
import {
  checkRouteAccess,
  routeAccessConfigs,
} from 'src/utils/checkRouteAccess';

export const Route = createFileRoute('/_auth/dashboard/units/')({
  beforeLoad: ({ context, location }) => {
    console.log(`LOCATION`, location);
    return checkRouteAccess(
      context,
      routeAccessConfigs.fleetOnly,
      location.pathname as ValidPaths
    );
  },
  component: Page,
});
