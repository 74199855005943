import { api } from '@cvx/api';
import { EnhancedRequest } from '@cvx/types/entities/requestsEntityTypes';
import {
  ServiceCategoryId,
  VendorServiceId,
} from '@cvx/types/entities/sharedIds';
import { Box, ListItemText, Stack, Typography } from '@mui/material';
import { useQuery } from 'convex/react';
import { Doc } from 'src/convex/_generated/dataModel';
import { ServiceGroup } from 'src/convex/functions/serviceCategories';
import { EnhancedUser } from 'src/convex/functions/users';
import { getVehicleDisplayName } from 'src/convex/schema/entities/vehicles';
import { getFriendlyPaymentMethodName } from 'src/convex/schema/enums/paymentMethod';
import { Label } from 'src/minimal-theme/components/label';
import { RequestDetailsCard } from 'src/sections/request/RequestDetailsCard';
import { RequestLocationCard } from 'src/sections/request/RequestLocationCard';
import { RequestMapDisplay } from 'src/sections/request/RequestMapDisplay';

type RequestDisplayViewProps = {
  request: EnhancedRequest;
  me: EnhancedUser;
};

export const RequestDisplayView = ({
  request,
  me,
}: RequestDisplayViewProps) => {
  const services = useQuery(
    api.functions.serviceCategories.getServicesGroupedByCategory,
    {}
  );

  let fleetReferenceNumber = request.fleetReferenceNumber ?? 'N/A';
  let tempVehicleUnitNumber = request.tempVehicleUnitNumber ?? 'N/A';
  const companyName = `${request.requesterLocationName ?? ''}${request.requesterLocationName ? ' - ' : ''}${request.requesterCompanyName ?? ''}`;

  if (fleetReferenceNumber.length === 0) {
    fleetReferenceNumber = 'N/A';
  }

  if (tempVehicleUnitNumber.length === 0) {
    tempVehicleUnitNumber = 'N/A';
  }

  const { servicesRequiredByFleet } = request;

  const isThereADriver =
    request.activeDriver?.clerkUser.fullName || request.tempDriverPhone;

  const driverName = isThereADriver
    ? (request.activeDriver?.clerkUser.fullName ??
      `${request.tempDriverFirstName} ${request.tempDriverLastName}`)
    : `No Driver`;

  return (
    <>
      <RequestDetailsCard subheader="" detailViewOnly>
        <Stack spacing={2}>
          <ListItemText primary="Company" secondary={companyName} />
          {servicesRequiredByFleet && services && (
            <ListItemText
              primary="Services Required"
              secondary={formatSelectedServices(
                servicesRequiredByFleet,
                services,
                request.tireSize
              )}
              secondaryTypographyProps={{ component: 'div' }}
            />
          )}
          {request.description && (
            <ListItemText
              primary="Additional Notes"
              secondary={request.description}
            />
          )}
          <ListItemText
            primary="Fleet Reference #"
            secondary={fleetReferenceNumber}
          />
          <ListItemText
            primary="Unit"
            secondary={
              request.vehicle
                ? getVehicleDisplayName(request.vehicle)
                : `VIN: ${request.tempVehicleInfo?.vin}, Unit #: ${tempVehicleUnitNumber}`
            }
          />
          <ListItemText primary="Driver" secondary={driverName} />

          {request.nationalAccountNumber && (
            <ListItemText
              primary="National Account Number"
              secondary={request.nationalAccountNumber}
            />
          )}
          {request.paymentMethod && (
            <ListItemText
              primary="Payment Method"
              secondary={getFriendlyPaymentMethodName(request.paymentMethod)}
            />
          )}
        </Stack>
      </RequestDetailsCard>

      <RequestLocationCard subheader="">
        <Box columnGap={2} rowGap={3} display="grid">
          <ListItemText
            primary="Address"
            secondary={request.address}
            primaryTypographyProps={{ component: 'div' }}
            secondaryTypographyProps={{ component: 'div' }}
          />
          {request.address && (
            <RequestMapDisplay
              driverVehicle={request.vehicle}
              technician={request.technician}
              coordinatesOverride={
                !!request.longitude && !!request.latitude
                  ? {
                      longitude: request.longitude,
                      latitude: request.latitude,
                    }
                  : undefined
              }
            />
          )}
        </Box>
      </RequestLocationCard>
    </>
  );
};

const formatSelectedServices = (
  servicesRequired: VendorServiceId[],
  groupedServices: ServiceGroup[],
  tireSize?: string
) => {
  // Get flattened map of all services for quick lookup
  const servicesById = new Map(
    groupedServices.flatMap(group =>
      group.services.map(service => [
        service._id,
        { ...service, category: group.category },
      ])
    )
  );

  // Group selected services by their categories
  const selectedByCategory = servicesRequired.reduce(
    (acc, serviceId) => {
      const service = servicesById.get(serviceId);
      if (!service) return acc;

      const categoryId = service.category._id;
      if (!acc[categoryId]) {
        acc[categoryId] = {
          categoryName: service.category.name,
          services: [],
        };
      }
      acc[categoryId].services.push(service);
      return acc;
    },
    {} as Record<
      ServiceCategoryId,
      { categoryName: string; services: Doc<'services'>[] }
    >
  );

  return (
    <Stack spacing={1}>
      {Object.values(selectedByCategory).map(group => (
        <Stack key={group.categoryName}>
          <Typography variant="subtitle2" sx={{ mb: 0.5, fontSize: '.8rem' }}>
            {group.categoryName}
          </Typography>
          <Stack direction="row" spacing={0.5} flexWrap="wrap" gap={0.5}>
            {group.services.map(service => (
              <Label key={service._id} color="warning">
                {service.name}
              </Label>
            ))}
          </Stack>
        </Stack>
      ))}
      {tireSize && (
        <Stack>
          <Typography variant="subtitle2" sx={{ mb: 0.5, fontSize: '.8rem' }}>
            Tire Size
          </Typography>
          <Stack direction="row" spacing={0.5} flexWrap="wrap" gap={0.5}>
            <Label key={tireSize} color="warning">
              {tireSize}
            </Label>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
