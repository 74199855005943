import { ServiceRequest } from '@cvx/types/entities/requestsEntityTypes';
import { Collapse, Grid, ListItemText, Paper, Stack } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { HighlightText } from 'src/components/text/HighlightText';
import {
  getOrganizedComponents,
  getRequestSearchComponentDisplay,
} from 'src/convex/schema/entities/requests/requests';
import { usePopover } from 'src/minimal-theme/components/custom-popover';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { Label } from 'src/minimal-theme/components/label';
import { MuiTypeSafeLink } from 'src/minimal-theme/components/routes/components/mui-link';
import { useBoolean } from 'src/minimal-theme/hooks/use-boolean';
import { RequestSearchHit } from 'src/sections/request/view';
import { fDate, fTime } from 'src/utils/format-time';
import { getRequestStatusColor } from 'src/utils/helper';

type TRequestTableRowProps = {
  row: ServiceRequest;
  selected: boolean;
  onViewRow: () => void;
  onSelectRow: () => void;
  onDeleteRow: () => void;
  searchHits: Map<string, RequestSearchHit[]>;
};

export function RequestTableRow({
  row,
  selected,
  onViewRow,
  onSelectRow,
  onDeleteRow,
  searchHits,
}: TRequestTableRowProps) {
  const confirm = useBoolean();

  const hasHits = searchHits.has(row._id);

  const collapse = useBoolean(hasHits);

  const popover = usePopover();

  const renderPrimary = (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox
          checked={selected}
          onClick={onSelectRow}
          inputProps={{
            id: `row-checkbox-${row._id}`,
            'aria-label': `Row checkbox`,
          }}
        />
      </TableCell>

      <TableCell>
        <MuiTypeSafeLink
          to="/dashboard/requests/$requestId"
          params={{ requestId: row._id }}
          color="inherit"
          underline="always"
          sx={{ cursor: 'pointer' }}
        >
          <HighlightText
            text={row.caseNumber}
            matches={(searchHits.get(row._id) ?? [])
              .filter(hit => hit.type === 'CASE_NUMBER')
              .flatMap(hit => hit.matches)} // TODO: Not quite working
          />
        </MuiTypeSafeLink>
      </TableCell>

      <TableCell>{row.address}</TableCell>

      <TableCell>{row.requesterCompanyName ?? ''}</TableCell>

      <TableCell>
        <Label variant="soft" color={getRequestStatusColor(row.status)}>
          {row.status}
        </Label>
      </TableCell>

      <TableCell>
        {fDate(row.createdAt)}, {fTime(row.createdAt)}
      </TableCell>

      <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
        <IconButton
          color={collapse.value ? 'inherit' : 'default'}
          onClick={hasHits ? () => {} : collapse.onToggle}
          sx={{ ...(collapse.value && { bgcolor: 'action.hover' }) }}
        >
          <Iconify icon="eva:arrow-ios-downward-fill" />
        </IconButton>

        {/* <IconButton
          color={popover.open ? 'inherit' : 'default'}
          onClick={popover.onOpen}
        >
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton> */}
      </TableCell>
    </TableRow>
  );

  const renderSecondary = (
    <TableRow>
      <TableCell sx={{ p: 0, border: 'none' }} colSpan={8}>
        <Collapse
          in={hasHits ? true : collapse.value}
          timeout="auto"
          unmountOnExit
        >
          <Paper sx={{ m: 1.5 }}>
            {hasHits ? (
              // Show only search hits
              [...(searchHits.get(row._id)?.values() || [])].map(hit => (
                <Stack
                  key={hit.text}
                  direction="row"
                  alignItems="center"
                  sx={{
                    p: theme => theme.spacing(1.5, 2, 1.5, 1.5),
                    '&:not(:last-of-type)': {
                      borderBottom: theme =>
                        `solid 2px ${theme.vars.palette.background.neutral}`,
                    },
                  }}
                >
                  <ListItemText
                    primary={getRequestSearchComponentDisplay(hit.type)}
                    secondary={
                      <HighlightText text={hit.text} matches={hit.matches} />
                    }
                    primaryTypographyProps={{
                      component: 'div',
                      sx: {
                        color: 'text.primary',
                        fontSize: '0.8rem',
                        fontWeight: 700,
                      },
                    }}
                    secondaryTypographyProps={{
                      component: 'div',
                      sx: {
                        fontSize: '0.8125rem',
                        ...(hit.type === 'DESCRIPTION' && {
                          whiteSpace: 'pre-wrap',
                          display: '-webkit-box',
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                        }),
                      },
                    }}
                  />
                </Stack>
              ))
            ) : (
              // Show all search components when manually expanded
              <Stack spacing={1} sx={{ p: 2 }}>
                <Grid container spacing={2}>
                  {getOrganizedComponents(row.searchComponents).map(
                    component => (
                      <Grid
                        item
                        key={`${component.type}-${component.text}`}
                        // Description spans full width, others take 1/3 of space with responsive fallbacks
                        xs={12}
                        sm={component.type === 'DESCRIPTION' ? 12 : 6}
                        md={component.type === 'DESCRIPTION' ? 12 : 4}
                      >
                        <ListItemText
                          primary={getRequestSearchComponentDisplay(
                            component.type
                          )}
                          secondary={component.text}
                          primaryTypographyProps={{
                            component: 'div',
                            sx: {
                              color: 'text.primary',
                              fontSize: '0.8rem',
                              fontWeight: 700,
                            },
                          }}
                          secondaryTypographyProps={{
                            component: 'div',
                            sx: {
                              fontSize: '0.8125rem',
                              ...(component.type === 'DESCRIPTION' && {
                                whiteSpace: 'pre-wrap',
                                display: '-webkit-box',
                                WebkitLineClamp: 3,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                              }),
                            },
                          }}
                        />
                      </Grid>
                    )
                  )}
                </Grid>
              </Stack>
            )}
          </Paper>
        </Collapse>
      </TableCell>
    </TableRow>
  );

  return (
    <>
      {renderPrimary}
      {renderSecondary}

      {/* <CustomPopover
        open={popover.open}
        anchorEl={popover.anchorEl}
        onClose={popover.onClose}
        slotProps={{ arrow: { placement: 'right-top' } }}
      >
        <MenuList>
          <MenuItem
            onClick={() => {
              confirm.onTrue();
              popover.onClose();
            }}
            sx={{ color: 'error.main' }}
          >
            <Iconify icon="solar:trash-bin-trash-bold" />
            Delete
          </MenuItem>

          <MenuItem
            onClick={() => {
              onViewRow();
              popover.onClose();
            }}
          >
            <Iconify icon="solar:eye-bold" />
            View
          </MenuItem>
        </MenuList>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      /> */}
    </>
  );
}
