import { api } from '@cvx/api';
import { ConvexUserId, RequestId } from '@cvx/types/entities/sharedIds';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { useParams } from '@tanstack/react-router';
import { useMutation, useQuery } from 'convex/react';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { ActionConfirmationDialog } from 'src/sections/request/actions/ActionConfirmationDialog';
import { useActionConfirmationDialog } from 'src/sections/request/actions/hooks/useActionConfirmationDialog';

interface RequestFindMechanicProps {
  open: boolean;
  onClose: () => void;
  inviteMechanic: () => void;
  onError?: (error: string) => void;
}

export const RequestFindMechanic = ({
  open,
  onClose,
  inviteMechanic,
  onError,
}: RequestFindMechanicProps) => {
  const params = useParams({ from: '/_auth/dashboard/requests/$requestId' });
  const requestId = params.requestId as RequestId;
  const [selectedMechanic, setSelectedMechanic] = useState<ConvexUserId | null>(
    null
  );

  const {
    confirmAction,
    isOpen,
    onClose: onCloseConfirmationDialog,
    pendingAction,
  } = useActionConfirmationDialog();

  const [isLoading, setIsLoading] = useState(false);

  const me = useQuery(api.functions.users.getMe);

  // Use "skip" string instead of null
  const mechanics = useQuery(
    api.functions.requests.getAvailableTechnicians,
    open ? { requestId } : 'skip'
  );

  const assignMechanic = useMutation(api.functions.requests.assignTechnician);

  const setLocationToSoleProprietorship = useMutation(
    api.functions.companies.setServiceLocationToSoleProprieter
  );

  const assignToSelf = useMutation(
    api.functions.requests.claimServiceDispatchRequest
  );

  const handleChangeLocationToSoleProprietorship = async () => {
    const resp = await setLocationToSoleProprietorship({});

    if (resp.success) {
      toast.success('Location set to Sole Proprietorship');
    }
  };

  const handleConfirmSoleProprietorChange = () => {
    confirmAction({
      title: `Set Location to Sole Proprietorship`,
      message:
        "Are you sure you're the sole mechanic in your organization and there are no dispatchers?",
      onConfirm: () => handleChangeLocationToSoleProprietorship(),
    });
  };

  // Reset selection when dialog closes
  useEffect(() => {
    if (!open) {
      setSelectedMechanic(null);
    }
  }, [open]);

  // Handle mechanics query error
  useEffect(() => {
    if (mechanics instanceof Error && open) {
      onError?.(mechanics.message || 'Failed to load technicians');
      onClose();
    }
  }, [mechanics, open, onError, onClose]);

  const handleAssignMechanic = async () => {
    if (!selectedMechanic) return;

    setIsLoading(true);
    try {
      const res = await assignMechanic({
        requestId: requestId,
        technicianId: selectedMechanic,
      });

      if (res.success) {
        toast.success('Mechanic assigned');
        onClose();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error('Failed to assign mechanic');
      onError?.(
        error instanceof Error ? error.message : 'Unknown error occurred'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleAssignToSelf = async () => {
    setIsLoading(true);
    try {
      const res = await assignToSelf({
        requestId: requestId,
      });

      if (res.success) {
        toast.success('Assigned');
        onClose();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error('Failed to assign');
      onError?.(
        error instanceof Error ? error.message : 'Unknown error occurred'
      );
    } finally {
      setIsLoading(false);
    }
  };

  // TODO : currently if no technicians within service area, show all technicians

  const availableMechanics = mechanics?.techniciansWithDistance || [];
  const allMechanics = mechanics?.technicians || [];

  // Notify user if no technicians with distance are available
  const mechanicsToShow =
    availableMechanics.length > 0 ? availableMechanics : allMechanics;
  // const showNotification = availableMechanics.length === 0;

  if (mechanics instanceof Error) {
    return null;
  }

  const askIfSoleProprietor =
    mechanics &&
    mechanics.techniciansWithDistance.length === 0 &&
    !me?.company.accountExecutiveId &&
    !me?.primaryLocation.noDispatchSoleProprietor;

  const assignToSelfAvailable =
    me?.primaryLocation.noDispatchSoleProprietor &&
    !!me?.roles.find(r => r?.type === 'TECHNICIAN_PROVIDER');

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { maxWidth: 500 } }}
    >
      <DialogTitle>Find Mechanic</DialogTitle>

      <DialogContent>
        {/* {showNotification && (
          <Alert severity="warning">
            No nearby mechanics available. Showing all mechanics.
          </Alert>
        )} */}
        {mechanics && !assignToSelfAvailable && (
          <Autocomplete
            fullWidth
            options={mechanicsToShow}
            getOptionLabel={option =>
              `${option.clerkUser.firstName} ${option.clerkUser.lastName}`
            }
            value={
              mechanicsToShow.find(m => m._id === selectedMechanic) || null
            }
            onChange={(event, newValue) => {
              setSelectedMechanic(newValue?._id || null);
            }}
            renderInput={params => (
              <TextField {...params} label="Mechanic" required />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option._id}>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <Avatar src={option.clerkUser.imageUrl} />
                  {option.clerkUser.firstName} {option.clerkUser.lastName}{' '}
                  {'distance' in option &&
                    `(${option.distance === Infinity ? 'Missing Location)' : `${(option.distance as number).toFixed(1)} miles away)`}`}
                </Box>
              </li>
            )}
            sx={{ mt: 1 }}
          />
        )}
        {askIfSoleProprietor && (
          <ListItemButton
            sx={{ mt: 2, bgcolor: 'rgba(0, 184, 217, 0.08)', borderRadius: 3 }}
            onClick={handleConfirmSoleProprietorChange}
          >
            <ListItemIcon>
              <Iconify icon="solar:question-circle-bold" width={32} />
            </ListItemIcon>
            <ListItemText
              primary="I am the sole mechanic in my organization"
              secondary="Click this button to configure your location as a sole proprietorship to eliminate some 'dispatch' steps"
            />
          </ListItemButton>
        )}
        {!assignToSelfAvailable && (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mt: 2, textAlign: 'center' }}
          >
            Can't find the mechanic you're looking for?
            <Link
              onClick={() => {
                onClose();
                inviteMechanic();
              }}
              sx={{
                cursor: 'pointer',
                textDecoration: 'none !important',
                ml: 1,
              }}
            >
              Invite a mechanic
            </Link>
          </Typography>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        {assignToSelfAvailable ? (
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            disabled={isLoading}
            loading={isLoading}
            onClick={handleAssignToSelf}
          >
            Assign To Myself
          </LoadingButton>
        ) : (
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            disabled={!selectedMechanic || isLoading}
            loading={isLoading}
            onClick={handleAssignMechanic}
          >
            Assign
          </LoadingButton>
        )}
      </DialogActions>
      <ActionConfirmationDialog
        open={isOpen}
        onClose={onCloseConfirmationDialog}
        {...pendingAction}
      />
    </Dialog>
  );
};
