import type { LoadedClerk, UserResource } from '@clerk/types';
import { CssBaseline } from '@mui/material';
import * as Sentry from '@sentry/react';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import { ConvexReactClient } from 'convex/react';
import { lazy, Suspense } from 'react';
import { GlobalErrorFallbackDialog } from 'src/components/global/GlobalErrorFallbackDialog';
import { LocationTrackingProvider } from 'src/context/LocationTrackingProvider';
import { EnhancedUser } from 'src/convex/functions/users';
import { useReauthentication } from 'src/hooks/useReauthentication';
import { MotionLazy } from 'src/minimal-theme/components/animate/motion-lazy';
import { ProgressBar } from 'src/minimal-theme/components/progress-bar';
import { SettingsDrawer } from 'src/minimal-theme/components/settings/drawer/settings-drawer';
import { Snackbar } from 'src/minimal-theme/components/snackbar';
import { SignInView } from 'src/sections/sign-in/view/sign-in-view';
import { ThemeProvider } from '../minimal-theme/theme/theme-provider';

const TanStackRouterDevtools = import.meta.env.DEV
  ? lazy(() =>
      import('@tanstack/router-devtools').then(res => ({
        default: res.TanStackRouterDevtools,
        // For Embedded Mode
        // default: res.TanStackRouterDevtoolsPanel
      }))
    )
  : () => null;

export type RouterContext = {
  convex: ConvexReactClient;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  user?: UserResource | null;
  clerk?: LoadedClerk;
  me?: EnhancedUser;
  impersonatingServiceProvider: boolean;
  impersonatingThirdParty: boolean;
  impersonatingFleet: boolean;
};
export const Route = createRootRouteWithContext<RouterContext>()({
  component: RootComponent,
});

function RootComponent() {
  // TODO: I don't think this is working
  const { showReauthDialog, closeReauthDialog, isLoading } =
    useReauthentication();

  return (
    <>
      <ThemeProvider>
        <MotionLazy>
          <ProgressBar />
          <Snackbar />
          <SettingsDrawer />
          <CssBaseline />
          <Sentry.ErrorBoundary
            fallback={({ error, componentStack, resetError, eventId }) => (
              <GlobalErrorFallbackDialog
                error={error as any}
                componentStack={componentStack}
                resetError={resetError}
                eventId={eventId}
              />
            )}
          >
            {!isLoading && showReauthDialog && (
              <SignInView dialogMode closeDialog={closeReauthDialog} />
            )}
            <LocationTrackingProvider>
              <Outlet />
            </LocationTrackingProvider>
          </Sentry.ErrorBoundary>
          {/* <GlobalImpersonationSnackbar /> */}
        </MotionLazy>
      </ThemeProvider>
      <Suspense>
        <TanStackRouterDevtools position="bottom-right" />
      </Suspense>
    </>
  );
}
