import { useCallback, useState } from 'react';
import { CONFIG } from 'src/config-global';

type DirectionsResponse = {
  routes: {
    duration: number;
    geometry: {
      coordinates: [number, number][];
    };
  }[];
};

export const useMapDirections = () => {
  const [routeGeometry, setRouteGeometry] = useState<any>(null);
  const [travelTime, setTravelTime] = useState<number | null>(null);

  const getDirections = useCallback(
    async (start: [number, number], end: [number, number]) => {
      try {
        const response = await fetch(
          `https://api.mapbox.com/directions/v5/mapbox/driving/${start[0]},${start[1]};${end[0]},${end[1]}?geometries=geojson&access_token=${CONFIG.mapboxApiKey}`
        );
        const data: DirectionsResponse = await response.json();

        if (data.routes && data.routes[0]) {
          setRouteGeometry({
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: data.routes[0].geometry.coordinates,
            },
          });
          setTravelTime(Math.round(data.routes[0].duration / 60));
        }
      } catch (error) {
        console.error('Error getting directions:', error);
      }
    },
    []
  );

  return { routeGeometry, travelTime, getDirections };
};
