import { EnhancedRequest } from '@cvx/types/entities/requestsEntityTypes';
import { Card, CardHeader, Stack } from '@mui/material';
import { StripeSetupAlerts } from 'src/components/stripe/StripeSetupAlerts';
import { InvoiceWithItems } from 'src/convex/functions/invoices';
import { EnhancedUser } from 'src/convex/functions/users';
import { CreateInvoiceAction } from 'src/sections/request/actions/CreateInvoiceAction';
import { PayInvoiceAction } from 'src/sections/request/actions/PayInvoiceAction';
import { getCreateInvoiceActionState } from 'src/sections/request/actions/utils/getCreateInvoiceActionState';
import { getRequestStateInformation } from 'src/sections/request/actions/utils/getRequestStateInformation';

type RequestInvoicesProps = {
  request: EnhancedRequest;
  me: EnhancedUser;
  invoices: InvoiceWithItems[];
};

export function RequestInvoices({
  request,
  me,
  invoices,
}: RequestInvoicesProps) {
  const requestStateInformation = getRequestStateInformation(
    request,
    me,
    invoices
  );

  const actionProps = { request, me, requestStateInformation, invoices };

  const actionState = getCreateInvoiceActionState({
    request,
    me,
    requestStateInformation,
  });

  const paidAndViewableInvoices = invoices?.filter(
    invoice =>
      invoice.invoice.status !== 'SENT' &&
      invoice.invoice.recipientGroupId === me?.primaryLocationGroupId &&
      invoice.invoice.publicInvoiceUrl
  );

  // Check if user can pay any of the invoices
  const payableInvoices = invoices?.filter(
    invoice =>
      invoice.invoice.status === 'SENT' &&
      invoice.invoice.recipientGroupId === me?.primaryLocationGroupId &&
      invoice.invoice.publicInvoiceUrl
  );

  const viewOnlyForTechnicians =
    me.myCompanySettings?.allowTechniciansToViewInvoices &&
    requestStateInformation.isTechnician;

  if (
    !actionState.isVisible &&
    !paidAndViewableInvoices?.length &&
    !payableInvoices?.length &&
    !viewOnlyForTechnicians
  ) {
    return null;
  }

  return (
    <Card>
      <CardHeader title="Invoices" />

      <Stack direction="column" spacing={2} sx={{ p: 3 }}>
        <CreateInvoiceAction
          {...actionProps}
          viewOnlyForTechnicians={viewOnlyForTechnicians}
        />
        <PayInvoiceAction
          {...actionProps}
          paidAndViewableInvoices={paidAndViewableInvoices}
          payableInvoices={payableInvoices}
        />
        <StripeSetupAlerts
          requestStateShortCode={request.stateShortCode}
          requestCountryCode={request.countryCode}
          me={me}
          inRequest
        />
      </Stack>
    </Card>
  );
}
