import { GetToken } from '@clerk/types';
import { RequestId } from '@cvx/types/entities/sharedIds';

export async function downloadServiceRequestPdf({
  requestId,
  getToken,
}: {
  requestId: RequestId;
  getToken: GetToken;
}) {
  try {
    const token = await getToken();
    const url = new URL(`${import.meta.env.VITE_CONVEX_SITE_URL}/pdf/request`);
    url.searchParams.set('requestId', requestId);

    const response = await fetch(url.href, {
      credentials: 'include',
      headers: { Authorization: `Bearer ${token}` },
    });

    if (!response.ok) throw new Error('Failed to download PDF');

    const blob = await response.blob();

    // Create a download link
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = `service-request-${requestId}.pdf`;
    link.click();

    // Clean up
    URL.revokeObjectURL(blobUrl);

    return true;
  } catch (error) {
    console.error('Error downloading PDF:', error);
    return false;
  }
}
