import { zid } from 'convex-helpers/server/zod';

export const CityZodId = zid('cities');
export const CompanyZodId = zid('companies');
export const CountryZodId = zid('countries');
export const GroupZodId = zid('groups');
export const ServiceRequestZodId = zid('requests');
export const RoleDefinitionZodId = zid('roleDefinitions');
export const ServiceCategoryZodId = zid('serviceCategories');
export const VehicleZodId = zid('vehicles');
export const ConvexUserZodId = zid('users');
export const StateZodId = zid('states');
export const VendorServiceZodId = zid('services');
export const InvoiceZodId = zid('invoices');
export const UserGroupZodId = zid('userGroups');
export const UserRoleZodId = zid('userRoles');
export const InvoiceItemZodId = zid('invoiceItems');
export const RequestImageZodId = zid('requestImages');
export const RequestChatZodId = zid('requestChats');
export const StorageZodId = zid('_storage');
export const DriverHistoryZodId = zid('driverHistory');
export const ScheduledFunctionZodId = zid('_scheduled_functions');
export const CompanySettingsZodId = zid('companySettings');
