import { api } from '@cvx/api';
import { ConvexUser } from '@cvx/types/entities/usersEntityTypes';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { useMutation, useQuery } from 'convex/react';
import { useState } from 'react';
import { useScheduledActions } from 'src/hooks/useScheduledActions';
import { toast } from 'src/minimal-theme/components/snackbar';

type DeleteUserDialogProps = {
  onClose: () => void;
  user?: ConvexUser | null;
};

export function DeleteUserDialog({ onClose, user }: DeleteUserDialogProps) {
  const [isConfirming, setIsConfirming] = useState(false);
  const activeRequestsForUser = useQuery(
    api.functions.requests.getActiveRequestsForUser,
    user ? { userId: user._id } : 'skip'
  );

  const { watchIds } = useScheduledActions();

  const deleteUser = useMutation(api.functions.users.deleteUser);

  const onDelete = async () => {
    if (user) {
      setIsConfirming(true);
      const res = await deleteUser({
        userId: user._id,
      });

      if (res.success && res.scheduleId) {
        await watchIds([res.scheduleId], {
          onSuccess: () => {},
          onError: failedIds => {
            // Maybe show an error message or handle failed invites
            console.error('Failed invites:', failedIds);
          },
        });
      } else {
        toast.error(res.message);
      }

      setIsConfirming(false);
      onClose();
    }
  };

  const disableConfirm =
    !activeRequestsForUser || activeRequestsForUser?.length > 0;

  let additionalContent = null;

  if (activeRequestsForUser && activeRequestsForUser?.length > 0) {
    additionalContent = (
      <>
        <List>
          {activeRequestsForUser?.map(request => (
            <ListItem key={request._id}>
              <ListItemText primary={request.caseNumber} />
            </ListItem>
          ))}
        </List>
      </>
    );
  }

  // TODO: Need to fix things to avoid the flash at the end of the deletion

  return (
    <Dialog
      open={!!user && !!activeRequestsForUser}
      onClose={isConfirming ? () => {} : () => onClose}
      disableEscapeKeyDown={isConfirming}
    >
      <DialogTitle>{`Delete ${user?.clerkUser.fullName}`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {disableConfirm && !isConfirming
            ? 'You cannot delete this user as they are currently participating in active requests.'
            : 'Are you sure you want to delete this user?'}
          {additionalContent}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isConfirming}>
          Cancel
        </Button>
        <LoadingButton
          loading={isConfirming}
          disabled={isConfirming || disableConfirm}
          onClick={onDelete}
          variant="contained"
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
