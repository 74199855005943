import { api } from '@cvx/api';
import { EnhancedUser } from '@cvx/functions/users';
import { getUserRoleDisplay } from '@cvx/schema/enums/userRole';
import { EnhancedRequest } from '@cvx/types/entities/requestsEntityTypes';
import { ConvexUser } from '@cvx/types/entities/usersEntityTypes';
import { ParticipantPermission } from '@cvx/utils/participants/participantPermissions';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useMutation, useQuery } from 'convex/react';
import { useState } from 'react';
import { toast } from 'src/minimal-theme/components/snackbar';

type ParticipantModifyDialogProps = {
  open: boolean;
  onClose: () => void;
  request: EnhancedRequest;
  me: EnhancedUser;
  permission: ParticipantPermission;
};

export function ParticipantModifyDialog({
  open,
  onClose,
  request,
  me,
  permission,
}: ParticipantModifyDialogProps) {
  const relevantRoleType = permission.participantType!;

  let relevantParticipant: ConvexUser | null = null;

  if (relevantRoleType === 'FLEET_DISPATCHER') {
    relevantParticipant = request.fleetDispatcher;
  } else if (relevantRoleType === 'SERVICE_DISPATCHER') {
    relevantParticipant = request.serviceDispatcher;
  } else if (relevantRoleType === 'THIRD_PARTY_DISPATCHER') {
    relevantParticipant = request.activeBroker;
  }

  const [selectedParticipant, setSelectedParticipant] =
    useState<ConvexUser | null>(relevantParticipant);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const potentialParticipants = useQuery(
    api.functions.users.getUsersOfRoleForLocation,
    {
      roleType: relevantRoleType,
    }
  );

  const modifyParticipant = useMutation(
    api.functions.requestParticipants.modifyParticipant
  );

  const handleSubmit = async () => {
    if (
      !selectedParticipant ||
      !relevantParticipant ||
      selectedParticipant._id === relevantParticipant?._id
    )
      return;

    setIsSubmitting(true);
    try {
      const resp = await modifyParticipant({
        requestId: request._id,
        addingParticipantId: selectedParticipant._id,
        removingParticipantId: relevantParticipant?._id,
        removeRole: relevantRoleType,
        addRole: relevantRoleType,
        action: 'SWAP',
      });

      if (resp.success) {
        toast.success(resp.message);
      }
      onClose();
    } catch (error) {
      console.error('Error modifying participant:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Modify {getUserRoleDisplay(relevantRoleType)}</DialogTitle>

      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel>Select New Participant</InputLabel>
          <Select
            value={selectedParticipant?._id || ''}
            onChange={e =>
              setSelectedParticipant(
                potentialParticipants?.find(p => p._id === e.target.value) ||
                  null
              )
            }
            label="Select New Participant"
          >
            {potentialParticipants?.map(participant => (
              <MenuItem key={participant._id} value={participant._id}>
                {participant.clerkUser.fullName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          loading={isSubmitting}
          disabled={
            selectedParticipant?._id === relevantParticipant?._id ||
            isSubmitting
          }
        >
          Swap Participant
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
