import { api } from '@cvx/api';
import { RequestId, VehicleId } from '@cvx/types/entities/sharedIds';
import { useMutation } from 'convex/react';
import { useState } from 'react';
import { getMapboxPlaceInformation } from 'src/convex/schema/enums/timezones';
import { getCurrentPosition } from 'src/utils/getCurrentPosition';
import { reverseGeocode } from 'src/utils/reverseGeocode';

type LocationUpdateOptions = {
  vehicleId?: VehicleId | null;
  requestId?: RequestId | null;
};

export function useRequestLocationUpdater({
  vehicleId,
  requestId,
}: LocationUpdateOptions) {
  const [isUpdating, setIsUpdating] = useState(false);
  const updateVehicleLocation = useMutation(
    api.functions.vehicles.updateVehicleLocation
  );
  const updateRequestLocation = useMutation(
    api.functions.requests.updateRequestLocation
  );

  const updateLocation = async () => {
    try {
      setIsUpdating(true);
      const position = await getCurrentPosition();
      const { latitude, longitude } = position.coords;

      const feature = await reverseGeocode(latitude, longitude);
      if (!feature) {
        throw new Error('Could not find address for this location');
      }

      const location = {
        latitude,
        longitude,
        address: feature.place_name,
      };

      const updatePromises: Promise<any>[] = [];

      const info = getMapboxPlaceInformation(feature, longitude);

      console.log(info);

      let additionalLocationInfo = {};

      if (info) {
        additionalLocationInfo = { ...info };
      }

      if (vehicleId) {
        updatePromises.push(
          updateVehicleLocation({
            vehicleId,
            location,
            ...additionalLocationInfo,
          })
        );
      }

      if (requestId) {
        updatePromises.push(
          updateRequestLocation({
            requestId,
            location,
            ...additionalLocationInfo,
          })
        );
      }

      await Promise.all(updatePromises);
      setIsUpdating(false);
      return {
        location,
        feature,
      };
    } catch (error) {
      setIsUpdating(false);
      console.error('Error updating location:', error);
      throw error;
    }
  };

  return {
    updateLocation,
    isUpdating,
  };
}
