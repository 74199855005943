// src/components/admin/MetricsDisplay.tsx
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import { MetricsDisplayData } from 'src/convex/functions/billingMetrics';
import { CompanyType } from 'src/convex/schema/enums/companyType';
import { MetricStats } from 'src/sections/admin/MetricStats';

type MetricsDisplayProps = {
  metrics: MetricsDisplayData;
  companyType: CompanyType;
};

export function MetricsDisplay({ metrics, companyType }: MetricsDisplayProps) {
  const { companyMetrics, locationMetrics } = metrics;

  if (!companyMetrics) {
    return (
      <Typography variant="h6">
        No metrics available for this company on this date
      </Typography>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <MetricStats metrics={companyMetrics.metrics} companyType={companyType} />

      <Paper sx={{ width: '100%', height: 400 }}>
        <List>
          {locationMetrics.map(l => (
            <ListItem key={l._id}>
              <ListItemText
                primary={l.locationName}
                secondary={
                  <MetricStats
                    metrics={l.metrics}
                    variant="inline"
                    companyType={companyType}
                  />
                }
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Box>
  );
}
