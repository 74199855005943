import { api } from '@cvx/api';
import { useLoaderData } from '@tanstack/react-router';
import { useQuery } from 'convex/react';
import { RequestListView } from 'src/sections/request/view';

export function MyAssignmentsView() {
  const loaderData = useLoaderData({
    from: '/_auth/dashboard/requests/my-assignments',
  });
  const assignedRequests = useQuery(
    api.functions.requests.getAssignedRequests,
    {}
  );

  return (
    <RequestListView
      title="My Assignments"
      requests={assignedRequests ?? loaderData}
    />
  );
}
