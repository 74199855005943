import { ConvexUser } from '@cvx/types/entities/usersEntityTypes';

export async function generateUserSearchFields(user: ConvexUser) {
  const components = [
    user.clerkUser.fullName,
    user.clerkUser.primaryEmailAddress?.emailAddress,
    user.clerkUser.primaryPhoneNumber?.phoneNumber,
  ].filter(Boolean);

  return {
    searchQueryText: components.join(' ').toLowerCase(),
  };
}
