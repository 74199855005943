import { internal } from '@cvx/api';
import { MutationCtx, QueryCtx } from '@cvx/server';
import { ServiceRequest } from '@cvx/types/entities/requestsEntityTypes';
import {
  ConvexUserId,
  GroupId,
  RequestId,
  ScheduledFunctionId,
} from '@cvx/types/entities/sharedIds';
import { ConvexUser } from '@cvx/types/entities/usersEntityTypes';
import {
  NotificationSetting,
  NotificationType,
} from '../../../schema/entities/users';

type NotificationUserMap = {
  driver?: ConvexUser | null;
  fleetDispatcher?: ConvexUser | null;
  serviceDispatcher?: ConvexUser | null;
  brokerageDispatcher?: ConvexUser | null;
  technician?: ConvexUser | null;
};

type SendToIfAvailableMap = {
  driver?: boolean;
  fleetDispatcher?: boolean;
  serviceDispatcher?: boolean;
  brokerageDispatcher?: boolean;
  technician?: boolean;
};

type NotificationConfig = {
  message: string;
  subject: string;
  users: NotificationUserMap;
  sendToIfAvailable: SendToIfAvailableMap;
  request: ServiceRequest;
  ctx: MutationCtx;
  companyInitiatingNotification: string;
  notificationType: NotificationType;
};

export async function notifyRequestParticipants({
  message,
  subject,
  users,
  sendToIfAvailable,
  request,
  ctx,
  companyInitiatingNotification,
  notificationType,
}: NotificationConfig) {
  const contacts = getRequestContacts(
    request,
    users,
    sendToIfAvailable,
    notificationType
  );

  return sendBulkNotifications(
    ctx,
    contacts,
    message,
    request._id,
    request.caseNumber,
    subject,
    companyInitiatingNotification,
    request.fleetReferenceNumber,
    request.tempVehicleUnitNumber
  );
}

export type ContactMethods = {
  email?: string;
  phone?: string;
  doNotIncludeRequestLink?: boolean;
  name: string;
  config?: NotificationSetting | undefined;
};

export function getRequestContacts(
  request: ServiceRequest,
  users: NotificationUserMap,
  sendToIfAvailable: SendToIfAvailableMap,
  notificationType: NotificationType
): ContactMethods[] {
  const contacts: ContactMethods[] = [];

  // Check active users
  if (users.driver && sendToIfAvailable.driver) {
    contacts.push({
      email: users.driver.clerkUser.primaryEmailAddress?.emailAddress,
      phone: users.driver.clerkUser.primaryPhoneNumber?.phoneNumber,
      name: users.driver.clerkUser.fullName,
      config: users.driver.notificationSettings?.[notificationType],
    });
  } else if (
    (request.tempDriverPhone || request.tempDriverEmail) &&
    sendToIfAvailable.driver
  ) {
    contacts.push({
      email: request.tempDriverEmail,
      phone: request.tempDriverPhone,
      name: `${request.tempDriverFirstName} ${request.tempDriverLastName}`.trim(),
      doNotIncludeRequestLink: true,
    });
  }

  if (users.fleetDispatcher && sendToIfAvailable.fleetDispatcher) {
    contacts.push({
      email: users.fleetDispatcher.clerkUser.primaryEmailAddress?.emailAddress,
      phone: users.fleetDispatcher.clerkUser.primaryPhoneNumber?.phoneNumber,
      name: users.fleetDispatcher.clerkUser.fullName,
      config: users.fleetDispatcher.notificationSettings?.[notificationType],
    });
  } else if (
    (request.tempFleetDispatchPhone || request.tempFleetDispatchEmail) &&
    sendToIfAvailable.fleetDispatcher
  ) {
    contacts.push({
      email: request.tempFleetDispatchEmail,
      phone: request.tempFleetDispatchPhone,
      name: `${request.tempFleetDispatchFirstName} ${request.tempFleetDispatchLastName}`.trim(),
      doNotIncludeRequestLink: true,
    });
  }

  if (users.brokerageDispatcher && sendToIfAvailable.brokerageDispatcher) {
    contacts.push({
      email:
        users.brokerageDispatcher.clerkUser.primaryEmailAddress?.emailAddress,
      phone:
        users.brokerageDispatcher.clerkUser.primaryPhoneNumber?.phoneNumber,
      name: users.brokerageDispatcher.clerkUser.fullName,
      config:
        users.brokerageDispatcher.notificationSettings?.[notificationType],
    });
  }

  if (users.serviceDispatcher && sendToIfAvailable.serviceDispatcher) {
    contacts.push({
      email:
        users.serviceDispatcher.clerkUser.primaryEmailAddress?.emailAddress,
      phone: users.serviceDispatcher.clerkUser.primaryPhoneNumber?.phoneNumber,
      name: users.serviceDispatcher.clerkUser.fullName,
      config: users.serviceDispatcher.notificationSettings?.[notificationType],
    });
  }

  if (users.technician && sendToIfAvailable.technician) {
    contacts.push({
      email: users.technician.clerkUser.primaryEmailAddress?.emailAddress,
      phone: users.technician.clerkUser.primaryPhoneNumber?.phoneNumber,
      name: users.technician.clerkUser.fullName,
      config: users.technician.notificationSettings?.[notificationType],
    });
  }

  return contacts;
}

// Helper to send all notifications at once
export async function sendBulkNotifications(
  ctx: MutationCtx,
  contacts: ContactMethods[],
  message: string,
  requestId: RequestId,
  caseNumber: string,
  subject: string,
  companyInitiatingNotification: string,
  fleetReferenceNumber?: string,
  unitNumber?: string
) {
  const promises: Array<Promise<ScheduledFunctionId>> = [];

  contacts.forEach(contact => {
    const emailsOn = contact.config?.email || contact.config === undefined;
    const smsOn = contact.config?.sms || contact.config === undefined;

    if (contact.email && emailsOn) {
      promises.push(
        ctx.scheduler.runAfter(
          0,
          internal.actions.sendEmail.sendEmailUsingLocalTemplates,
          {
            to: contact.email,
            emailType: 'NOTIFICATION',
            data: {
              userName: contact.name,
              requestId: contact.doNotIncludeRequestLink
                ? undefined
                : requestId,
              caseNumber,
              subject,
              additionalContext: message,
              companyInitiatingNotification,
              fleetReferenceNumber,
              unitNumber,
            },
          }
        )
      );
    }

    if (contact.phone && smsOn) {
      promises.push(
        ctx.scheduler.runAfter(0, internal.actions.sendSms.sendSmsAction, {
          phoneNumber: contact.phone,
          message: `${message} - [Case #${caseNumber}]`,
          requestId: contact.doNotIncludeRequestLink ? undefined : requestId,
          fleetReferenceNumber,
          unitNumber,
        })
      );
    }
  });

  await Promise.all(promises);
}

type RequestParticipantType =
  | 'driver'
  | 'fleetDispatcher'
  | 'serviceDispatcher'
  | 'brokerageDispatcher'
  | 'technician';

export async function getRequestParticipants<T extends RequestParticipantType>(
  ctx: MutationCtx | QueryCtx,
  requestedParticipants: Partial<Record<T, ConvexUserId | undefined>>
): Promise<Partial<Record<T, ConvexUser | null>>> {
  const participants = await Promise.all(
    Object.entries(requestedParticipants)
      .filter((entry): entry is [T, ConvexUserId] => entry[1] !== undefined)
      .map(async ([key, id]) => {
        const user = await ctx.db.get(id);
        return [key, user] as [T, ConvexUser | null];
      })
  );

  return Object.fromEntries(participants) as Partial<
    Record<T, ConvexUser | null>
  >;
}

export async function notifyServiceProviderDispatchers({
  ctx,
  locationGroupId,
  message,
  subject,
  request,
  companyInitiatingNotification,
  notificationType,
  timeoutMinutes = 10,
}: {
  ctx: MutationCtx;
  // Repair shop location or central dispatch location
  locationGroupId?: GroupId;
  message: string;
  subject: string;
  request: ServiceRequest;
  companyInitiatingNotification: string;
  notificationType: NotificationType;
  timeoutMinutes?: number;
}) {
  if (!locationGroupId) {
    return;
  }
  const serviceDispatchers = await ctx.db
    .query('users')
    .withIndex('by_primaryLocationGroupId_and_primaryRoleType', q =>
      q
        .eq('primaryLocationGroupId', locationGroupId)
        .eq('primaryRoleType', 'SERVICE_DISPATCHER')
    )
    .collect();

  const activeDispatchers = serviceDispatchers.filter(
    u => u.isDeleted !== true
  );

  // Format message to include timeout warning
  const messageWithTimeout = `${message} You or another dispatcher at your location have ${timeoutMinutes} minutes to claim this request before it's returned to the requesting party.`;
  const contacts: ContactMethods[] = activeDispatchers.map(dispatcher => ({
    email: dispatcher.clerkUser.primaryEmailAddress?.emailAddress,
    phone: dispatcher.clerkUser.primaryPhoneNumber?.phoneNumber,
    name: dispatcher.clerkUser.fullName,
    config: dispatcher.notificationSettings?.[notificationType],
  }));

  return sendBulkNotifications(
    ctx,
    contacts,
    messageWithTimeout,
    request._id,
    request.caseNumber,
    subject,
    companyInitiatingNotification,
    request.fleetReferenceNumber,
    request.tempVehicleUnitNumber
  );
}
