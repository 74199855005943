// @cvx/utils/participantPermissions.ts

import { UserRole } from '@cvx/schema/enums/userRole';
import { GroupId } from '@cvx/types/entities/sharedIds';

export type ParticipantPermission = {
  canModify: boolean;
  participantType: UserRole | null;
};

export function getParticipantModificationPermissions(params: {
  userRoles: UserRole[];
  userLocationId?: GroupId;
  requestFleetDispatchGroupId?: GroupId;
  requestMechanicDispatchGroupId?: GroupId;
  requestBrokerageGroupId?: GroupId;
}): Partial<Record<UserRole, ParticipantPermission>> {
  const {
    userRoles,
    userLocationId,
    requestFleetDispatchGroupId,
    requestMechanicDispatchGroupId,
    requestBrokerageGroupId,
  } = params;

  const isFleetDispatcher =
    userRoles.includes('FLEET_DISPATCHER') &&
    requestFleetDispatchGroupId === userLocationId;

  const isServiceDispatcher =
    userRoles.includes('SERVICE_DISPATCHER') &&
    requestMechanicDispatchGroupId === userLocationId;

  const isThirdPartyDispatcher =
    userRoles.includes('THIRD_PARTY_DISPATCHER') &&
    requestBrokerageGroupId === userLocationId;

  return {
    FLEET_DISPATCHER: {
      canModify: isFleetDispatcher,
      participantType: isFleetDispatcher ? 'FLEET_DISPATCHER' : null,
    },
    SERVICE_DISPATCHER: {
      canModify: isServiceDispatcher,
      participantType: isServiceDispatcher ? 'SERVICE_DISPATCHER' : null,
    },
    THIRD_PARTY_DISPATCHER: {
      canModify: isThirdPartyDispatcher,
      participantType: isThirdPartyDispatcher ? 'THIRD_PARTY_DISPATCHER' : null,
    },
    DRIVER: { canModify: false, participantType: null },
    TECHNICIAN: { canModify: false, participantType: null },
  };
}
