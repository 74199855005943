import { api } from '@cvx/api';
import { EnhancedUser } from '@cvx/functions/users';
import {
  EnhancedRequest,
  TechnicianLineItem,
} from '@cvx/types/entities/requestsEntityTypes';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Fab,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation } from 'convex/react';
import { useState } from 'react';
import { Iconify } from 'src/minimal-theme/components/iconify';

type ExtraDetailsFormProps = {
  request: EnhancedRequest;
  me: EnhancedUser;
  editable: boolean;
};

export const ExtraDetailsForm = ({
  request,
  me,
  editable,
}: ExtraDetailsFormProps) => {
  const [parts, setParts] = useState<TechnicianLineItem[]>(
    request.repairDetails?.technicianEnteredPartsInfo || []
  );

  const [hours, setHours] = useState<string>(
    request.repairDetails?.totalTechnicianHours?.toString() || ''
  );

  const [newName, setNewName] = useState('');
  const [newQuantity, setNewQuantity] = useState('');
  const [newPrice, setNewPrice] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitExtraDetails = useMutation(
    api.functions.requests.submitExtraDetails
  );

  const handleAddPart = () => {
    if (newName && newQuantity) {
      const quantity = parseInt(newQuantity);
      const price = newPrice ? parseFloat(newPrice) : 0;
      setParts([
        ...parts,
        {
          partNumber: `${parts.length + 1}`,
          partDescription: newName,
          quantity,
          unitPrice: price,
          total: quantity * price,
        },
      ]);
      setNewName('');
      setNewQuantity('');
      setNewPrice('');
    }
  };

  const handleRemovePart = (index: number) => {
    const newParts = [...parts];
    newParts.splice(index, 1);
    setParts(newParts);
  };

  const calculateTotal = () => {
    return parts.reduce((sum, part) => sum + part.unitPrice * part.quantity, 0);
  };

  const handleSubmit = async () => {
    //if (!parts.length && !hours) return;
    setIsSubmitting(true);

    try {
      await submitExtraDetails({
        requestId: request._id,
        technicianEnteredPartsInfo: parts,
        totalTechnicianHours: hours ? parseFloat(hours) : undefined,
      });
    } catch (error) {
      console.error('Failed to submit extra details:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!editable) return null;

  const {
    allowTechniciansToEnterHoursWorked,
    allowTechniciansToEnterPartLineItems,
  } = me.myCompanySettings;

  console.log(
    'allowTechniciansToEnterPartLineItems;',
    allowTechniciansToEnterPartLineItems
  );

  return (
    <Card>
      <CardHeader
        title={
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              component="span"
              sx={{ display: 'flex', alignItems: 'center', color: '#e67e22' }}
            >
              <Iconify icon="solar:lock-bold" />
            </Box>
            <Typography variant="h6">Extra Details</Typography>
            <Box
              component="span"
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                backgroundColor: 'rgba(230, 126, 34, 0.1)',
                color: '#e67e22',
                fontSize: '0.75rem',
                fontWeight: 'bold',
                borderRadius: '4px',
                padding: '0 8px',
                height: '20px',
              }}
            >
              Service Provider Only
            </Box>
          </Stack>
        }
        subheader={
          <Typography variant="body2" fontWeight="medium">
            These details are only visible to service providers
          </Typography>
        }
        sx={{ backgroundColor: 'rgba(230, 126, 34, 0.05)', pb: 2 }}
      />
      <CardContent>
        <Stack spacing={3}>
          {(allowTechniciansToEnterPartLineItems ||
            request.repairDetails?.technicianEnteredPartsInfo) && (
            <>
              <Box>
                <Stack spacing={1}>
                  {/* Parts List */}
                  <Typography variant="subtitle1">Parts</Typography>
                  {/* Column Headers */}
                  <Stack direction="row" alignItems="center" sx={{ py: 1 }}>
                    <Typography
                      variant="body2"
                      sx={{ width: '5%', color: 'text.secondary' }}
                    >
                      #
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ width: '45%', color: 'text.secondary' }}
                    >
                      Name
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        width: '15%',
                        textAlign: 'center',
                        color: 'text.secondary',
                      }}
                    >
                      Qty
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        width: '15%',
                        textAlign: 'center',
                        color: 'text.secondary',
                      }}
                    >
                      Price
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        width: '15%',
                        textAlign: 'right',
                        color: 'text.secondary',
                      }}
                    >
                      Total
                    </Typography>
                    {editable && <Box sx={{ width: '5%' }} />}
                  </Stack>

                  {parts.map((part, index) => (
                    <Stack key={index} direction="row" alignItems="center">
                      <Typography sx={{ width: '5%' }}>{index + 1}.</Typography>
                      <Typography sx={{ width: '45%' }}>
                        {part.partDescription}
                      </Typography>
                      <Typography sx={{ width: '15%', textAlign: 'center' }}>
                        {part.quantity}
                      </Typography>
                      <Typography sx={{ width: '15%', textAlign: 'right' }}>
                        {part.unitPrice > 0
                          ? `$${part.unitPrice.toFixed(2)}`
                          : '-'}
                      </Typography>
                      <Typography sx={{ width: '15%', textAlign: 'right' }}>
                        {part.unitPrice > 0
                          ? `$${(part.unitPrice * part.quantity).toFixed(2)}`
                          : '-'}
                      </Typography>
                      {editable && (
                        <Box sx={{ width: '5%', textAlign: 'right' }}>
                          <Button
                            onClick={() => handleRemovePart(index)}
                            color="error"
                            size="small"
                            sx={{
                              minWidth: '24px',
                              width: '24px',
                              height: '24px',
                              p: 0,
                              borderRadius: '50%',
                            }}
                          >
                            ×
                          </Button>
                        </Box>
                      )}
                    </Stack>
                  ))}

                  {/* Add Part Form */}
                  {editable && (
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Box sx={{ width: '2%' }}>{parts.length + 1}.</Box>
                      <TextField
                        // size="small"
                        label="Part Name"
                        value={newName}
                        onChange={e => setNewName(e.target.value)}
                        sx={{ width: '45%' }}
                      />
                      <TextField
                        // size="small"
                        label="Quantity"
                        type="number"
                        value={newQuantity}
                        onChange={e => setNewQuantity(e.target.value)}
                        sx={{ width: '15%' }}
                      />
                      <TextField
                        // size="small"
                        label="Price"
                        type="number"
                        value={newPrice}
                        onChange={e => setNewPrice(e.target.value)}
                        sx={{ width: '15%' }}
                      />
                      {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddPart}
                        disabled={!newName || !newQuantity}
                        sx={{ width: '15%' }}
                        size="large"
                      >
                        Add
                      </Button> */}
                      <Fab
                        color="primary"
                        size="small"
                        onClick={handleAddPart}
                        disabled={!newName || !newQuantity}
                      >
                        +
                      </Fab>
                    </Stack>
                  )}
                </Stack>
              </Box>

              {/* Total */}
              {parts.length > 0 && (
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  sx={{
                    pt: 2,
                    borderTop: 1,
                    borderColor: 'divider',
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    sx={{ mr: 3 }}
                  >
                    Total Parts Cost:
                  </Typography>
                  <Typography variant="subtitle1" fontWeight="bold">
                    ${calculateTotal().toFixed(2)}
                  </Typography>
                </Stack>
              )}
            </>
          )}

          {(allowTechniciansToEnterHoursWorked ||
            request.repairDetails?.totalTechnicianHours) && (
            <>
              <Typography variant="subtitle1">Technician Hours</Typography>
              <TextField
                label="Technician Hours"
                type="number"
                value={hours}
                onChange={e => setHours(e.target.value)}
                disabled={!editable}
              />
            </>
          )}
          {editable && (
            <Box display="flex" justifyContent="flex-end">
              <LoadingButton
                variant="contained"
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                Save Extra Details
              </LoadingButton>
            </Box>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};
