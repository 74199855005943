import { ServiceRequestZodId } from '@cvx/types/zod/commonZodId';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  Autocomplete,
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Card from '@mui/material/Card';
import { formHelperTextClasses } from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useForm } from '@tanstack/react-form';
import { useLoaderData, useNavigate, useParams } from '@tanstack/react-router';
import { useMutation, useQuery } from 'convex/react';
import dayjs from 'dayjs';
import React from 'react';
import { toast } from 'sonner';
import { api } from 'src/convex/_generated/api';
import { Id } from 'src/convex/_generated/dataModel';
import { INVOICE_SERVICE_TYPE_DISPLAY } from 'src/convex/schema/enums/invoiceServiceType';
import { useScheduledActions } from 'src/hooks/useScheduledActions';
import { Label } from 'src/minimal-theme/components/label';
import { useResponsive } from 'src/minimal-theme/hooks/use-responsive';
import zod from 'zod';
import { useInvoiceLineItems } from './hooks/useInvoiceLineItems';
import { InvoiceNewEditDetails } from './invoice-new-edit-details';

export type NewInvoiceSchemaType = zod.infer<typeof NewInvoiceSchema>;

export const NewInvoiceSchema = zod.object({
  requestId: zod.string().pipe(ServiceRequestZodId),
  clientProvidedDueDate: zod
    .string()
    .datetime()
    .min(1, 'Please provide a due date for the invoice'),
  description: zod.string().min(3, 'Please provide a meaningful description'),
});

export function InvoiceNewEditForm() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const mdUp = useResponsive('up', 'md');
  const navigate = useNavigate();

  // Get the current route to determine if we're in edit or new mode
  // const isNewMode = useRouter().state.matches.some(
  //   match => match.routeId === '/_auth/dashboard/invoices/new'
  // );

  // Only try to get params and loader data if we're in edit mode
  const params = useParams({
    from: '/_auth/dashboard/invoices/$invoiceId',
  });

  const realTimeInvoice = useQuery(
    api.functions.invoices.getInvoiceById,
    params?.invoiceId
      ? {
          invoiceId: params.invoiceId as Id<'invoices'>,
        }
      : 'skip'
  );

  const loaderData = useLoaderData({
    from: '/_auth/dashboard/invoices/$invoiceId',
  });

  const currentInvoice = realTimeInvoice ?? loaderData;

  const user = useQuery(api.functions.users.getMe);

  const { watchIds } = useScheduledActions({
    hidePendingMessage: true,
    successMessage: 'Saved',
  });

  const createInvoice = useMutation(api.functions.invoices.finalizeInvoice);

  const requests = useQuery(api.functions.requests.getAllRequests, {});

  const enhancedRequest = useQuery(
    api.functions.requests.getRequest,
    currentInvoice?.invoice?.requestId
      ? { requestId: currentInvoice.invoice.requestId }
      : 'skip'
  );

  const createInitialInvoiceDraft = useMutation(
    api.functions.invoices.createInitialInvoiceDraft
  );

  const updateInvoice = useMutation(
    api.functions.invoices.fillInvoiceDetailsAndUpdateOnStripe
  );

  const { items, isDirty, hasValidItems } = useInvoiceLineItems(
    currentInvoice?.invoice?._id
  );

  const [isCreatingInvoice, setIsCreatingInvoice] = React.useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);

  const draftMode =
    currentInvoice && currentInvoice?.invoice?.status === 'DRAFT';

  const onInvoiceUpdate = async (key: string, value: string | number) => {
    if (!currentInvoice) {
      return;
    }
    const response = await updateInvoice({
      invoiceId: currentInvoice?.invoice?._id,
      [key]: value,
    });

    if (response.success && response.data.scheduleId) {
      await watchIds([response.data.scheduleId], {
        onError: failedIds => {
          console.error('Failed save:', failedIds);
          toast.error('Failed to update invoice');
        },
      });
    } else {
      toast.error(response.message);
    }
  };

  const canPayInvoice =
    currentInvoice?.invoice?.status === 'SENT' &&
    user?.primaryLocationGroupId === currentInvoice?.invoice?.recipientGroupId;

  const form = useForm({
    defaultValues: {
      requestId: currentInvoice?.invoice?.requestId || '',
      description: currentInvoice?.invoice?.description || '',
      clientProvidedDueDate: currentInvoice?.invoice?.dueDate
        ? dayjs(currentInvoice.invoice.dueDate * 1000).toISOString()
        : null,
      purchaseOrderNumber: currentInvoice?.invoice?.purchaseOrderNumber || '',
    },
    onSubmit: async ({ value }) => {
      if (!currentInvoice) {
        toast.error('Please select a request first');
        return;
      }

      // Don't allow submission if items are still being edited
      if (isDirty) {
        toast.error(
          'Please save all line item changes before creating the invoice'
        );
        return;
      }

      setIsCreatingInvoice(true);
      try {
        const response = await createInvoice({
          invoiceId: currentInvoice.invoice._id,
        });

        if (response.success && response.data.scheduleId) {
          await watchIds([response.data.scheduleId], {
            onSuccess: () => {
              toast.success('Invoice created successfully');
              setIsCreatingInvoice(false);
            },
            onError: failedIds => {
              console.error('Failed invites:', failedIds);
              toast.error('Failed to create invoice');
              setIsCreatingInvoice(false);
            },
          });
        } else {
          toast.error(response.message);
          setIsCreatingInvoice(false);
        }
      } catch (error) {
        console.error('Error creating invoice:', error);
        toast.error('Failed to create invoice');
        setIsCreatingInvoice(false);
      }
    },
  });

  const { Field, Subscribe, handleSubmit, reset, store } = form;

  const handleCreateInvoice = () => {
    if (!currentInvoice) {
      toast.error('Please select a request first');
      return;
    }

    if (!items || items.length === 0) {
      toast.error('At least one line item is required');
      return;
    }

    if (!hasValidItems) {
      toast.error('Please ensure all line items are valid');
      return;
    }

    if (isDirty) {
      toast.error(
        'Please save all line item changes before creating the invoice'
      );
      return;
    }

    setOpenConfirmDialog(true);
  };

  if (!user) return;

  const disabled = !draftMode;

  const totalAmount = currentInvoice?.invoice?.amount
    ? currentInvoice.invoice.amount / 100
    : 0;

  return (
    <>
      <Card>
        <CardHeader
          title={
            !currentInvoice
              ? 'Add Invoice'
              : `Invoice: ${enhancedRequest?.caseNumber}`
          }
          sx={{ mb: 3 }}
          subheader={
            currentInvoice && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap={1}
              >
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography variant="h6">Status:</Typography>
                  <Label
                    variant="soft"
                    color={
                      (currentInvoice?.invoice?.status === 'PAID' &&
                        'success') ||
                      (currentInvoice?.invoice?.status === 'SENT' &&
                        'warning') ||
                      (currentInvoice?.invoice?.status === 'OVERDUE' &&
                        'error') ||
                      'default'
                    }
                  >
                    {currentInvoice?.invoice?.status}
                  </Label>
                </Stack>
                {canPayInvoice && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      window.open(
                        currentInvoice.invoice.publicInvoiceUrl,
                        '_blank'
                      );
                    }}
                  >
                    Pay Invoice
                  </Button>
                )}
              </Stack>
            )
          }
        />
        <Divider />
        <Stack sx={{ p: 3 }}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={2}
            sx={{ p: 3 }}
          >
            <Field
              name="requestId"
              validators={{
                onChangeAsync: NewInvoiceSchema.shape.requestId,
              }}
              children={({ state, handleChange }) => (
                <Autocomplete
                  disabled={!!currentInvoice}
                  fullWidth
                  options={requests || []}
                  getOptionLabel={option =>
                    `${option.caseNumber} - ${option.status}`
                  }
                  value={requests?.find(r => r._id === state.value) || null}
                  isOptionEqualToValue={(option, value) =>
                    option?._id === value?._id
                  }
                  onChange={async (event, newValue) => {
                    handleChange(newValue?._id || '');

                    if (newValue?._id) {
                      const response = await createInitialInvoiceDraft({
                        requestId: newValue?._id,
                      });

                      if (response.success && response.data.invoiceDraftId) {
                        await navigate({
                          to: '/dashboard/invoices/$invoiceId',
                          params: {
                            invoiceId: response.data.invoiceDraftId,
                          },
                        });
                      }
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Service Request"
                      required
                      error={state.meta.errors.length > 0}
                      helperText={state.meta.errors[0]}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      {option.caseNumber} - {option.status}
                    </li>
                  )}
                  sx={{ width: { md: '33.33%' } }}
                />
              )}
            />

            <Field
              name="clientProvidedDueDate"
              validators={{
                onChangeAsync: NewInvoiceSchema.shape.clientProvidedDueDate,
              }}
              children={({ state, handleChange }) => {
                return (
                  <DesktopDatePicker
                    label="Due Date"
                    disabled={disabled}
                    value={state.value ? dayjs(state.value) : null}
                    onChange={async (newValue: dayjs.Dayjs | null) => {
                      const adjustedDate = newValue?.endOf('day');
                      const isoString = adjustedDate?.toISOString() ?? null;

                      handleChange(isoString || '');

                      if (adjustedDate?.isValid()) {
                        const timestampInSeconds = Math.floor(
                          adjustedDate.valueOf() / 1000
                        );
                        await onInvoiceUpdate(
                          'clientProvidedDueDate',
                          timestampInSeconds
                        );
                      }
                    }}
                    format="MM/DD/YYYY"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: state.meta.errors.length > 0,
                        helperText: state.meta.errors[0],
                      },
                      inputAdornment: {
                        position: 'start',
                      },
                    }}
                    sx={{
                      width: { md: '33.33%' },
                      [`& .${formHelperTextClasses.root}`]: {
                        bottom: { md: -40 },
                        position: { md: 'absolute' },
                      },
                    }}
                  />
                );
              }}
            />
            <Field
              name="purchaseOrderNumber"
              children={({ state, handleChange }) => (
                <TextField
                  fullWidth
                  label="Purchase Order Number"
                  disabled={disabled}
                  value={state.value || ''}
                  onChange={e => handleChange(e.target.value)}
                  sx={{ width: { md: '33.33%' } }}
                  onBlur={async () => {
                    await onInvoiceUpdate('purchaseOrderNumber', state.value);
                  }}
                />
              )}
            />
          </Stack>

          <Stack
            spacing={{ xs: 3, md: 5 }}
            direction={{ xs: 'column', md: 'row' }}
            divider={
              <Divider
                flexItem
                orientation={mdUp ? 'vertical' : 'horizontal'}
                sx={{ borderStyle: 'dashed' }}
              />
            }
            sx={{ p: 3 }}
          >
            <Stack sx={{ width: 1 }}>
              <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
                <Typography
                  variant="h6"
                  sx={{ color: 'text.disabled', flexGrow: 1 }}
                >
                  From:
                </Typography>
              </Stack>

              <Stack spacing={1}>
                <Typography variant="subtitle2">
                  {currentInvoice?.invoice.serviceCompanyName} —{' '}
                  {currentInvoice?.invoice.serviceLocationName}
                </Typography>
                <Typography variant="body2">
                  {currentInvoice?.invoice.serviceLocationAddress ?? ''}
                </Typography>
                <Typography variant="body2">
                  {currentInvoice?.invoice.serviceLocationDispatchPhone ?? ''}
                </Typography>
              </Stack>
            </Stack>

            <Stack sx={{ width: 1 }}>
              <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
                <Typography
                  variant="h6"
                  sx={{ color: 'text.disabled', flexGrow: 1 }}
                >
                  To:
                </Typography>
              </Stack>

              {currentInvoice?.invoice.recipientUser && (
                <Stack spacing={1}>
                  <Typography variant="subtitle2">
                    {currentInvoice.invoice.recipientUser.clerkUser?.fullName}
                  </Typography>
                  <Typography variant="body2">
                    {
                      currentInvoice.invoice.recipientUser.clerkUser
                        ?.primaryEmailAddress?.emailAddress
                    }
                  </Typography>
                  <Typography variant="body2">
                    {
                      currentInvoice.invoice.recipientUser.clerkUser
                        ?.primaryPhoneNumber?.phoneNumber
                    }
                  </Typography>
                </Stack>
              )}

              {!currentInvoice?.invoice.recipientUser &&
                currentInvoice?.invoice.tempFleetDispatchPhone && (
                  <Stack spacing={1}>
                    <Typography variant="subtitle2">
                      {enhancedRequest?.requesterCompanyName} — &nbsp;
                      {currentInvoice?.invoice.tempFleetDispatchFirstName}{' '}
                      {currentInvoice?.invoice.tempFleetDispatchLastName}
                    </Typography>
                    <Typography variant="body2">
                      {enhancedRequest?.address}
                    </Typography>
                    <Typography variant="body2">
                      {currentInvoice?.invoice.tempFleetDispatchPhone}
                    </Typography>
                  </Stack>
                )}

              {!currentInvoice?.invoice.recipientUser &&
                !currentInvoice?.invoice.tempFleetDispatchPhone &&
                currentInvoice?.invoice.tempDriverPhone && (
                  <Stack spacing={1}>
                    <Typography variant="subtitle2">
                      {enhancedRequest?.requesterCompanyName} — &nbsp;
                      {currentInvoice?.invoice.tempDriverFirstName}{' '}
                      {currentInvoice?.invoice.tempDriverLastName}
                    </Typography>
                    <Typography variant="body2">
                      {enhancedRequest?.address}
                    </Typography>
                    <Typography variant="body2">
                      {currentInvoice?.invoice.tempDriverPhone}
                    </Typography>
                  </Stack>
                )}
            </Stack>
          </Stack>

          <Stack sx={{ p: 3 }}>
            <Stack spacing={2} sx={{ width: isMobile ? '100%' : '50%' }}>
              <Field
                name="description"
                validators={{
                  onChangeAsync: NewInvoiceSchema.shape.description,
                }}
                children={({ state, handleChange }) => (
                  <TextField
                    multiline
                    disabled={disabled}
                    rows={4}
                    label="Description"
                    value={state.value || ''}
                    onChange={e => handleChange(e.target.value)}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    onBlur={() => {
                      onInvoiceUpdate('description', state.value);
                    }}
                  />
                )}
              />
            </Stack>
          </Stack>

          {currentInvoice && (
            <InvoiceNewEditDetails
              disabled={disabled}
              invoiceId={currentInvoice.invoice._id}
              status={currentInvoice.invoice.status}
            />
          )}
        </Stack>
      </Card>
      <Stack
        justifyContent="flex-end"
        direction="row"
        spacing={2}
        sx={{ mt: 3 }}
      >
        <>
          {!disabled && (
            <Subscribe
              selector={state => [state.canSubmit, state.isSubmitting]}
              children={([canSubmit, isSubmitting]) => {
                const hasItems = items && items.length > 0;
                const canCreateInvoice = canSubmit && hasItems && hasValidItems;

                return (
                  <LoadingButton
                    type="button"
                    color="primary"
                    variant="contained"
                    loading={isCreatingInvoice}
                    disabled={
                      !canCreateInvoice ||
                      currentInvoice?.invoice?.status !== 'DRAFT' ||
                      isCreatingInvoice
                    }
                    onClick={handleCreateInvoice}
                  >
                    Send Invoice To Customer
                  </LoadingButton>
                );
              }}
            />
          )}
        </>
      </Stack>

      {/* Confirmation Dialog */}
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        PaperProps={{ sx: { maxWidth: 800 } }}
      >
        <DialogTitle>Create Invoice</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" sx={{ mb: 2, mt: 1 }}>
            Please review the invoice details before creating:
          </Typography>

          {items && items.length > 0 ? (
            <>
              <Stack spacing={2}>
                {/* Header */}
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    py: 1,
                    borderBottom: 1,
                    borderColor: 'divider',
                  }}
                >
                  <Typography variant="subtitle2" sx={{ width: '5%' }}>
                    #
                  </Typography>
                  <Typography variant="subtitle2" sx={{ width: '20%' }}>
                    Category
                  </Typography>
                  <Typography variant="subtitle2" sx={{ width: '35%' }}>
                    Description
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ width: '15%', textAlign: 'center' }}
                  >
                    Quantity
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ width: '25%', textAlign: 'right' }}
                  >
                    Amount
                  </Typography>
                </Stack>

                {/* Items */}
                {items.map((item, index) => (
                  <Stack
                    key={item._id}
                    direction="row"
                    alignItems="center"
                    sx={{ py: 1 }}
                  >
                    <Typography sx={{ width: '5%' }}>{index + 1}.</Typography>
                    <Typography sx={{ width: '20%' }}>
                      {INVOICE_SERVICE_TYPE_DISPLAY[
                        item.category as keyof typeof INVOICE_SERVICE_TYPE_DISPLAY
                      ] || item.category}
                    </Typography>
                    <Typography sx={{ width: '35%' }}>
                      {item.description}
                    </Typography>
                    <Typography sx={{ width: '15%', textAlign: 'center' }}>
                      {item.quantity || 1}
                    </Typography>
                    <Typography sx={{ width: '25%', textAlign: 'right' }}>
                      ${Number(item.amount / 100).toFixed(2)}
                    </Typography>
                  </Stack>
                ))}

                <Divider />

                {/* Total */}
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  sx={{ pt: 1 }}
                >
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    sx={{ mr: 3 }}
                  >
                    Total Amount:
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    sx={{ width: '25%', textAlign: 'right' }}
                  >
                    ${totalAmount}
                  </Typography>
                </Stack>
              </Stack>

              <Alert severity="warning" sx={{ mt: 3 }}>
                This action cannot be undone. The invoice will be sent to the
                customer for payment.
              </Alert>
            </>
          ) : (
            <Typography color="error">
              No items found. Please add items before creating the invoice.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setOpenConfirmDialog(false)}
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={() => {
              setOpenConfirmDialog(false);
              handleSubmit();
            }}
            color="primary"
            variant="contained"
            loading={isCreatingInvoice}
            disabled={!items || items.length === 0}
          >
            Create & Send Invoice
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
