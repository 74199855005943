import { api } from '@cvx/api';
import { CompanyGroup } from '@cvx/types/entities/groupsEntityTypes';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Tooltip from '@mui/material/Tooltip';
import { useQuery } from 'convex/react';
import { useCallback } from 'react';
import { ConfirmDialog } from 'src/minimal-theme/components/custom-dialog';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { RouterLink } from 'src/minimal-theme/components/routes/components';
import { Scrollbar } from 'src/minimal-theme/components/scrollbar';
import {
  emptyRows,
  getComparator,
  rowInPage,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/minimal-theme/components/table';
import { useBoolean } from 'src/minimal-theme/hooks/use-boolean';
import { useSetState } from 'src/minimal-theme/hooks/use-set-state';
import { DashboardContent } from 'src/minimal-theme/layouts/dashboard';
import type { LocationTableFilters } from 'src/types/location';
import { LocationTableFiltersResult } from '../location-table-filters-result';
import { LocationTableRow } from '../location-table-row';
import { LocationTableToolbar } from '../location-table-toolbar';

const STATUS_OPTIONS = [
  { value: 'all', label: 'All' },
  { value: 'isNetworkMember', label: 'myMechanic Network' },
];

const TABLE_HEAD = [
  { id: 'name', label: 'Location', width: 250 },
  { id: 'parentCompany', label: 'Parent Company' },
  { id: 'locationType', label: 'Location Type' },
  { id: 'email', label: 'Contact Email' },
  { id: 'phoneNumber', label: 'Contact Phone' },
  // { id: 'isNetworkMember', label: 'myMechanic Member' },
  { id: 'actions', label: 'Actions' },
];

export function LocationListView() {
  const table = useTable();

  const confirm = useBoolean();

  const tableData = useQuery(api.functions.companies.getLocations, {});

  const filters = useSetState<LocationTableFilters>({
    search: '',
    isNetworkMember: false,
  });

  const dataFiltered = applyFilter({
    inputData: tableData ?? [],
    comparator: getComparator(table.order, table.orderBy),
    filters: filters.state,
  });

  const dataInPage = rowInPage(dataFiltered, table.page, table.rowsPerPage);

  const canReset =
    !!filters.state.search || filters.state.isNetworkMember !== false;

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleDeleteRow = useCallback((id: string) => {
    console.log('delete', id);
  }, []);

  const handleDeleteRows = useCallback((ids: string[]) => {
    console.log('delete rows', ids);
  }, []);

  const handleEditRow = useCallback((id: string) => {
    console.log('edit', id);
  }, []);

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      table.onResetPage();
      filters.setState({
        isNetworkMember: newValue === 'isNetworkMember',
      });
    },
    [filters, table]
  );

  return (
    <>
      <DashboardContent>
        <Button
          component={RouterLink}
          href={'/dashboard/locations/new'}
          color="primary"
          variant="contained"
          startIcon={<Iconify icon="mingcute:add-line" />}
          sx={{ width: 'fit-content', mb: 2, ml: 'auto' }}
        >
          Add Location
        </Button>
        <Card>
          {/* <Tabs
            value={filters.state.isNetworkMember ? 'isNetworkMember' : 'all'}
            onChange={handleFilterStatus}
            sx={{
              px: 2.5,
              boxShadow: theme =>
                `inset 0 -2px 0 0 ${varAlpha(theme.vars.palette.grey['500Channel'], 0.08)}`,
            }}
          >
            {STATUS_OPTIONS.map(tab => (
              <Tab
                key={tab.value}
                iconPosition="end"
                value={tab.value}
                label={tab.label}
                icon={
                  <Label
                    variant={
                      (tab.value === 'all' && !filters.state.isNetworkMember) ||
                      (tab.value === 'isNetworkMember' &&
                        filters.state.isNetworkMember)
                        ? 'filled'
                        : 'soft'
                    }
                    color={
                      tab.value === 'isNetworkMember' ? 'primary' : 'default'
                    }
                  >
                    {tab.value === 'all'
                      ? tableData?.length
                      : tableData?.filter(
                          location =>
                            location.isNetworkMember ===
                            (tab.value === 'isNetworkMember')
                        ).length}
                  </Label>
                }
              />
            ))}
          </Tabs> */}

          <LocationTableToolbar
            filters={filters}
            onResetPage={table.onResetPage}
          />

          {canReset && (
            <LocationTableFiltersResult
              filters={filters}
              totalResults={dataFiltered.length}
              onResetPage={table.onResetPage}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <Box sx={{ position: 'relative' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected.length}
              rowCount={dataFiltered.length}
              onSelectAllRows={checked =>
                table.onSelectAllRows(
                  checked,
                  dataFiltered.map(row => row._id)
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table
                size={table.dense ? 'small' : 'medium'}
                sx={{ minWidth: 960 }}
              >
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={dataFiltered.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                  onSelectAllRows={checked =>
                    table.onSelectAllRows(
                      checked,
                      dataFiltered.map(row => row._id)
                    )
                  }
                />

                <TableBody>
                  {dataFiltered
                    .slice(
                      table.page * table.rowsPerPage,
                      table.page * table.rowsPerPage + table.rowsPerPage
                    )
                    .map(row => (
                      <LocationTableRow
                        key={row._id}
                        row={row}
                        selected={table.selected.includes(row._id)}
                        onSelectRow={() => table.onSelectRow(row._id)}
                        onDeleteRow={() => handleDeleteRow(row._id)}
                        onEditRow={() => handleEditRow(row._id)}
                      />
                    ))}

                  <TableEmptyRows
                    height={table.dense ? 56 : 56 + 20}
                    emptyRows={emptyRows(
                      table.page,
                      table.rowsPerPage,
                      dataFiltered.length
                    )}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </Box>

          <TablePaginationCustom
            page={table.page}
            dense={table.dense}
            count={dataFiltered.length}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onChangeDense={table.onChangeDense}
            onRowsPerPageChange={table.onChangeRowsPerPage}
          />
        </Card>
      </DashboardContent>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete{' '}
            <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              // handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

type ApplyFilterProps = {
  inputData: CompanyGroup[];
  filters: LocationTableFilters;
  comparator: (a: any, b: any) => number;
};

function applyFilter({ inputData, comparator, filters }: ApplyFilterProps) {
  const { search, isNetworkMember } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map(el => el[0]);

  if (search) {
    inputData = inputData.filter(
      user => user.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
  }

  // if (isNetworkMember !== false) {
  //   inputData = inputData.filter(user => user.isNetworkMember === true);
  // }

  return inputData;
}
