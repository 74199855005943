import { useAuth } from '@clerk/clerk-react';
import { api } from '@cvx/api';
import { EnhancedRequest } from '@cvx/types/entities/requestsEntityTypes';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Fab,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from 'convex/react';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { toast } from 'sonner';
import { InvoiceWithItems } from 'src/convex/functions/invoices';
import { EnhancedUser } from 'src/convex/functions/users';
import {
  getImageCategoryColor,
  getImageCategoryLabel,
  ImageCategory,
} from 'src/convex/schema/entities/requests/requestImages';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { Image } from 'src/minimal-theme/components/image';
import { Label } from 'src/minimal-theme/components/label';
import { Lightbox, useLightBox } from 'src/minimal-theme/components/lightbox';
import { ActionConfirmationDialog } from 'src/sections/request/actions/ActionConfirmationDialog';
import { useActionConfirmationDialog } from 'src/sections/request/actions/hooks/useActionConfirmationDialog';
import { getRequestStateInformation } from 'src/sections/request/actions/utils/getRequestStateInformation';
import { AddPhotosDialog } from 'src/sections/request/AddPhotosDialog';
import { useAvailableCategories } from 'src/sections/request/hooks/useAvailableCategories';
import { useRequestImages } from 'src/sections/request/hooks/useRequestImages';
import {
  downloadImage,
  downloadImagesAsZip,
} from 'src/sections/request/utils/downloadHelpers';

type RequestPhotosProps = {
  request: EnhancedRequest;
  me: EnhancedUser;
  invoices: InvoiceWithItems[];
};

export const RequestPhotos = ({
  request,
  me,
  invoices,
}: RequestPhotosProps) => {
  const [addPhotoDialogOpen, setIsAddPhotoDialogOpen] =
    useState<boolean>(false);

  const { getToken } = useAuth();

  const filterOptions = useAvailableCategories(me);

  const [filters, setFilters] = useState<ImageCategory[]>(filterOptions);

  const images = useQuery(
    api.functions.requestImages.getAccessibleRequestPhotos,
    {
      requestId: request._id,
    }
  );

  const resetFiltersToDefault = () => {
    setFilters(filterOptions);
  };

  const { confirmAction, isOpen, onClose, pendingAction } =
    useActionConfirmationDialog();

  const deletePhotos = useMutation(
    api.functions.requestImages.deleteRequestPhotos
  );

  const confirmedImages = useMemo(
    () => images?.filter(i => !!i.confirmed) ?? [],
    [images]
  );

  const imageStates = useRequestImages(
    request._id,
    confirmedImages.map(img => img._id)
  );

  const filteredImages = confirmedImages.filter(i =>
    filters.includes(i.category!)
  );

  const slides = filteredImages.map(img => ({
    src: imageStates[img._id]?.url || '',
  }));

  console.log(slides);
  const lightbox = useLightBox(slides);

  // const slides = confirmedImages.map(c => ({ src: c.url }));

  // TODO: @Andrii maybe you can figure this one out?  It works but the styling is all messed up, so they must have a lightbox... container or something somewhere I just can't find it
  // const lightbox = useLightBox(slides);

  const isShowingAll = filters.length === filterOptions.length;

  const requestStateInformation = getRequestStateInformation(
    request,
    me,
    invoices
  );
  const { canEditChatAndPhotos, canViewAndDownloadPhotos } =
    requestStateInformation;

  return (
    <Card>
      <CardHeader
        title="Request Photos"
        subheader={'Add or view request photos'}
        action={
          <Stack direction="row" spacing={1}>
            {filteredImages.length > 0 && canViewAndDownloadPhotos && (
              <Tooltip title="Download All Photos">
                <Fab
                  color="secondary"
                  size="medium"
                  onClick={async () => {
                    try {
                      await downloadImagesAsZip(
                        confirmedImages.map(img => ({
                          id: img._id,
                          baseFilename: `photo-${dayjs(img._creationTime).format('YYYY-MM-DD-HH-mm')}`,
                        })),
                        request.caseNumber,
                        getToken
                      );
                    } catch (err) {
                      toast.error('Failed to download images');
                    }
                  }}
                >
                  <Iconify icon="solar:download-square-bold" />
                </Fab>
              </Tooltip>
            )}
            {canEditChatAndPhotos && (
              <Tooltip title="Add photos">
                <Fab
                  color="primary"
                  size="medium"
                  onClick={() => {
                    resetFiltersToDefault();
                    setIsAddPhotoDialogOpen(true);
                  }}
                  aria-label="Add photos"
                >
                  <Iconify icon="material-symbols:photo-camera" />
                </Fab>
              </Tooltip>
            )}
          </Stack>
        }
      />
      <CardContent>
        <Stack direction="row" spacing={1} flexWrap={'wrap'} sx={{ mb: 2 }}>
          <Chip
            variant="soft"
            label="Show All"
            color={isShowingAll ? 'primary' : 'default'}
            onClick={() => setFilters(filterOptions)}
            icon={
              isShowingAll ? (
                <Iconify icon="material-symbols:check" />
              ) : undefined
            }
          />
          {filterOptions.map(o => (
            <Chip
              key={o}
              variant="soft"
              sx={{
                p: 1,
                bgcolor: filters.includes(o)
                  ? getImageCategoryColor(o).light
                  : 'default',
                color: filters.includes(o)
                  ? getImageCategoryColor(o).main
                  : 'default',
              }}
              label={getImageCategoryLabel(o)}
              icon={
                filters.includes(o) ? (
                  <Iconify icon="material-symbols:check" />
                ) : undefined
              }
              onClick={() => {
                if (isShowingAll) {
                  // If all are selected, clicking one should show ONLY that one
                  setFilters([o]);
                } else {
                  // Otherwise toggle the clicked filter
                  setFilters(prev =>
                    prev.includes(o) ? prev.filter(f => f !== o) : [...prev, o]
                  );
                }
              }}
            />
          ))}
        </Stack>
        {filteredImages.map(i => {
          return (
            <Box key={i._id} sx={{ position: 'relative', mb: 2 }}>
              <Image
                //   alt={slide.src}
                src={imageStates[i._id]?.url || ''}
                //   ratio="1/1"
                onClick={() => lightbox.onOpen(imageStates[i._id]?.url || '')}
                sx={{
                  borderRadius: 2,
                  cursor: 'pointer',
                  transition: theme => theme.transitions.create('opacity'),
                  '&:hover': { opacity: 0.8 },
                  mb: 2,
                }}
              />
              <Stack direction={'row'} spacing={1}>
                {canViewAndDownloadPhotos && (
                  <IconButton
                    onClick={async () => {
                      try {
                        await downloadImage({
                          imageId: i._id,
                          baseFilename: `photo-${dayjs(i._creationTime).format('YYYY-MM-DD-HH-mm')}`,
                          getToken,
                        });
                      } catch (err) {
                        toast.error('Failed to download image');
                      }
                    }}
                    disabled={!imageStates[i._id]?.url}
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 48,
                      bgcolor: 'rgba(255, 255, 255, 0.9)',
                      '&:hover': { bgcolor: 'rgba(255, 255, 255, 1)' },
                      boxShadow: 1,
                    }}
                    size="small"
                  >
                    <Iconify icon="material-symbols:download" />
                  </IconButton>
                )}
                <IconButton
                  onClick={() =>
                    confirmAction({
                      title: 'Delete Photo',
                      message: 'Are you sure you want to delete this photo?',
                      onConfirm: async () => {
                        const res = await deletePhotos({
                          photoIds: [i._id],
                        });

                        if (res.success) {
                          toast.success(res.message);
                        } else {
                          toast.error(res.message);
                        }
                      },
                    })
                  }
                  disabled={i.createdById !== me._id}
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    bgcolor: 'rgba(255, 255, 255, 0.9)', // Add opacity to background
                    '&:hover': { bgcolor: 'rgba(255, 255, 255, 1)' }, // Solid on hover
                    boxShadow: 1,
                  }}
                  size="small"
                >
                  <Iconify icon="material-symbols:delete-outline" />
                </IconButton>
              </Stack>
              <Box
                sx={{
                  position: 'absolute',
                  bottom: -2,
                  left: 0,
                  right: 0,
                  mx: 2, // A
                  display: 'flex',
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{
                      bgcolor: 'white',
                      borderRadius: '9px',
                      p: 1,
                      boxShadow: 1,
                      fontWeight: 700,
                    }}
                  >
                    By {i.createdBy.fullName} on{' '}
                    {dayjs(i._creationTime).format('MMM D, YYYY h:mm A')}
                  </Typography>
                  <Box
                    sx={{
                      bgcolor: 'white',
                      borderRadius: '9px',
                      boxShadow: 1,
                      display: 'flex',
                    }}
                  >
                    <Label
                      variant="soft"
                      sx={{
                        bgcolor: getImageCategoryColor(i.category!).light,
                        color: getImageCategoryColor(i.category!).main,
                      }}
                    >
                      {getImageCategoryLabel(i.category!)}
                    </Label>
                  </Box>
                </Stack>
              </Box>
            </Box>
          );
        })}
        <Lightbox
          slides={slides}
          open={lightbox.open}
          close={lightbox.onClose}
          index={lightbox.selected}
        />
      </CardContent>
      <AddPhotosDialog
        me={me}
        open={addPhotoDialogOpen}
        onClose={() => setIsAddPhotoDialogOpen(false)}
        requestId={request._id}
      />
      <ActionConfirmationDialog
        open={isOpen}
        onClose={onClose}
        {...pendingAction}
      />
    </Card>
  );
};
