import { createFileRoute } from '@tanstack/react-router';
import Page from 'src/pages/dashboard/invoice/list';
import {
  checkRouteAccess,
  routeAccessConfigs,
} from 'src/utils/checkRouteAccess';

export const Route = createFileRoute('/_auth/dashboard/invoices/')({
  beforeLoad: ({ context }) => {
    checkRouteAccess(context, {
      ...routeAccessConfigs.dispatcherGeneral,
      allowSoleProprietor: true,
    });
  },
  component: Page,
});
