import { ConvexUser } from '@cvx/types/entities/usersEntityTypes';
import { Triggers } from 'convex-helpers/server/triggers';
import { GenericDatabaseWriter, GenericMutationCtx } from 'convex/server';
import { DataModel } from '../_generated/dataModel';
import { MutationCtx } from '../_generated/server';
import {
  handleUserDeletion,
  handleUserUpsert,
} from '../triggers/helpers/userTriggerHelpers';
import { generateSearchFields } from '../triggers/requests';
import { generateVehicleSearchFields } from './helpers/generateVehicleSearchFields';

export const triggers = new Triggers<DataModel, EnhancedTriggerContext>();

export type TriggerContext = MutationCtx & {
  innerDb: GenericDatabaseWriter<DataModel>;
};

export type EnhancedTriggerContext = GenericMutationCtx<DataModel> & {
  user?: ConvexUser | null;
};

triggers.register('requests', async (ctx, change) => {
  if (!change.newDoc) return;
  const oldDoc = change.oldDoc;

  const { searchComponents, searchText } = await generateSearchFields(
    ctx,
    change.newDoc
  );

  // Only update if components changed
  if (
    !oldDoc ||
    JSON.stringify(searchComponents) !==
      JSON.stringify(oldDoc.searchComponents) ||
    searchText !== oldDoc.searchText
  ) {
    await ctx.innerDb.patch(change.id, {
      searchComponents,
      fleetSearchText: searchText,
      serviceSearchText: searchText,
    });
  }
});

triggers.register('users', async (ctx, change) => {
  console.log(ctx.user);

  if (change.operation === 'delete') {
    await handleUserDeletion(ctx, change, ctx.user);
  } else if (change.operation === 'insert' || change.operation === 'update') {
    await handleUserUpsert(ctx, change, ctx.user);
  }
});

triggers.register('vehicles', async (ctx, change) => {
  if (change.operation === 'insert' || change.operation === 'update') {
    if (change.newDoc?.primaryLocationGroupId) {
      const oldDoc = change.oldDoc;

      if (!oldDoc?.primaryLocationGroupId) return;

      const searchFields = await generateVehicleSearchFields(change.newDoc);

      // Only update if search text changed
      if (!oldDoc || searchFields.searchQueryText !== oldDoc.searchQueryText) {
        await ctx.innerDb.patch(change.id, searchFields);
      }

      const primaryLocation = await ctx.innerDb.get(
        change.newDoc.primaryLocationGroupId
      );
      if (
        primaryLocation?.defaultDispatchGroupId &&
        change.newDoc.defaultDispatchGroupId !==
          primaryLocation.defaultDispatchGroupId
      ) {
        await ctx.innerDb.patch(change.id, {
          defaultDispatchGroupId: primaryLocation.defaultDispatchGroupId,
        });
      }
    }
  }
});

// TODO: We need to think of an alternative to this trigger/overall approach before we hit scale... it's just super inefficient on a write side
// Imagine when a location has 8000 completed requests that never get touched... but the dispatchers may want to find one of these records occasionally... there's some better alternative we can figure out rather than keeping their group ids up to date
// When a location's defaultDispatchGroupId changes
triggers.register('groups', async (ctx, change) => {
  if (
    change.newDoc?.defaultDispatchGroupId ===
    change.oldDoc?.defaultDispatchGroupId
  )
    return;

  // Get all affected users first
  const users = await ctx.db
    .query('users')
    .withIndex('by_primaryLocationGroupId', q =>
      q.eq('primaryLocationGroupId', change.id)
    )
    .collect();

  const BATCH_SIZE = 100;
  for (let i = 0; i < users.length; i += BATCH_SIZE) {
    const batch = users.slice(i, i + BATCH_SIZE);
    await Promise.all(
      batch.map(user => {
        if (
          user.defaultDispatchGroupId !== change.newDoc?.defaultDispatchGroupId
        ) {
          return ctx.innerDb.patch(user._id, {
            defaultDispatchGroupId: change.newDoc?.defaultDispatchGroupId,
          });
        }
      })
    );
  }

  const vehicles = await ctx.db
    .query('vehicles')
    .withIndex('by_primaryLocationGroupId', q =>
      q.eq('primaryLocationGroupId', change.id)
    )
    .collect();

  for (let i = 0; i < vehicles.length; i += BATCH_SIZE) {
    const batch = vehicles.slice(i, i + BATCH_SIZE);
    await Promise.all(
      batch.map(vehicle => {
        if (
          vehicle.defaultDispatchGroupId !==
          change.newDoc?.defaultDispatchGroupId
        ) {
          return ctx.innerDb.patch(vehicle._id, {
            defaultDispatchGroupId: change.newDoc?.defaultDispatchGroupId,
          });
        }
      })
    );
  }

  const isFleetLocation =
    change.newDoc?.locationType === 'FLEET_TERMINAL' ||
    change.newDoc?.locationType === 'FLEET_YARD';
  const isServiceLocation =
    change.newDoc?.locationType === 'REPAIR_SHOP' ||
    change.newDoc?.locationType === 'MOBILE_REPAIR_BASE';

  // TODO: eventually need to handle for other cases
  if (!isFleetLocation && !isServiceLocation) {
    return;
  }

  const requests = await ctx.db
    .query('requests')
    .withIndex(
      isFleetLocation
        ? 'by_driverGroupId_and_status_and_createdAt'
        : 'by_mechanicServiceGroupId_and_status_and_createdAt',
      q =>
        q.eq(
          isFleetLocation ? 'driverGroupId' : 'mechanicServiceGroupId',
          change.id
        )
    )
    .collect();

  for (let i = 0; i < requests.length; i += BATCH_SIZE) {
    const batch = requests.slice(i, i + BATCH_SIZE);
    await Promise.all(
      batch.map(request => {
        const updates = isFleetLocation
          ? { fleetDispatchGroupId: change.newDoc?.defaultDispatchGroupId }
          : { mechanicDispatchGroupId: change.newDoc?.defaultDispatchGroupId };
        return ctx.innerDb.patch(request._id, updates);
      })
    );
  }
});
