import { useAuth } from '@clerk/clerk-react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import { ConnectComponentsProvider } from '@stripe/react-connect-js';
import { ReactNode, useEffect, useState } from 'react';

type StripeEmbeddedWrapperProps = {
  dialogMode?: boolean;
  show?: boolean; // Controls whether it even renders so we don't fetch client secret until we actually need to
  onClose: () => void;
  children?: ReactNode;
};

export function StripeEmbeddedWrapper({
  dialogMode,
  show,
  onClose,
  children,
}: StripeEmbeddedWrapperProps) {
  const { getToken } = useAuth();

  const [stripeConnectInstance, setStripeConnectInstance] = useState<any>(null);

  useEffect(() => {
    if (!show) {
      return;
    }

    const fetchClientSecret = async () => {
      try {
        const token = await getToken();
        const url = new URL(
          `${import.meta.env.VITE_CONVEX_SITE_URL}/stripe/accountSession`
        );

        const response = await fetch(url.href, {
          credentials: 'include',
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!response.ok) {
          throw new Error('Failed to load Stripe session');
        }

        const { clientSecret } = await response.json();
        return clientSecret;
      } catch (error) {
        console.error('Stripe session error', error);
        return undefined;
      }
    };

    const initStripe = async () => {
      const instance = loadConnectAndInitialize({
        publishableKey: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
        fetchClientSecret,
        appearance: {
          variables: {
            colorPrimary: '#2d6dca',
          },
        },
      });
      setStripeConnectInstance(instance);
    };

    void initStripe();
  }, [show, getToken]);

  if (!stripeConnectInstance || !show) return null;

  const content = (
    <>
      {stripeConnectInstance && (
        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
          {children}
        </ConnectComponentsProvider>
      )}
    </>
  );

  if (dialogMode) {
    return (
      <Dialog fullWidth maxWidth="xs" open onClose={onClose}>
        <DialogContent sx={{ py: 5, px: 3, display: 'flex' }}>
          {content}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return <>{content}</>;
}
