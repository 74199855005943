// hooks/useRequestImages.ts
import { useAuth } from '@clerk/clerk-react';
import { RequestId, RequestImageId } from '@cvx/types/entities/sharedIds';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  dbPromise,
  fetchImageBlob,
} from 'src/sections/request/utils/downloadHelpers';

const CACHE_DURATION_DAYS = 30;

type ImageState = {
  isLoading: boolean;
  url: string | null;
  error: Error | null;
};

export const useRequestImages = (
  requestId: RequestId,
  imageIds: RequestImageId[]
) => {
  const { getToken } = useAuth();
  const [imageStates, setImageStates] = useState<Record<string, ImageState>>(
    {}
  );
  const mountedRef = useRef(true);
  const urlCache = useRef<Record<string, string>>({});

  // Cleanup function to revoke object URLs
  const revokeUrls = useCallback(() => {
    Object.values(urlCache.current).forEach(URL.revokeObjectURL);
    urlCache.current = {};
  }, []);

  // Load a single image with caching
  const loadImage = useCallback(
    async (imageId: RequestImageId) => {
      if (!mountedRef.current) return;

      try {
        setImageStates(prev => ({
          ...prev,
          [imageId]: { isLoading: true, url: null, error: null },
        }));

        const blob = await fetchImageBlob({ imageId, getToken, requestId });

        if (mountedRef.current) {
          const url = URL.createObjectURL(blob);
          urlCache.current[imageId] = url;
          setImageStates(prev => ({
            ...prev,
            [imageId]: { isLoading: false, url, error: null },
          }));
        }
      } catch (error) {
        if (mountedRef.current) {
          setImageStates(prev => ({
            ...prev,
            [imageId]: {
              isLoading: false,
              url: null,
              error:
                error instanceof Error ? error : new Error('Unknown error'),
            },
          }));
        }
      }
    },
    [getToken, requestId]
  );

  // Load new images when ids change
  useEffect(() => {
    const newImageIds = imageIds.filter(id => !imageStates[id]?.url);
    newImageIds.forEach(loadImage);
  }, [imageIds.join(',')]);

  // Cleanup on unmount
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
      revokeUrls();
    };
  }, []);

  // Periodic cache cleanup
  useEffect(() => {
    const cleanup = async () => {
      const db = await dbPromise;
      const cutoff = Date.now() - CACHE_DURATION_DAYS * 24 * 60 * 60 * 1000;
      const tx = db.transaction('images', 'readwrite');
      const store = tx.objectStore('images');
      await store.delete(IDBKeyRange.upperBound(cutoff, true));
    };

    // Run cleanup once per day
    const interval = setInterval(cleanup, 24 * 60 * 60 * 1000);
    cleanup(); // Run once immediately

    return () => clearInterval(interval);
  }, []);

  return imageStates;
};
