import { api } from '@cvx/api';
import { useQuery } from 'convex/react';
import { useState } from 'react';
import { Doc } from 'src/convex/_generated/dataModel';

export function useCompanySearch() {
  const [debouncedTerm, setDebouncedTerm] = useState('');
  const [selectedCompany, setSelectedCompany] =
    useState<Doc<'companies'> | null>(null);

  // Get search results based on text
  const companies = useQuery(api.functions.companies.searchCompaniesByName, {
    searchTerm: debouncedTerm,
  });

  const returnedCompanies: Doc<'companies'>[] = companies || [];

  return {
    debouncedTerm,
    setDebouncedTerm,
    companies: returnedCompanies,
    selectedCompany,
    setSelectedCompany,
  };
}
