import { api } from '@cvx/api';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from '@tanstack/react-router';
import { useMutation } from 'convex/react';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { toast } from 'src/minimal-theme/components/snackbar';
import { ActionConfirmationDialog } from 'src/sections/request/actions/ActionConfirmationDialog';
import { useActionConfirmationDialog } from 'src/sections/request/actions/hooks/useActionConfirmationDialog';
import { getDeclineRequestActionState } from 'src/sections/request/actions/utils/getDeclineRequestActionState';
import { RequestActionProps } from 'src/sections/request/types/RequestActionProps';

export const DeclineRequestAction = ({
  request,
  me,
  requestStateInformation,
}: RequestActionProps) => {
  const { confirmAction, isOpen, pendingAction, onClose } =
    useActionConfirmationDialog();
  const navigate = useNavigate();

  const declineRequestAssignmentAsServiceDispatcher = useMutation(
    api.functions.requests.declineRequestAssignmentAsServiceDispatcher
  );

  const actionState = getDeclineRequestActionState({
    request,
    me,
    requestStateInformation,
  });

  if (!actionState.isVisible) {
    return null;
  }

  const handleDeclineRequest = async () => {
    try {
      navigate({ to: '/dashboard/requests' });
      const result = await declineRequestAssignmentAsServiceDispatcher({
        requestId: request._id,
      });

      if (result.success) {
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (e) {
      console.log('Something went wrong');
    }
  };

  return (
    <>
      <LoadingButton
        onClick={() =>
          confirmAction({
            title: actionState.confirmationTitle!,
            message: actionState.confirmationMessage!,
            onConfirm: handleDeclineRequest,
          })
        }
        fullWidth
        sx={{ height: '100%' }}
        color="error"
        variant="contained"
        disabled={!actionState.isEnabled}
        startIcon={
          <Iconify icon="solar:close-square-bold-duotone" width={40} />
        }
      >
        {actionState.message}
      </LoadingButton>

      <ActionConfirmationDialog
        open={isOpen}
        onClose={onClose}
        {...pendingAction}
      />
    </>
  );
};
