import {
  CompanyId,
  ConvexUserId,
  VehicleId,
} from '@cvx/types/entities/sharedIds';
import {
  ConvexUserZodId,
  DriverHistoryZodId,
  VehicleZodId,
} from '@cvx/types/zod/commonZodId';
import { getAll, getManyFrom } from 'convex-helpers/server/relationships';
import { DatabaseReader, MutationCtx } from '../_generated/server';
import {
  VehicleAssignmentStatus,
  vehicleAssignmentStatus,
} from '../schema/entities/driverHistory';
import { getUserContext } from '../utils/getUserContext';
import { zInternalMutation, zQuery } from './helpers/zodHelpers';

// Core query helpers for driver-vehicle relationships
const driverHistoryQueries = {
  /**
   * Gets current drivers assigned to a vehicle (not necessarily actively driving)
   */
  async getAssignedDriversForVehicle(db: DatabaseReader, vehicleId: VehicleId) {
    const assignments = await db
      .query('driverHistory')
      .withIndex('by_vehicleId', q => q.eq('vehicleId', vehicleId))
      .filter(q =>
        q.and(
          q.neq(q.field('started'), undefined),
          q.eq(q.field('stopped'), undefined)
        )
      )
      .collect();

    return await getAll(
      db,
      assignments.map(a => a.userId)
    );
  },

  /**
   * Gets all current vehicles for a driver
   */
  async getAssignedVehiclesForDriver(db: DatabaseReader, userId: ConvexUserId) {
    const assignments = await db
      .query('driverHistory')
      .withIndex('by_userId', q => q.eq('userId', userId))
      .filter(q =>
        q.and(
          q.neq(q.field('started'), undefined),
          q.eq(q.field('stopped'), undefined)
        )
      )
      .collect();

    return await getAll(
      db,
      assignments.map(a => a.vehicleId)
    );
  },

  /**
   * Gets the vehicle a driver is actively driving (if any)
   */
  async getActivelyDrivingVehicle(db: DatabaseReader, userId: ConvexUserId) {
    const assignment = await db
      .query('driverHistory')
      .withIndex('by_userId_status', q =>
        q.eq('userId', userId).eq('status', 'ACTIVE')
      )
      .first();

    if (!assignment) return null;
    return await db.get(assignment.vehicleId);
  },

  /**
   * Gets the driver currently operating this vehicle (if any)
   */
  async getActiveDriverForVehicle(db: DatabaseReader, vehicleId: VehicleId) {
    const assignment = await db
      .query('driverHistory')
      .withIndex('by_vehicle_status', q =>
        q.eq('vehicleId', vehicleId).eq('status', 'ACTIVE')
      )
      .first();

    if (!assignment) return null;
    return await db.get(assignment.userId);
  },

  /**
   * Gets the driver currently operating this vehicle (if any)
   */
  async getActiveAssignmentForVehicle(
    db: DatabaseReader,
    vehicleId: VehicleId
  ) {
    return await db
      .query('driverHistory')
      .withIndex('by_vehicle_status', q =>
        q.eq('vehicleId', vehicleId).eq('status', 'ACTIVE')
      )
      .first();
  },

  /**
   * Gets current assignment between specific driver and vehicle (if exists)
   */
  async getCurrentAssignment(
    db: DatabaseReader,
    params: { userId: ConvexUserId; vehicleId: VehicleId }
  ) {
    return await db
      .query('driverHistory')
      .withIndex('by_vehicle_user_status', q =>
        q.eq('vehicleId', params.vehicleId).eq('userId', params.userId)
      )
      .filter(q => q.eq(q.field('stopped'), undefined))
      .first();
  },
};

export const driverHistoryMutations = {
  async endAllAssignmentsForUser(
    ctx: MutationCtx,
    params: {
      userId: ConvexUserId;
      updatedBy: ConvexUserId;
    }
  ) {
    const { userId, updatedBy } = params;

    // Find all active assignments for this user
    const activeAssignments = await ctx.db
      .query('driverHistory')
      .withIndex('by_userId', q => q.eq('userId', userId))
      .filter(q => q.eq(q.field('stopped'), undefined))
      .collect();

    // End all assignments in parallel
    await Promise.all(
      activeAssignments.map(assignment =>
        ctx.db.patch(assignment._id, {
          status: 'INACTIVE',
          stopped: new Date().toISOString(),
          updatedBy,
        })
      )
    );

    return activeAssignments.length;
  },

  async endAllAssignmentsForVehicle(
    ctx: MutationCtx,
    params: {
      vehicleId: VehicleId;
      updatedBy: ConvexUserId;
    }
  ) {
    const { vehicleId, updatedBy } = params;

    // Find all active assignments for this vehicle
    const activeAssignments = await ctx.db
      .query('driverHistory')
      .withIndex('by_vehicleId', q => q.eq('vehicleId', vehicleId))
      .filter(q => q.eq(q.field('stopped'), undefined))
      .collect();

    // End all assignments in parallel
    await Promise.all(
      activeAssignments.map(assignment =>
        ctx.db.patch(assignment._id, {
          status: 'INACTIVE',
          stopped: new Date().toISOString(),
          updatedBy,
        })
      )
    );

    return activeAssignments.length;
  },

  // Assigning a driver (giving permission)
  async assignDriver(
    ctx: MutationCtx,
    params: {
      driverId: ConvexUserId;
      vehicleId: VehicleId;
      companyId: CompanyId;
      updatedBy: ConvexUserId;
      status?: VehicleAssignmentStatus;
    }
  ) {
    const {
      driverId,
      vehicleId,
      companyId,
      updatedBy,
      status = 'INACTIVE',
    } = params;

    // Validate ownership and get existing assignment if any
    const { existingAssignment } = await validateDriverVehicleOwnership(
      ctx.db,
      {
        driverId,
        vehicleId,
        companyId,
      }
    );

    // If already assigned, nothing to do
    if (existingAssignment) {
      return existingAssignment._id;
    }

    // Create new assignment (not actively driving yet)
    return await ctx.db.insert('driverHistory', {
      userId: driverId,
      vehicleId,
      started: new Date().toISOString(),
      status,
      createdBy: updatedBy,
      updatedBy,
    });
  },

  // Changing who is actively driving
  async switchActiveDriver(
    ctx: MutationCtx,
    params: {
      driverId: ConvexUserId;
      vehicleId: VehicleId;
      companyId: CompanyId;
      updatedBy: ConvexUserId;
    }
  ) {
    const { driverId, vehicleId, companyId, updatedBy } = params;

    // Validate ownership and get assignment
    const { existingAssignment } = await validateDriverVehicleOwnership(
      ctx.db,
      {
        driverId,
        vehicleId,
        companyId,
      }
    );

    // Must be assigned before can actively drive
    // TODO: there might be a case where... this doesn't have to be so
    if (!existingAssignment) {
      throw new Error(
        'Driver must be assigned to vehicle before they can drive it'
      );
    }

    // End current active driver's session if any
    const activeAssignment =
      await driverHistoryQueries.getActiveAssignmentForVehicle(
        ctx.db,
        vehicleId
      );

    if (activeAssignment) {
      await ctx.db.patch(activeAssignment._id, {
        status: 'INACTIVE',
        updatedBy,
      });
    }

    // Make the new driver active
    await ctx.db.patch(existingAssignment._id, {
      status: 'ACTIVE',
      updatedBy,
    });

    return existingAssignment._id;
  },
};

// Queries
export const getDriverHistoryByUserId = zQuery({
  args: { userId: ConvexUserZodId },
  handler: async (ctx, args) => {
    return await getManyFrom(ctx.db, 'driverHistory', 'by_userId', args.userId);
  },
});

export const getCurrentVehicleAssignments = zQuery({
  args: { userId: ConvexUserZodId },
  handler: (ctx, args) =>
    driverHistoryQueries.getAssignedVehiclesForDriver(ctx.db, args.userId),
});

export const getCurrentDriverAssignments = zQuery({
  args: { vehicleId: VehicleZodId },
  handler: (ctx, args) =>
    driverHistoryQueries.getAssignedDriversForVehicle(ctx.db, args.vehicleId),
});

export const getDriverActiveVehicle = zQuery({
  handler: async ctx => {
    const { user } = await getUserContext(ctx);
    if (!user) throw new Error('Not authenticated');

    return driverHistoryQueries.getActivelyDrivingVehicle(ctx.db, user._id);
  },
});

export const getDriverVehicleHistory = zQuery({
  handler: async ctx => {
    const { user } = await getUserContext(ctx);
    if (!user) throw new Error('Not authenticated');

    return await getManyFrom(ctx.db, 'driverHistory', 'by_userId', user._id);
  },
});

export const updateDriverStatus = zInternalMutation({
  args: {
    historyId: DriverHistoryZodId,
    status: vehicleAssignmentStatus,
    updatedBy: ConvexUserZodId,
  },
  handler: async (ctx, args) => {
    await ctx.db.patch(args.historyId, {
      status: args.status,
      updatedBy: args.updatedBy,
      ...(args.status === 'INACTIVE' && {
        stopped: new Date().toISOString(),
      }),
    });
  },
});

// export const handleBreakdownAssignment = zMutation({
//   args: {
//     breakdownRequestId: ServiceRequestZodId,
//     vehicleId: VehicleZodId,
//     isTemporaryVehicle: z.boolean().optional(),
//   },
//   handler: async (ctx, args) => {
//     const { user } = await getUserContext(ctx);
//     if (!user) throw new Error('Not authenticated');

//     // If reporting breakdown for current vehicle, no changes needed
//     const currentAssignment = await driverHistoryQueries.getCurrentVehicle(
//       ctx.db,
//       user._id
//     );

//     if (currentAssignment?.vehicleId === args.vehicleId) {
//       return true;
//     }

//     // // If temporary vehicle, create new temporary assignment
//     // if (args.isTemporaryVehicle) {
//     //   await driverHistoryMutations.assignTemporaryVehicle(ctx, {
//     //     userId: user._id,
//     //     vehicleId: args.vehicleId,
//     //     createdBy: user._id,
//     //   });
//     //   return true;
//     // }

//     // Otherwise switch to different assigned vehicle
//     await driverHistoryMutations.switchDrivers(ctx, {
//       vehicleId: args.vehicleId,
//       newDriverId: user._id,
//       updatedBy: user._id,
//     });

//     return true;
//   },
// });

// Common validation helper
const validateDriverVehicleOwnership = async (
  db: DatabaseReader,
  {
    driverId,
    vehicleId,
    companyId,
  }: {
    driverId: ConvexUserId;
    vehicleId: VehicleId;
    companyId: CompanyId;
  }
) => {
  const [driver, vehicle, existingAssignment] = await Promise.all([
    db.get(driverId),
    db.get(vehicleId),
    driverHistoryQueries.getCurrentAssignment(db, {
      userId: driverId,
      vehicleId,
    }),
  ]);

  if (!driver || !vehicle) {
    throw new Error('Driver or vehicle not found');
  }

  if (driver.companyId !== companyId || vehicle.companyId !== companyId) {
    throw new Error('Driver or vehicle does not belong to your company');
  }

  return { driver, vehicle, existingAssignment };
};
