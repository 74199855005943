import { api } from '@cvx/api';
import { Badge, IconButton, MenuItem, MenuList } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useQuery } from 'convex/react';
import { Label } from 'src/minimal-theme/components/label';
import { MuiTypeSafeLink } from 'src/minimal-theme/components/routes/components/mui-link';

import { ConvexUser } from '@cvx/types/entities/usersEntityTypes';
import { Iconify } from 'src/minimal-theme/components/iconify';
import {
  getBadgeVariant,
  getStatusColor,
  getStatusLabel,
} from 'src/utils/helper';
import {
  CustomPopover,
  usePopover,
} from '../../minimal-theme/components/custom-popover';
import { useBoolean } from '../../minimal-theme/hooks/use-boolean';

type MechanicTableRowProps = {
  row: any;
  selected: boolean;
  onSelectRow: () => void;
  setUserToDelete: (user: ConvexUser) => void;
};

export function MechanicTableRow({
  row,
  selected,
  onSelectRow,
  setUserToDelete,
}: MechanicTableRowProps) {
  const confirm = useBoolean();

  const popover = usePopover();
  const me = useQuery(api.functions.users.getMe);

  // const activeRequests = useQuery(api.functions.requests.getActiveRequests, {
  //   userId: row._id,
  // });

  return (
    <>
      <TableRow hover selected={selected} aria-checked={selected} tabIndex={-1}>
        <TableCell padding="checkbox">
          <Checkbox id={row._id} checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell>
          <Stack spacing={2} direction="row" alignItems="center">
            <Badge
              variant={getBadgeVariant(row.status)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              sx={{ mr: 2 }}
            >
              <Avatar alt={row.clerkUser.firstName} src={row.imageUrl}>
                {row.clerkUser.firstName[0]}
              </Avatar>
            </Badge>

            <Stack
              sx={{
                typography: 'body2',
                flex: '1 1 auto',
                alignItems: 'flex-start',
              }}
            >
              <MuiTypeSafeLink
                to="/dashboard/mechanics/$mechanicId"
                params={{ mechanicId: row._id }}
                color="inherit"
                sx={{ cursor: 'pointer' }}
              >
                {row.clerkUser.firstName} {row.clerkUser.lastName}
              </MuiTypeSafeLink>
              <Box component="span" sx={{ color: 'text.disabled' }}>
                {row.clerkUser.primaryEmailAddress.emailAddress}
              </Box>
            </Stack>
          </Stack>
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {row.clerkUser.primaryPhoneNumber.phoneNumber}
        </TableCell>

        <TableCell>
          <Label variant="soft" color={getStatusColor(row.status)}>
            {getStatusLabel(row.status)}
          </Label>
        </TableCell>

        {/* <TableCell>
          {activeRequests?.map((request, index) => (
            <>
              <MuiTypeSafeLink
                key={request._id}
                to="/dashboard/requests/$requestId"
                params={{ requestId: request._id }}
                color="primary"
                sx={{ cursor: 'pointer' }}
              >
                {request.caseNumber}
              </MuiTypeSafeLink>
              {index < activeRequests.length - 1 && ', '}
            </>
          ))}
        </TableCell> */}
        <TableCell>
          <IconButton
            color={popover.open ? 'inherit' : 'default'}
            onClick={popover.onOpen}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
        {/* <TableCell>
          <Stack direction="row" alignItems="center">
            <Tooltip
              title={row.assignedServiceRequestId ? 'Unassign' : 'Assign'}
              placement="top"
              arrow
            >
              <IconButton
                color={assignServiceRequest.value ? 'inherit' : 'default'}
                onClick={assignServiceRequest.onTrue}
              >
                <Iconify
                  icon={
                    row.assignedServiceRequestId
                      ? 'mdi:remove-circle-outline'
                      : 'mdi:add-circle-outline'
                  }
                />
              </IconButton>
            </Tooltip>

            <IconButton
              color={popover.open ? 'inherit' : 'default'}
              onClick={popover.onOpen}
            >
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </Stack>
        </TableCell> */}
      </TableRow>

      {/* <MechanicQuickAssignForm
        userId={row._id}
        open={assignServiceRequest.value}
        onClose={assignServiceRequest.onFalse}
      />  */}

      <CustomPopover
        open={popover.open}
        anchorEl={popover.anchorEl}
        onClose={popover.onClose}
        slotProps={{ arrow: { placement: 'right-top' } }}
      >
        <MenuList>
          <MenuItem
            component={MuiTypeSafeLink}
            to="/dashboard/mechanics/$mechanicId"
            params={{ mechanicId: row._id }}
            onClick={() => {
              confirm.onTrue();
              popover.onClose();
            }}
            sx={{ color: 'inherit' }}
          >
            <Iconify icon="solar:pen-bold" />
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              setUserToDelete(row);
              popover.onClose();
            }}
            sx={{ color: 'inherit' }}
            disabled={row._id === me?._id}
          >
            <Iconify icon="solar:trash-bin-trash-bold" />
            Delete
          </MenuItem>
        </MenuList>
      </CustomPopover>
    </>
  );
}
