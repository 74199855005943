import { api } from '@cvx/api';
import { useConvexAuth, useQuery } from 'convex/react';

export function useActiveAssignments() {
  const { isAuthenticated } = useConvexAuth();

  const me = useQuery(api.functions.users.getMe, isAuthenticated ? {} : 'skip');

  const activeRequestsAll = useQuery(
    api.functions.requests.getActiveRequests,
    {}
  );

  const activeAssignments = activeRequestsAll?.filter(
    r => r.currentAssignedToId === me?._id
  );

  return {
    activeAssignments,
  };
}
