import { api } from '@cvx/api';
import { LoadingButton } from '@mui/lab';
import { Alert, Button, Stack } from '@mui/material';
import { ConnectTaxRegistrations } from '@stripe/react-connect-js';
import { useMutation } from 'convex/react';
import { useState } from 'react';
import { useTaxRegistrations } from 'src/components/stripe/hooks/useTaxRegistrations';
import { isOnboardedAndReadyToCharge } from 'src/components/stripe/utils/isStripeTestMode';
import { EnhancedUser } from 'src/convex/functions/users';
import { useScheduledActions } from 'src/hooks/useScheduledActions';
import { toast } from 'src/minimal-theme/components/snackbar';
import { StripeEmbeddedWrapper } from 'src/sections/invoice/view/StripeEmbeddedWrapper';
import { locationHasRequiredTaxRegistrations } from 'src/utils/tax';

export function StripeSetupAlerts({
  requestStateShortCode,
  requestCountryCode,
  me,
  inRequest,
}: {
  requestStateShortCode?: string;
  requestCountryCode?: string;
  me: EnhancedUser;
  inRequest?: boolean;
}) {
  const { checkRegistrations, isChecking } = useTaxRegistrations();

  const [showTaxRegistrationDialog, setShowTaxRegistrationDialog] =
    useState(false);

  const createConnectedAccount = useMutation(
    api.functions.invoices.createConnectedAccount
  );

  const { watchIds } = useScheduledActions({
    successMessage: 'Stripe Connected account successfully created',
  });

  const canIssueInvoices =
    me.roles.some(r => r.type === 'SERVICE_DISPATCHER') ||
    me.roles.some(r => r.type === 'THIRD_PARTY_DISPATCHER') ||
    (me.roles.some(r => r.type === 'TECHNICIAN_PROVIDER') &&
      me.primaryLocation?.noDispatchSoleProprietor);

  const canOnboardConnectedAccount =
    me.isCompanyPrimaryAdmin &&
    me.primaryLocation._id === me.primaryLocation.defaultDispatchGroupId;

  const CheckTaxRegistrationsButton = (
    <Stack direction="row" justifyContent={'flex-end'} sx={{ mt: 1 }}>
      <LoadingButton
        variant="contained"
        color="primary"
        disabled={isChecking}
        loading={isChecking}
        sx={{ width: '270px' }}
        onClick={async () => {
          setShowTaxRegistrationDialog(true);
          await checkRegistrations();
        }}
      >
        Manage Registrations
      </LoadingButton>
    </Stack>
  );

  if (!me.clerkUser.primaryEmailAddress?.emailAddress) {
    return (
      <Alert severity="error" sx={{ py: 2, mb: 2 }}>
        Your account does not have an email address associated with it. Please
        go to your profile settings and add an email address to onboard to
        Stripe payments.
      </Alert>
    );
  }

  if (
    !me.primaryLocation.stripeAccountId &&
    canIssueInvoices &&
    canOnboardConnectedAccount
  ) {
    return (
      <Alert
        severity="warning"
        sx={{ py: 2, mb: 2 }}
        action={
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              try {
                const response = await createConnectedAccount({});

                if (response.success && response.data.scheduleId) {
                  await watchIds([response.data.scheduleId], {});
                } else {
                  toast.error(response.message);
                }
              } catch (error) {
                toast.error('Error creating Stripe connected account');
              }
            }}
          >
            Create Stripe Account
          </Button>
        }
      >
        Your location doesn't have an active Stripe account. Please click the
        button to start the setup process.
      </Alert>
    );
  }

  if (
    !isOnboardedAndReadyToCharge(me.primaryLocation) &&
    canIssueInvoices &&
    canOnboardConnectedAccount
  ) {
    return (
      <Alert
        severity="info"
        sx={{ py: 2, mb: 2 }}
        action={
          <Button
            variant="contained"
            color="primary"
            href={me.primaryLocation.stripeConnectedAccountOnboardingLink}
            sx={{ width: '170px' }}
          >
            Finish Stripe Setup
          </Button>
        }
      >
        You have successfully setup a Stripe account! Please click the button to
        finish the setup process. Without this, you will not be able to
        calculate taxes for invoices.
      </Alert>
    );
  }

  if (
    requestStateShortCode &&
    requestCountryCode &&
    canIssueInvoices &&
    canOnboardConnectedAccount
  ) {
    const disabledDueToTaxRegistrationIssues =
      !locationHasRequiredTaxRegistrations({
        requestCountryCode,
        requestStateShortCode,
        stateTaxRegistrations: me.primaryLocation.stateTaxRegistrations,
      });

    if (disabledDueToTaxRegistrationIssues) {
      return (
        <>
          <StripeEmbeddedWrapper
            dialogMode
            show={showTaxRegistrationDialog}
            onClose={() => setShowTaxRegistrationDialog(false)}
          >
            <ConnectTaxRegistrations
              onAfterTaxRegistrationAdded={checkRegistrations}
            />
          </StripeEmbeddedWrapper>
          <Alert severity="warning" sx={{ py: 2, mb: 2 }}>
            {`You do not have a Stripe tax registration set up for the region where the service took place (${requestStateShortCode}). 
            Please ensure you have set up a tax registration for this region in your Stripe account.`}{' '}
            {CheckTaxRegistrationsButton}
          </Alert>
        </>
      );
    }
  }

  const noStateOrCountryCode = !requestStateShortCode || !requestCountryCode;

  if (
    canIssueInvoices &&
    canOnboardConnectedAccount &&
    noStateOrCountryCode &&
    inRequest
  ) {
    return (
      <Alert severity="warning" sx={{ py: 2, mb: 2 }}>
        Driver has not shared there location and no address has been filled in.
        Breakdown location is required for invoice creation.
      </Alert>
    );
  }

  if (
    canIssueInvoices &&
    canOnboardConnectedAccount &&
    (!me.primaryLocation.stateTaxRegistrations ||
      me.primaryLocation.stateTaxRegistrations?.length === 0)
  ) {
    return (
      <>
        <StripeEmbeddedWrapper
          dialogMode
          show={showTaxRegistrationDialog}
          onClose={() => setShowTaxRegistrationDialog(false)}
        >
          <ConnectTaxRegistrations />
        </StripeEmbeddedWrapper>
        <Alert severity="warning" sx={{ py: 2, mb: 2 }}>
          You currently do not have any tax registrations set up in your Stripe
          account. Please ensure you have set up a tax registration for all
          applicable states in your Stripe account (any state where services are
          being provided).
          {CheckTaxRegistrationsButton}
        </Alert>
      </>
    );
  }

  return null;
}
