import { createFileRoute, redirect } from '@tanstack/react-router';
import Page from 'src/pages/sign-in';
import { z } from 'zod';

const signInSearchSchema = z.object({
  redirect: z.string().optional(),
});

export const Route = createFileRoute('/sign-in')({
  validateSearch: search => signInSearchSchema.parse(search),
  beforeLoad: async ({ context: { isAuthenticated }, search }) => {
    if (isAuthenticated) {
      // Use the redirect parameter if it exists
      const redirectTo = search.redirect || '/dashboard/requests';
      throw redirect({ to: redirectTo });
    }
  },
  component: Page,
});
