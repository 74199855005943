import { api } from '@cvx/api';
import { Avatar, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { useQuery } from 'convex/react';
import { useState } from 'react';
import { friendlyTypeNames } from 'src/convex/schema/enums/vehicleType';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { Label } from 'src/minimal-theme/components/label';
import { MuiTypeSafeLink } from 'src/minimal-theme/components/routes/components/mui-link';
import { useBoolean } from 'src/minimal-theme/hooks/use-boolean';
import { DeleteVehicleDialogButton } from 'src/sections/DeleteVehicleDialogButton';

import { Vehicle } from '@cvx/types/entities/vehiclesEntityTypes';
import {
  CustomPopover,
  usePopover,
} from '../../minimal-theme/components/custom-popover';
import { UnitQuickAssignForm } from './unit-quick-assign-form';

const VEHICLE_TYPE_MAPPING: { [key: string]: string } = {
  TRUCK: 'Truck',
  TRAILER: 'Trailer',
  TOW_TRUCK: 'Tow Truck',
};

const VEHICLE_STATUS_MAPPING: { [key: string]: string } = {
  ACTIVE: 'Active',
  OUT_OF_SERVICE: 'Out of Service',
  RETIRED: 'Retired',
  SOLD: 'Sold',
};

interface UnitTableRowProps {
  row: Vehicle;
  selected: boolean;
  onSelectRow: () => void;
}

export function UnitTableRow({
  row,
  selected,
  onSelectRow,
}: UnitTableRowProps) {
  const confirm = useBoolean();

  const popover = usePopover();

  const quickEdit = useBoolean();

  const location = useQuery(
    api.functions.companies.getLocationById,
    row.primaryLocationGroupId ? { id: row.primaryLocationGroupId } : 'skip'
  );

  const currentDriverAssignments = useQuery(
    api.functions.driverHistory.getCurrentDriverAssignments,
    { vehicleId: row._id }
  );

  const [actionType, setActionType] = useState<'assign' | 'unassign'>('assign');

  return (
    <>
      <TableRow hover selected={selected} aria-checked={selected} tabIndex={-1}>
        <TableCell padding="checkbox">
          <Checkbox id={row.vin} checked={selected} onClick={onSelectRow} />
        </TableCell>
        <TableCell>
          <MuiTypeSafeLink
            to="/dashboard/units/$unitId"
            params={{ unitId: row._id }}
            color="inherit"
          >
            #{row.vin}
          </MuiTypeSafeLink>
        </TableCell>
        <TableCell>{row.maker}</TableCell>

        <TableCell>{row.model}</TableCell>

        <TableCell>{row.type ? friendlyTypeNames[row.type] : 'N/A'}</TableCell>

        <TableCell>{row.modelYear}</TableCell>

        <TableCell>{row.unitNumber}</TableCell>

        <TableCell>{location?.name}</TableCell>

        <TableCell>
          <Label variant="soft" color="success">
            {row.status ? VEHICLE_STATUS_MAPPING[row.status] : ''}
          </Label>
        </TableCell>

        <TableCell>
          {currentDriverAssignments &&
            currentDriverAssignments
              .filter(
                (assignment): assignment is NonNullable<typeof assignment> =>
                  assignment !== null
              )
              .map(assignment => (
                <MuiTypeSafeLink
                  key={assignment._id}
                  to="/dashboard/drivers/$driverId"
                  params={{ driverId: assignment._id }}
                  color="inherit"
                  sx={{ cursor: 'pointer' }}
                >
                  <Stack spacing={1} direction="row" alignItems="center">
                    <Avatar
                      alt={assignment.clerkUser.fullName}
                      src={assignment.clerkUser.imageUrl}
                      sx={{ width: 24, height: 24 }}
                    />

                    <Stack
                      sx={{
                        flex: '1 1 auto',
                        alignItems: 'flex-start',
                      }}
                    >
                      <Typography variant="body2">
                        {assignment.clerkUser.fullName}
                      </Typography>
                    </Stack>
                  </Stack>
                </MuiTypeSafeLink>
              ))}
        </TableCell>

        <TableCell>
          <Stack direction="row" alignItems="center">
            <Tooltip title="Assign Driver" placement="top" arrow>
              <IconButton
                onClick={() => {
                  setActionType('assign');
                  quickEdit.onTrue();
                }}
              >
                <Iconify icon="material-symbols:person-add" />
              </IconButton>
            </Tooltip>

            {currentDriverAssignments &&
              currentDriverAssignments.length > 0 && (
                <Tooltip title="Unassign Driver" placement="top" arrow>
                  <IconButton
                    onClick={() => {
                      setActionType('unassign');
                      quickEdit.onTrue();
                    }}
                  >
                    <Iconify icon="material-symbols:person-remove" />
                  </IconButton>
                </Tooltip>
              )}

            <IconButton
              color={popover.open ? 'inherit' : 'default'}
              onClick={popover.onOpen}
            >
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>

      <UnitQuickAssignForm
        open={quickEdit.value}
        onClose={quickEdit.onFalse}
        truckId={row._id}
        currentDrivers={
          actionType === 'unassign'
            ? currentDriverAssignments?.filter(
                (driver): driver is NonNullable<typeof driver> =>
                  driver !== null
              )
            : undefined
        }
      />

      <CustomPopover
        open={popover.open}
        anchorEl={popover.anchorEl}
        onClose={popover.onClose}
        slotProps={{ arrow: { placement: 'right-top' } }}
      >
        <MenuList>
          <MenuItem
            component={MuiTypeSafeLink}
            to="/dashboard/units/$unitId"
            params={{ unitId: row._id }}
            onClick={() => {
              popover.onClose();
            }}
          >
            <Iconify icon="solar:pen-bold" />
            Edit
          </MenuItem>
          <DeleteVehicleDialogButton
            vehicle={row}
            onClosePopper={() => popover.onClose()}
          />
        </MenuList>
      </CustomPopover>
    </>
  );
}
