import { RequestActionProps } from 'src/sections/request/types/RequestActionProps';

export const getNavigateActionState = ({
  requestStateInformation,
}: RequestActionProps) => {
  const { isTechnician, isTechnicianAccepted, isTechnicianEnRoute } =
    requestStateInformation;

  const isVisible =
    isTechnician && (isTechnicianAccepted || isTechnicianEnRoute);

  if (!isVisible) {
    return {
      isVisible: false,
      isEnabled: false,
      message: '',
    };
  }

  const declineMessage = 'Decline Request';
  const canPerform = isVisible;

  return {
    isVisible: true,
    isEnabled: canPerform,
    message: canPerform ? declineMessage : `Waiting On Service Dispatcher`,
    confirmationTitle: declineMessage,
    confirmationMessage: 'Are you sure you want to decline this request?',
  };
};
