import { api } from '@cvx/api';
import { ConvexUser } from '@cvx/types/entities/usersEntityTypes';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { useQuery } from 'convex/react';
import { useCallback, useState } from 'react';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { RouterLink } from 'src/minimal-theme/components/routes/components/router-link';
import { Scrollbar } from 'src/minimal-theme/components/scrollbar';
import {
  emptyRows,
  getComparator,
  rowInPage,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/minimal-theme/components/table';
import { useSetState } from 'src/minimal-theme/hooks/use-set-state';
import { DashboardContent } from 'src/minimal-theme/layouts/dashboard';
import { DeleteUserDialog } from 'src/sections/DeleteUserDialogButton';
import { DriverTableFilters } from 'src/types/user';
import { DriverTableFiltersResult } from '../driver-table-filters-result';
import { DriverTableRow } from '../driver-table-row';
import { DriverTableToolbar } from '../driver-table-toolbar';

const TABLE_HEAD = [
  { id: 'clerkUser.firstName', label: 'Name' },
  { id: 'clerkUser.primaryPhoneNumber.phoneNumber', label: 'Phone Number' },
  { id: 'location', label: 'Primary Location' },
  { id: 'assignedVehicle', label: 'Assigned Truck' },
  { id: 'actions', label: 'Actions' },
];

export function DriverListView() {
  const table = useTable();

  const tableData = useQuery(
    api.functions.users.getUsersOfRoleForMultipleLocations,
    {
      roleType: 'DRIVER_FLEET',
    }
  );

  const filters = useSetState<DriverTableFilters>({
    name: '',
    status: 'all',
  });

  const dataFiltered = applyFilter({
    inputData: tableData ?? [],
    comparator: getComparator(table.order, table.orderBy),
    filters: filters.state,
  });

  const [userToDelete, setUserToDelete] = useState<ConvexUser | null>(null);

  const dataInPage = rowInPage(dataFiltered, table.page, table.rowsPerPage);

  const canReset = !!filters.state.name || filters.state.status !== 'all';

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleEditRow = useCallback((id: string) => {}, []);

  const handleFilterStatus = useCallback(
    (_event: React.SyntheticEvent, newValue: string) => {
      table.onResetPage();
      filters.setState({ status: newValue });
    },
    [filters, table]
  );

  if (!tableData) return null;

  return (
    <>
      <DashboardContent>
        <Button
          component={RouterLink}
          href={'/dashboard/drivers/new'}
          color="primary"
          variant="contained"
          startIcon={<Iconify icon="mingcute:add-line" />}
          sx={{ width: 'fit-content', mb: 2, ml: 'auto' }}
        >
          Add Driver
        </Button>
        <Card>
          <DriverTableToolbar
            filters={filters}
            onResetPage={table.onResetPage}
          />

          {canReset && (
            <DriverTableFiltersResult
              filters={filters}
              totalResults={dataFiltered.length}
              onResetPage={table.onResetPage}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <Box sx={{ position: 'relative' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected.length}
              rowCount={dataFiltered.length}
              onSelectAllRows={checked =>
                table.onSelectAllRows(
                  checked,
                  dataFiltered.map(row => row._id)
                )
              }
            />

            <Scrollbar>
              <Table
                size={table.dense ? 'small' : 'medium'}
                sx={{ minWidth: 960 }}
              >
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={dataFiltered.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                  onSelectAllRows={checked =>
                    table.onSelectAllRows(
                      checked,
                      dataFiltered.map(row => row._id)
                    )
                  }
                />

                <TableBody>
                  {dataInPage.map(row => (
                    <DriverTableRow
                      key={row._id}
                      row={row}
                      selected={table.selected.includes(row._id)}
                      onSelectRow={() => table.onSelectRow(row._id)}
                      onEditRow={() => handleEditRow(row._id)}
                      setUserToDelete={setUserToDelete}
                    />
                  ))}

                  <TableEmptyRows
                    height={table.dense ? 56 : 56 + 20}
                    emptyRows={emptyRows(
                      table.page,
                      table.rowsPerPage,
                      dataFiltered.length
                    )}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </Box>

          <TablePaginationCustom
            page={table.page}
            dense={table.dense}
            count={dataFiltered.length}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onChangeDense={table.onChangeDense}
            onRowsPerPageChange={table.onChangeRowsPerPage}
          />
        </Card>
        <DeleteUserDialog
          user={userToDelete}
          onClose={() => setUserToDelete(null)}
        />
      </DashboardContent>
    </>
  );
}

type ApplyFilterProps = {
  inputData: any[];
  filters: DriverTableFilters;
  comparator: (a: any, b: any) => number;
};

function applyFilter({ inputData, comparator, filters }: ApplyFilterProps) {
  const { name, status } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map(el => el[0]);

  if (name) {
    inputData = inputData.filter(
      user =>
        user.clerkUser.firstName.toLowerCase().indexOf(name.toLowerCase()) !==
          -1 ||
        user.clerkUser.lastName.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }

  if (status !== 'all') {
    inputData = inputData.filter(user => user.status === status);
  }

  // if (role.length) {
  //   inputData = inputData.filter(user => role.includes(user.role));
  // }

  return inputData;
}
