import { MyPrimaryLocation } from '@cvx/types/entities/groupsEntityTypes';

export const isStripeTestMode = () => {
  const stripeKey = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;

  if (stripeKey.startsWith('pk_test_')) {
    return true;
  } else if (stripeKey.startsWith('pk_live_')) {
    return false;
  }

  return false;
};

export const isOnboardedAndReadyToCharge = (location: MyPrimaryLocation) => {
  const testMode = isStripeTestMode();

  if (!testMode) {
    return !!location.stripeAccountInfo?.charges_enabled;
  }
  return !!location.stripeAccountInfo?.details_submitted;
};
