import { api } from '@cvx/api';
import { ConvexUserId } from '@cvx/types/entities/sharedIds';
import { createFileRoute, notFound } from '@tanstack/react-router';
import { ValidPaths } from 'src/minimal-theme/layouts/config-nav-dashboard';
import Page from 'src/pages/dashboard/mechanic/edit';
import {
  checkRouteAccess,
  routeAccessConfigs,
} from 'src/utils/checkRouteAccess';

export const Route = createFileRoute('/_auth/dashboard/mechanics/$mechanicId')({
  beforeLoad: ({ context, location }) =>
    checkRouteAccess(
      context,
      routeAccessConfigs.serviceProviderOnly,
      location.pathname as ValidPaths
    ),
  loader: async ({ context, params }) => {
    try {
      const user = await context.convex.query(api.functions.users.getUserById, {
        id: params.mechanicId as ConvexUserId,
      });

      return user;
    } catch (error) {
      // TODO: Come up with a better pattern all around for this
      // could be not found (deleted) or not authorized.. found but not authorized
      throw notFound();
    }
  },
  component: Page,
});
