import { InvoiceItemId } from '@cvx/types/entities/sharedIds';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import {
  getInvoiceServiceTypeDisplay,
  invoiceServiceType,
  InvoiceServiceType,
} from 'src/convex/schema/enums/invoiceServiceType';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { LocalInvoiceItem } from 'src/sections/invoice/hooks/useInvoiceLineItems';

type InvoiceLineItemProps = {
  item: LocalInvoiceItem;
  onRemove: (id: InvoiceItemId) => void;
  onSave: (id: InvoiceItemId, item: LocalInvoiceItem) => void;
  invoiceStatus?: string;
  disabled?: boolean;
};

export function InvoiceLineItem({
  item,
  onRemove,
  onSave,
  invoiceStatus,
  disabled,
}: InvoiceLineItemProps) {
  const [formState, setFormState] = useState(() => ({
    category: item.category ?? '',
    description: item.description ?? '',
    quantity: item.quantity ?? 0,
    amount: (item.amount ?? 0) / 100,
    total: ((item.quantity ?? 0) * (item.amount ?? 0)) / 100,
    isDirty: false,
  }));

  const { category, description, quantity, amount, total, isDirty } = formState;

  const handleAmountChange = (val: number) => {
    setFormState(prev => ({
      ...prev,
      amount: val,
      total: prev.quantity * val,
      isDirty: true,
    }));
  };

  const handleQuantityChange = (val: number) => {
    setFormState(prev => ({
      ...prev,
      quantity: val,
      total: val * prev.amount,
      isDirty: true,
    }));
  };

  const handleCategoryChange = (value: InvoiceServiceType) => {
    setFormState(prev => ({
      ...prev,
      category: value,
      isDirty: true,
    }));
  };

  const handleDescriptionChange = (value: string) => {
    setFormState(prev => ({
      ...prev,
      description: value,
      isDirty: true,
    }));
  };

  const handleSave = () => {
    const amountInCents = Math.round(amount * 100);
    const { isDirty, total, ...relevantFields } = formState;

    onSave(item._id!, {
      ...item,
      ...relevantFields,
      amount: amountInCents,
      quantity: formState.quantity,
      description: formState.description,
      category: formState.category as InvoiceServiceType,
    });
    setFormState(prev => ({ ...prev, isDirty: false }));
  };

  const isValid = Boolean(
    category &&
      typeof quantity === 'number' &&
      quantity > 0 &&
      typeof amount === 'number' &&
      amount > 0
  );

  const canSave = isDirty && isValid && item._id && invoiceStatus === 'DRAFT';
  const canRemove = item._id && invoiceStatus === 'DRAFT';

  return (
    <Stack alignItems="flex-end" spacing={1.5}>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={2}
        sx={{ width: 1 }}
      >
        <FormControl fullWidth>
          <Box width={'100%'}>
            <InputLabel id="category-select-label">Category</InputLabel>
            <Select
              fullWidth
              disabled={disabled}
              inputProps={{ id: 'category-select-label' }}
              value={category}
              label="Category"
              placeholder="Select Category"
              onChange={e =>
                handleCategoryChange(e.target.value as InvoiceServiceType)
              }
            >
              {invoiceServiceType.options.map(option => (
                <MenuItem key={option} value={option}>
                  {getInvoiceServiceTypeDisplay(option)}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </FormControl>

        <Box width={'100%'}>
          <TextField
            fullWidth
            disabled={disabled}
            value={description}
            label="Description"
            placeholder="Description"
            onChange={e => handleDescriptionChange(e.target.value)}
          />
        </Box>

        <NumericFormat
          customInput={TextField}
          thousandSeparator
          disabled={disabled}
          label="Quantity"
          onValueChange={values => {
            handleQuantityChange(values.floatValue ?? 0);
          }}
          value={quantity || ''}
          valueIsNumericString
          allowNegative={false}
          decimalScale={0}
          isAllowed={values => {
            const { floatValue } = values;
            return (
              floatValue === undefined ||
              (floatValue >= 0 && Number.isInteger(floatValue))
            );
          }}
          sx={{ minWidth: '75px' }}
        />
        <NumericFormat
          customInput={TextField}
          thousandSeparator
          label="Amount"
          onValueChange={values => {
            handleAmountChange(values.floatValue ?? 0);
          }}
          value={amount}
          valueIsNumericString
          disabled={disabled}
          prefix="$"
          decimalScale={2}
          fixedDecimalScale
          allowNegative={false}
          isAllowed={values => {
            const { floatValue } = values;
            return floatValue === undefined || floatValue >= 0;
          }}
          sx={{ minWidth: '100px' }}
        />
        <NumericFormat
          customInput={TextField}
          thousandSeparator
          label="Total"
          value={total}
          valueIsNumericString
          prefix="$"
          decimalScale={2}
          fixedDecimalScale
          disabled
          sx={{ minWidth: '100px' }}
        />
      </Stack>

      {!disabled && (
        <Stack direction="row" spacing={1}>
          <Button
            size="small"
            color="primary"
            disabled={!canSave}
            startIcon={<Iconify icon="solar:diskette-bold" />}
            onClick={handleSave}
          >
            Save
          </Button>
          {invoiceStatus === 'DRAFT' && (
            <Button
              size="small"
              color="error"
              startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
              disabled={!canRemove}
              onClick={() => onRemove(item._id!)}
            >
              Remove
            </Button>
          )}
        </Stack>
      )}
    </Stack>
  );
}
