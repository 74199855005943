import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import { useNavigate } from '@tanstack/react-router';
import { DashboardContent } from 'src/minimal-theme/layouts/dashboard';
import { InvoiceNewEditForm } from '../../invoice/invoice-new-edit-form';

export function InvoiceEditView() {
  const navigate = useNavigate();
  return (
    <DashboardContent>
      <ErrorBoundary
        fallback={
          <Dialog
            open={true}
            onClose={() => navigate({ to: '/dashboard/invoices' })}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>Invoice Error</DialogTitle>
            <DialogContent sx={{ py: 5, px: 3 }}>
              An error occurred when generating your invoice. Please check your
              Stripe dashboard to ensure you have correctly onboarded and there
              are no outstanding requirements.
            </DialogContent>
          </Dialog>
        }
      >
        <InvoiceNewEditForm />
      </ErrorBoundary>
    </DashboardContent>
  );
}
