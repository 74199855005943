import { RequestActionProps } from 'src/sections/request/types/RequestActionProps';

export const getVerifyWorkActionState = ({
  requestStateInformation,
}: RequestActionProps) => {
  const {
    needsVerification,
    isAssignedToMe,
    fleetInitiated,
    isServiceDispatcher,
    thirdPartyInitiated,
  } = requestStateInformation;

  const isVisible = needsVerification;

  if (!isVisible) {
    return {
      isVisible: false,
      isEnabled: false,
      message: '',
    };
  }

  const acceptMessage = 'Verify Completion';
  const canPerform =
    isAssignedToMe ||
    (!fleetInitiated && !thirdPartyInitiated && isServiceDispatcher);

  return {
    isVisible: true,
    isEnabled: canPerform,
    message: canPerform ? acceptMessage : `Waiting On Verification`,
    confirmationTitle: acceptMessage,
    confirmationMessage: 'Are you sure you are ready to close out the request?',
  };
};
