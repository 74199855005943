import { api } from '@cvx/api';
import {
  EnhancedRequest,
  RepairDetails,
} from '@cvx/types/entities/requestsEntityTypes';
import { tempVehicleInfoSchema } from '@cvx/types/zod/vehiclesZod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useForm, useStore } from '@tanstack/react-form';
import { useMutation, useQuery } from 'convex/react';
import { useState } from 'react';
import { repairDetailsFormSchema } from 'src/convex/types/zod/requestsZod';
import {
  decodeVin,
  mapVinData,
} from 'src/convex/utils/vinDecoder/vinDecoder.utils';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { Label } from 'src/minimal-theme/components/label';
import { RequiredPhotosPrompt } from 'src/sections/request/RequiredPhotosPrompt';
import { z } from 'zod';

type RepairDetailsFormProps = {
  request: EnhancedRequest;
  currentRepairDetails?: RepairDetails;
  editable: boolean;
};
export const RepairDetailsForm = ({
  request,
  currentRepairDetails,
  editable,
}: RepairDetailsFormProps) => {
  const [isDecoding, setIsDecoding] = useState<boolean>(false);
  const submitRepairDetails = useMutation(
    api.functions.requests.submitRepairDetails
  );

  const me = useQuery(api.functions.users.getMe);

  const { servicesRequiredByFleet } = request;

  const services = useQuery(
    api.functions.serviceCategories.getServicesGroupedByCategory,
    {}
  );

  // TODO: This is pretty brittle, need to think of a better way down the road
  const dotService = services
    ?.find(group => group.category.name === 'Inspections')
    ?.services.find(
      service => service.name === 'Federal DOT Inspection (US ONLY)'
    );

  const isDotServiceRequired = servicesRequiredByFleet?.some(
    serviceId => serviceId === dotService?._id
  );

  const requestId = request._id;

  const form = useForm({
    defaultValues: {
      cause: currentRepairDetails?.cause ?? '',
      correction: currentRepairDetails?.correction ?? '',
      wasATemporaryFix: !!currentRepairDetails?.wasATemporaryFix,
      notes: currentRepairDetails?.notes ?? '',
      tempVehicleInfo: request.tempVehicleInfo || { vin: '' },
    },
    validators: {
      onSubmit: repairDetailsFormSchema.extend({
        tempVehicleInfo: tempVehicleInfoSchema
          .extend({ vin: z.string().optional() })
          .optional(),
      }),
    },
    onSubmit: async ({ value }) => {
      try {
        // Always submit the text fields
        const submissionData = {
          requestId,
          wasATemporaryFix: !!value.wasATemporaryFix,
          cause: value.cause,
          correction: value.correction,
          notes: value.notes,
          tempVehicleInfo: value.tempVehicleInfo,
        };

        await submitRepairDetails(submissionData);
      } catch (error) {
        console.error('Error submitting repair details:', error);
        throw error;
      }
    },
  });

  const { Field, Subscribe, handleSubmit, store } = form;

  const tempVehicleInfo = useStore(store, s => s.values.tempVehicleInfo);

  const vinDecodedAndValid = !!tempVehicleInfo?.isVinDecoded;

  return (
    <Card>
      <CardHeader
        title={
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              component="span"
              sx={{ display: 'flex', alignItems: 'center', color: '#3498db' }}
            >
              <Iconify icon="solar:eye-bold" />
            </Box>
            <Typography variant="h6">Repair Details</Typography>
            <Box
              component="span"
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                backgroundColor: '#eaf6ff',
                color: '#3498db',
                fontSize: '0.75rem',
                borderRadius: '4px',
                padding: '0 8px',
                height: '20px',
              }}
            >
              Visible to All Participants
            </Box>
          </Stack>
        }
        subheader={
          editable
            ? 'Complete repair information visible to all participants'
            : 'Technician will fill in details about the repair work'
        }
        sx={{ backgroundColor: 'rgba(52, 152, 219, 0.05)', pb: 2 }}
      />

      <CardContent>
        <form
          onSubmit={async e => {
            e.preventDefault();
            e.stopPropagation();
            await handleSubmit();
          }}
        >
          <Stack spacing={3}>
            {editable && isDotServiceRequired && me && (
              <RequiredPhotosPrompt request={request} me={me} />
            )}
            <Field
              name="cause"
              validators={{
                onChange: z.string().min(1, { message: 'Cause is required' }),
              }}
              children={({ state, handleChange, handleBlur }) => (
                <TextField
                  label="Cause of Repair"
                  disabled={!editable}
                  required
                  fullWidth
                  multiline
                  rows={3}
                  value={state.value}
                  error={state.meta.errors.length > 0}
                  helperText={state.meta.errors[0]}
                  onChange={e => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  placeholder="Describe what caused the issue"
                />
              )}
            />

            <Field
              name="correction"
              validators={{
                onChange: z
                  .string()
                  .min(1, { message: 'Correction is required' }),
              }}
              children={({ state, handleChange, handleBlur }) => (
                <TextField
                  label="Correction Applied"
                  required
                  fullWidth
                  disabled={!editable}
                  multiline
                  rows={3}
                  value={state.value}
                  error={state.meta.errors.length > 0}
                  helperText={state.meta.errors[0]}
                  onChange={e => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  placeholder="Describe what was done to fix the issue"
                />
              )}
            />

            <Field
              name="wasATemporaryFix"
              children={({ state, handleChange, handleBlur }) => (
                <FormControlLabel
                  label={'This repair was a temporary solution'}
                  disabled={!editable}
                  onBlur={handleBlur}
                  control={
                    <Checkbox
                      size="small"
                      checked={Boolean(state.value)}
                      onChange={(e, checked) => handleChange(checked)}
                    />
                  }
                />
              )}
            />
            {!request.vehicleId && (
              <Field
                name="tempVehicleInfo.vin"
                validators={{
                  onChange: ({ value }) => {
                    // If no VIN is entered or VIN is exactly 17 characters, no error
                    return !value || (!!value && value.length === 17)
                      ? undefined
                      : 'VIN must be 17 characters';
                  },
                }}
                listeners={{
                  onChange: async ({ value, fieldApi }) => {
                    if (!value) {
                      fieldApi.form.setFieldValue('tempVehicleInfo', {
                        vin: '',
                      });
                      return;
                    }

                    // Only attempt decoding if full 17-character VIN
                    if (value.length === 17) {
                      try {
                        setIsDecoding(true);
                        const vinData = await decodeVin(value);
                        setIsDecoding(false);

                        const mappedData = mapVinData(value, vinData);

                        fieldApi.form.setFieldValue('tempVehicleInfo', {
                          ...mappedData,
                        });
                      } catch (error) {
                        setIsDecoding(false);
                        // Optionally handle decoding error
                        fieldApi.form.setFieldValue('tempVehicleInfo', {
                          vin: value,
                          isVinDecoded: false,
                        });
                      }
                    }
                  },
                }}
                // TODO: We need to put this into something reusable, no time right now, might not even exist in the near future, who knows
                children={({ state, handleChange }) => (
                  <Stack direction="row" spacing={1} alignItems="top">
                    <TextField
                      label="Optional VIN"
                      placeholder="12345678901234567"
                      name="vin"
                      autoComplete="off"
                      disabled={!!state.value && state.value.length === 17}
                      fullWidth
                      value={state.value}
                      onChange={e => handleChange(e.target.value)}
                      error={!!state.value && state.meta.errors.length > 0}
                      helperText={state.meta.errors[0]}
                    />
                    {!!state.value && state.value.length > 0 && (
                      <Label
                        sx={{ minHeight: 54, px: 3, minWidth: 130 }}
                        startIcon={
                          vinDecodedAndValid ? (
                            <Iconify icon="solar:check-circle-bold-duotone" />
                          ) : isDecoding ? (
                            <Iconify icon="solar:key-minimalistic-bold-duotone" />
                          ) : (
                            <Iconify icon="solar:close-square-bold-duotone" />
                          )
                        }
                        color={
                          vinDecodedAndValid
                            ? 'success'
                            : isDecoding
                              ? 'secondary'
                              : 'error'
                        }
                      >
                        {vinDecodedAndValid
                          ? 'Valid VIN'
                          : isDecoding
                            ? 'Decoding'
                            : 'Invalid Vin'}
                      </Label>
                    )}
                    <Button
                      variant="contained"
                      onClick={() => handleChange('')}
                      sx={{ minWidth: 100, maxHeight: 54 }}
                    >
                      Clear
                    </Button>
                  </Stack>
                )}
              />
            )}
            <Field
              name="notes"
              children={({ state, handleChange, handleBlur }) => (
                <TextField
                  label="Additional Repair Notes"
                  fullWidth
                  disabled={!editable}
                  multiline
                  rows={3}
                  value={state.value}
                  onChange={e => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  placeholder="Any additional repair notes or observations (optional)"
                />
              )}
            />

            {editable && (
              <Box display="flex" justifyContent="flex-end">
                <Subscribe
                  selector={state => ({
                    cause: state.values.cause || '',
                    correction: state.values.correction || '',
                    isSubmitting: state.isSubmitting,
                  })}
                  children={({ cause, correction, isSubmitting }) => {
                    const hasAllText =
                      cause.length > 0 && correction.length > 0;

                    const isDisabled = !hasAllText;

                    return (
                      <LoadingButton
                        type="submit"
                        color="primary"
                        variant="contained"
                        loading={isSubmitting}
                        disabled={isDisabled}
                      >
                        Save Repair Details
                      </LoadingButton>
                    );
                  }}
                />
              </Box>
            )}
          </Stack>
        </form>
      </CardContent>
    </Card>
  );
};
