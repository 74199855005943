import {
  EnhancedRequest,
  ServiceRequest,
} from '@cvx/types/entities/requestsEntityTypes';
import {
  CompanyId,
  ConvexUserId,
  GroupId,
  RequestId,
  RoleDefinitionId,
  ScheduledFunctionId,
  VehicleId,
} from '@cvx/types/entities/sharedIds';
import { ConvexUser } from '@cvx/types/entities/usersEntityTypes';
import { Vehicle } from '@cvx/types/entities/vehiclesEntityTypes';
import { phoneSchema } from '@cvx/types/zod/commonZod';
import {
  CompanyZodId,
  ConvexUserZodId,
  GroupZodId,
  RoleDefinitionZodId,
  ServiceRequestZodId,
  StorageZodId,
  VehicleZodId,
  VendorServiceZodId,
} from '@cvx/types/zod/commonZodId';
import {
  servicesRequiredSchema,
  submitExtraDetailsInput,
  submitRepairDetailsInput,
} from '@cvx/types/zod/requestsZod';
import { tempVehicleInfoSchema } from '@cvx/types/zod/vehiclesZod';
import {
  getManyVia,
  getOneFrom,
  getOneFromOrThrow,
} from 'convex-helpers/server/relationships';
import { paginationOptsValidator } from 'convex/server';
import { ConvexError, v } from 'convex/values';
import uniqBy from 'lodash-es/uniqBy';
import { z } from 'zod';
import { internal } from '../_generated/api';
import {
  DatabaseReader,
  MutationCtx,
  query,
  QueryCtx,
} from '../_generated/server';
import { LOCATION_CHECK_INTERVAL } from '../nonnodeactions/requests';
import { calculateDistance } from '../schema/entities/groups/groups';
import {
  createHistoryEntry,
  formatHistoryEntry,
  messageTemplates,
} from '../schema/entities/requests/requestHistory';
import { generateCaseNumber } from '../schema/entities/requests/requests';
import { paymentMethod } from '../schema/enums/paymentMethod';
import { requestStatusType } from '../schema/enums/requestStatusType';
import { getUserRoleDisplay, userRole } from '../schema/enums/userRole';
import { getUserContext, userQueryByClerkId } from '../utils/getUserContext';
import { createRequestSubmissionHistory } from './helpers/request/base';
import {
  fleetSubmissionSchema,
  getFleetDispatcherDraftInitiationFields,
} from './helpers/request/fleetDispatchInitiated';
import { getFleetDriverDraftInitiationFields } from './helpers/request/fleetDriverInitiated';
import {
  getRequestParticipants,
  notifyRequestParticipants,
  notifyServiceProviderDispatchers,
} from './helpers/request/notifications';
import {
  getServiceDispatcherDraftInitiationFields,
  getServiceTechnicianDraftInitiationFields,
  refinedServiceSubmissionSchema,
} from './helpers/request/serviceInitiated';
import { getThirdPartyDispatcherDraftInitiationFields } from './helpers/request/thirdPartyDispatcherInitiated';
import { createResponse } from './helpers/responses/responses';
import {
  zInternalMutation,
  zInternalQuery,
  zMutation,
  zQuery,
} from './helpers/zodHelpers';
import { updateTechnicianStatusHelper } from './users';
import { updateDriverAndVehicleLocation } from './vehicles';

// Queries TODO: For now this basic getRequests is for all user roles to get everything and client side filter... will be made more robust
export const getRequestsParticipatingIn = zQuery({
  args: { status: requestStatusType.optional() },
  handler: async (ctx, { status }) => {
    const { user } = await getUserContext(ctx);

    if (user.primaryRoleType === 'FLEET_DISPATCHER') {
      const req = await ctx.db
        .query('requests')
        .withIndex('by_activeFleetDispatcherId_and_status', q => {
          const baseQuery = q.eq('activeFleetDispatcherId', user._id);
          return status ? baseQuery.eq('status', status) : baseQuery;
        })
        .order('desc')
        .filter(q => q.neq(q.field('status'), 'DRAFT')) // TODO: Should be able to CONDITIONALLY run this... asked a question in the discord about it
        .collect();

      return sanitizeRequestsRepairDetails(req, user);
    }

    if (user.primaryRoleType === 'SERVICE_DISPATCHER') {
      const req = await ctx.db
        .query('requests')
        .withIndex('by_activeServiceDispatcherId_and_status', q => {
          const baseQuery = q.eq('activeServiceDispatcherId', user._id);
          return status ? baseQuery.eq('status', status) : baseQuery;
        })
        .order('desc')
        .filter(q => q.neq(q.field('status'), 'DRAFT'))
        .collect();

      return sanitizeRequestsRepairDetails(req, user);
    }

    return [];
  },
});

export const getAllRequests = zQuery({
  handler: async ctx => {
    const { user } = await getUserContext(ctx);

    let requests: ServiceRequest[] = [];

    switch (user.primaryRoleType) {
      // Driver gets requests where they are the active driver
      case 'DRIVER_FLEET':
        requests = await ctx.db
          .query('requests')
          .withIndex('by_activeDriverId_and_status', q =>
            q.eq('activeDriverId', user._id)
          )
          .filter(q => q.neq(q.field('status'), 'DRAFT'))
          .order('desc')
          .collect();
        break;

      // Fleet dispatcher gets requests where they are the fleet group
      case 'FLEET_DISPATCHER':
        requests = await ctx.db
          .query('requests')
          .withIndex('by_fleetDispatchGroupId_and_status_and_createdAt', q =>
            q.eq('fleetDispatchGroupId', user.primaryLocationGroupId)
          )
          .filter(q => q.neq(q.field('status'), 'DRAFT'))
          .order('desc')
          .collect();
        break;

      case 'THIRD_PARTY_DISPATCHER':
        requests = await ctx.db
          .query('requests')
          .withIndex('by_brokerageGroupId_and_status_and_createdAt', q =>
            q.eq('brokerageGroupId', user.primaryLocationGroupId)
          )
          .filter(q => q.neq(q.field('status'), 'DRAFT'))
          .order('desc')
          .collect();
        break;

      // Service dispatcher gets requests where they are the mechanic dispatch group
      case 'SERVICE_DISPATCHER':
        requests = await ctx.db
          .query('requests')
          .withIndex('by_mechanicDispatchGroupId_and_status_and_createdAt', q =>
            q.eq('mechanicDispatchGroupId', user.primaryLocationGroupId)
          )
          .order('desc')
          .filter(q => q.neq(q.field('status'), 'DRAFT'))
          .collect();
        break;

      // Technician gets requests where they are the active technician
      case 'TECHNICIAN_PROVIDER':
        requests = await ctx.db
          .query('requests')
          .withIndex('by_activeTechnicianId_and_status', q =>
            q.eq('activeTechnicianId', user._id)
          )
          .order('desc')
          .filter(q => q.neq(q.field('status'), 'DRAFT'))
          .collect();
        break;

      default:
        requests = [];
    }

    return sanitizeRequestsRepairDetails(requests, user);
  },
});

/**
 * Get a single request by ID, with permission checking
 */
export const getRequest = zQuery({
  args: {
    requestId: ServiceRequestZodId,
  },
  handler: async (ctx, { requestId }) => {
    const { user, company, roles, primaryLocation } = await getUserContext(ctx);

    const request = await ctx.db.get(requestId);
    if (!request) return null;

    const hasAccess = hasRequestAccess(request, {
      userId: user._id,
      companyId: company._id,
      roles: roles,
      locationGroupId: primaryLocation._id,
    });

    if (!hasAccess) {
      throw new ConvexError('Not authorized to view this request');
    }

    const enhancedRequest = await getEnhancedRequest(ctx, { requestId });

    if (!enhancedRequest) {
      throw new ConvexError('Request not found');
    }

    return sanitizeRequestRepairDetails(enhancedRequest, user);
  },
});

export const getRequestInternally = zInternalQuery({
  args: {
    requestId: ServiceRequestZodId,
  },
  handler: async (ctx, { requestId }) => ctx.db.get(requestId),
});

const getDraftRequest = (db: DatabaseReader, userId: ConvexUserId) =>
  db
    .query('requests')
    .withIndex('by_createdById_and_status', q =>
      q.eq('createdById', userId).eq('status', 'DRAFT')
    )
    .first();

export const getMyDraftRequest = zQuery({
  handler: async ctx => {
    const { user } = await getUserContext(ctx);

    return getDraftRequest(ctx.db, user._id);
  },
});

// TODO: Will have to tweak for dispatcher teams that look after multiple location groups
/**
 * Get requests in queue for a specific role
 */
export const getQueuedRequests = zQuery({
  handler: async ctx => {
    const { user, roles } = await getUserContext(ctx);

    const fleetDispatcherRole = roles.find(r => r?.type === 'FLEET_DISPATCHER');
    const serviceDispatcherRole = roles.find(
      r => r?.type === 'SERVICE_DISPATCHER'
    );

    if (!fleetDispatcherRole && !serviceDispatcherRole) {
      throw new Error('Must be a dispatcher to view queued requests');
    }

    let requests: ServiceRequest[] = [];
    if (fleetDispatcherRole) {
      requests = await ctx.db
        .query('requests')
        .withIndex('by_fleetQueue', q =>
          q
            .eq('currentRequiredRoleId', fleetDispatcherRole._id)
            .eq('fleetDispatchGroupId', user.primaryLocationGroupId)
            .eq('status', 'ACTIVE')
            .eq('currentStepState', 'QUEUED')
        )
        .order('desc')
        .collect();
    } else if (serviceDispatcherRole) {
      requests = await ctx.db
        .query('requests')
        .withIndex('by_serviceQueue', q =>
          q
            .eq('currentRequiredRoleId', serviceDispatcherRole._id)
            .eq('mechanicDispatchGroupId', user.primaryLocationGroupId)
            .eq('status', 'ACTIVE')
            .eq('currentStepState', 'QUEUED')
        )
        .order('desc')
        .collect();
    }

    return sanitizeRequestsRepairDetails(requests, user);
  },
});

/**
 * Get requests currently assigned to the user
 */
export const getAssignedRequests = zQuery({
  handler: async ctx => {
    const { user } = await getUserContext(ctx);

    const requests = await ctx.db
      .query('requests')
      .withIndex('by_currentAssignedToId_and_status', q =>
        q.eq('currentAssignedToId', user._id).eq('status', 'ACTIVE')
      )
      .order('desc')
      .collect();

    return sanitizeRequestsRepairDetails(requests, user);
  },
});

export const getActiveRequestsForUser = zQuery({
  args: { userId: ConvexUserZodId },
  handler: async (ctx, { userId }) => {
    const { user, company, isDispatcher } = await getUserContext(ctx);

    const userBeingQueried = await ctx.db.get(userId);

    const isAuthororizedDispatcher =
      isDispatcher && company._id === userBeingQueried?.companyId;

    if (user.clerkUser.isSuperAdmin || isAuthororizedDispatcher) {
      // TODO: Really need to change to adding participants joiner document

      const { allNonDraftRequests } = await getActiveRequestsForUserHelper(
        ctx,
        userId
      );

      const sanitizedRequests = sanitizeRequestsRepairDetails(
        allNonDraftRequests,
        user
      );

      return uniqBy(sanitizedRequests, '_id');
    }
  },
});

export const getActiveRequestsForVehicle = zQuery({
  args: { vehicleId: VehicleZodId },
  handler: async (ctx, { vehicleId }) => {
    const { user, isDispatcher, company } = await getUserContext(ctx);

    const vehicleBeingQueries = await ctx.db.get(vehicleId);

    const isAuthororizedDispatcher =
      isDispatcher && company._id === vehicleBeingQueries?.companyId;

    if (user.clerkUser.isSuperAdmin || isAuthororizedDispatcher) {
      // TODO: Really need to change to adding participants joiner document

      const { activeRequests } = await getActiveRequestsForVehicleHelper(
        ctx,
        vehicleId
      );

      return sanitizeRequestsRepairDetails(activeRequests, user);
    }
  },
});

export type ActiveRequestsForUser = {
  requestsAssigned: ServiceRequest[];
  requestsAsDriver: ServiceRequest[];
  requestsAsFleetDispatcher: ServiceRequest[];
  requestsAsServiceDispatcher: ServiceRequest[];
  requestsAsTechnician: ServiceRequest[];
  requestsAsThirdPartyDispatcher: ServiceRequest[];
  draftRequestsAsDriver: ServiceRequest[];
  draftRequestsAsFleetDispatcher: ServiceRequest[];
  draftRequestsAsThirdPartyDispatcher: ServiceRequest[];
  allNonDraftRequests: ServiceRequest[];
};

export const getActiveRequestsForUserHelper = async (
  ctx: MutationCtx | QueryCtx,
  userId: ConvexUserId
) => {
  const [
    requestsAssigned,
    requestsAsDriver,
    requestsAsFleetDispatcher,
    requestsAsServiceDispatcher,
    requestsAsTechnician,
    draftRequestsAsDriver, // Need to clear
    draftRequestsAsFleetDispatcher, // Need to clear
    requestsAsThirdPartyDispatcher,
    draftRequestsAsThirdPartyDispatcher,
  ] = await Promise.all([
    ctx.db
      .query('requests')
      .withIndex('by_currentAssignedToId_and_status', q =>
        q.eq('currentAssignedToId', userId).eq('status', 'ACTIVE')
      )
      .order('desc')
      .collect(),
    ctx.db
      .query('requests')
      .withIndex('by_activeDriverId_and_status', q =>
        q.eq('activeDriverId', userId).eq('status', 'ACTIVE')
      )
      .order('desc')
      .collect(),
    ctx.db
      .query('requests')
      .withIndex('by_activeFleetDispatcherId_and_status', q =>
        q.eq('activeFleetDispatcherId', userId).eq('status', 'ACTIVE')
      )
      .order('desc')
      .collect(),
    ctx.db
      .query('requests')
      .withIndex('by_activeServiceDispatcherId_and_status', q =>
        q.eq('activeServiceDispatcherId', userId).eq('status', 'ACTIVE')
      )
      .order('desc')
      .collect(),
    ctx.db
      .query('requests')
      .withIndex('by_activeTechnicianId_and_status', q =>
        q.eq('activeTechnicianId', userId).eq('status', 'ACTIVE')
      )
      .collect(),
    ctx.db
      .query('requests')
      .withIndex('by_activeDriverId_and_status', q =>
        q.eq('activeDriverId', userId).eq('status', 'DRAFT')
      )
      .collect(),
    ctx.db
      .query('requests')
      .withIndex('by_activeFleetDispatcherId_and_status', q =>
        q.eq('activeFleetDispatcherId', userId).eq('status', 'DRAFT')
      )
      .collect(),
    ctx.db
      .query('requests')
      .withIndex('by_activeBrokerageDispatcherId_and_status', q =>
        q.eq('activeBrokerageDispatcherId', userId).eq('status', 'DRAFT')
      )
      .collect(),
    ctx.db
      .query('requests')
      .withIndex('by_activeBrokerageDispatcherId_and_status', q =>
        q.eq('activeBrokerageDispatcherId', userId).eq('status', 'DRAFT')
      )
      .collect(),
  ]);

  return {
    requestsAssigned,
    requestsAsDriver,
    requestsAsFleetDispatcher,
    requestsAsServiceDispatcher,
    requestsAsTechnician,
    draftRequestsAsDriver,
    draftRequestsAsFleetDispatcher,
    requestsAsThirdPartyDispatcher,
    draftRequestsAsThirdPartyDispatcher,
    allNonDraftRequests: [
      ...requestsAssigned,
      ...requestsAsDriver,
      ...requestsAsFleetDispatcher,
      ...requestsAsServiceDispatcher,
      ...requestsAsTechnician,
      ...requestsAsThirdPartyDispatcher,
    ],
  };
};

export const getActiveRequestsForVehicleHelper = async (
  ctx: MutationCtx | QueryCtx,
  vehicleId: VehicleId
) => {
  const [activeRequests, draftRequests] = await Promise.all([
    ctx.db
      .query('requests')
      .withIndex('by_vehicleId_and_status', q =>
        q.eq('vehicleId', vehicleId).eq('status', 'ACTIVE')
      )
      .collect(),
    ctx.db
      .query('requests')
      .withIndex('by_vehicleId_and_status', q =>
        q.eq('vehicleId', vehicleId).eq('status', 'DRAFT')
      )
      .collect(),
  ]);

  return { activeRequests, draftRequests };
};

// TODO: Enable passing in a status to make this more flexible.. possibly merge with some other query
export const getActiveRequests = zQuery({
  handler: async ctx => {
    const { user, primaryLocation } = await getUserContext(ctx);

    switch (user.primaryRoleType) {
      case 'DRIVER_FLEET':
        return ctx.db
          .query('requests')
          .withIndex('by_activeDriverId_and_status', q =>
            q.eq('activeDriverId', user._id).eq('status', 'ACTIVE')
          )
          .order('desc')
          .collect();

      case 'FLEET_DISPATCHER':
        return ctx.db
          .query('requests')
          .withIndex('by_fleetDispatchGroupId_and_status_and_createdAt', q =>
            q
              .eq('fleetDispatchGroupId', primaryLocation._id)
              .eq('status', 'ACTIVE')
          )
          .order('desc')
          .collect();

      case 'THIRD_PARTY_DISPATCHER':
        return ctx.db
          .query('requests')
          .withIndex('by_brokerageGroupId_and_status_and_createdAt', q =>
            q.eq('brokerageGroupId', primaryLocation._id).eq('status', 'ACTIVE')
          )
          .order('desc')
          .collect();

      case 'SERVICE_DISPATCHER':
        return ctx.db
          .query('requests')
          .withIndex('by_mechanicDispatchGroupId_and_status_and_createdAt', q =>
            q
              .eq('mechanicDispatchGroupId', primaryLocation._id)
              .eq('status', 'ACTIVE')
          )
          .order('desc')
          .collect();

      case 'TECHNICIAN_PROVIDER':
        return ctx.db
          .query('requests')
          .withIndex('by_activeTechnicianId_and_status', q =>
            q.eq('activeTechnicianId', user._id).eq('status', 'ACTIVE')
          )
          .order('desc')
          .collect();

      default:
        return [];
    }
  },
});

/**
 * Create a draft request with minimal information
 * This allows users to save partial progress when filling out the form
 */
export const createDraftRequest = zMutation({
  args: {},
  handler: async ctx => {
    const { user, roles, company, primaryLocation } = await getUserContext(ctx);

    const existingDraft = await getDraftRequest(ctx.db, user._id);

    if (existingDraft) {
      return existingDraft._id;
    }

    const fleetDispatcherRole = roles.find(r => r?.type === 'FLEET_DISPATCHER');
    const fleetDriverRole = roles.find(r => r?.type === 'DRIVER_FLEET');
    const serviceDispatchRole = roles.find(
      r => r?.type === 'SERVICE_DISPATCHER'
    );

    const technicianRole = roles.find(r => r?.type === 'TECHNICIAN_PROVIDER');

    let roleSpecificFields = fleetDispatcherRole
      ? getFleetDispatcherDraftInitiationFields(user, primaryLocation)
      : fleetDriverRole
        ? getFleetDriverDraftInitiationFields(user, primaryLocation)
        : serviceDispatchRole
          ? getServiceDispatcherDraftInitiationFields(
              user,
              company,
              primaryLocation
            )
          : getThirdPartyDispatcherDraftInitiationFields(
              user,
              primaryLocation,
              company
            );

    if (technicianRole && primaryLocation.noDispatchSoleProprietor) {
      roleSpecificFields = getServiceTechnicianDraftInitiationFields(
        user,
        company,
        primaryLocation
      );
    }

    // TODO: independent drivers with no dispatch can create...

    // Create draft request with minimal required info
    return await ctx.db.insert('requests', {
      status: 'DRAFT',
      createdById: user._id,
      requesterCompanyId: company._id,
      caseNumber: generateCaseNumber(),
      ...roleSpecificFields,
    });
  },
});

// Input schemas
const requestInput = z.object({
  description: z.string(),
  vehicleId: VehicleZodId,
  address: z.string(),
  timezone: z.string().optional(), // Doesn't make sense to get the dispatchers timezone, only the driver/vehicle
  activeDriverId: ConvexUserZodId,
  requestId: ServiceRequestZodId,
});

export const updateRequestLocation = zMutation({
  args: {
    requestId: ServiceRequestZodId,
    timezone: z.string().optional(),
    location: z.object({
      latitude: z.number(),
      longitude: z.number(),
      address: z.string(),
    }),
    city: z.string().optional(),
    state: z.string().optional(),
    postCode: z.string().optional(),
    country: z.string().optional(),
    countryCode: z.string().optional(),
    stateShortCode: z.string().optional(),
  },
  handler: async (
    ctx,
    {
      location: { latitude, longitude, address },
      requestId,
      timezone,
      city,
      state,
      postCode,
      country,
      countryCode,
      stateShortCode,
    }
  ) => {
    const { user } = await getUserContext(ctx);

    const request = await ctx.db.get(requestId);

    if (request && user._id === request.activeDriverId) {
      await ctx.db.patch(requestId, {
        address,
        longitude,
        latitude,
        timezone,
        city,
        state,
        postCode,
        country,
        stateShortCode,
        countryCode,
      });
    }
  },
});

export const updateRequestRoute = zInternalMutation({
  args: {
    userId: ConvexUserZodId,
    requestId: ServiceRequestZodId,
    route: z.string(),
  },
  handler: async (ctx, { userId, requestId, route }) => {
    const [user, request] = await Promise.all([
      ctx.runQuery(internal.functions.users.getUserByIdInternally, {
        id: userId,
      }),
      ctx.db.get(requestId),
    ]);

    if (
      request &&
      hasRequestEditAccess(request, {
        userId: user._id,
        locationGroupId: user.primaryLocationGroupId,
      })
    ) {
      await ctx.db.patch(requestId, {
        route,
      });
      console.log('[Route] Route updated on the request');
    } else {
      console.log(
        "[Route] Cannot save the route, the user doesn't have permissions"
      );
    }
  },
});

export const updateDraftRequest = zMutation({
  args: {
    requestId: ServiceRequestZodId,
    description: z.string().optional(),
    vehicleId: VehicleZodId.optional(),
    activeDriverId: ConvexUserZodId.nullable().optional(),
    activeFleetDispatcherId: ConvexUserZodId.nullable().optional(),
    fleetReferenceNumber: z.string().optional(),
    nationalAccountNumber: z.string().optional(),
    tireSize: z.string().optional(),
    tempDriverFirstName: z.string().nullable().optional(),
    tempDriverLastName: z.string().nullable().optional(),
    tempDriverPhone: phoneSchema.nullable().optional(),
    tempDriverEmail: z.string().email().nullable().optional(),
    isDriverCalling: z.boolean().optional(),
    isNoActiveDriver: z.boolean().optional(),
    paymentMethod: paymentMethod.optional(),

    servicesRequiredByFleet: z.array(VendorServiceZodId).optional(),

    // Dispatcher info - all optional at schema level (in front-end form, we enforce name/phone if there is a dispatcher involved and not driver calling directly)
    tempFleetDispatchFirstName: z.string().nullable().optional(),
    tempFleetDispatchLastName: z.string().nullable().optional(),
    tempFleetDispatchPhone: phoneSchema.nullable().optional(),
    tempFleetDispatchEmail: z.string().email().nullable().optional(),

    tempVehicleUnitNumber: z.string().nullable().optional(),

    requesterCompanyName: z.string().nullable().optional(),

    // Location info
    address: z.string().optional(),
    longitude: z.number().optional(),
    latitude: z.number().optional(),

    // Vehicle info
    tempVehicleInfo: tempVehicleInfoSchema
      .extend({ vin: z.string().optional() })
      .optional(),
  },
  handler: async (ctx, args) => {
    const { requestId, ...updates } = args;
    const { user } = await getUserContext(ctx);

    const [request, driver, dispatcher] = await Promise.all([
      ctx.db.get(requestId),
      updates.activeDriverId
        ? ctx.db.get(updates.activeDriverId)
        : Promise.resolve(null),
      updates.activeFleetDispatcherId
        ? ctx.db.get(updates.activeFleetDispatcherId)
        : Promise.resolve(null),
    ]);

    if (request && user._id === request.createdById) {
      // Create a new object that preserves undefined values TODO: I don't think this is actually working or a good pattern, we need nullables/null
      const sanitizedUpdates = Object.fromEntries(
        Object.entries(updates).map(([key, value]) =>
          value === null ? [key, undefined] : [key, value]
        )
      );

      const sanitizedTempVehicleInfo = sanitizeVehicleUpdates(
        updates.tempVehicleInfo
      );

      const patches: Partial<ServiceRequest> = {
        ...sanitizedUpdates,
        tempVehicleInfo: sanitizedTempVehicleInfo,
      };

      // Handle driver updates
      if (
        'activeDriverId' in args &&
        updates.activeDriverId &&
        driver?.companyId
      ) {
        const company = await ctx.db.get(driver.companyId);
        const location = driver.primaryLocationGroupId
          ? await ctx.db.get(driver.primaryLocationGroupId)
          : null;

        patches.requesterCompanyId = driver.companyId;
        patches.requesterCompanyName = company?.name;

        if (location) {
          patches.requesterLocationName = location.name;
        }
      }
      // Handle dispatcher updates, but respect driver's info if present
      else if (
        'activeFleetDispatcherId' in args &&
        updates.activeFleetDispatcherId &&
        dispatcher?.companyId
      ) {
        const company = await ctx.db.get(dispatcher.companyId);
        const location = dispatcher.primaryLocationGroupId
          ? await ctx.db.get(dispatcher.primaryLocationGroupId)
          : null;

        // Always update company info when explicitly changing dispatcher
        // This ensures that when changing from one dispatcher to another, the company info updates
        patches.requesterCompanyId = dispatcher.companyId;
        patches.requesterCompanyName = company?.name;

        // Only set location name if no driver is associated
        if (!request.activeDriverId && location) {
          patches.requesterLocationName = location.name;
        }
      }
      // If driver is being removed, and no dispatcher exists, clear company info
      else if (
        'activeDriverId' in args &&
        updates.activeDriverId === null &&
        !request.activeFleetDispatcherId
      ) {
        if (request.activeFleetDispatcherId) {
          const fleetDispatcher = await ctx.db.get(
            request.activeFleetDispatcherId
          );
          if (fleetDispatcher?.companyId) {
            const dispatcherCompany = await ctx.db.get(
              fleetDispatcher.companyId
            );
            patches.requesterCompanyId = fleetDispatcher.companyId;
            patches.requesterCompanyName = dispatcherCompany?.name;
          }
        }
      }

      await ctx.db.patch(requestId, patches);

      return { success: true, message: 'Request updated successfully' };
    }
  },
});

export const addUserToRequestAsParticipant = zInternalMutation({
  args: {
    requestId: ServiceRequestZodId,
    roleType: userRole,
    userId: ConvexUserZodId,
    inviterId: ConvexUserZodId,
    inviterLocationId: GroupZodId,
  },
  handler: async (
    ctx,
    { requestId, roleType, userId, inviterId, inviterLocationId }
  ) => {
    const user = await ctx.db.get(userId);

    if (!user || !user.primaryLocationGroupId || !user.companyId) {
      throw new ConvexError('User not found');
    }

    const [location, company, previousCustomerRecord, request] =
      await Promise.all([
        ctx.db.get(user.primaryLocationGroupId),
        ctx.db.get(user.companyId),
        ctx.db
          .query('previousCustomers')
          .withIndex('by_userId_and_providerLocation', q =>
            q.eq('userId', user._id).eq('providerLocation', inviterLocationId)
          )
          .first(),
        ctx.db.get(requestId),
      ]);

    if (!location || !company || !request) {
      throw new ConvexError('Location or company not found');
    }

    const previousCustomerPromise = previousCustomerRecord
      ? ctx.db.patch(previousCustomerRecord._id, {
          lastUsed: Date.now(),
          locationName: location.name,
          companyName: company.name,
        })
      : ctx.db.insert('previousCustomers', {
          userId,
          roleType,
          providerLocation: inviterLocationId,
          createdBy: inviterId,
          locationName: location.name,
          companyName: company.name,
          lastUsed: Date.now(),
        });

    if (roleType === 'DRIVER_FLEET') {
      await ctx.db.patch(requestId, {
        activeDriverId: userId,
        driverGroupId: location._id,
        requesterCompanyId: company._id,
        requesterCompanyName: company.name,
        requesterLocationName: location.name,
      });
    } else if (roleType === 'FLEET_DISPATCHER') {
      const updateObj: Partial<ServiceRequest> = {
        activeFleetDispatcherId: userId,
        fleetDispatchGroupId: location._id,
      };

      // Only set company info if no requesterCompanyId exists yet
      if (!request.requesterCompanyId) {
        updateObj.requesterCompanyId = company._id;
        updateObj.requesterCompanyName = company.name;
      }

      // Only set location name if no driver is associated with the request
      // and no location name has been set yet
      if (!request.activeDriverId && !request.requesterLocationName) {
        updateObj.requesterLocationName = location.name;
      }

      await ctx.db.patch(requestId, updateObj);
    }

    if (previousCustomerPromise) {
      await previousCustomerPromise;
    }
  },
});

// TODO: Need to change this ... lots of mess and duplication all over the place..
export const updateRequest = zMutation({
  args: {
    requestId: ServiceRequestZodId,
    description: z.string().optional(),
    fleetReferenceNumber: z.string().optional(),
    nationalAccountNumber: z.string().optional(),
    paymentMethod: paymentMethod.optional(),
    tireSize: z.string().optional(),

    tempDriverFirstName: z.string().optional(),
    tempDriverLastName: z.string().optional(),
    tempDriverPhone: phoneSchema.optional(),
    tempDriverEmail: z.string().email().optional(),
    isDriverCalling: z.boolean().optional(),
    isNoActiveDriver: z.boolean().optional(),

    // Dispatcher info - all optional at schema level (in front-end form, we enforce name/phone if there is a dispatcher involved and not driver calling directly)
    tempFleetDispatchFirstName: z.string().optional(),
    tempFleetDispatchLastName: z.string().optional(),
    tempFleetDispatchPhone: phoneSchema.optional(),
    tempFleetDispatchEmail: z.string().email().optional(),

    servicesRequiredByFleet: servicesRequiredSchema,

    tempVehicleUnitNumber: z.string().optional(),
    requesterCompanyName: z.string().optional(),

    // Location info
    address: z.string().optional(),
    longitude: z.number().optional(),
    latitude: z.number().optional(),

    city: z.string().optional(),
    state: z.string().optional(),
    postCode: z.string().optional(),
    country: z.string().optional(),
    countryCode: z.string().optional(),
    stateShortCode: z.string().optional(),

    timezone: z.string().optional(),

    // Vehicle info
    tempVehicleInfo: tempVehicleInfoSchema
      .extend({ vin: z.string().optional() })
      .optional(),
  },
  handler: async (ctx, args) => {
    const { requestId, ...updates } = args;
    const { user } = await getUserContext(ctx);
    const request = await ctx.db.get(requestId);

    if (
      request &&
      hasRequestEditAccess(request, {
        userId: user._id,
        locationGroupId: user.primaryLocationGroupId,
      })
    ) {
      // Create a new object that preserves undefined values
      const definedUpdates: Record<string, any> = Object.keys(updates).reduce(
        (acc: Record<string, any>, key) => {
          acc[key] = updates[key as keyof typeof updates];
          return acc;
        },
        {}
      );

      const sanitizedTempVehicleInfo = sanitizeVehicleUpdates(
        args.tempVehicleInfo
      );

      await ctx.db.patch(requestId, {
        ...definedUpdates,
        tempVehicleInfo: sanitizedTempVehicleInfo,
      });

      return { success: true, message: 'Request updated successfully' };
    } else {
      return { success: false, message: 'Request not found or not authorized' };
    }
  },
});

export const submitFleetDispatchRequest = zMutation({
  args: {
    input: fleetSubmissionSchema,
  },
  handler: async (ctx, { input }) => {
    const { user, roles, company, primaryLocation } = await getUserContext(ctx);
    const dispatcherRole = roles.find(r => r?.type === 'FLEET_DISPATCHER');

    if (!dispatcherRole) {
      return createResponse({
        success: false,
        message: 'Invalid role for this request type',
        error: 'Must be a fleet dispatcher to submit this request type',
      });
    }

    const [vehicle, request, participants] = await Promise.all([
      ctx.db.get(input.vehicleId),
      ctx.db.get(input.requestId),
      getRequestParticipants(ctx, { driver: input.activeDriverId }),
    ]);

    const { driver } = participants;

    if (!request || !driver || !vehicle) {
      return createResponse({
        success: false,
        message: 'Request, driver or vehicle not found',
      });
    }

    const vehicleLocationInfo: Partial<ServiceRequest> = {
      address: vehicle.location?.address,
      longitude: vehicle.location?.longitude,
      latitude: vehicle.location?.latitude,
      city: vehicle.city,
      state: vehicle.state,
      postCode: vehicle.postCode,
      country: vehicle.country,
      stateShortCode: vehicle.stateShortCode,
      timezone: vehicle.timezone,
    };

    const driverLocation = await ctx.db.get(driver.primaryLocationGroupId!);

    if (!driverLocation) {
      return createResponse({
        success: false,
        message: 'Driver location not found',
      });
    }

    const { requestId, ...restOfRequest } = input;
    // If we don't have location, assign to driver to confirm location
    if (!isLocationRecentlyUpdated(vehicle)) {
      await Promise.all([
        ctx.db.patch(requestId, {
          ...restOfRequest,
          status: 'ACTIVE',
          requestCreationContext: 'FLEET_DISPATCHER_WITH_LOCATION_CONFIRM',
          createdAt: Date.now(),
          currentStepStartedAt: Date.now(),
          createdById: user._id,
          requesterCompanyId: company._id,
          currentPhase: 'FLEET_DISPATCH',
          currentStepType: 'DRIVER_CONFIRM_LOCATION',
          currentStepState: 'ASSIGNED',
          currentRequiredRoleId: undefined,
          currentAssignedToId: input.activeDriverId,
          activeFleetDispatcherId: user._id,
          driverGroupId: driver.primaryLocationGroupId,
          requesterCompanyName: company.name,
          requesterLocationName: driverLocation.name,
          fleetDispatchGroupId: primaryLocation._id,
          requiresVehicleLocation: true,
          ...vehicleLocationInfo,
        }),
        createRequestSubmissionHistory({
          ctx,
          requestId: input.requestId,
          user,
          userRole: roles.find(r => r.type === 'FLEET_DISPATCHER')!,
          dispatcherAssigned: true,
          driver,
        }),
        notifyRequestParticipants({
          message: `${user.clerkUser.fullName} [Dispatcher] needs you to confirm location for`,
          subject: 'Share Vehicle Location',
          users: participants,
          sendToIfAvailable: { driver: true },
          request,
          ctx,
          companyInitiatingNotification: company.name,
          notificationType: 'CONFIRM_LOCATION',
        }),
      ]);

      return createResponse({
        success: true,
        message: `Request ${request.caseNumber} submitted - awaiting driver location confirmation`,
      });
    }

    await Promise.all([
      ctx.db.patch(requestId, {
        ...restOfRequest,
        status: 'ACTIVE',
        createdAt: Date.now(),
        requestCreationContext: 'FLEET_DISPATCHER_INITIATED',
        currentStepStartedAt: Date.now(),
        createdById: user._id,
        requesterCompanyId: company._id,
        currentPhase: 'FLEET_DISPATCH',
        currentStepType: 'DISPATCH_TRIAGE',
        currentStepState: 'ASSIGNED',
        currentRequiredRoleId: undefined,
        currentAssignedToId: user._id,
        activeFleetDispatcherId: user._id,
        driverGroupId: driver.primaryLocationGroupId,
        fleetDispatchGroupId: primaryLocation._id,
        requiresVehicleLocation: false,
        requesterCompanyName: company.name,
        requesterLocationName: driverLocation.name,
        ...vehicleLocationInfo,
      }),
      createRequestSubmissionHistory({
        ctx,
        requestId: input.requestId,
        user,
        userRole: roles.find(r => r.type === 'FLEET_DISPATCHER')!,
      }),
      notifyRequestParticipants({
        message: `${user.clerkUser.fullName} [Dispatcher] has created your request and is working on finding a service provider`,
        subject: 'Request Created',
        users: participants,
        sendToIfAvailable: { driver: true },
        request,
        ctx,
        companyInitiatingNotification: company.name,
        notificationType: 'REQUEST_CREATED_BY_FLEET_DISPATCH',
      }),
    ]);

    return createResponse({
      success: true,
      message: `Request ${request.caseNumber} submitted successfully`,
    });
  },
});

export const submitFleetDriverRequest = zMutation({
  args: {
    input: fleetSubmissionSchema,
  },
  handler: async (ctx, { input }) => {
    const { user, roles, primaryLocation, company } = await getUserContext(ctx);

    const driverRole = roles.find(r => r?.type === 'DRIVER_FLEET');

    if (!driverRole) {
      return createResponse({
        success: false,
        message: 'Invalid role for this request type',
        error: 'Must be a driver to submit this request type',
      });
    }

    const [vehicle, request, dispatcherRoleDef] = await Promise.all([
      ctx.db.get(input.vehicleId),
      ctx.db.get(input.requestId),
      getOneFrom(
        ctx.db,
        'roleDefinitions',
        'by_type',
        'FLEET_DISPATCHER',
        'type'
      ),
    ]);

    if (!request || !vehicle || !dispatcherRoleDef) {
      return createResponse({
        success: false,
        message: 'Request, vehicle, or role not found',
      });
    }

    if (!isLocationRecentlyUpdated(vehicle)) {
      return createResponse({
        success: false,
        message:
          'Driver must confirm current location before submitting request',
      });
    }

    const { requestId, ...restOfInput } = input;

    const soleProprietor = !!primaryLocation.noDispatchSoleProprietor;

    await Promise.all([
      ctx.db.patch(requestId, {
        ...restOfInput,
        status: 'ACTIVE',
        createdAt: Date.now(),
        currentStepStartedAt: Date.now(),
        requestCreationContext: soleProprietor
          ? 'FLEET_DRIVER_SOLE_PROPRIETOR'
          : 'FLEET_DRIVER_INITIATED',
        createdById: user._id,
        requesterCompanyId: company._id,
        currentPhase: 'FLEET_DISPATCH',
        currentStepType: 'DISPATCH_TRIAGE',
        currentStepState: soleProprietor ? 'ASSIGNED' : 'QUEUED',
        currentRequiredRoleId: soleProprietor
          ? undefined
          : dispatcherRoleDef._id,
        currentAssignedToId: soleProprietor ? user._id : undefined,
        activeFleetDispatcherId: undefined,
        fleetDispatchGroupId: primaryLocation.defaultDispatchGroupId,
        address: vehicle.location?.address,
        longitude: vehicle.location?.longitude,
        latitude: vehicle.location?.latitude,
        requesterCompanyName: company.name,
        requesterLocationName: primaryLocation.name,
      }),
      createRequestSubmissionHistory({
        ctx,
        requestId: input.requestId,
        user,
        userRole: roles.find(r => r.type === 'DRIVER_FLEET')!,
      }),
    ]);

    return createResponse({
      success: true,
      message: `Request ${request.caseNumber} submitted successfully`,
    });
  },
});

export const submitServiceProviderRequest = zMutation({
  args: {
    input: refinedServiceSubmissionSchema,
  },
  handler: async (ctx, { input }) => {
    const { user, roles, company, primaryLocation } = await getUserContext(ctx);
    const serviceDispatcherRole = roles.find(
      r => r?.type === 'SERVICE_DISPATCHER'
    );

    if (!serviceDispatcherRole) {
      return createResponse({
        success: false,
        message: 'Invalid role for this request type',
        error: 'Must be a service dispatcher to submit this request type',
      });
    }

    const request = await ctx.db.get(input.requestId);
    if (!request) {
      return createResponse({
        success: false,
        message: 'Request not found',
      });
    }

    const { requestId, ...restOfRequest } = input;
    await Promise.all([
      ctx.db.patch(requestId, {
        ...restOfRequest,
        status: 'ACTIVE',
        createdAt: Date.now(),
        currentStepStartedAt: Date.now(),
        createdById: user._id,
        requestCreationContext: 'SERVICE_DISPATCHER_INITIATED',
        currentPhase: 'MECHANIC_DISPATCH',
        currentStepType: 'WITH_SERVICE_PROVIDER_DISPATCH',
        currentStepState: 'ASSIGNED',
        currentAssignedToId: user._id,
        activeServiceDispatcherId: user._id,
        mechanicDispatchGroupId: primaryLocation._id,
        mechanicServiceGroupId: primaryLocation._id,
        serviceProviderCompanyId: company._id,
      }),
      createRequestSubmissionHistory({
        ctx,
        requestId: input.requestId,
        user,
        userRole: serviceDispatcherRole,
      }),
    ]);

    const patchedRequest = await ctx.db.get(requestId);

    if (!patchedRequest) {
      throw new Error('Failed to get updated request');
    }

    const participants = await getRequestParticipants(ctx, {
      driver: patchedRequest.activeDriverId,
      fleetDispatcher: patchedRequest.activeFleetDispatcherId,
    });

    await notifyRequestParticipants({
      ctx,
      message: `${user.clerkUser.fullName} from ${primaryLocation.name} has created a case for your vehicle service request`,
      subject: 'Request Created',
      users: participants,
      sendToIfAvailable: { driver: true, fleetDispatcher: true },
      request: patchedRequest,
      companyInitiatingNotification: company.name,
      notificationType: 'REQUEST_CREATED_BY_SERVICE_DISPATCH',
    });

    return createResponse({
      success: true,
      message: `Request ${request.caseNumber} submitted successfully`,
    });
  },
});

export const submitTechnicianRequest = zMutation({
  args: {
    input: refinedServiceSubmissionSchema,
  },
  handler: async (ctx, { input }) => {
    const { user, roles, company, primaryLocation } = await getUserContext(ctx);

    const techRole = roles.find(r => r?.type === 'TECHNICIAN_PROVIDER');

    const soleProprietorTech =
      !!techRole && primaryLocation.noDispatchSoleProprietor;

    if (!soleProprietorTech) {
      return createResponse({
        success: false,
        message: 'Invalid role for this request type',
        error: 'Must be a technician to submit this request type',
      });
    }

    const request = await ctx.db.get(input.requestId);
    if (!request) {
      return createResponse({
        success: false,
        message: 'Request not found',
      });
    }

    const { requestId, ...restOfRequest } = input;
    await Promise.all([
      ctx.db.patch(requestId, {
        ...restOfRequest,
        status: 'ACTIVE',
        createdAt: Date.now(),
        currentStepStartedAt: Date.now(),
        createdById: user._id,
        requestCreationContext: 'SERVICE_TECHNICIAN_SOLE_PROPRIETOR',
        currentPhase: 'MECHANIC_SERVICE',
        currentStepType: 'TECHNICIAN_ACCEPTED_PRE_EN_ROUTE',
        currentStepState: 'ASSIGNED',
        currentAssignedToId: user._id,
        activeTechnicianId: user._id,
        mechanicDispatchGroupId: primaryLocation._id,
        mechanicServiceGroupId: primaryLocation._id,
        serviceProviderCompanyId: company._id,
      }),
      createRequestSubmissionHistory({
        ctx,
        requestId: input.requestId,
        user,
        userRole: techRole,
      }),
    ]);

    const patchedRequest = await ctx.db.get(requestId);

    if (!patchedRequest) {
      throw new Error('Failed to get updated request');
    }

    const participants = await getRequestParticipants(ctx, {
      driver: patchedRequest.activeDriverId,
      fleetDispatcher: patchedRequest.activeFleetDispatcherId,
    });

    await notifyRequestParticipants({
      ctx,
      message: `${user.clerkUser.fullName} from ${primaryLocation.name} has created a case for your vehicle service request`,
      subject: 'Request Created',
      users: participants,
      sendToIfAvailable: { driver: true, fleetDispatcher: true },
      request: patchedRequest,
      companyInitiatingNotification: company.name,
      notificationType: 'REQUEST_CREATED_BY_SERVICE_DISPATCH',
    });

    return createResponse({
      success: true,
      message: `Request ${request.caseNumber} submitted successfully`,
    });
  },
});

export const submitThirdPartyRequest = zMutation({
  args: {
    input: refinedServiceSubmissionSchema,
  },
  handler: async (ctx, { input }) => {
    const { user, roles, company, primaryLocation } = await getUserContext(ctx);
    const thirdPartyDispatcherRole = roles.find(
      r => r?.type === 'THIRD_PARTY_DISPATCHER'
    );

    if (!thirdPartyDispatcherRole) {
      return createResponse({
        success: false,
        message: 'Invalid role for this request type',
        error: 'Must be a third party dispatcher to submit this request type',
      });
    }

    const request = await ctx.db.get(input.requestId);
    if (!request) {
      return createResponse({
        success: false,
        message: 'Request not found',
      });
    }

    const { requestId, ...restOfRequest } = input;
    await Promise.all([
      ctx.db.patch(requestId, {
        ...restOfRequest,
        status: 'ACTIVE',
        createdAt: Date.now(),
        currentStepStartedAt: Date.now(),
        createdById: user._id,
        currentPhase: 'FLEET_DISPATCH',
        requestCreationContext: 'THIRD_PARTY_DISPATCHER_INITIATED',
        currentStepType: 'DISPATCH_TRIAGE',
        currentStepState: 'ASSIGNED',
        currentRequiredRoleId: undefined,
        currentAssignedToId: user._id,
        activeBrokerageDispatcherId: user._id,
        brokerageGroupId: primaryLocation._id,
        brokerageCompanyId: company._id,
      }),
      createRequestSubmissionHistory({
        ctx,
        requestId: input.requestId,
        user,
        userRole: thirdPartyDispatcherRole,
      }),
    ]);

    const patchedRequest = await ctx.db.get(requestId);

    if (!patchedRequest) {
      throw new Error('Failed to get updated request');
    }

    const participants = await getRequestParticipants(ctx, {
      driver: patchedRequest.activeDriverId,
      fleetDispatcher: patchedRequest.activeFleetDispatcherId,
    });

    await notifyRequestParticipants({
      ctx,
      message: `${user.clerkUser.fullName} from ${primaryLocation.name} has created a case for your vehicle service request`,
      subject: 'Request Created',
      users: participants,
      sendToIfAvailable: { driver: true, fleetDispatcher: true },
      request: patchedRequest,
      companyInitiatingNotification: company.name,
      notificationType: 'REQUEST_CREATED_BY_FLEET_DISPATCH',
    });

    return createResponse({
      success: true,
      message: `Request ${request.caseNumber} submitted successfully`,
    });
  },
});

export const driverConfirmLocationAndSendBackToDispatch = zMutation({
  args: {
    requestId: ServiceRequestZodId,
    timezone: z.string(),
    location: z.object({
      latitude: z.number(),
      longitude: z.number(),
      address: z.string(),
    }),
    city: z.string().optional(),
    state: z.string().optional(),
    postCode: z.string().optional(),
    country: z.string().optional(),
    stateShortCode: z.string().optional(),
    countryCode: z.string().optional(),
  },
  handler: async (
    ctx,
    {
      requestId,
      location,
      timezone,
      city,
      state,
      postCode,
      country,
      stateShortCode,
      countryCode,
    }
  ) => {
    const { user, roles, company } = await getUserContext(ctx);

    const driverRole = roles.find(r => r?.type === 'DRIVER_FLEET');

    if (!driverRole) {
      throw new Error('Must be a driver');
    }

    // Get current request state
    const request = await ctx.db.get(requestId);

    if (!request) {
      throw new Error('Request not found');
    }

    // Validate request is in correct state to be claimed
    if (
      request.status !== 'ACTIVE' ||
      request.currentPhase !== 'FLEET_DISPATCH' ||
      request.currentStepState !== 'ASSIGNED' ||
      request.currentStepType !== 'DRIVER_CONFIRM_LOCATION' ||
      !request.vehicleId ||
      !request.activeFleetDispatcherId
    ) {
      throw new Error(
        'Request is not in the correct state for confirming location and sending back to fleet dispatcher'
      );
    }

    const participants = await getRequestParticipants(ctx, {
      fleetDispatcher: request.activeFleetDispatcherId,
    });

    const { fleetDispatcher } = participants;

    await Promise.all([
      ctx.db.patch(requestId, {
        currentStepStartedAt: Date.now(),
        currentStepType: 'DISPATCH_TRIAGE',
        currentStepState: 'ASSIGNED',
        currentAssignedToId: request.activeFleetDispatcherId,
        longitude: location.longitude,
        latitude: location.latitude,
        address: location.address,
        timezone,
        city,
        state,
        postCode,
        country,
        stateShortCode,
        countryCode,
      }),
      updateDriverAndVehicleLocation(
        ctx,
        {
          vehicleId: request.vehicleId,
          location,
          timezone,
          city,
          state,
          postCode,
          country,
          stateShortCode,
        },
        user._id
      ),
      createHistoryEntry(ctx.db, {
        requestId,
        type: 'WORKFLOW_TRANSITION',
        userId: user._id,
        userRole: driverRole._id,
        messageComponents: messageTemplates.driverConfirmsLocation({
          driverName: user.clerkUser.fullName,
          driverRole: driverRole.type,
          dispatcherName: fleetDispatcher?.clerkUser.fullName ?? '',
          dispatcherRole:
            fleetDispatcher?.primaryRoleType ?? 'FLEET_DISPATCHER',
        }),
        visibleToRoles: [],
      }),
      notifyRequestParticipants({
        ctx,
        message: `${user.clerkUser.fullName} [Driver] shared location and assigned back to you`,
        subject: 'Driver Has Shared Location',
        users: participants,
        sendToIfAvailable: { fleetDispatcher: true },
        request,
        companyInitiatingNotification: company.name,
        notificationType: 'DRIVER_SHARED_LOCATION',
      }),
    ]);

    return { success: true, message: 'Shared location and sent to dispatch' };
  },
});

/**
 * Allow fleet dispatcher to claim a request from the fleet dispatch queue
 */
export const claimFleetDispatchRequest = zMutation({
  args: {
    requestId: ServiceRequestZodId,
  },
  handler: async (ctx, { requestId }) => {
    const { user, roles, company } = await getUserContext(ctx);

    // TODO: more authorization checks needed... i.e. is the fleet dispatcher in the correct group to handle requests stemming from the relevant fleet driver group
    // Verify user is a fleet dispatcher
    const dispatcherRole = roles.find(r => r?.type === 'FLEET_DISPATCHER');
    if (!dispatcherRole) {
      throw new Error('Must be a fleet dispatcher to claim requests');
    }

    // Get current request state
    const request = await ctx.db.get(requestId);

    if (!request) {
      throw new Error('Request not found');
    }

    const participants = await getRequestParticipants(ctx, {
      driver: request.activeDriverId,
    });

    // Validate request is in correct state to be claimed
    if (
      request.status !== 'ACTIVE' ||
      request.currentPhase !== 'FLEET_DISPATCH' ||
      request.currentStepState !== 'QUEUED' ||
      !request.currentRequiredRoleId
    ) {
      throw new Error('Request is not available for claiming');
    }

    // Verify the required role matches
    if (request.currentRequiredRoleId !== dispatcherRole._id) {
      throw new Error('User does not have required role to claim this request');
    }

    try {
      await Promise.all([
        ctx.db.patch(requestId, {
          currentStepState: 'ASSIGNED',
          currentAssignedToId: user._id,
          activeFleetDispatcherId: user._id,
          currentRequiredRoleId: undefined,
        }),
        createHistoryEntry(ctx.db, {
          requestId,
          type: 'WORKFLOW_TRANSITION',
          userId: user._id,
          userRole: dispatcherRole._id,
          messageComponents: messageTemplates.fleetDispatcherClaimed({
            dispatcherName: user.clerkUser.fullName,
            dispatcherRole: user.primaryRoleType ?? 'FLEET_DISPATCHER',
          }),
          visibleToRoles: [],
        }),
        notifyRequestParticipants({
          ctx,
          message: `Dispatcher ${user.clerkUser.fullName} has accepted your request and is working on it`,
          subject: 'Dispatcher Accepted Request',
          users: participants,
          sendToIfAvailable: { driver: true },
          request,
          companyInitiatingNotification: company.name,
          notificationType: 'FLEET_DISPATCHER_ACCEPTED_REQUEST',
        }),
      ]);
    } catch (error) {
      throw new Error('Failed to claim request');
    }

    return {
      success: true,
      message: 'You have successfully claimed this request',
    };
  },
});

export function hasRequestEditAccess(
  request: ServiceRequest,
  {
    userId,
    locationGroupId,
  }: {
    userId: ConvexUserId;
    locationGroupId?: GroupId;
  }
): boolean {
  if (!locationGroupId) {
    return false;
  }

  const isDirectParticipant = [
    request.activeDriverId,
    request.activeFleetDispatcherId,
    request.activeServiceDispatcherId,
    request.activeTechnicianId,
    request.activeTowOperatorId,
    request.currentAssignedToId,
    request.activeBrokerageDispatcherId,
    request.createdById,
  ].includes(userId);

  if (isDirectParticipant) {
    return true;
  }

  return false;
}
// TODO: Needs lots of changes and enhancements, very basic...
/**
 * Check if a user has permission to access a request based on their role and involvement
 */
export function hasRequestAccess(
  request: ServiceRequest,
  {
    userId,
    companyId,
    roles,
    locationGroupId,
    isSuperAdmin,
  }: {
    userId: ConvexUserId;
    companyId: CompanyId;
    roles: Array<{ type: string; _id: string } | null>;
    locationGroupId?: GroupId;
    isSuperAdmin?: boolean;
  }
): boolean {
  if (!locationGroupId) {
    return false;
  }
  // Check if user is directly involved in request
  const isDirectParticipant = [
    request.activeDriverId,
    request.activeFleetDispatcherId,
    request.activeServiceDispatcherId,
    request.activeTechnicianId,
    request.activeTowOperatorId,
    request.currentAssignedToId,
    request.activeBrokerageDispatcherId,
    request.createdById,
  ].includes(userId);

  if (isDirectParticipant || isSuperAdmin) return true;

  // Check if user belongs to one of the involved companies, TODO: This makes things super open, revisit
  const isInvolvedCompany = [
    request.requesterCompanyId,
    request.serviceProviderCompanyId,
    request.towingCompanyId,
    request.brokerageCompanyId,
  ].includes(companyId);

  const hasLocationAccess = [
    request.driverGroupId,
    request.fleetDispatchGroupId,
    request.towDispatchGroupId,
    request.towServiceGroupId,
    request.mechanicDispatchGroupId,
    request.mechanicServiceGroupId,
    request.brokerageGroupId,
  ].includes(locationGroupId);

  // TODO: For dispatchers, need to check dispatchCoverageGroupIds or whatever equivalent we create so dispatchers can cover multiple locations

  if (!isInvolvedCompany || !hasLocationAccess) return false;

  // TODO: Think this through again, restrictions were causing too many headaches that weren't easily remediated, leave open
  return true;
}

const assignToServiceProviderInput = z.object({
  requestId: ServiceRequestZodId,
  locationGroupId: GroupZodId, // The service provider location group
  estimatedTimeOfArrival: z.string().optional(),
});

export const assignToServiceProviderDispatch = zMutation({
  args: assignToServiceProviderInput.shape,
  handler: async (
    ctx,
    { requestId, locationGroupId, estimatedTimeOfArrival }
  ) => {
    const { user, roles, company, primaryLocation } = await getUserContext(ctx);

    // Verify user is fleet dispatcher
    const fleetDispatcherRole = roles.find(r => r?.type === 'FLEET_DISPATCHER');
    const thirdPartyDispatcherRole = roles.find(
      r => r?.type === 'THIRD_PARTY_DISPATCHER'
    );

    const soleProprietorDriver =
      primaryLocation.noDispatchSoleProprietor &&
      !!roles.find(r => r?.type === 'DRIVER_FLEET');

    if (
      !fleetDispatcherRole &&
      !thirdPartyDispatcherRole &&
      !soleProprietorDriver
    ) {
      throw new Error('Only fleet dispatchers can assign to service providers');
    }

    const [request, locationGroup, serviceDispatcherRole] = await Promise.all([
      ctx.db.get(requestId),
      ctx.db.get(locationGroupId),
      getOneFrom(
        ctx.db,
        'roleDefinitions',
        'by_type',
        'SERVICE_DISPATCHER',
        'type'
      ),
    ]);

    if (!request || !serviceDispatcherRole) {
      throw new Error('Request or role not found');
    }

    const participants = await getRequestParticipants(ctx, {
      driver: request.activeDriverId,
      // If there is a broker, we need to notify the fleet dispatcher if they exist, TODO: add to notifications, this is a new scenario
      fleetDispatcher: request.activeFleetDispatcherId
        ? request.activeFleetDispatcherId
        : undefined,
    });

    if (
      request.status !== 'ACTIVE' ||
      request.currentPhase !== 'FLEET_DISPATCH' ||
      request.currentStepState !== 'ASSIGNED' ||
      (request.activeFleetDispatcherId !== user._id &&
        request.activeBrokerageDispatcherId !== user._id &&
        !(soleProprietorDriver && request.activeDriverId === user._id))
    ) {
      throw new Error('Request is not in valid state for assignment');
    }

    if (!locationGroup || !locationGroup.companyId) {
      throw new Error('Service provider location not found');
    }

    if (
      !user.primaryLocationGroupId
      // TODO: removing this because it is WRONG, but we still need to have some sort of check here... just can't think of what it is now
      // (user.primaryLocationGroupId !== request.driverGroupId &&
      //   primaryLocation?.dispatchCoverageGroupIds?.some(
      //     g => g === request.driverGroupId
      //   ))
    ) {
      throw new Error('Not authorized to assign requests from this location');
    }

    await Promise.all([
      ctx.db.patch(requestId, {
        currentPhase: 'MECHANIC_DISPATCH',
        currentStepType: 'WITH_SERVICE_PROVIDER_DISPATCH',
        currentStepState: 'QUEUED',
        currentStepStartedAt: Date.now(),
        serviceProviderCompanyId: locationGroup.companyId,
        mechanicDispatchGroupId: locationGroup.defaultDispatchGroupId,
        mechanicServiceGroupId: locationGroup._id,
        currentRequiredRoleId: serviceDispatcherRole._id,
        currentAssignedToId: undefined,
        estimatedTimeOfArrival,
      }),
      createHistoryEntry(ctx.db, {
        requestId,
        type: 'WORKFLOW_TRANSITION',
        userRole: user.primaryRoleId!,
        userId: user._id,
        visibleToRoles: [],
        messageComponents:
          messageTemplates.fleetDispatcherAssignedToServiceProvider({
            dispatcherName: user.clerkUser.fullName,
            dispatcherRole: user.primaryRoleType ?? 'FLEET_DISPATCHER',
            serviceProviderName: locationGroup.name,
          }),
      }),
      notifyRequestParticipants({
        ctx,
        message: `Dispatcher ${user.clerkUser.fullName} has assigned your request to ${locationGroup.name}, waiting for service provider to accept`,
        subject: 'Dispatcher Submitted Request To Service Provider',
        users: participants,
        sendToIfAvailable: {
          driver: !soleProprietorDriver,
          fleetDispatcher: true,
        },
        request,
        companyInitiatingNotification: company.name,
        notificationType: 'DISPATCHER_SUBMITTED_REQUEST_TO_SERVICE_PROVIDER',
      }),
      notifyServiceProviderDispatchers({
        ctx,
        message: `Dispatcher ${user.clerkUser.fullName} has assigned a service request to your queue, please accept or decline`,
        subject: 'Customer Submitted Service Request',
        request,
        companyInitiatingNotification: company.name,
        notificationType: 'REQUEST_ASSIGNED_TO_SERVICE_PROVIDER_QUEUE',
        locationGroupId: locationGroup.defaultDispatchGroupId,
      }),
    ]);

    // Schedule timeout check for 10 minutes from now
    const TIMEOUT_MS = 10 * 60 * 1000;

    await ctx.scheduler.runAfter(
      TIMEOUT_MS,
      internal.nonnodeactions.requests.handleServiceProviderTimeout,
      {
        requestId,
        originalDispatcherId: user._id,
        locationGroupId: locationGroup.defaultDispatchGroupId!,
        serviceShopLocationId: locationGroup._id,
      }
    );

    return {
      success: true,
      message: 'Request assigned to service provider dispatch queue',
    };
  },
});

const assignDirectlyToInvitedServiceDispatcherInput = z.object({
  requestId: ServiceRequestZodId,
  locationGroupId: GroupZodId,
  companyId: CompanyZodId,
  serviceDispatcherId: ConvexUserZodId,
  roleOfInviterId: RoleDefinitionZodId,
  userInvitingId: ConvexUserZodId,
  userBeingInvitedId: ConvexUserZodId,
});

export const assignDirectlyToInvitedServiceDispatcher = zInternalMutation({
  args: assignDirectlyToInvitedServiceDispatcherInput.shape,
  handler: async (
    ctx,
    {
      requestId,
      locationGroupId,
      serviceDispatcherId,
      companyId,
      roleOfInviterId,
      userInvitingId,
      userBeingInvitedId,
    }
  ) => {
    try {
      const [userInviting, userBeingInvited, locationGroup] = await Promise.all(
        [
          ctx.db.get(userInvitingId),
          ctx.db.get(userBeingInvitedId),
          ctx.db.get(locationGroupId),
        ]
      );

      if (!userInviting || !userBeingInvited || !locationGroup) {
        throw new Error(
          `Failed to update request: inviter or invitee user could not be found`
        );
      } else {
        await Promise.all([
          ctx.db.patch(requestId, {
            currentPhase: 'MECHANIC_DISPATCH',
            currentStepType: 'WITH_SERVICE_PROVIDER_DISPATCH',
            currentStepState: 'ASSIGNED',
            currentStepStartedAt: Date.now(),
            serviceProviderCompanyId: companyId,
            mechanicDispatchGroupId: locationGroup.defaultDispatchGroupId,
            mechanicServiceGroupId: locationGroup._id,
            currentRequiredRoleId: undefined,
            currentAssignedToId: serviceDispatcherId,
            activeServiceDispatcherId: serviceDispatcherId,
          }),
          createHistoryEntry(ctx.db, {
            requestId,
            type: 'WORKFLOW_TRANSITION',
            userId: userInviting._id,
            userRole: roleOfInviterId,
            visibleToRoles: [],
            messageComponents:
              messageTemplates.fleetDispatcherAssignedToServiceProvider({
                dispatcherName: userInviting.clerkUser.fullName,
                dispatcherRole:
                  userInviting.primaryRoleType ?? 'FLEET_DISPATCHER',
                serviceProviderName: locationGroup.name,
                serviceDispatcherName: userBeingInvited.clerkUser.fullName,
                serviceDispatcherRole:
                  userBeingInvited.primaryRoleType ?? 'SERVICE_DISPATCHER',
              }),
          }),
        ]);
        return {
          success: true,
          message: 'Request assigned to service provider dispatch queue',
        };
      }
    } catch (error) {
      // Log error for debugging
      console.error('Failed to assign to service dispatcher:', error);
      // Throw technical error for caller to handle
      throw new Error(
        `Failed to update request ${requestId} or create history entry: ${error instanceof Error ? error.message : 'Unknown error'}`
      );
    }
  },
});

const assignDirectlyToInvitedProviderTechnicianInput = z.object({
  requestId: ServiceRequestZodId,
  technicianId: ConvexUserZodId,
  serviceDispatcherId: ConvexUserZodId,
  dispatcherRoleId: RoleDefinitionZodId,
  dispatcherName: z.string(),
  estimatedTimeOfArrival: z.string().optional(),
  companyName: z.string(),
});

export const assignDirectlyToInvitedProviderTechnician = zInternalMutation({
  args: assignDirectlyToInvitedProviderTechnicianInput.shape,
  handler: async (ctx, args) => {
    await assignTechnicianToRequest(ctx, args);

    return {
      success: true,
      message: 'Request assigned to provider technician',
    };
  },
});

// For service dispatchers to claim requests from their queue
export const claimServiceDispatchRequest = zMutation({
  args: {
    requestId: ServiceRequestZodId,
  },
  handler: async (ctx, { requestId }) => {
    const { user, roles, company, primaryLocation } = await getUserContext(ctx);

    // Verify user is service dispatcher
    const dispatcherRole = roles.find(r => r?.type === 'SERVICE_DISPATCHER');
    const technicianRole = roles.find(r => r?.type === 'TECHNICIAN_PROVIDER');

    const soleProprietorTech =
      !!technicianRole && primaryLocation.noDispatchSoleProprietor;

    if (!dispatcherRole) {
      throw new Error('Must be a service dispatcher to claim requests');
    }

    // Get and validate request
    const request = await ctx.db.get(requestId);
    if (!request) throw new Error('Request not found');

    // TODO: Cleanup
    if (soleProprietorTech) {
      // TODO: Really wonky... getting it working
      if (
        request.status !== 'ACTIVE' ||
        request.currentPhase !== 'MECHANIC_DISPATCH' ||
        request.currentStepState !== 'QUEUED' ||
        !request.currentRequiredRoleId ||
        request.currentRequiredRoleId !== dispatcherRole._id
      ) {
        // Need to handle case where invited user was directly assigned as dispatch, and now needs to "claim" as technician, TODO: probably needs more conditions
        if (!request.currentAssignedToId) {
          throw new Error('Request is not available for claiming');
        }
      }
      const [participants] = await Promise.all([
        getRequestParticipants(ctx, {
          driver: request.activeDriverId,
          fleetDispatcher: request.activeFleetDispatcherId,
          brokerageDispatcher: request.activeBrokerageDispatcherId,
        }),
        ctx.db.patch(requestId, {
          currentPhase: 'MECHANIC_SERVICE',
          currentStepType: 'TECHNICIAN_ACCEPTED_PRE_EN_ROUTE', // Update to the new type here
          currentStepState: 'ASSIGNED',
          currentAssignedToId: user._id,
          activeServiceDispatcherId: user._id,
          activeTechnicianId: user._id,
          currentRequiredRoleId: undefined,
          mechanicServiceGroupId: primaryLocation._id,
          currentStepStartedAt: Date.now(),
        }),
        createHistoryEntry(ctx.db, {
          requestId,
          type: 'WORKFLOW_TRANSITION',
          userId: user._id,
          userRole: technicianRole._id,
          messageComponents: messageTemplates.technicianAction({
            technicianName: user.clerkUser.fullName,
            technicianRole: user.primaryRoleType!,
            action: ' has accepted the assignment and is ready to serve',
          }),
          visibleToRoles: [],
        }),
      ]);

      await notifyRequestParticipants({
        ctx,
        message: `${user.clerkUser.fullName} [Technician] has accepted the case`,
        subject: 'Technician Has Accepted Case',
        users: participants,
        sendToIfAvailable: {
          fleetDispatcher: true,
          driver: true,
          brokerageDispatcher: true,
        },
        request,
        companyInitiatingNotification: company.name,
        notificationType: 'TECHNICIAN_ACCEPTED_REQUEST',
      });

      return {
        success: true,
        message: 'Successfully claimed request',
      };
    }

    if (
      request.status !== 'ACTIVE' ||
      request.currentPhase !== 'MECHANIC_DISPATCH' ||
      request.currentStepState !== 'QUEUED' ||
      !request.currentRequiredRoleId ||
      request.currentRequiredRoleId !== dispatcherRole._id
    ) {
      throw new Error('Request is not available for claiming');
    }

    const [participants] = await Promise.all([
      getRequestParticipants(ctx, {
        driver: request.activeDriverId,
        fleetDispatcher: request.activeFleetDispatcherId,
        brokerageDispatcher: request.activeBrokerageDispatcherId,
      }),
      ctx.db.patch(requestId, {
        currentStepState: 'ASSIGNED',
        currentAssignedToId: user._id,
        activeServiceDispatcherId: user._id,
        currentRequiredRoleId: undefined,
      }),
      createHistoryEntry(ctx.db, {
        requestId,
        type: 'WORKFLOW_TRANSITION',
        userId: user._id,
        userRole: dispatcherRole._id,
        messageComponents: messageTemplates.serviceDispatcherClaimed({
          dispatcherName: user?.clerkUser.fullName ?? '',
          dispatcherRole: user?.primaryRoleType ?? 'SERVICE_DISPATCHER',
        }),
        visibleToRoles: [],
      }),
    ]);

    await notifyRequestParticipants({
      ctx,
      message: `Service dispatch has accepted the request`,
      subject: 'Service Dispatch Accepted Request',
      users: participants,
      sendToIfAvailable: {
        fleetDispatcher: true,
        driver: true,
        brokerageDispatcher: true,
      },
      request,
      companyInitiatingNotification: company.name,
      notificationType: 'SERVICE_DISPATCHER_ACCEPTED_REQUEST',
    });

    return {
      success: true,
      message: 'Successfully claimed request',
    };
  },
});

export const getAvailableTechnicians = zQuery({
  args: {
    requestId: ServiceRequestZodId,
  },
  handler: async (ctx, { requestId }) => {
    const { company } = await getUserContext(ctx);

    const request = await ctx.db.get(requestId);
    if (!request) throw new Error('Request not found');

    // Verify dispatcher belongs to service provider company
    if (request.serviceProviderCompanyId !== company._id) {
      throw new Error('Not authorized to view technicians for this request');
    }

    const technicians = await ctx.db
      .query('users')
      .withIndex('by_companyId_and_primaryRoleType', q =>
        q
          .eq('companyId', company._id)
          .eq('primaryRoleType', 'TECHNICIAN_PROVIDER')
      )
      .collect();

    const nonDeletedTechnicians = technicians.filter(t => !t.isDeleted);

    let jobSiteLong = request.longitude;
    let jobSiteLat = request.latitude;

    if (request.vehicleId) {
      const vehicle = request.vehicleId
        ? await ctx.db.get(request.vehicleId)
        : null;
      if (!vehicle || !vehicle?.location) {
        throw new Error('Vehicle location not found');
      }

      jobSiteLong = vehicle.location.longitude;
      jobSiteLat = vehicle.location.latitude;
    }

    // Calculate distances and filter by service radius TODO: use more geospatial indexing here
    const techniciansWithDistance = nonDeletedTechnicians
      .map(tech => {
        if (
          !tech.location?.latitude ||
          !tech.location?.longitude ||
          !jobSiteLat ||
          !jobSiteLong
        ) {
          // TODO: We don't want to hide technicians who haven't shared their location yet...
          return {
            ...tech,
            distance: Infinity,
            estimatedMinutes: null,
            withinServiceArea: true,
            hasLocation: false,
          };
        }

        const distance = calculateDistance(
          jobSiteLat,
          jobSiteLong,
          tech.location.latitude,
          tech.location.longitude
        );

        return {
          ...tech,
          distance,
          estimatedMinutes: Math.round((distance / 45) * 60), // TODO: Figure out if we should hide this or if it's even close to a good rough estimate, etc.
          // withinServiceArea: distance <= (tech.activityRadius || 50),
          withinServiceArea: true,
          hasLocation: true,
        };
      })
      .filter(
        (tech): tech is NonNullable<typeof tech> =>
          tech !== null && tech.withinServiceArea
      )
      .sort((a, b) => {
        // First sort by whether they have a location
        if (a.hasLocation !== b.hasLocation) {
          return a.hasLocation ? -1 : 1;
        }
        // Then sort by distance for techs that have locations
        return a.distance - b.distance;
      });

    //TODO : if no technicians within service area, show all technicians
    return {
      techniciansWithDistance,
      technicians: nonDeletedTechnicians,
    };
  },
});

// Assign technician to request
export const assignTechnician = zMutation({
  args: {
    requestId: ServiceRequestZodId,
    technicianId: ConvexUserZodId,
    estimatedTimeOfArrival: z.string().optional(),
  },
  handler: async (ctx, args) => {
    const { user, roles, company } = await getUserContext(ctx);

    // Verify user is service dispatcher
    const serviceDispatcherRole = roles.find(
      r => r?.type === 'SERVICE_DISPATCHER'
    );

    const isServiceDispatcher = !!serviceDispatcherRole;

    if (!isServiceDispatcher) {
      throw new Error('Must be a service dispatcher to assign technicians');
    }

    await assignTechnicianToRequest(ctx, {
      ...args,
      serviceDispatcherId: user._id,
      dispatcherRoleId: serviceDispatcherRole._id,
      dispatcherName: user.clerkUser.fullName,
      companyName: company.name,
    });

    return {
      success: true,
      message: 'Technician successfully assigned',
    };
  },
});

async function assignTechnicianToRequest(
  ctx: MutationCtx,
  params: {
    requestId: RequestId;
    technicianId: ConvexUserId;
    serviceDispatcherId: ConvexUserId;
    dispatcherName: string;
    dispatcherRoleId: RoleDefinitionId;
    estimatedTimeOfArrival?: string;
    companyName: string;
  }
) {
  const [request, participants, technicianRole] = await Promise.all([
    ctx.db.get(params.requestId),
    getRequestParticipants(ctx, {
      technician: params.technicianId,
    }),
    getOneFrom(
      ctx.db,
      'roleDefinitions',
      'by_type',
      'TECHNICIAN_PROVIDER',
      'type'
    ),
  ]);

  const { technician } = participants;

  if (!request || !technician || !technicianRole) {
    throw new Error('Request, technician or technician role not found');
  }

  await Promise.all([
    ctx.db.patch(params.requestId, {
      currentPhase: 'MECHANIC_SERVICE',
      currentStepType: 'TECHNICIAN_ASSIGNED',
      currentStepStartedAt: Date.now(),
      currentStepState: 'ASSIGNED',
      currentAssignedToId: params.technicianId,
      currentRequiredRoleId: technicianRole._id,
      activeTechnicianId: params.technicianId,
      mechanicServiceGroupId: technician.primaryLocationGroupId,
      estimatedTimeOfArrival: params.estimatedTimeOfArrival,
    }),
    updateTechnicianStatusHelper(ctx, params.technicianId, 'BUSY'),
    createHistoryEntry(ctx.db, {
      requestId: params.requestId,
      type: 'WORKFLOW_TRANSITION',
      userId: params.serviceDispatcherId,
      userRole: params.dispatcherRoleId,
      messageComponents: messageTemplates.technicianAssignedToRequest({
        dispatcherName: params.dispatcherName,
        dispatcherRole: 'SERVICE_DISPATCHER',
        technicianName: technician.clerkUser.fullName,
        technicianRole: technicianRole.type,
      }),
      visibleToRoles: [],
      details: {
        targetUserId: params.technicianId,
        targetRoleId: technicianRole._id,
      },
    }),
    notifyRequestParticipants({
      ctx,
      message: `${params.dispatcherName} [Dispatcher] assigned a request to you`,
      subject: 'You Have Been Assigned a Case',
      users: participants,
      sendToIfAvailable: { technician: true },
      request,
      companyInitiatingNotification: params.companyName,
      notificationType: 'DISPATCHER_ASSIGNED_REQUEST_TO_TECHNICIAN',
    }),
  ]);

  return { technician, request };
}

export const acceptTechnicianAssignment = zMutation({
  args: {
    requestId: ServiceRequestZodId,
  },
  handler: async (ctx, { requestId }) => {
    const { user, roles, company } = await getUserContext(ctx);

    // Verify user is technician
    const technicianRole = roles.find(r => r?.type === 'TECHNICIAN_PROVIDER');
    if (!technicianRole) {
      throw new Error('Must be a technician to accept assignments');
    }

    // Get and validate request
    const request = await ctx.db.get(requestId);
    if (!request) throw new Error('Request not found');

    if (
      request.status !== 'ACTIVE' ||
      request.currentPhase !== 'MECHANIC_SERVICE' ||
      request.currentStepState !== 'ASSIGNED' ||
      request.activeTechnicianId !== user._id
    ) {
      throw new Error('Request is not available for acceptance');
    }

    const [participants] = await Promise.all([
      getRequestParticipants(ctx, {
        fleetDispatcher: request.activeFleetDispatcherId,
        driver: request.activeDriverId,
        serviceDispatcher: request.activeServiceDispatcherId,
        brokerageDispatcher: request.activeBrokerageDispatcherId,
      }),
      ctx.db.patch(requestId, {
        currentStepType: 'TECHNICIAN_ACCEPTED_PRE_EN_ROUTE', // Use the new step type
        currentStepStartedAt: Date.now(),
        currentAssignedToId: user._id,
        currentRequiredRoleId: undefined,
      }),
      createHistoryEntry(ctx.db, {
        requestId,
        type: 'WORKFLOW_TRANSITION',
        userId: user._id,
        userRole: technicianRole._id,
        visibleToRoles: [],
        messageComponents: messageTemplates.technicianAction({
          technicianName: user.clerkUser.fullName,
          technicianRole: user.primaryRoleType!,
          action: ' has accepted the assignment, but is not en route yet',
        }),
      }),
    ]);

    await notifyRequestParticipants({
      ctx,
      message: `${user.clerkUser.fullName} [Technician] has accepted the case, but has not confirmed they are en route yet`,
      subject: 'Technician Has Accepted Case',
      users: participants,
      sendToIfAvailable: {
        fleetDispatcher: true,
        driver: true,
        serviceDispatcher: true,
        brokerageDispatcher: true,
      },
      request,
      companyInitiatingNotification: company.name,
      notificationType: 'TECHNICIAN_ACCEPTED_REQUEST',
    });

    return {
      success: true,
      message: 'Assignment accepted successfully',
    };
  },
});

export const technicianConfirmEnRoute = zMutation({
  args: {
    requestId: ServiceRequestZodId,
  },
  handler: async (ctx, { requestId }) => {
    const { user, roles, company } = await getUserContext(ctx);

    // Verify user is technician
    const technicianRole = roles.find(r => r?.type === 'TECHNICIAN_PROVIDER');
    if (!technicianRole) {
      throw new Error('Must be a technician to confirm en route');
    }

    // Get and validate request
    const request = await ctx.db.get(requestId);
    if (!request) throw new Error('Request not found');

    // Check if the request is in TECHNICIAN_ACCEPTED_PRE_EN_ROUTE or TECHNICIAN_ASSIGNED state
    // This allows both new flow and legacy assignments to transition to en route
    if (
      request.status !== 'ACTIVE' ||
      request.currentPhase !== 'MECHANIC_SERVICE' ||
      (request.currentStepType !== 'TECHNICIAN_ACCEPTED_PRE_EN_ROUTE' &&
        request.currentStepType !== 'TECHNICIAN_ASSIGNED') ||
      request.currentStepState !== 'ASSIGNED' ||
      request.activeTechnicianId !== user._id
    ) {
      throw new Error('Request is not in valid state to confirm en route');
    }

    if (user?.location && request.latitude && request.longitude) {
      await ctx.scheduler.runAfter(0, internal.actions.googleMaps.createRoute, {
        userId: user._id,
        requestId: request._id,
        technicianLocation: {
          latitude: user.location.latitude,
          longitude: user.location.longitude,
        },
        requestLocation: {
          latitude: request.latitude,
          longitude: request.longitude,
        },
      });
    } else {
      console.log(
        'Error creating the route, missing some coordinates',
        user.location?.latitude,
        user.location?.longitude,
        request.latitude,
        request.longitude
      );
    }

    const [participants] = await Promise.all([
      getRequestParticipants(ctx, {
        fleetDispatcher: request.activeFleetDispatcherId,
        driver: request.activeDriverId,
        serviceDispatcher: request.activeServiceDispatcherId,
        brokerageDispatcher: request.activeBrokerageDispatcherId,
      }),
      ctx.db.patch(requestId, {
        currentStepType: 'TECHNICIAN_ACCEPTED',
        currentStepStartedAt: Date.now(),
        currentAssignedToId: user._id,
        currentRequiredRoleId: undefined,
      }),
      ctx.scheduler.runAfter(
        LOCATION_CHECK_INTERVAL,
        internal.nonnodeactions.requests.monitorTechnicianLocation,
        {
          requestId,
          technicianId: user._id,
        }
      ),
      createHistoryEntry(ctx.db, {
        requestId,
        type: 'WORKFLOW_TRANSITION',
        userId: user._id,
        userRole: technicianRole._id,
        visibleToRoles: [],
        messageComponents: messageTemplates.technicianAction({
          technicianName: user.clerkUser.fullName,
          technicianRole: user.primaryRoleType!,
          action: ' is on the way to the location',
        }),
      }),
    ]);

    await notifyRequestParticipants({
      ctx,
      message: `${user.clerkUser.fullName} [Technician] is on the way`,
      subject: 'Technician Is On The Way',
      users: participants,
      sendToIfAvailable: {
        fleetDispatcher: true,
        driver: true,
        serviceDispatcher: true,
        brokerageDispatcher: true,
      },
      request,
      companyInitiatingNotification: company.name,
      notificationType: 'TECHNICIAN_ACCEPTED_REQUEST',
    });

    return {
      success: true,
      message: 'Confirmed en route successfully',
    };
  },
});

export const declineTechnicianAssignment = zMutation({
  args: {
    requestId: ServiceRequestZodId,
  },
  handler: async (ctx, { requestId }) => {
    const { user, roles, company } = await getUserContext(ctx);

    // Verify user is technician
    const technicianRole = roles.find(r => r?.type === 'TECHNICIAN_PROVIDER');
    if (!technicianRole) {
      throw new Error('Must be a technician to accept assignments');
    }

    // Get and validate request
    const request = await ctx.db.get(requestId);
    if (!request) throw new Error('Request not found');

    if (
      request.status !== 'ACTIVE' ||
      request.currentPhase !== 'MECHANIC_SERVICE' ||
      request.currentStepState !== 'ASSIGNED' ||
      request.activeTechnicianId !== user._id
    ) {
      throw new Error('Request is not available for acceptance');
    }

    // Notify everyone
    // for each of the users that need to be notified run the send email action
    const [participants] = await Promise.all([
      getRequestParticipants(ctx, {
        fleetDispatcher: request.activeFleetDispatcherId,
        driver: request.activeDriverId,
        serviceDispatcher: request.activeServiceDispatcherId,
        brokerageDispatcher: request.activeBrokerageDispatcherId,
      }),
      ctx.db.patch(requestId, {
        currentStepType: 'WITH_SERVICE_PROVIDER_DISPATCH',
        currentStepStartedAt: Date.now(),
        currentAssignedToId: request.activeServiceDispatcherId,
        currentRequiredRoleId: undefined,
        activeTechnicianId: undefined,
      }),
      createHistoryEntry(ctx.db, {
        requestId,
        type: 'WORKFLOW_TRANSITION',
        userId: user._id,
        userRole: technicianRole._id,
        visibleToRoles: [],
        messageComponents: messageTemplates.technicianAction({
          technicianName: user.clerkUser.fullName,
          technicianRole: user.primaryRoleType!,
          action:
            ' has declined the assignment, sent back to service dispatcher',
        }),
      }),
    ]);

    await notifyRequestParticipants({
      ctx,
      message: `${user.clerkUser.fullName} [Technician] has declined the assignment and sent it back to the service dispatcher for reassignment`,
      subject: 'Technician Has Declined Case',
      users: participants,
      sendToIfAvailable: {
        fleetDispatcher: true,
        driver: true,
        serviceDispatcher: true,
        brokerageDispatcher: true,
      },
      request,
      companyInitiatingNotification: company.name,
      notificationType: 'TECHNICIAN_DECLINED_REQUEST',
    });

    return {
      success: true,
      message: 'Declined assignment successfully',
    };
  },
});

// Mark technician as arrived at location
export const technicianArrivedAtLocation = zMutation({
  args: {
    requestId: ServiceRequestZodId,
  },
  handler: async (ctx, { requestId }) => {
    const { user, roles, company } = await getUserContext(ctx);

    // Verify user is technician
    const technicianRole = roles.find(r => r?.type === 'TECHNICIAN_PROVIDER');
    if (!technicianRole) {
      throw new Error('Must be a technician to update arrival status');
    }

    // Get and validate request
    const request = await ctx.db.get(requestId);
    if (!request) throw new Error('Request not found');

    // Validate request state
    if (
      request.status !== 'ACTIVE' ||
      request.currentPhase !== 'MECHANIC_SERVICE' ||
      request.currentStepType !== 'TECHNICIAN_ACCEPTED' ||
      request.activeTechnicianId !== user._id
    ) {
      throw new Error('Request is not in valid state for arrival update');
    }

    const [participants] = await Promise.all([
      getRequestParticipants(ctx, {
        fleetDispatcher: request.activeFleetDispatcherId,
        driver: request.activeDriverId,
        serviceDispatcher: request.activeServiceDispatcherId,
        brokerageDispatcher: request.activeBrokerageDispatcherId,
      }),
      ctx.db.patch(requestId, {
        currentStepType: 'TECHNICIAN_ARRIVED',
        currentStepStartedAt: Date.now(),
      }),
      createHistoryEntry(ctx.db, {
        requestId,
        type: 'WORKFLOW_TRANSITION',
        userId: user._id,
        userRole: technicianRole._id,
        messageComponents: messageTemplates.technicianAction({
          technicianName: user.clerkUser.fullName,
          technicianRole: user.primaryRoleType!,
          action: ' has arrived on site',
        }),
        visibleToRoles: [],
      }),
    ]);

    await notifyRequestParticipants({
      ctx,
      message: `${user.clerkUser.fullName} [Technician] has arrived at the job site`,
      subject: 'Technician Has Arrived At Job Site',
      users: participants,
      sendToIfAvailable: {
        fleetDispatcher: true,
        driver: true,
        serviceDispatcher: true,
        brokerageDispatcher: true,
      },
      request,
      companyInitiatingNotification: company.name,
      notificationType: 'TECHNICIAN_ARRIVED_AT_JOB_SITE',
    });

    return {
      success: true,
      message: 'Arrival status updated successfully',
    };
  },
});

// Start work on request
export const startWorkOnRequest = zMutation({
  args: {
    requestId: ServiceRequestZodId,
  },
  handler: async (ctx, { requestId }) => {
    const { user, roles, company } = await getUserContext(ctx);

    // Verify user is technician
    const technicianRole = roles.find(r => r?.type === 'TECHNICIAN_PROVIDER');
    if (!technicianRole) {
      throw new Error('Must be a technician to start work');
    }

    // Get and validate request
    const request = await ctx.db.get(requestId);
    if (!request) throw new Error('Request not found');

    // Validate request state
    if (
      request.status !== 'ACTIVE' ||
      request.currentPhase !== 'MECHANIC_SERVICE' ||
      request.currentStepType !== 'TECHNICIAN_ARRIVED' ||
      request.activeTechnicianId !== user._id
    ) {
      throw new Error('Request is not in valid state to start work');
    }

    // Record in history
    const [participants] = await Promise.all([
      getRequestParticipants(ctx, {
        fleetDispatcher: request.activeFleetDispatcherId,
        driver: request.activeDriverId,
        serviceDispatcher: request.activeServiceDispatcherId,
        brokerageDispatcher: request.activeBrokerageDispatcherId,
      }),
      ctx.db.patch(requestId, {
        currentStepType: 'TECHNICIAN_STARTED_WORK',
        currentStepStartedAt: Date.now(),
      }),
      createHistoryEntry(ctx.db, {
        requestId,
        type: 'WORKFLOW_TRANSITION',
        userId: user._id,
        userRole: technicianRole._id,
        messageComponents: messageTemplates.technicianAction({
          technicianName: user.clerkUser.fullName,
          technicianRole: user.primaryRoleType!,
          action: ' started work on the repair',
        }),
        visibleToRoles: [],
      }),
    ]);

    await notifyRequestParticipants({
      ctx,
      message: `${user.clerkUser.fullName} [Technician] has started work`,
      subject: 'Technician Has Started Work',
      users: participants,
      sendToIfAvailable: {
        fleetDispatcher: true,
        driver: true,
        serviceDispatcher: true,
        brokerageDispatcher: true,
      },
      request,
      companyInitiatingNotification: company.name,
      notificationType: 'TECHNICIAN_STARTED_WORK',
    });

    return {
      success: true,
      message: 'Work started successfully',
    };
  },
});

// Complete work on request
export const completeWorkOnRequest = zMutation({
  args: {
    requestId: ServiceRequestZodId,
  },
  handler: async (ctx, { requestId }) => {
    const { user, roles, company } = await getUserContext(ctx);

    // Verify user is technician
    const technicianRole = roles.find(r => r?.type === 'TECHNICIAN_PROVIDER');
    const serviceDispatchRole = roles.find(
      r => r?.type === 'SERVICE_DISPATCHER'
    );
    if (!technicianRole && !serviceDispatchRole) {
      throw new Error(
        'Must be a technician or service dispatcher to complete work'
      );
    }

    // Get and validate request
    const request = await ctx.db.get(requestId);
    if (!request) throw new Error('Request not found');

    // Get fleet dispatcher role for reassignment
    const fleetDispatcherRole = await getOneFromOrThrow(
      ctx.db,
      'roleDefinitions',
      'by_type',
      'FLEET_DISPATCHER',
      'type'
    );

    // Validate request state
    if (
      request.status !== 'ACTIVE' ||
      request.currentPhase !== 'MECHANIC_SERVICE' ||
      request.currentStepType !== 'TECHNICIAN_STARTED_WORK' ||
      (request.activeTechnicianId !== user._id &&
        request.activeServiceDispatcherId !== user._id)
    ) {
      throw new Error('Request is not in valid state to complete work');
    }

    let targetId = request.activeBrokerageDispatcherId;

    const fleetInitiated = request.requestCreationContext?.startsWith('FLEET_');

    if (!targetId && request.activeFleetDispatcherId && fleetInitiated) {
      targetId = request.activeFleetDispatcherId;
    } else if (!targetId && request.activeDriverId && fleetInitiated) {
      targetId = request.activeDriverId;
    } else if (
      !targetId &&
      request.activeServiceDispatcherId !== request.activeTechnicianId
    ) {
      targetId = request.activeServiceDispatcherId;
    }

    // If targetId is still undefined, just complete the request as it will be a tech sole proprietor completing the work where driver/fleet dispatcher are not active users in the system
    const noVerificationRequired = !targetId;

    const promises: Promise<any>[] = [
      getRequestParticipants(ctx, {
        fleetDispatcher: request.activeFleetDispatcherId,
        driver: request.activeDriverId,
        serviceDispatcher: request.activeServiceDispatcherId,
        brokerageDispatcher: request.activeBrokerageDispatcherId,
      }),
    ];

    if (noVerificationRequired) {
      promises.push(
        ...[
          ctx.db.patch(requestId, {
            status: 'COMPLETED',
            currentStepType: 'COMPLETED',
            completedAt: Date.now(),
            currentStepStartedAt: Date.now(),
            currentStepState: 'ASSIGNED',
            currentAssignedToId: targetId, // TODO: This SHOULD handle the case where it was service initiated and there was never a fleet dispatcher
            currentRequiredRoleId: undefined,
          }),
        ]
      );

      const historyDetails = {
        requestId,
        type: 'REQUEST_COMPLETED' as const,
        userId: user._id,
        userRole: technicianRole?._id,
        messageComponents: messageTemplates.requestCompleted({
          completedByName: user.clerkUser.fullName,
          completedByRole: user.primaryRoleType!,
        }),
        visibleToRoles: [],
      };

      if (technicianRole) {
        historyDetails.userRole = technicianRole._id;
      }

      if (serviceDispatchRole) {
        historyDetails.userRole = serviceDispatchRole._id;
      }

      if (historyDetails.userRole !== undefined) {
        const safeHistoryDetails = {
          ...historyDetails,
          userRole: historyDetails.userRole as RoleDefinitionId,
        };

        promises.push(createHistoryEntry(ctx.db, safeHistoryDetails));
      }
    } else {
      promises.push(
        ...[
          ctx.db.patch(requestId, {
            currentStepType: 'COMPLETION_VERIFICATION',
            currentPhase: 'VERIFICATION',
            currentStepState: 'ASSIGNED',
            currentAssignedToId: targetId, // TODO: This SHOULD handle the case where it was service initiated and there was never a fleet dispatcher
            currentRequiredRoleId: undefined,
            currentStepStartedAt: Date.now(),
          }),
        ]
      );

      const historyDetails = {
        requestId,
        type: 'WORKFLOW_TRANSITION' as const,
        userId: user._id,
        userRole: technicianRole?._id,
        messageComponents: messageTemplates.technicianAction({
          technicianName: user.clerkUser.fullName,
          technicianRole: user.primaryRoleType!,
          action: ' completed work and submitted for verification',
        }),
        visibleToRoles: [],
        details: {
          targetRoleId: fleetDispatcherRole._id,
        },
      };

      if (technicianRole) {
        historyDetails.userRole = technicianRole._id;
      }

      if (serviceDispatchRole) {
        historyDetails.userRole = serviceDispatchRole._id;
      }

      if (historyDetails.userRole !== undefined) {
        const safeHistoryDetails = {
          ...historyDetails,
          userRole: historyDetails.userRole as RoleDefinitionId,
        };

        promises.push(createHistoryEntry(ctx.db, safeHistoryDetails));
      }
    }

    promises.push(updateTechnicianStatusHelper(ctx, user._id, 'AVAILABLE'));

    // Update request state - assign back to original fleet dispatcher for verification
    const [participants] = await Promise.all(promises);

    if (noVerificationRequired) {
      await notifyRequestParticipants({
        ctx,
        message: `${user.clerkUser.fullName} [Technician] has completed their work on the job and closed out the request`,
        subject: 'Request Has Been Completed',
        users: participants,
        sendToIfAvailable: {
          fleetDispatcher: request.activeFleetDispatcherId !== user._id, // If current user is service dispatcher, include fleet dispatcher (and vice versa)
          serviceDispatcher: request.activeServiceDispatcherId !== user._id,
          brokerageDispatcher: request.activeBrokerageDispatcherId !== user._id,
          driver: true,
        },
        request,
        companyInitiatingNotification: company.name,
        notificationType: 'REQUEST_COMPLETED',
      });
    } else {
      await notifyRequestParticipants({
        ctx,
        message: `${user.clerkUser.fullName} ${technicianRole ? '[Technician] has completed their work on the job, dispatch must now verify the work' : '[Service Dispatcher] has set the repairs to completed and submitted it for verification'}`,
        subject: `${technicianRole ? 'Technician' : 'Service Dispatcher'} Has Completed The Job`,
        users: participants,
        sendToIfAvailable: {
          fleetDispatcher: true,
          driver: true,
          serviceDispatcher: !!technicianRole,
          brokerageDispatcher: true,
        },
        request,
        companyInitiatingNotification: company.name,
        notificationType: 'TECHNICIAN_COMPLETED_WORK',
      });
    }

    return {
      success: true,
      message: 'Work completed successfully',
    };
  },
});

// Fleet dispatcher verifies completion
export const verifyRequestCompletion = zMutation({
  args: {
    requestId: ServiceRequestZodId,
  },
  handler: async (ctx, { requestId }) => {
    const { user, roles, company, primaryLocation } = await getUserContext(ctx);

    // Get and validate request
    const request = await ctx.db.get(requestId);
    if (!request) throw new Error('Request not found');

    const fleetDispatcherRole = roles.find(r => r?.type === 'FLEET_DISPATCHER');
    const serviceDispatcherRole = roles.find(
      r => r?.type === 'SERVICE_DISPATCHER'
    );

    const thirdPartyDispatcherRole = roles.find(
      r => r?.type === 'THIRD_PARTY_DISPATCHER'
    );

    const driverRole = roles.find(r => r?.type === 'DRIVER_FLEET');

    const soleProprietor =
      primaryLocation.noDispatchSoleProprietor && !!driverRole;

    const fleetInitiated = request.requestCreationContext?.startsWith('FLEET_');

    if (request.activeBrokerageDispatcherId && !thirdPartyDispatcherRole) {
      throw new Error('Must be a dispatcher to verify completion');
    }

    if (
      request.activeFleetDispatcherId &&
      !fleetDispatcherRole &&
      fleetInitiated
    ) {
      throw new Error('Must be a fleet dispatcher to verify completion');
    }

    if (
      !request.activeFleetDispatcherId &&
      request.activeDriverId &&
      !request.activeBrokerageDispatcherId &&
      !soleProprietor &&
      !driverRole &&
      fleetInitiated
    ) {
      throw new Error('Must be a driver to verify completion');
    }

    if (
      !request.activeFleetDispatcherId &&
      !request.activeBrokerageDispatcherId &&
      !soleProprietor &&
      !serviceDispatcherRole &&
      user._id !== request.currentAssignedToId
    ) {
      throw new Error('Must be a service dispatcher to verify completion');
    }

    // Validate request state
    if (
      request.status !== 'ACTIVE' ||
      request.currentPhase !== 'VERIFICATION' ||
      request.currentStepType !== 'COMPLETION_VERIFICATION'
    ) {
      throw new Error(
        'Request is not in valid state for completion verification'
      );
    }

    let actingRole = thirdPartyDispatcherRole || fleetDispatcherRole;

    if (!actingRole && soleProprietor) {
      actingRole = driverRole;
    } else if (!actingRole && serviceDispatcherRole) {
      actingRole = serviceDispatcherRole;
    } else {
      // TODO: hacky fix
      actingRole = driverRole;
    }

    // Update request state to completed
    const [participants] = await Promise.all([
      getRequestParticipants(ctx, {
        fleetDispatcher:
          request.activeFleetDispatcherId !== user._id
            ? request.activeFleetDispatcherId
            : undefined,
        serviceDispatcher:
          request.activeServiceDispatcherId !== user._id
            ? request.activeServiceDispatcherId
            : undefined,
        driver: request.activeDriverId,
        technician: request.activeTechnicianId,
        brokerageDispatcher:
          request.activeBrokerageDispatcherId !== user._id
            ? request.activeBrokerageDispatcherId
            : undefined,
      }),
      ctx.db.patch(requestId, {
        status: 'COMPLETED',
        currentStepType: 'COMPLETED',
        completedAt: Date.now(),
        currentStepStartedAt: Date.now(),
      }),
      createHistoryEntry(ctx.db, {
        requestId,
        type: 'REQUEST_COMPLETED',
        userId: user._id,
        userRole: actingRole?._id ?? user.primaryRoleId!,
        messageComponents: messageTemplates.requestCompleted({
          completedByName: user.clerkUser.fullName,
          completedByRole: user.primaryRoleType!,
        }),
        visibleToRoles: [],
      }),
    ]);

    await notifyRequestParticipants({
      ctx,
      message: `${user.clerkUser.fullName} [Dispatch] has completed verification and closed out the request`,
      subject: 'Request Has Been Completed',
      users: participants,
      sendToIfAvailable: {
        fleetDispatcher: request.activeFleetDispatcherId !== user._id, // If current user is service dispatcher, include fleet dispatcher (and vice versa)
        serviceDispatcher: request.activeServiceDispatcherId !== user._id,
        brokerageDispatcher: request.activeBrokerageDispatcherId !== user._id,
        driver: true,
        technician: true,
      },
      request,
      companyInitiatingNotification: company.name,
      notificationType: 'REQUEST_COMPLETED',
    });

    return {
      success: true,
      message: 'Request completed successfully',
    };
  },
});

// what happens when a repair is not verified as done well (disputes)

export const submitRepairDetails = zMutation({
  args: submitRepairDetailsInput.shape,
  handler: async (
    ctx,
    { requestId, cause, correction, notes, wasATemporaryFix, tempVehicleInfo }
  ) => {
    const { user, roles } = await getUserContext(ctx);

    // Verify user is technician
    const technicianRole = roles.find(r => r?.type === 'TECHNICIAN_PROVIDER');
    const serviceDispatchRole = roles.find(
      r => r?.type === 'SERVICE_DISPATCHER'
    );

    if (!technicianRole && !serviceDispatchRole) {
      throw new Error(
        'Must be a technician or service dispatcher to submit repair details'
      );
    }

    // Get and validate request
    const request = await ctx.db.get(requestId);
    if (!request) throw new Error('Request not found');

    // Validate request state
    if (
      (request.currentStepType !== 'TECHNICIAN_STARTED_WORK' &&
        request.currentStepType !== 'COMPLETION_VERIFICATION' &&
        request.currentStepType !== 'COMPLETED') ||
      (request.activeTechnicianId !== user._id &&
        request.activeServiceDispatcherId !== user._id)
    ) {
      throw new Error('Request is not in valid state to submit repair details');
    }

    const sanitizedTempVehicleInfo = sanitizeVehicleUpdates(tempVehicleInfo);

    const promises: Array<Promise<any>> = [
      ctx.db.patch(requestId, {
        repairDetails: {
          cause,
          correction,
          wasATemporaryFix,
          notes,
          completedAt: new Date().toISOString(),
          technicianId: user._id,
        },
        tempVehicleInfo: sanitizedTempVehicleInfo,
      }),
    ];

    const historyDetails = {
      requestId,
      type: 'UPDATED_DETAILS' as const,
      userId: user._id,
      userRole: technicianRole?._id,
      messageComponents: messageTemplates.updatedDetails({
        name: user.clerkUser.fullName,
        role: user.primaryRoleType!,
        action: ' updated repair details',
      }),
      visibleToRoles: [],
    };

    if (technicianRole) {
      historyDetails.userRole = technicianRole._id;
    }

    if (serviceDispatchRole) {
      historyDetails.userRole = serviceDispatchRole._id;
    }

    if (historyDetails.userRole !== undefined) {
      const safeHistoryDetails = {
        ...historyDetails,
        userRole: historyDetails.userRole as RoleDefinitionId,
      };

      promises.push(createHistoryEntry(ctx.db, safeHistoryDetails));
    }

    await Promise.all(promises);

    return {
      success: true,
      message: 'Repair details submitted successfully',
    };
  },
});

export const declineRequestAssignmentAsServiceDispatcher = zMutation({
  args: { requestId: ServiceRequestZodId },
  handler: async (ctx, { requestId }) => {
    const { user, roles, company } = await getUserContext(ctx);

    // Verify user is technician
    const dispatcherRole = roles.find(r => r?.type === 'SERVICE_DISPATCHER');
    if (!dispatcherRole) {
      throw new Error('Must be a service dispatcher to decline');
    }

    // Get and validate request
    const request = await ctx.db.get(requestId);
    if (!request) throw new Error('Request not found');

    // Validate request state
    if (
      request.status !== 'ACTIVE' ||
      request.currentPhase !== 'MECHANIC_DISPATCH' ||
      request.currentStepType !== 'WITH_SERVICE_PROVIDER_DISPATCH' ||
      request.currentAssignedToId !== undefined
    ) {
      throw new Error('Request is not in valid state to decline');
    }

    const [participants, _, __] = await Promise.all([
      getRequestParticipants(ctx, {
        fleetDispatcher: request.activeFleetDispatcherId,
        driver: request.activeDriverId,
        brokerageDispatcher: request.activeBrokerageDispatcherId,
      }),
      ctx.db.patch(requestId, {
        currentStepStartedAt: Date.now(),
        currentStepType: 'DISPATCH_TRIAGE',
        currentStepState: 'ASSIGNED',
        currentPhase: 'FLEET_DISPATCH',
        // If there is a broker/third party dispatcher they take precedence on the "return to" currently, driver is the final fallback and will occur in sole proprietor scenarios
        currentAssignedToId:
          request.activeBrokerageDispatcherId ??
          request.activeFleetDispatcherId ??
          request.activeDriverId,
        currentRequiredRoleId: undefined,
        mechanicDispatchGroupId: undefined,
        mechanicServiceGroupId: undefined,
        serviceProviderCompanyId: undefined,
        estimatedTimeOfArrival: undefined,
      }),
      createHistoryEntry(ctx.db, {
        requestId,
        type: 'WORKFLOW_TRANSITION',
        userId: user._id,
        userRole: dispatcherRole._id,
        messageComponents: messageTemplates.assignmentRejected({
          rejecterName: user.clerkUser.fullName,
          rejecterRole: user.primaryRoleType!,
        }),
        visibleToRoles: [],
      }),
    ]);

    await notifyRequestParticipants({
      ctx,
      message: `Service provider has declined the request and it has been send back to fleet dispatch`,
      subject: 'Service Provider Declined Case',
      users: participants,
      sendToIfAvailable: {
        fleetDispatcher: true,
        driver: true,
        brokerageDispatcher: true,
      },
      request,
      companyInitiatingNotification: company.name,
      notificationType: 'SERVICE_PROVIDER_DECLINED_REQUEST',
    });

    return {
      success: true,
      message: 'Successfully declined request',
    };
  },
});

export const timeoutServiceProviderAssignment = zInternalMutation({
  args: {
    requestId: ServiceRequestZodId,
    originalDispatcherId: ConvexUserZodId,
    locationGroupId: GroupZodId,
  },
  handler: async (
    ctx,
    { requestId, originalDispatcherId, locationGroupId } // TODO: get rid of originalDispatcherId?
  ) => {
    const [request, locationGroup] = await Promise.all([
      ctx.db.get(requestId),
      ctx.db.get(locationGroupId),
    ]);

    if (!request || !locationGroup) {
      throw new Error('Request or location not found');
    }

    // Determine the current active dispatcher or sole proprietor driver
    // Priority order:
    // 1. Brokerage dispatcher (if exists)
    // 2. Fleet dispatcher (if exists)
    // 3. Driver (for sole proprietor cases or as fallback)
    const currentAssigneeId =
      request.activeBrokerageDispatcherId ??
      request.activeFleetDispatcherId ??
      request.activeDriverId;

    if (!currentAssigneeId) {
      throw new Error('No active user found to return the request to');
    }

    // Update request state - revert back to fleet dispatch
    const [participants, _, __] = await Promise.all([
      getRequestParticipants(ctx, {
        fleetDispatcher: request.activeFleetDispatcherId,
        driver: request.activeDriverId,
        brokerageDispatcher: request.activeBrokerageDispatcherId,
      }),
      ctx.db.patch(requestId, {
        currentPhase: 'FLEET_DISPATCH',
        currentStepType: 'DISPATCH_TRIAGE',
        currentStepState: 'ASSIGNED',
        currentAssignedToId: currentAssigneeId,
        currentRequiredRoleId: undefined,
        mechanicDispatchGroupId: undefined,
        mechanicServiceGroupId: undefined,
        serviceProviderCompanyId: undefined,
        estimatedTimeOfArrival: undefined,
        currentStepStartedAt: Date.now(),
      }),
      createHistoryEntry(ctx.db, {
        requestId,
        type: 'SYSTEM_TIMEOUT',
        userId: currentAssigneeId,
        visibleToRoles: [],
        userRole: request.currentRequiredRoleId!,
        messageComponents: messageTemplates.systemTimeout({
          entityName: locationGroup.name,
          timeoutDescription:
            ' did not respond within the required timeframe. Request has been returned to fleet dispatch',
        }),
      }),
    ]);

    await notifyRequestParticipants({
      ctx,
      message: `Service provider did not respond within the required timeframe. Request has been returned to fleet dispatch.`,
      subject: 'Case Returned Due To Timeout',
      users: participants,
      sendToIfAvailable: {
        fleetDispatcher: true,
        driver: true,
        brokerageDispatcher: true,
      },
      request,
      companyInitiatingNotification: 'System Message',
      notificationType: 'SERVICE_PROVIDER_TIMEOUT',
    });
  },
});

export const sendPingReminderToAssignee = zMutation({
  args: {
    requestId: ServiceRequestZodId,
  },
  handler: async (ctx, { requestId }) => {
    const { user, company } = await getUserContext(ctx);
    // TODO: Confirm user has permissions to send ping
    const request = await ctx.db.get(requestId);

    if (
      user?._id !== request?.activeFleetDispatcherId &&
      user?._id !== request?.activeServiceDispatcherId &&
      user?._id !== request?.activeBrokerageDispatcherId
    ) {
      throw new Error('Not authorized to send ping');
    }

    if (request && request?.currentAssignedToId) {
      const assignee = await ctx.db.get(request?.currentAssignedToId);

      if (assignee) {
        if (assignee.clerkUser.primaryPhoneNumber?.phoneNumber) {
          await ctx.scheduler.runAfter(
            0,
            internal.actions.sendSms.sendSmsAction,
            {
              phoneNumber: assignee.clerkUser.primaryPhoneNumber?.phoneNumber,
              message: `${user.clerkUser.fullName} [${getUserRoleDisplay(user.primaryRoleType)}] sent reminder re`,
              requestId: request._id,
            }
          );
        } else {
          throw new Error(
            `Assignee ${assignee._id} does not have a phone number`
          );
        }
        await ctx.scheduler.runAfter(
          0,
          internal.actions.sendEmail.sendEmailUsingLocalTemplates,
          {
            to: assignee.clerkUser.primaryEmailAddress?.emailAddress ?? '',
            emailType: 'NOTIFICATION',
            data: {
              userName: assignee.clerkUser.firstName,
              requestId,
              caseNumber: request.caseNumber,
              subject: 'Case Assignment Reminder',
              additionalContext: `You have been sent a reminder regarding your current assignment`,
              companyInitiatingNotification: company.name,
              fleetReferenceNumber: request.fleetReferenceNumber,
            },
          }
        );
      }

      return {
        success: true,
        message: 'Sent reminder ping to current assignee',
      };
    } else {
      throw new Error('Not currently assigned, cannot send ping');
    }
  },
});

export const getRequestHistory = query({
  args: {
    requestId: v.id('requests'),
    paginationOpts: paginationOptsValidator,
  },
  handler: async (ctx, args) => {
    const { user, company, roles, primaryLocation } = await getUserContext(ctx);

    const { requestId, paginationOpts } = args;

    const request = await ctx.db.get(requestId);
    if (!request) throw new Error('Request not found');

    const hasAccess = hasRequestAccess(request, {
      userId: user._id,
      companyId: company._id,
      roles: roles,
      locationGroupId: primaryLocation._id,
    });

    if (!hasAccess) {
      throw new Error('Not authorized to view request history');
    }

    const historyEntries = await ctx.db
      .query('requestHistory')
      .withIndex('by_requestId', q => q.eq('requestId', requestId))
      .order('asc')
      .paginate(paginationOpts);

    return {
      ...historyEntries,
      page: historyEntries.page.map(formatHistoryEntry),
    };
  },
});

export const getRequestHistoryByRequestId = async (
  ctx: QueryCtx,
  requestId: RequestId
) => {
  const historyEntries = await ctx.db
    .query('requestHistory')
    .withIndex('by_requestId', q => q.eq('requestId', requestId))
    .order('desc')
    .collect();

  return historyEntries.map(formatHistoryEntry);
};

export function isLocationRecentlyUpdated(vehicle?: Vehicle | null) {
  if (!vehicle?.location) {
    console.log('No vehicle or location data');
    return false;
  }

  const { latitude, longitude, lastUpdated } = vehicle.location;
  if (!latitude || !longitude || !lastUpdated) {
    console.log('Missing required location data:', {
      latitude,
      longitude,
      lastUpdated,
    });
    return false;
  }

  const locationTime = new Date(vehicle.location.lastUpdated).getTime();
  const fifteenMinutesAgo = Date.now() - 15 * 60 * 1000;

  return locationTime > fifteenMinutesAgo;
}

export const generateServiceRequestPdf = zMutation({
  args: {
    requestId: ServiceRequestZodId,
  },
  handler: async (ctx, { requestId }) => {
    const { user, company, roles, primaryLocation } = await getUserContext(ctx);

    const request = await ctx.db.get(requestId);
    if (!request) throw new Error('Request not found');

    const hasAccess = hasRequestAccess(request, {
      userId: user._id,
      companyId: company._id,
      roles: roles,
      locationGroupId: primaryLocation._id,
    });

    if (!hasAccess) {
      throw new Error('Not authorized to view request history');
    }

    const scheduleId: ScheduledFunctionId = await ctx.scheduler.runAfter(
      0,
      internal.actions.requests.generateServiceRequestPdfHelper,
      { requestId }
    );

    return {
      success: true,
      message: 'PDF generating',
      scheduleId,
    };
  },
});

export const cancelRequest = zMutation({
  args: { requestId: ServiceRequestZodId, reason: z.string().optional() },
  handler: async (ctx, { requestId, reason }) => {
    const { user, company } = await getUserContext(ctx);

    // TODO: Verify user performing action has the auth

    // make sure request is in valid state for cancellation the easy way... i.e. straight to "completed"/"cancelled" state
    // If not .. i.e. post point of no return (technician is EN ROUTE), we move to the payment processing state, where SP will charge a "call out fee" or whatever it's called
    // Verify user is technician
    // TODO: for now we just go to completed state and add the reasoning to history... payments piece comes next

    // Get and validate request
    const request = await ctx.db.get(requestId);
    if (!request) throw new Error('Request not found');

    // Validate request state
    if (request.status !== 'ACTIVE') {
      throw new Error('Cannot cancel a non active request');
    }

    if (
      user._id !== request.activeFleetDispatcherId &&
      user._id !== request.activeServiceDispatcherId &&
      user._id !== request.activeDriverId &&
      user._id !== request.activeTechnicianId &&
      user._id !== request.activeBrokerageDispatcherId
    ) {
      throw new Error('Not authorized to cancel request');
    }

    const [participants] = await Promise.all([
      getRequestParticipants(ctx, {
        fleetDispatcher:
          user._id === request.activeFleetDispatcherId
            ? undefined
            : request.activeFleetDispatcherId,
        serviceDispatcher:
          user._id === request.activeServiceDispatcherId
            ? undefined
            : request.activeServiceDispatcherId,
        driver:
          user._id === request.activeDriverId
            ? undefined
            : request.activeDriverId,
        technician:
          user._id === request.activeTechnicianId
            ? undefined
            : request.activeTechnicianId,
        brokerageDispatcher:
          user._id === request.activeBrokerageDispatcherId
            ? undefined
            : request.activeBrokerageDispatcherId,
      }),
      ctx.db.patch(requestId, {
        status: 'CANCELLED',
        currentStepType: 'COMPLETED',
        cancellationReason: reason,
        completedAt: Date.now(),
      }),
      createHistoryEntry(ctx.db, {
        requestId,
        type: 'REQUEST_CANCELED',
        userId: user._id,
        userRole: user.primaryRoleId!,
        messageComponents: messageTemplates.requestCancelled({
          cancelledByName: user.clerkUser.fullName,
          cancelledByRole: user.primaryRoleType!,
          reason,
        }),
        visibleToRoles: [],
      }),
    ]);

    await notifyRequestParticipants({
      ctx,
      message: `${user.clerkUser.fullName} [${getUserRoleDisplay(user.primaryRoleType)}] has cancelled the request${reason ? ` with reason: ${reason}` : ''}`,
      subject: 'Request Has Been Completed',
      users: participants,
      sendToIfAvailable: {
        fleetDispatcher: true,
        serviceDispatcher: true,
        driver: true,
        technician: true,
        brokerageDispatcher: true,
      },
      request,
      companyInitiatingNotification: company.name,
      notificationType: 'REQUEST_CANCELLED',
    });

    return {
      success: true,
      message: 'Request cancelled successfully',
    };
  },
});

/**
 * Sanitize tempVehicleInfo updates by converting nulls to undefined
 */
function sanitizeVehicleUpdates(tempVehicleInfo?: Record<string, any> | null) {
  return tempVehicleInfo
    ? Object.fromEntries(
        Object.entries(tempVehicleInfo).map(([key, value]) =>
          value === null ? [key, undefined] : [key, value]
        )
      )
    : undefined;
}

export async function getEnhancedRequest(
  ctx: QueryCtx,
  { requestId }: { requestId: RequestId }
): Promise<EnhancedRequest | null> {
  const request = await ctx.db.get(requestId);
  if (!request) return null;

  const [
    vehicle,
    activeDriver,
    fleetDispatcher,
    serviceDispatcher,
    technician,
    towOperator,
    activeBroker,
  ] = await Promise.all([
    request.vehicleId ? ctx.db.get(request.vehicleId) : Promise.resolve(null),
    request.activeDriverId
      ? ctx.db.get(request.activeDriverId)
      : Promise.resolve(null),
    request.activeFleetDispatcherId
      ? ctx.db.get(request.activeFleetDispatcherId)
      : Promise.resolve(null),
    request.activeServiceDispatcherId
      ? ctx.db.get(request.activeServiceDispatcherId)
      : Promise.resolve(null),
    request.activeTechnicianId
      ? ctx.db.get(request.activeTechnicianId)
      : Promise.resolve(null),
    request.activeTowOperatorId
      ? ctx.db.get(request.activeTowOperatorId)
      : Promise.resolve(null),
    request.activeBrokerageDispatcherId
      ? ctx.db.get(request.activeBrokerageDispatcherId)
      : Promise.resolve(null),
  ]);

  // Return request with related data
  return {
    ...request,
    vehicle,
    activeDriver,
    fleetDispatcher,
    serviceDispatcher,
    technician,
    towOperator,
    activeBroker,
  };
}

export const storeGeneratedServiceRequestPdfStorageId = zInternalMutation({
  args: {
    requestId: ServiceRequestZodId,
    storageId: StorageZodId,
    documentName: z.string(),
  },
  handler: async (ctx, { requestId, storageId, documentName }) => {
    await ctx.db.patch(requestId, {
      serviceRequestPdfStorageId: storageId,
      serviceRequestPdfDocumentName: documentName,
    });

    return {
      success: true,
      message: 'PDF storage ID stored',
    };
  },
});

export const submitExtraDetails = zMutation({
  args: submitExtraDetailsInput.shape,
  handler: async (
    ctx,
    { requestId, technicianEnteredPartsInfo, totalTechnicianHours }
  ) => {
    const { user, roles } = await getUserContext(ctx);

    // Verify user is technician or service dispatcher
    const technicianRole = roles.find(r => r?.type === 'TECHNICIAN_PROVIDER');
    const serviceDispatchRole = roles.find(
      r => r?.type === 'SERVICE_DISPATCHER'
    );

    if (!technicianRole && !serviceDispatchRole) {
      throw new Error(
        'Must be a technician or service dispatcher to submit extra details'
      );
    }

    // Get and validate request
    const request = await ctx.db.get(requestId);
    if (!request) throw new Error('Request not found');

    // Validate request state
    if (
      (request.currentStepType !== 'TECHNICIAN_STARTED_WORK' &&
        request.currentStepType !== 'COMPLETION_VERIFICATION' &&
        request.currentStepType !== 'COMPLETED') ||
      (request.activeTechnicianId !== user._id &&
        request.activeServiceDispatcherId !== user._id)
    ) {
      throw new Error('Request is not in valid state to submit extra details');
    }

    const promises: Array<Promise<any>> = [
      ctx.db.patch(requestId, {
        repairDetails: {
          cause: request.repairDetails?.cause || '',
          correction: request.repairDetails?.correction || '',
          wasATemporaryFix: request.repairDetails?.wasATemporaryFix || false,
          notes: request.repairDetails?.notes || '',
          completedAt: new Date().toISOString(),
          technicianId: user._id,
          technicianEnteredPartsInfo,
          totalTechnicianHours,
        },
      }),
    ];

    const historyDetails = {
      requestId,
      type: 'UPDATED_DETAILS' as const,
      userId: user._id,
      userRole: technicianRole?._id || serviceDispatchRole?._id,
      messageComponents: messageTemplates.updatedDetails({
        name: user.clerkUser.fullName,
        role: user.primaryRoleType!,
        action: ' updated extra details',
      }),
      visibleToRoles: [],
    };

    if (historyDetails.userRole !== undefined) {
      const safeHistoryDetails = {
        ...historyDetails,
        userRole: historyDetails.userRole as RoleDefinitionId,
      };

      promises.push(createHistoryEntry(ctx.db, safeHistoryDetails));
    }

    await Promise.all(promises);

    return {
      success: true,
      message: 'Extra details submitted successfully',
    };
  },
});

export const getPdfDependencies = zInternalQuery({
  args: { requestId: ServiceRequestZodId },
  handler: async (ctx, { requestId }) => {
    const request = await getEnhancedRequest(ctx, { requestId });
    if (!request) throw new Error('Request not found');

    let customerGroupId: GroupId | null = null;

    if (request.driverGroupId) {
      customerGroupId = request.driverGroupId;
    } else if (request.fleetDispatchGroupId) {
      customerGroupId = request.fleetDispatchGroupId;
    }

    const [requestHistory, customerLocation, technicianLocation] =
      await Promise.all([
        getRequestHistoryByRequestId(ctx, requestId),
        customerGroupId ? ctx.db.get(customerGroupId) : null,
        request.mechanicServiceGroupId
          ? ctx.db.get(request.mechanicServiceGroupId)
          : null,
      ]);

    return {
      request,
      requestHistory,
      customerLocation,
      technicianLocation,
    };
  },
});

/**
 * Sanitizes sensitive repair detail fields from a request
 */
export function sanitizeRequestRepairDetails<
  T extends ServiceRequest | EnhancedRequest,
>(request: T, user: ConvexUser): T {
  // If no repair details exist, no sanitization needed
  if (!request.repairDetails) {
    return request;
  }

  // Check if user has permission to see sensitive repair details
  const canViewSensitiveFields =
    user._id === request.activeServiceDispatcherId ||
    user._id === request.activeTechnicianId;

  if (canViewSensitiveFields) {
    return request;
  }

  // Clone request and sanitize sensitive fields
  return {
    ...request,
    repairDetails: {
      ...request.repairDetails,
      technicianEnteredPartsInfo: undefined,
      totalTechnicianHours: undefined,
    },
  };
}

/**
 * Sanitizes sensitive repair details in an array of basic requests
 */
export function sanitizeRequestsRepairDetails(
  requests: ServiceRequest[],
  user: ConvexUser
): ServiceRequest[] {
  return requests.map(request => sanitizeRequestRepairDetails(request, user));
}

/**
 * Sanitizes sensitive repair details in an array of enhanced requests
 */
export function sanitizeEnhancedRequestsRepairDetails(
  requests: EnhancedRequest[],
  user: ConvexUser
): EnhancedRequest[] {
  return requests.map(request => sanitizeRequestRepairDetails(request, user));
}

export const checkPdfAccess = zInternalQuery({
  args: { requestId: ServiceRequestZodId, clerkUserId: z.string() },
  handler: async (ctx, { requestId, clerkUserId }) => {
    // Get user by Clerk ID
    const user = await userQueryByClerkId(ctx, clerkUserId);

    if (!user) throw new ConvexError('User not found');

    // Handle impersonation for super admins
    let companyId = user.companyId!;
    let primaryLocationGroupId = user.primaryLocationGroupId!;

    if (user.clerkUser?.isSuperAdmin) {
      if (user.impersonatingCompanyId) {
        companyId = user.impersonatingCompanyId;
      }
      if (user.impersonatingLocationId) {
        primaryLocationGroupId = user.impersonatingLocationId;
      }
    }

    // Get necessary context
    const [roles, company, primaryLocation, request] = await Promise.all([
      getManyVia(
        ctx.db,
        'userRoles',
        'roleDefinitionId',
        'by_userId',
        user._id
      ),
      ctx.db.get(companyId),
      ctx.db.get(primaryLocationGroupId),
      ctx.db.get(requestId),
    ]);

    if (!company || !primaryLocation) {
      throw new ConvexError(
        'User must belong to a company and primary location'
      );
    }

    if (!request) {
      throw new ConvexError('Request not found');
    }

    // Check if user has access to the request
    if (
      !hasRequestAccess(request, {
        userId: user._id,
        companyId: company._id,
        roles,
        locationGroupId: primaryLocation._id,
        isSuperAdmin: user.clerkUser?.isSuperAdmin,
      })
    ) {
      return { storageId: null, access: false };
    }

    // If user has access to the request, check if the PDF exists
    if (!request.serviceRequestPdfStorageId) {
      return { storageId: null, access: false };
    }

    return {
      storageId: request.serviceRequestPdfStorageId,
      access: true,
    };
  },
});
