import { api } from '@cvx/api';
import { Vehicle } from '@cvx/types/entities/vehiclesEntityTypes';
import { List, ListItem, ListItemText, MenuItem } from '@mui/material';
import { useMutation, useQuery } from 'convex/react';
import { useCallback } from 'react';
import { useScheduledActions } from 'src/hooks/useScheduledActions';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { toast } from 'src/minimal-theme/components/snackbar';
import { ActionConfirmationDialog } from 'src/sections/request/actions/ActionConfirmationDialog';
import { useActionConfirmationDialog } from 'src/sections/request/actions/hooks/useActionConfirmationDialog.ts';

type DeleteVehicleDialogButtonProps = {
  onClosePopper: () => void;
  vehicle: Vehicle;
};

export function DeleteVehicleDialogButton({
  onClosePopper,
  vehicle,
}: DeleteVehicleDialogButtonProps) {
  const activeRequestsForVehicle = useQuery(
    api.functions.requests.getActiveRequestsForVehicle,
    { vehicleId: vehicle._id }
  );
  const { confirmAction, isOpen, onClose, pendingAction } =
    useActionConfirmationDialog();

  const { watchIds } = useScheduledActions();

  const deleteVehicle = useMutation(api.functions.vehicles.deleteVehicle);

  const onDelete = async () => {
    const res = await deleteVehicle({
      vehicleId: vehicle._id,
    });

    if (res.success) {
      toast.success(res.message);
      onClosePopper();
    } else {
      toast.error(res.message);
      onClosePopper();
    }
  };

  const handleOpenDeleteDialog = useCallback(() => {
    const disableConfirm =
      !activeRequestsForVehicle || activeRequestsForVehicle?.length > 0;

    let additionalContent = null;

    if (activeRequestsForVehicle && activeRequestsForVehicle?.length > 0) {
      additionalContent = (
        <>
          <List>
            {activeRequestsForVehicle?.map(request => (
              <ListItem key={request._id}>
                <ListItemText primary={request.caseNumber} />
              </ListItem>
            ))}
          </List>
        </>
      );
    }
    confirmAction({
      title: `Delete Unit${vehicle.unitNumber ? ` ${vehicle.unitNumber}` : ''}`,
      message: disableConfirm
        ? 'You cannot delete this unit as it is currently referenced in active requests.'
        : 'Are you sure you want to delete this unit?',
      onConfirm: onDelete,
      additionalContent,
      disableConfirm,
    });
  }, [confirmAction, activeRequestsForVehicle]);

  return (
    <>
      <MenuItem onClick={handleOpenDeleteDialog} sx={{ color: 'inherit' }}>
        <Iconify icon="solar:trash-bin-trash-bold" />
        Delete
      </MenuItem>
      <ActionConfirmationDialog
        open={isOpen}
        onClose={onClose}
        {...pendingAction}
      />
    </>
  );
}
