import { api } from '@cvx/api';
import { GroupZodId } from '@cvx/types/zod/commonZodId';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { useForm, ValidationError } from '@tanstack/react-form';
import { useMutation, useQuery } from 'convex/react';
import { useCallback, useMemo, useRef } from 'react';
import { toast } from 'sonner';
import { baseInviteSchema } from 'src/convex/functions/invitations';
import { updateUserSchema } from 'src/convex/functions/users';
import {
  DEFAULT_NOTIFICATION_SETTINGS,
  notificationInfo,
  NotificationSetting,
  NotificationSettings,
  NotificationType,
  notificationTypes,
} from 'src/convex/schema/entities/users';
import { UserRole } from 'src/convex/schema/enums/userRole';
import { useScheduledActions } from 'src/hooks/useScheduledActions';
import { AnimateAvatar } from 'src/minimal-theme/components/animate';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { PhoneInput } from 'src/minimal-theme/components/phone-input';
import { useBoolean } from 'src/minimal-theme/hooks/use-boolean';
import {
  AvatarWrapper,
  HoverOverlay,
} from 'src/minimal-theme/layouts/components/account-drawer';
import { AvatarUploadDialog } from 'src/minimal-theme/layouts/components/AvatarUploadDialog';
import { varAlpha } from 'src/minimal-theme/theme/styles';
import { fData } from 'src/minimal-theme/utils/format-number';
import { ActionConfirmationDialog } from 'src/sections/request/actions/ActionConfirmationDialog';
import { useActionConfirmationDialog } from 'src/sections/request/actions/hooks/useActionConfirmationDialog';

export function UserEditForm() {
  const me = useQuery(api.functions.users.getMe);

  const locations = useQuery(
    api.functions.companies.getLocations,
    me &&
      (me?.primaryRoleType === 'FLEET_DISPATCHER' ||
        me?.primaryRoleType === 'SERVICE_DISPATCHER' ||
        me?.primaryRoleType === 'THIRD_PARTY_DISPATCHER')
      ? {}
      : 'skip'
  );
  const updateUser = useMutation(api.functions.users.updateUser);
  const phoneRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const profileImageUploadDialog = useBoolean();

  const { confirmAction, isOpen, onClose, pendingAction } =
    useActionConfirmationDialog();

  const { watchIds } = useScheduledActions();

  const { Field, Subscribe, handleSubmit, reset } = useForm({
    defaultValues: {
      firstName: me?.clerkUser.firstName ?? '',
      lastName: me?.clerkUser.lastName ?? '',
      phone: me?.clerkUser.primaryPhoneNumber?.phoneNumber ?? '',
      email: me?.clerkUser.primaryEmailAddress?.emailAddress,
      knownLocationInvitationId: me?.primaryLocationGroupId,
      notificationSettings: me?.notificationSettings,
    },
    validators: {
      onChange: me
        ? updateUserSchema.omit({ userId: true }).extend({
            knownLocationInvitationId: GroupZodId.optional(),
            primaryLocationGroupId: GroupZodId.optional(),
          })
        : baseInviteSchema.omit({
            role: true,
          }),
    },
    onSubmit: async ({ value }) => {
      const { knownLocationInvitationId, ...restOfValues } = value;

      if (!knownLocationInvitationId) {
        return;
      }

      const res = await updateUser({
        userId: me!._id,
        ...restOfValues,
        primaryLocationGroupId: knownLocationInvitationId,
      });

      if (res.success && res.scheduleId) {
        await watchIds([res.scheduleId], {
          onSuccess: () => {
            // navigate({ to: '/dashboard/dispatchers' });
          },
          onError: failedIds => {
            // Maybe show an error message or handle failed invites
            console.error('Failed to update');
          },
        });
      } else {
        toast.error(res.message);
      }
    },
  });

  const handleFormSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      e.stopPropagation();

      confirmAction({
        title: 'Update Profile',
        message: 'Are you sure you want to update this profile?',
        onConfirm: handleSubmit,
      });
    },
    [confirmAction, handleSubmit, me]
  );

  return (
    <>
      <Card>
        <CardHeader
          title="Update Profile"
          subheader={me?.clerkUser.fullName}
          sx={{ mb: 3 }}
        />
        {/* <Divider /> */}
        <Stack spacing={3} sx={{ p: 3 }}>
          <form onSubmit={handleFormSubmit} style={{ width: '100%' }}>
            <Box
              sx={{
                mb: 5,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <>
                <AvatarWrapper>
                  <AnimateAvatar
                    width={130}
                    slotProps={{
                      avatar: {
                        src: me?.clerkUser.imageUrl,
                        alt: me?.clerkUser.fullName,
                      },
                      overlay: {
                        border: 2,
                        spacing: 3,
                        color: `linear-gradient(135deg, ${varAlpha(theme.vars.palette.primary.mainChannel, 0)} 25%, ${theme.vars.palette.primary.main} 100%)`,
                      },
                    }}
                  >
                    {me?.clerkUser?.fullName?.charAt(0).toUpperCase()}
                  </AnimateAvatar>

                  <HoverOverlay
                    className="overlay"
                    onClick={profileImageUploadDialog.onTrue}
                  >
                    <Iconify
                      icon="solar:camera-minimalistic-bold"
                      color="white"
                      width={24}
                    />
                  </HoverOverlay>
                </AvatarWrapper>
                <Typography
                  variant="caption"
                  sx={{
                    mt: 3,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.disabled',
                  }}
                >
                  Allowed *.jpeg, *.jpg, *.png, *.gif
                  <br /> max size of {fData(3145728)}
                </Typography>
              </>
            </Box>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <Field
                name="firstName"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.value}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                  />
                )}
              />
              <Field
                name="lastName"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={state.value}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    onChange={e => handleChange(e.target.value)}
                    onBlur={handleBlur}
                  />
                )}
              />
              <Field
                name="phone"
                // validators={{
                //   onChange: phoneSchema,
                // }}
                children={({ state, handleChange, handleBlur }) => (
                  <PhoneInput
                    ref={phoneRef}
                    required
                    placeholder="(123) 456-7890"
                    fullWidth
                    value={state.value}
                    onChange={newValue => handleChange(newValue || '')}
                    onBlur={handleBlur}
                    helperText={state.meta.errors[0]}
                    label="Phone Number"
                  />
                )}
              />
              <Field
                name="email"
                children={({ state, handleChange, handleBlur }) => (
                  <TextField
                    label="Email Address"
                    variant="outlined"
                    fullWidth
                    value={state.value ?? ''}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                    onChange={e => {
                      const value = e.target.value.trim();
                      handleChange(value || undefined);
                    }}
                    onBlur={handleBlur}
                  />
                )}
              />
              {locations && locations?.length > 1 && (
                <FormControl>
                  <Field
                    name="knownLocationInvitationId"
                    children={({ state, handleChange }) => (
                      <Autocomplete
                        fullWidth
                        options={locations || []}
                        getOptionLabel={option => option.name}
                        value={
                          locations?.find(l => state.value === l._id) || null
                        }
                        isOptionEqualToValue={(option, value) =>
                          option?._id === value?._id
                        }
                        onChange={(event, newValue) =>
                          handleChange(newValue ? newValue._id : undefined)
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Location"
                            required
                            error={state.meta.errors.length > 0}
                            helperText={state.meta.errors[0]}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option._id}>
                            {option.name}
                          </li>
                        )}
                      />
                    )}
                  />
                </FormControl>
              )}
            </Box>
            <Stack sx={{ mt: 2 }}>
              <Field
                name="notificationSettings"
                children={({ state, handleChange }) => (
                  <NotificationSettingsPicker
                    value={{ ...DEFAULT_NOTIFICATION_SETTINGS, ...state.value }}
                    onChange={handleChange}
                    userRole={me?.primaryRoleType!}
                    error={state.meta.errors.length > 0}
                    helperText={state.meta.errors[0]}
                  />
                )}
              />
            </Stack>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <Subscribe
                selector={state => [state.canSubmit, state.isSubmitting]}
                children={([canSubmit, isSubmitting]) => {
                  return (
                    <LoadingButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      loading={isSubmitting}
                      disabled={!canSubmit}
                    >
                      Save changes
                    </LoadingButton>
                  );
                }}
              />
            </Stack>
          </form>
          <AvatarUploadDialog
            open={profileImageUploadDialog.value}
            onClose={profileImageUploadDialog.onFalse}
          />
          <ActionConfirmationDialog
            open={isOpen}
            onClose={onClose}
            {...pendingAction}
          />
        </Stack>
      </Card>
    </>
  );
}

const NotificationSettingsPicker = ({
  value,
  onChange,
  userRole,
  error,
  helperText,
}: {
  value: NotificationSettings;
  onChange: (value: NotificationSettings) => void;
  userRole: UserRole;
  error?: boolean;
  helperText?: ValidationError;
}) => {
  // Filter notifications visible to this role
  const visibleNotifications = useMemo(
    () =>
      notificationTypes.options.filter(key =>
        notificationInfo[key].visibleToRoles.includes(userRole)
      ),
    [userRole]
  );

  const handleSettingChange = (
    notificationType: NotificationType,
    channel: keyof NotificationSetting,
    checked: boolean
  ) => {
    onChange({
      ...value,
      [notificationType]: {
        ...value[notificationType],
        [channel]: checked,
      },
    });
  };

  return (
    <FormControl error={error} fullWidth>
      <FormLabel sx={{ mb: 2 }}>
        <Typography variant="h6">Notification Preferences</Typography>
        <Typography variant="body2" color="text.secondary">
          Choose how you want to receive notifications for different events
        </Typography>
      </FormLabel>

      <Grid container spacing={2}>
        {visibleNotifications.map(notifType => (
          <Grid item xs={12} sm={6} md={4} key={notifType}>
            <Card
              variant="outlined"
              sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Box mb={2}>
                  <Typography variant="subtitle1">
                    {notificationInfo[notifType].title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {notificationInfo[notifType].description}
                  </Typography>
                </Box>
                <FormGroup>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={value[notifType].email}
                          onChange={e =>
                            handleSettingChange(
                              notifType,
                              'email',
                              e.target.checked
                            )
                          }
                        />
                      }
                      label="Email"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={value[notifType].sms}
                          onChange={e =>
                            handleSettingChange(
                              notifType,
                              'sms',
                              e.target.checked
                            )
                          }
                        />
                      }
                      label="SMS"
                    />
                  </Box>
                </FormGroup>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {helperText && (
        <Typography color="error" variant="body2" sx={{ mt: 2 }}>
          {helperText}
        </Typography>
      )}
    </FormControl>
  );
};
