import { api } from '@cvx/api';
import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
import { useMutation } from 'convex/react';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { Label } from 'src/minimal-theme/components/label';
import { ActionConfirmationDialog } from 'src/sections/request/actions/ActionConfirmationDialog';
import { useActionConfirmationDialog } from 'src/sections/request/actions/hooks/useActionConfirmationDialog';
import { getCreateInvoiceActionState } from 'src/sections/request/actions/utils/getCreateInvoiceActionState';
import { RequestActionProps } from 'src/sections/request/types/RequestActionProps';
import { locationHasRequiredTaxRegistrations } from 'src/utils/tax';

export const CreateInvoiceAction = ({
  request,
  me,
  invoices,
  requestStateInformation,
  viewOnlyForTechnicians,
}: RequestActionProps & { viewOnlyForTechnicians?: boolean }) => {
  const { confirmAction, isOpen, pendingAction, onClose } =
    useActionConfirmationDialog();

  const navigate = useNavigate();

  const createInitialInvoiceDraft = useMutation(
    api.functions.invoices.createInitialInvoiceDraft
  );

  const { isTechnician } = requestStateInformation;

  const handleCreateInvoice = async () => {
    const response = await createInitialInvoiceDraft({
      requestId: request._id,
    });
    if (response.success && response.data.invoiceDraftId) {
      await navigate({
        to: '/dashboard/invoices/$invoiceId',
        params: {
          invoiceId: response.data.invoiceDraftId,
        },
      });
    }
  };

  const actionState = getCreateInvoiceActionState({
    request,
    me,
    requestStateInformation,
  });

  if (!actionState.isVisible && !(isTechnician && viewOnlyForTechnicians)) {
    return null;
  }

  const disabledDueToTaxRegistrationIssues =
    !locationHasRequiredTaxRegistrations({
      requestCountryCode: request.countryCode,
      requestStateShortCode: request.stateShortCode,
      stateTaxRegistrations: me.primaryLocation?.stateTaxRegistrations,
    });

  const invoiceExistsForMyCompany = invoices?.some(
    invoice => invoice.invoice.recipientCompanyId === me?.companyId
  );

  if (
    invoices?.length &&
    invoiceExistsForMyCompany &&
    me?.primaryRoleType !== 'THIRD_PARTY_DISPATCHER'
  ) {
    return null;
  }

  return (
    <>
      {!viewOnlyForTechnicians && (
        <LoadingButton
          onClick={() =>
            confirmAction({
              title: actionState.confirmationTitle!,
              message: actionState.confirmationMessage!,
              onConfirm: handleCreateInvoice,
            })
          }
          fullWidth
          sx={{ height: '100%' }}
          color="primary"
          variant="contained"
          disabled={
            !actionState.isEnabled || disabledDueToTaxRegistrationIssues
          }
          startIcon={<Iconify icon="solar:document-text-broken" width={40} />}
        >
          {actionState.message}
        </LoadingButton>
      )}
      <ActionConfirmationDialog
        open={isOpen}
        onClose={onClose}
        {...pendingAction}
      />
      {invoices?.map(invoice => (
        <Stack
          key={invoice.invoice._id}
          direction="row"
          spacing={2}
          alignItems="center"
        >
          <Button
            variant="contained"
            size="large"
            color="primary"
            fullWidth
            disabled={!!viewOnlyForTechnicians}
            onClick={() => {
              window.location.href = `/dashboard/invoices/${invoice.invoice._id}`;
            }}
            startIcon={
              <Iconify icon="solar:file-text-bold-duotone" width={40} />
            }
          >
            View Invoice — $
            {invoice.invoice.amount ? invoice.invoice.amount / 100 : 0}
          </Button>
          <Label
            variant="soft"
            color={
              (invoice.invoice.status === 'PAID' && 'success') ||
              (invoice.invoice.status === 'SENT' && 'warning') ||
              (invoice.invoice.status === 'OVERDUE' && 'error') ||
              'default'
            }
          >
            {invoice.invoice.status}
          </Label>
        </Stack>
      ))}
    </>
  );
};
