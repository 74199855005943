import { ConvexUser } from '@cvx/types/entities/usersEntityTypes';
import { getManyFrom } from 'convex-helpers/server/relationships';
import { Change } from 'convex-helpers/server/triggers';
import { z } from 'zod';
import { DataModel } from '../../_generated/dataModel';
import { generateUserSearchFields } from '../../functions/helpers/generateUserSearchFields';
import { TriggerContext } from '../../functions/triggers';
import { userHistoryChangeSchema } from '../../schema/entities/userHistories';

export type UserChange = Change<DataModel, 'users'>;

export async function handleUserDeletion(
  ctx: TriggerContext,
  change: UserChange,
  user?: ConvexUser | null
) {
  const oldDoc = change.oldDoc;
  if (!oldDoc?.primaryLocationGroupId) return;

  await Promise.all([
    // Delete from userRoles
    getManyFrom(ctx.innerDb, 'userRoles', 'by_userId', change.id).then(roles =>
      Promise.all(roles.map(r => ctx.innerDb.delete(r._id)))
    ),
    // Delete from userGroups
    getManyFrom(ctx.innerDb, 'userGroups', 'by_userId', change.id).then(
      groups => Promise.all(groups.map(g => ctx.innerDb.delete(g._id)))
    ),
    // Delete any driverHistory
    getManyFrom(ctx.innerDb, 'driverHistory', 'by_userId', change.id).then(
      history => Promise.all(history.map(h => ctx.innerDb.delete(h._id)))
    ),
  ]);
}

export async function handleUserUpsert(
  ctx: TriggerContext,
  change: UserChange,
  user?: ConvexUser | null
) {
  const changes: z.infer<typeof userHistoryChangeSchema>[] = [];

  const oldDoc = change.oldDoc;
  const newDoc = change.newDoc;

  if (newDoc?.primaryLocationGroupId) {
    const searchFields = await generateUserSearchFields(change.newDoc);

    // Only update if search text changed
    if (!oldDoc || searchFields.searchQueryText !== oldDoc.searchQueryText) {
      await ctx.innerDb.patch(change.id, searchFields);
    }

    const primaryLocation = await ctx.innerDb.get(
      newDoc.primaryLocationGroupId
    );
    if (
      primaryLocation?.defaultDispatchGroupId &&
      newDoc.defaultDispatchGroupId !== primaryLocation.defaultDispatchGroupId
    ) {
      await ctx.innerDb.patch(change.id, {
        defaultDispatchGroupId: primaryLocation.defaultDispatchGroupId,
      });
      changes.push({
        field: 'defaultDispatchGroupId',
        before: newDoc.defaultDispatchGroupId,
        after: primaryLocation.defaultDispatchGroupId,
      });
    }

    if (oldDoc) {
      if (oldDoc.clerkUser?.firstName !== newDoc.clerkUser?.firstName) {
        changes.push({
          field: 'clerkUser.firstName',
          before: oldDoc.clerkUser?.firstName,
          after: newDoc.clerkUser?.firstName,
        });
      }

      if (oldDoc.clerkUser?.lastName !== newDoc.clerkUser?.lastName) {
        changes.push({
          field: 'clerkUser.lastName',
          before: oldDoc.clerkUser?.lastName,
          after: newDoc.clerkUser?.lastName,
        });
      }

      if (
        oldDoc.clerkUser?.primaryEmailAddress?.emailAddress !==
        newDoc.clerkUser?.primaryEmailAddress?.emailAddress
      ) {
        changes.push({
          field: 'clerkUser.primaryEmailAddress.emailAddress',
          before: oldDoc.clerkUser?.primaryEmailAddress?.emailAddress,
          after: newDoc.clerkUser?.primaryEmailAddress?.emailAddress,
        });
      }

      if (
        oldDoc.clerkUser?.primaryPhoneNumber?.phoneNumber !==
        newDoc.clerkUser?.primaryPhoneNumber?.phoneNumber
      ) {
        changes.push({
          field: 'clerkUser.primaryPhoneNumber.phoneNumber',
          before: oldDoc.clerkUser?.primaryPhoneNumber?.phoneNumber,
          after: newDoc.clerkUser?.primaryPhoneNumber?.phoneNumber,
        });
      }

      if (oldDoc.isDeleted !== newDoc.isDeleted) {
        changes.push({
          field: 'isDeleted',
          before: oldDoc.isDeleted,
          after: newDoc.isDeleted,
        });
      }
    }

    if (changes.length > 0) {
      await ctx.innerDb.insert('userHistory', {
        userId: change.id,
        updatedBy: user?._id ?? null,
        changes,
      });
    }
  }
}
