import { createFileRoute } from '@tanstack/react-router';
import { AdminDashboard } from 'src/pages/dashboard/admin/AdminDashboard';
import {
  checkRouteAccess,
  routeAccessConfigs,
} from 'src/utils/checkRouteAccess';

export const Route = createFileRoute('/_auth/dashboard/admin-dashboard')({
  beforeLoad: ({ context }) =>
    checkRouteAccess(
      context,
      routeAccessConfigs.superAdminAndCompanyPrimaryAdmin
    ),
  component: AdminDashboard,
});
