import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material';
import { Iconify } from 'src/minimal-theme/components/iconify';

export const RequestDetailsCard = ({
  children,
  title = 'Request Details',
  subheader = 'Enter the details of the request',
  action,
  detailViewOnly,
}: {
  children: React.ReactNode;
  title?: string;
  subheader?: string;
  action?: React.ReactNode;
  detailViewOnly?: boolean;
}) => {
  const actualTitle = detailViewOnly ? (
    <Stack direction="row" spacing={1} alignItems="center">
      <Box
        component="span"
        sx={{ display: 'flex', alignItems: 'center', color: '#3498db' }}
      >
        <Iconify icon="solar:eye-bold" />
      </Box>
      <Typography variant="h6">{title}</Typography>
      <Box
        component="span"
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          backgroundColor: '#eaf6ff',
          color: '#3498db',
          fontSize: '0.75rem',
          borderRadius: '4px',
          padding: '0 8px',
          height: '20px',
        }}
      >
        Visible to All Participants
      </Box>
    </Stack>
  ) : (
    title
  );

  return (
    <Card>
      <CardHeader
        title={actualTitle}
        subheader={subheader}
        action={action}
        sx={
          detailViewOnly
            ? { backgroundColor: 'rgba(52, 152, 219, 0.05)', pb: 2 }
            : {}
        }
      />
      <CardContent>{children}</CardContent>
    </Card>
  );
};
