import { QueryCtx } from '@cvx/server';
import { CityId } from '@cvx/types/entities/sharedIds';
import { PhysicalLocationType } from '@cvx/types/enums/groupEnums';
import {
  CityZodId,
  CountryZodId,
  StateZodId,
  VehicleZodId,
} from '@cvx/types/zod/commonZodId';

import { z } from 'zod';

export const locationSchema = z.object({
  latitude: z.number(),
  longitude: z.number(),
  // Original address as entered
  address: z.string().optional(),
  zip: z.string().optional(),
  lastUpdated: z.string(),
});

export interface PhysicalLocation {
  latitude: number;
  longitude: number;
  address?: string;
  zip?: string;
  lastUpdated: string;
}

export const restrictiveLocationSchema = locationSchema.extend({
  address: z.string(),
});

export const locationReferencesSchema = z.object({
  cityId: CityZodId.optional(),
  stateId: StateZodId.optional(),
  countryId: CountryZodId.optional(),
});

export const vehicleLocationUpdateInput = z.object({
  vehicleId: VehicleZodId,
  location: z.object({
    latitude: z.number(),
    longitude: z.number(),
    address: z.string().optional(),
    zip: z.string().optional(),
  }),
  city: z.string().optional(),
  state: z.string().optional(),
  postCode: z.string().optional(),
  country: z.string().optional(),
  stateShortCode: z.string().optional(),
  countryCode: z.string().optional(),
  timezone: z.string().optional(),
});

export type VehicleLocationUpdateInput = z.infer<
  typeof vehicleLocationUpdateInput
>;

export const locationUpdateInput = z.object({
  latitude: z.number(),
  longitude: z.number(),
});

export type LocationUpdateInput = z.infer<typeof locationUpdateInput>;

export const locationHelpers = {
  async findPotentialDuplicates(
    ctx: QueryCtx,
    cityId: CityId,
    address: string
  ) {
    return await ctx.db
      .query('groups')
      .filter(q =>
        q.and(
          q.eq(q.field('cityId'), cityId),
          q.neq(q.field('location.address'), undefined)
        )
      )
      .collect();
  },

  async getLocationsByCity(
    ctx: QueryCtx,
    cityId: CityId,
    options?: {
      locationType?: PhysicalLocationType;
    }
  ) {
    let query = ctx.db
      .query('groups')
      .withIndex('by_cityId', q => q.eq('cityId', cityId));

    if (options?.locationType) {
      query = query.filter(q =>
        q.eq(q.field('locationType'), options.locationType)
      );
    }

    return await query.collect();
  },
};
