import { EnhancedUser } from 'src/convex/functions/users';

export function allowedToViewRequestInvoices(me?: EnhancedUser) {
  if (!me) {
    return false;
  }
  return (
    me.roles.some(r => r.type === 'SERVICE_DISPATCHER') ||
    me.roles.some(r => r.type === 'THIRD_PARTY_DISPATCHER') ||
    me.roles.some(r => r.type === 'FLEET_DISPATCHER') ||
    me.roles?.some(r => r.type === 'DRIVER_FLEET') ||
    (me.roles.some(r => r.type === 'TECHNICIAN_PROVIDER') &&
      !!me.myCompanySettings?.allowTechniciansToViewInvoices)
  );
}
