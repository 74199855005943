import { Helmet } from 'react-helmet-async';
import { CONFIG } from 'src/config-global';
import { StripeRefreshView } from 'src/sections/invoice/view/refresh-view';
const metadata = { title: `Invoices | Stripe Refresh - ${CONFIG.appName}` };

export default function StripeRefreshPage() {
  return (
    <>
      <Helmet>
        <title> {metadata.title}</title>
      </Helmet>

      <StripeRefreshView />
    </>
  );
}
