import {
  Alert,
  Box,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { useResponsive } from 'src/minimal-theme/hooks/use-responsive';

type Platform = 'chrome' | 'firefox' | 'safari' | 'android' | 'ios' | 'other';

function getLocationInstructions(isMobile: boolean): {
  platform: Platform;
  steps: string[];
} {
  // Detect platform
  const ua = navigator.userAgent.toLowerCase();

  // iOS
  if (/iphone|ipad|ipod/.test(ua)) {
    return {
      platform: 'ios',
      steps: [
        'Open your iPhone/iPad Settings',
        'Locate Safari Settings',
        'Find Location settings',
        'Ensure location sharing is enabled',
        'Return to this page and refresh',
      ],
    };
  }

  // Android
  if (/android/.test(ua)) {
    return {
      platform: 'android',
      steps: [
        'Tap the three dots menu in your browser',
        `Tap the (i) icon and select 'Permissions'`,
        'Allow location access',
        'Refresh page and if prompted again select allow',
      ],
    };
  }

  // Desktop Chrome
  if (/chrome/.test(ua) && !isMobile) {
    return {
      platform: 'chrome',
      steps: [
        'Look for the location pin icon in the address bar (next to the star/favorite icon)',
        'Click it and select "Always allow..." to enable location access',
        'Refresh the page',
      ],
    };
  }

  // Desktop Firefox
  if (/firefox/.test(ua) && !isMobile) {
    return {
      platform: 'firefox',
      steps: [
        `Click the location icon in the address bar with the '\\' through it`,
        `Click the 'Block Temporarily' button with the 'X' to clear the block and re-prompt`,
        'Refresh the page',
      ],
    };
  }

  // Desktop Safari
  if (/safari/.test(ua) && !isMobile) {
    return {
      platform: 'safari',
      steps: [
        'Click Safari > Preferences',
        'Go to the Privacy tab',
        'Under "Website use of location services", select "Prompt for each website"',
        'Refresh the page',
      ],
    };
  }

  // Generic fallback
  return {
    platform: 'other',
    steps: [
      'Check your browser settings for location permissions',
      'Look for location settings in your site permissions or privacy settings',
      'Enable location access for this website',
      'Refresh the page',
    ],
  };
}

export const LocationTrackingInstructions = () => {
  const isMobile = useResponsive('down', 'md');
  const { steps } = getLocationInstructions(isMobile);

  return (
    <>
      <Alert severity="info" sx={{ mb: 2 }}>
        Location access is currently blocked. Please follow these steps to
        enable it:
      </Alert>

      <Box sx={{ pl: 2 }}>
        <Stepper
          orientation="vertical"
          sx={{
            '& .MuiStepIcon-root': {
              color: 'primary.main',
            },

            '& .MuiStepConnector-line': {
              borderColor: 'primary.main',
            },
          }}
        >
          {steps.map((step, index) => (
            <Step key={index} expanded active>
              <StepLabel>{`Step ${index + 1}`}</StepLabel>
              <StepContent>
                <Typography>{step}</Typography>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </>
  );
};
