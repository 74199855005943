import { api } from '@cvx/api';
import { EnhancedRequest } from '@cvx/types/entities/requestsEntityTypes';
import { ConvexUser } from '@cvx/types/entities/usersEntityTypes';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  Box,
  Button,
  CardHeader,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from 'convex/react';
import { useEffect, useMemo, useState } from 'react';
import { PaymentMethodField } from 'src/components/form/PaymentMethodField';
import { RefinedServiceSubmissionInput } from 'src/convex/functions/helpers/request/serviceInitiated';
import { BaseInvite, TempLocationInfo } from 'src/convex/functions/invitations';
import { EnhancedUser } from 'src/convex/functions/users';
import { PaymentMethods } from 'src/convex/schema/enums/paymentMethod';
import { MapBoxPlaceInformation } from 'src/convex/schema/enums/timezones';
import { phoneSchema } from 'src/convex/types/zod/commonZod';
import { servicesRequiredSchema } from 'src/convex/types/zod/requestsZod';
import {
  decodeVin,
  mapVinData,
} from 'src/convex/utils/vinDecoder/vinDecoder.utils';
import { useDebouncedMutation } from 'src/hooks/useDebouncedMutation';
import { Iconify } from 'src/minimal-theme/components/iconify';
import { Label } from 'src/minimal-theme/components/label';
import { PhoneInput } from 'src/minimal-theme/components/phone-input';
import { toast } from 'src/minimal-theme/components/snackbar';
import { useServiceSelection } from 'src/sections/location/view/hooks/useServiceSelection';
import { ServiceSelection } from 'src/sections/location/view/ServiceSelection';
import { AddressInput } from 'src/sections/request/AddressInput';
import { PreviousCustomerSelection } from 'src/sections/request/PreviousCustomerSelection';
import { RequestDetailsCard } from 'src/sections/request/RequestDetailsCard';
import { RequestLocationCard } from 'src/sections/request/RequestLocationCard';
import { RequestMapDisplay } from 'src/sections/request/RequestMapDisplay';
import { useAddressSearch } from 'src/utils/useAddressSearch';
import { z } from 'zod';

type NonFleetFormProps = {
  request: EnhancedRequest;
  me: EnhancedUser;
  isDialog?: boolean;
  onClose?: () => void;
};

type FormState = Omit<
  RefinedServiceSubmissionInput,
  'timezone' | 'requestId'
> & {
  timezone?: string;
  nationalAccountNumber?: string;
  tireSize?: string;
  paymentMethod?: PaymentMethods;
} & { requesterCompanyName?: string };

type FormErrors = {
  [K in keyof FormState]?: string;
} & {
  'tempVehicleInfo.vin'?: string;
};

// TODO: These various forms are getting really complicated, once we get a few more customers onboarded we'll have enough use cases/feature requests to solidify an approach to forms and simplify...
export const NonFleetForm = ({
  request,
  me,
  isDialog,
  onClose,
}: NonFleetFormProps) => {
  const [formState, setFormState] = useState<FormState>({
    tempDriverFirstName: request.tempDriverFirstName || '',
    tempDriverLastName: request.tempDriverLastName || '',
    tempDriverPhone: request.tempDriverPhone,
    tempDriverEmail: request.tempDriverEmail,
    fleetReferenceNumber: request.fleetReferenceNumber || '',
    tempVehicleUnitNumber: request.tempVehicleUnitNumber || '',
    isDriverCalling: !!request.isDriverCalling,
    isNoActiveDriver: !!request.isNoActiveDriver,
    tempFleetDispatchFirstName: request.tempFleetDispatchFirstName || '',
    tempFleetDispatchLastName: request.tempFleetDispatchLastName || '',
    tempFleetDispatchPhone: request.tempFleetDispatchPhone,
    tempFleetDispatchEmail: request.tempFleetDispatchEmail,
    address: request.address,
    longitude: request.longitude,
    latitude: request.latitude,
    tempVehicleInfo: request.tempVehicleInfo || { vin: '' },
    description: request.description || '',
    requesterCompanyName: request.requesterCompanyName || '',
    servicesRequiredByFleet: request.servicesRequiredByFleet || [],
    nationalAccountNumber: request.nationalAccountNumber || '',
    paymentMethod: request.paymentMethod,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [settingExistingDriver, setSettingExistingDriver] = useState(false);
  const [settingExistingDispatcher, setSettingExistingDispatcher] =
    useState(false);

  const { searchResults, coordinates, setCoordinates, searchAddress } =
    useAddressSearch();

  const [fullAddressInfo, setFullAddressInfo] =
    useState<MapBoxPlaceInformation>();

  const inviteUser = useMutation(api.functions.invitations.inviteUser);

  const [isDecoding, setIsDecoding] = useState<boolean>(false);

  const soleProprietorTech =
    !!me.roles.find(r => r.type === 'TECHNICIAN_PROVIDER') &&
    me.primaryLocation.noDispatchSoleProprietor;

  const serviceProviderTechnician = !!me.roles.find(
    r => r.type === 'TECHNICIAN_PROVIDER'
  );

  const thirdPartyDispatcher = !!me.roles.find(
    r => r.type === 'THIRD_PARTY_DISPATCHER'
  );

  const submitRequest = useMutation(
    soleProprietorTech || serviceProviderTechnician
      ? api.functions.requests.submitTechnicianRequest
      : thirdPartyDispatcher
        ? api.functions.requests.submitThirdPartyRequest
        : api.functions.requests.submitServiceProviderRequest
  );

  const updateRequest = useMutation(api.functions.requests.updateRequest);
  const updateDraftRequest = useMutation(
    api.functions.requests.updateDraftRequest
  );

  const debouncedUpdateDraftRequest = useDebouncedMutation(updateDraftRequest);

  const handleVinChange = async (value: string) => {
    setFormState(prev => ({
      ...prev,
      tempVehicleInfo: { ...prev.tempVehicleInfo, vin: value },
    }));

    // Handle empty VIN
    if (value === '') {
      if (!isDialog) {
        await debouncedUpdateDraftRequest({
          requestId: request._id,
          tempVehicleInfo: { vin: '' },
        });
      }
      return;
    }

    // Decode VIN if it's 17 characters
    if (value.length === 17) {
      try {
        setIsDecoding(true);
        const vinData = await decodeVin(value);
        setIsDecoding(false);

        const mappedData = mapVinData(value, vinData);

        setFormState(prev => ({
          ...prev,
          tempVehicleInfo: { ...mappedData },
        }));

        if (!isDialog) {
          await debouncedUpdateDraftRequest({
            requestId: request._id,
            tempVehicleInfo: { ...mappedData },
          });
        }
      } catch (error) {
        setIsDecoding(false);
        setFormState(prev => ({
          ...prev,
          tempVehicleInfo: { vin: value, isVinDecoded: false },
        }));
      }
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    // We remove the requesterCompanyName from the form state because it will already be set correctly from other things
    const { requesterCompanyName, ...otherFormState } = formState;

    try {
      if (isDialog) {
        const resp = await updateRequest({
          requestId: request._id,
          ...otherFormState,
          ...fullAddressInfo,
          longitude: coordinates?.long,
          latitude: coordinates?.lat,
        });

        if (resp.success) {
          toast.success(resp.message);
          onClose?.();
          return;
        }

        setIsSubmitting(false);
      }

      const submittingObject = {
        input: {
          ...otherFormState,
          requestId: request._id,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          ...fullAddressInfo,
          activeDriverId: request.activeDriverId,
          activeFleetDispatcherId: request.activeFleetDispatcherId,
          longitude: coordinates?.long,
          latitude: coordinates?.lat,
        },
      };

      const resp = await submitRequest(submittingObject);

      if (resp.success) {
        const { activeDriver, fleetDispatcher } = request;

        const knownLocationInvitationId =
          activeDriver?.primaryLocationGroupId ??
          fleetDispatcher?.primaryLocationGroupId;

        const needNewCompanyAndLocation = !knownLocationInvitationId;

        const companyName = formState.requesterCompanyName
          ? `${formState.requesterCompanyName}`
          : `${formState.tempDriverFirstName} - (Temporary Company Name)`;
        const locationName = formState.requesterCompanyName
          ? `${formState.requesterCompanyName}`
          : `${formState.tempDriverFirstName} - (Temporary Location Name)`;

        const tempLocationInfo: TempLocationInfo | undefined =
          needNewCompanyAndLocation
            ? {
                companyName,
                locationName,
                description: 'New location created from request',
              }
            : undefined;

        const isNewDriver = !activeDriver && formState.tempDriverPhone;
        const isNewDispatcher =
          !fleetDispatcher && formState.tempFleetDispatchPhone;

        const existingLocationId =
          activeDriver?.primaryLocationGroupId ||
          fleetDispatcher?.primaryLocationGroupId;

        const driverInvitationObject: { inviteInfo: BaseInvite } = {
          inviteInfo: {
            firstName: activeDriver
              ? activeDriver.clerkUser.firstName
              : formState.tempDriverFirstName!,
            lastName: activeDriver
              ? activeDriver.clerkUser.lastName
              : formState.tempDriverLastName!,
            email: activeDriver
              ? activeDriver.clerkUser.primaryEmailAddress.emailAddress
              : formState.tempDriverEmail,
            phone: activeDriver
              ? activeDriver.clerkUser.primaryPhoneNumber.phoneNumber
              : formState.tempDriverPhone!,
            role: 'DRIVER_FLEET' as const,
            requestId: request._id,
            // Invite new driver to fleet dispatcher's location if known
            ...(!activeDriver && knownLocationInvitationId
              ? { knownLocationInvitationId }
              : {}),
          },
        };

        const dispatcherInvitationObject: { inviteInfo: BaseInvite } = {
          inviteInfo: {
            firstName: fleetDispatcher
              ? fleetDispatcher.clerkUser.firstName
              : formState.tempFleetDispatchFirstName!,
            lastName: fleetDispatcher
              ? fleetDispatcher.clerkUser.lastName
              : formState.tempFleetDispatchLastName!,
            email: fleetDispatcher
              ? fleetDispatcher.clerkUser.primaryEmailAddress.emailAddress
              : formState.tempFleetDispatchEmail,
            phone: fleetDispatcher
              ? fleetDispatcher.clerkUser.primaryPhoneNumber.phoneNumber
              : formState.tempFleetDispatchPhone!,
            role: 'FLEET_DISPATCHER' as const,
            requestId: request._id,
            // Invite new dispatcher to driver's location if known
            ...(!fleetDispatcher && knownLocationInvitationId
              ? { knownLocationInvitationId }
              : {}),
          },
        };

        if (existingLocationId) {
          // Case: At least one existing user
          // Add the location to both invitations
          driverInvitationObject.inviteInfo.knownLocationInvitationId =
            existingLocationId;
          dispatcherInvitationObject.inviteInfo.knownLocationInvitationId =
            existingLocationId;

          await Promise.all([
            activeDriver || formState.tempDriverPhone
              ? inviteUser(driverInvitationObject)
              : Promise.resolve(null),
            fleetDispatcher || formState.tempFleetDispatchPhone
              ? inviteUser(dispatcherInvitationObject)
              : Promise.resolve(null),
          ]);
        } else if (isNewDriver) {
          // Case: New driver, no existing location, so possibly new fleet dispatcher or no fleet dispatcher at all
          const driverResponse = await inviteUser({
            ...driverInvitationObject,
            tempLocationInfo,
          });
          const newLocationId = driverResponse.targetLocationId;

          if (isNewDispatcher) {
            // Then invite dispatcher to that location
            dispatcherInvitationObject.inviteInfo.knownLocationInvitationId =
              newLocationId;
            await inviteUser(dispatcherInvitationObject);
          }
        } else if (isNewDispatcher) {
          // Case: New fleet dispatcher, no driver
          await inviteUser({
            ...dispatcherInvitationObject,
            tempLocationInfo,
          });
        } else {
          // Handle any other edge cases
          console.error('Unexpected user invitation scenario');
        }

        toast.success(resp.message);
      } else {
        toast.error(resp.error);
      }
    } catch (error) {
      toast.error("Couldn't submit request. Please try again.");
    }
    setIsSubmitting(false);
  };

  const {
    tempDriverPhone,
    tempFleetDispatchPhone,
    servicesRequiredByFleet,
    tempVehicleInfo,
    isNoActiveDriver,
  } = formState;

  const existingDriverResult = useQuery(
    api.functions.users.checkForLimitedUserByPhone,
    tempDriverPhone && phoneSchema.safeParse(tempDriverPhone).success
      ? { phone: tempDriverPhone }
      : 'skip'
  );

  const existingDispatcherResult = useQuery(
    api.functions.users.checkForLimitedUserByPhone,
    tempFleetDispatchPhone &&
      phoneSchema.safeParse(tempFleetDispatchPhone).success
      ? { phone: tempFleetDispatchPhone }
      : 'skip'
  );

  useEffect(() => {
    const handleDriverSelection = async () => {
      if (isDialog) return;

      if (
        formState.tempDriverPhone &&
        phoneSchema.safeParse(formState.tempDriverPhone).success
      ) {
        if (existingDriverResult) {
          if (existingDriverResult._id !== request.activeDriverId) {
            setSettingExistingDriver(true);
            await updateDraftRequest({
              requestId: request._id,
              activeDriverId: existingDriverResult._id,
              tempDriverFirstName: null,
              tempDriverLastName: null,
              tempDriverEmail: null,
              tempDriverPhone: null,
            });

            setFormState(prev => ({
              ...prev,
              tempDriverPhone: undefined,
              tempDriverFirstName: undefined,
              tempDriverLastName: undefined,
              tempDriverEmail: undefined,
            }));
          }
        } else if (request.activeDriverId) {
          await updateDraftRequest({
            requestId: request._id,
            activeDriverId: null,
          });
        }
      }
    };

    void handleDriverSelection();
  }, [
    updateDraftRequest,
    existingDriverResult,
    formState.tempDriverPhone,
    request.activeDriverId,
    request._id,
    isDialog,
  ]);

  useEffect(() => {
    if (settingExistingDriver && request.activeDriverId) {
      setSettingExistingDriver(false);
    }
  }, [request.activeDriverId, settingExistingDriver]);

  useEffect(() => {
    const handleDispatcherSelection = async () => {
      if (isDialog) return;

      if (
        formState.tempFleetDispatchPhone &&
        phoneSchema.safeParse(formState.tempFleetDispatchPhone).success
      ) {
        if (existingDispatcherResult) {
          if (
            existingDispatcherResult._id !== request.activeFleetDispatcherId
          ) {
            setSettingExistingDispatcher(true);
            await updateDraftRequest({
              requestId: request._id,
              activeFleetDispatcherId: existingDispatcherResult._id,
              tempFleetDispatchFirstName: null,
              tempFleetDispatchLastName: null,
              tempFleetDispatchEmail: null,
              tempFleetDispatchPhone: null,
            });

            setFormState(prev => ({
              ...prev,
              tempFleetDispatchPhone: undefined,
              tempFleetDispatchFirstName: undefined,
              tempFleetDispatchLastName: undefined,
              tempFleetDispatchEmail: undefined,
            }));
          }
        } else if (request.activeFleetDispatcherId) {
          await updateDraftRequest({
            requestId: request._id,
            activeFleetDispatcherId: null,
          });
        }
      }
    };

    void handleDispatcherSelection();
  }, [
    updateDraftRequest,
    existingDispatcherResult,
    formState.tempFleetDispatchPhone,
    request.activeFleetDispatcherId,
    request._id,
    isDialog,
  ]);

  useEffect(() => {
    if (settingExistingDispatcher && request.activeFleetDispatcherId) {
      setSettingExistingDispatcher(false);
    }
  }, [request.activeFleetDispatcherId, settingExistingDispatcher]);

  const { tireBrandsCategory, hasTireSalesSelected, hasBrandSelected } =
    useServiceSelection(servicesRequiredByFleet);

  const servicesValid =
    servicesRequiredByFleet.length > 0 &&
    (!hasTireSalesSelected || !tireBrandsCategory || hasBrandSelected);

  const vinDecodedAndValid = !!tempVehicleInfo?.isVinDecoded;

  // Submit button text
  let submitButtonText = 'Submit And Find Technician';
  if (soleProprietorTech || serviceProviderTechnician) {
    submitButtonText = 'Submit';
  } else if (thirdPartyDispatcher) {
    submitButtonText = 'Submit And Find A Service Provider';
  }

  const errors = useMemo(() => {
    const e: FormErrors = {};

    if (!request.activeDriverId && !formState.isNoActiveDriver) {
      e.tempDriverFirstName = validateRequired(formState.tempDriverFirstName);
      e.tempDriverLastName = validateRequired(formState.tempDriverLastName);
      e.tempDriverPhone = validatePhone(formState.tempDriverPhone);
      e.tempDriverEmail = validateEmail(formState.tempDriverEmail);
    }

    if (!request.activeFleetDispatcherId && !formState.isDriverCalling) {
      e.tempFleetDispatchFirstName = validateRequired(
        formState.tempFleetDispatchFirstName
      );
      e.tempFleetDispatchLastName = validateRequired(
        formState.tempFleetDispatchLastName
      );
      e.tempFleetDispatchPhone = validatePhone(
        formState.tempFleetDispatchPhone
      );
      e.tempFleetDispatchEmail = validateEmail(
        formState.tempFleetDispatchEmail
      );
    }

    const vin = formState.tempVehicleInfo?.vin;
    if (vin && vin.length !== 17) {
      e['tempVehicleInfo.vin'] = 'VIN must be 17 characters';
    }

    if (
      !servicesRequiredSchema.safeParse(formState.servicesRequiredByFleet)
        .success
    ) {
      e.servicesRequiredByFleet = 'Please select at least one service';
    }

    return e;
  }, [formState, request]);

  const companyNameField =
    request.activeDriver || request.fleetDispatcher ? (
      <Box
        sx={{
          border: 1,
          borderColor: 'divider',
          borderRadius: 1,
          p: 2,
          mb: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          bgcolor: 'background.neutral',
        }}
      >
        <CardHeader
          sx={{ p: 0 }}
          title={'Company'}
          subheader={request.requesterCompanyName ?? ''}
        />
      </Box>
    ) : request.requesterCompanyId && isDialog ? null : (
      <FormControl>
        <TextField
          fullWidth
          label="Requesting Company Name"
          autoFocus
          value={formState.requesterCompanyName}
          onChange={e =>
            setFormState(prev => ({
              ...prev,
              requesterCompanyName: e.target.value,
            }))
          }
          error={!!errors.requesterCompanyName}
          helperText={errors.requesterCompanyName}
        />
      </FormControl>
    );

  const detailsContent = (
    <Box columnGap={2} rowGap={3} display="grid" sx={{ mt: 2 }}>
      {companyNameField}
      <FormControl sx={{ display: 'flex', flexDirection: 'row' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formState.isDriverCalling}
              onChange={async () => {
                const newVal = !formState.isDriverCalling;
                const newNoActiveDriverValue = newVal
                  ? false
                  : formState.isNoActiveDriver;

                setFormState(prev => ({
                  ...prev,
                  isDriverCalling: newVal,
                  isNoActiveDriver: newNoActiveDriverValue, // Need to clear this if driver calling
                  tempFleetDispatchEmail: undefined,
                  tempFleetDispatchFirstName: undefined,
                  tempFleetDispatchLastName: undefined,
                  tempFleetDispatchPhone: undefined,
                  activeFleetDispatcherId: undefined,
                }));

                if (isDialog) {
                  return;
                }

                await debouncedUpdateDraftRequest({
                  isDriverCalling: newVal,
                  isNoActiveDriver: newNoActiveDriverValue,
                  tempFleetDispatchEmail: null,
                  tempFleetDispatchFirstName: null,
                  tempFleetDispatchLastName: null,
                  tempFleetDispatchPhone: null,
                  activeFleetDispatcherId: null,
                  requestId: request._id,
                });
              }}
            />
          }
          label="Driver Calling Directly (No Fleet Dispatcher)"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formState.isNoActiveDriver}
              onChange={async () => {
                const newVal = !formState.isNoActiveDriver;
                const newDriverCallingValue = newVal
                  ? false
                  : formState.isDriverCalling;

                setFormState(prev => ({
                  ...prev,
                  isNoActiveDriver: newVal,
                  isDriverCalling: newDriverCallingValue, // Need to clear this if no active driver
                  tempDriverFirstName: undefined,
                  tempDriverLastName: undefined,
                  tempDriverEmail: undefined,
                  tempDriverPhone: undefined,
                  activeDriverId: undefined,
                }));

                if (isDialog) {
                  return;
                }

                await debouncedUpdateDraftRequest({
                  isNoActiveDriver: newVal,
                  isDriverCalling: newDriverCallingValue,
                  tempDriverEmail: null,
                  tempDriverFirstName: null,
                  tempDriverLastName: null,
                  tempDriverPhone: null,
                  activeDriverId: null,
                  requestId: request._id,
                });
              }}
            />
          }
          label="No Active Driver"
        />
      </FormControl>

      {!formState.isNoActiveDriver && (
        <>
          <Typography variant="h6">Driver Information</Typography>
          {request.activeDriver ? (
            <SelectedUserCard
              user={request.activeDriver}
              onClear={async () => {
                setFormState(prev => ({
                  ...prev,
                  tempDriverPhone: undefined,
                  tempDriverEmail: undefined,
                  tempDriverFirstName: undefined,
                  tempDriverLastName: undefined,
                }));

                await debouncedUpdateDraftRequest({
                  requestId: request._id,
                  activeDriverId: null,
                  tempDriverPhone: null,
                  tempDriverEmail: null,
                  tempDriverFirstName: null,
                  tempDriverLastName: null,
                });
              }}
            />
          ) : settingExistingDriver ? (
            <ExistingUserLoader />
          ) : (
            <>
              <Stack direction="row" spacing={2}>
                <TextField
                  fullWidth
                  label="First Name"
                  required
                  value={formState.tempDriverFirstName}
                  onChange={async e => {
                    const value = e.target.value;
                    setFormState(prev => ({
                      ...prev,
                      tempDriverFirstName: value,
                    }));
                    if (isDialog) {
                      return;
                    }
                    await debouncedUpdateDraftRequest({
                      tempDriverFirstName: value,
                      requestId: request._id,
                    });
                  }}
                  error={!!errors.tempDriverFirstName}
                  helperText={errors.tempDriverFirstName}
                />
                <TextField
                  fullWidth
                  label="Last Name"
                  required
                  value={formState.tempDriverLastName}
                  onChange={async e => {
                    const value = e.target.value;
                    setFormState(prev => ({
                      ...prev,
                      tempDriverLastName: value,
                    }));
                    if (isDialog) {
                      return;
                    }
                    await debouncedUpdateDraftRequest({
                      tempDriverLastName: value,
                      requestId: request._id,
                    });
                  }}
                  error={!!errors.tempDriverLastName}
                  helperText={errors.tempDriverLastName}
                />
              </Stack>
              <PhoneInput
                required
                placeholder="(123) 456-7890"
                fullWidth
                label="Phone"
                value={formState.tempDriverPhone ?? ''}
                onChange={value =>
                  setFormState(prev => ({
                    ...prev,
                    tempDriverPhone: value,
                  }))
                }
                error={!!errors.tempDriverPhone}
                helperText={errors.tempDriverPhone}
              />
              <TextField
                fullWidth
                label="Email"
                value={formState.tempDriverEmail}
                onChange={e =>
                  setFormState(prev => ({
                    ...prev,
                    tempDriverEmail: e.target.value.length
                      ? e.target.value
                      : undefined,
                  }))
                }
                error={!!errors.tempDriverEmail}
                helperText={errors.tempDriverEmail}
              />
            </>
          )}
        </>
      )}

      {!formState.isDriverCalling && (
        <>
          <Typography variant="h6">Fleet Dispatcher Information</Typography>

          {request.fleetDispatcher ? (
            <SelectedUserCard
              user={request.fleetDispatcher}
              onClear={async () => {
                setFormState(prev => ({
                  ...prev,
                  tempFleetDispatchPhone: undefined,
                  tempFleetDispatchEmail: undefined,
                  tempFleetDispatchFirstName: undefined,
                  tempFleetDispatchLastName: undefined,
                }));

                await debouncedUpdateDraftRequest({
                  requestId: request._id,
                  activeFleetDispatcherId: null,
                  tempFleetDispatchPhone: null,
                  tempFleetDispatchEmail: null,
                  tempFleetDispatchFirstName: null,
                  tempFleetDispatchLastName: null,
                });
              }}
            />
          ) : settingExistingDispatcher ? (
            <ExistingUserLoader />
          ) : (
            <>
              <Stack direction="row" spacing={1}>
                <TextField
                  fullWidth
                  label="First Name"
                  required
                  value={formState.tempFleetDispatchFirstName}
                  onChange={async e => {
                    const value = e.target.value;
                    setFormState(prev => ({
                      ...prev,
                      tempFleetDispatchFirstName: value,
                    }));
                    if (isDialog) {
                      return;
                    }
                    await debouncedUpdateDraftRequest({
                      tempFleetDispatchFirstName: value,
                      requestId: request._id,
                    });
                  }}
                  error={!!errors.tempFleetDispatchFirstName}
                  helperText={errors.tempFleetDispatchFirstName}
                />
                <TextField
                  fullWidth
                  label="Last Name"
                  required
                  value={formState.tempFleetDispatchLastName}
                  onChange={async e => {
                    const value = e.target.value;
                    setFormState(prev => ({
                      ...prev,
                      tempFleetDispatchLastName: value,
                    }));
                    if (isDialog) {
                      return;
                    }
                    await debouncedUpdateDraftRequest({
                      tempFleetDispatchLastName: value,
                      requestId: request._id,
                    });
                  }}
                  error={!!errors.tempFleetDispatchLastName}
                  helperText={errors.tempFleetDispatchLastName}
                />
              </Stack>
              <PhoneInput
                required
                placeholder="(123) 456-7890"
                fullWidth
                label="Phone"
                value={formState.tempFleetDispatchPhone ?? ''}
                onChange={value =>
                  setFormState(prev => ({
                    ...prev,
                    tempFleetDispatchPhone: value,
                  }))
                }
                error={!!errors.tempFleetDispatchPhone}
                helperText={errors.tempFleetDispatchPhone}
              />
              <TextField
                fullWidth
                label="Email"
                value={formState.tempFleetDispatchEmail}
                onChange={e =>
                  setFormState(prev => ({
                    ...prev,
                    tempFleetDispatchEmail: e.target.value.length
                      ? e.target.value
                      : undefined,
                  }))
                }
                error={!!errors.tempFleetDispatchEmail}
                helperText={errors.tempFleetDispatchEmail}
              />
            </>
          )}
        </>
      )}
    </Box>
  );

  // TODO: fleetReferenceNumber doesn't really belong here, but will do for now
  const complaintContent = (
    <Box columnGap={2} rowGap={3} display="grid" sx={{ mt: 2 }}>
      {request.vehicleId && isDialog ? null : (
        <>
          <Typography variant="h6">Vehicle Information</Typography>
          <Stack direction="row" spacing={1} alignItems="top">
            <TextField
              label="Optional VIN"
              name="vin"
              autoComplete="off"
              placeholder="12345678901234567"
              disabled={
                !!formState.tempVehicleInfo.vin &&
                formState.tempVehicleInfo.vin.length === 17
              }
              fullWidth
              value={formState.tempVehicleInfo.vin ?? ''}
              onChange={e => handleVinChange(e.target.value)}
              error={!!errors['tempVehicleInfo.vin']}
              helperText={errors['tempVehicleInfo.vin']}
            />
            {!!formState.tempVehicleInfo.vin &&
              formState.tempVehicleInfo.vin.length > 0 && (
                <Label
                  sx={{ minHeight: 54, px: 3, minWidth: 130 }}
                  startIcon={
                    vinDecodedAndValid ? (
                      <Iconify icon="solar:check-circle-bold-duotone" />
                    ) : isDecoding ? (
                      <Iconify icon="solar:key-minimalistic-bold-duotone" />
                    ) : (
                      <Iconify icon="solar:close-square-bold-duotone" />
                    )
                  }
                  color={
                    vinDecodedAndValid
                      ? 'success'
                      : isDecoding
                        ? 'secondary'
                        : 'error'
                  }
                >
                  {vinDecodedAndValid
                    ? 'Valid VIN'
                    : isDecoding
                      ? 'Decoding'
                      : 'Invalid Vin'}
                </Label>
              )}
            <Button
              variant="contained"
              onClick={() => handleVinChange('')}
              sx={{ minWidth: 100, maxHeight: 54 }}
            >
              Clear
            </Button>
          </Stack>
        </>
      )}
      <FormControl>
        <TextField
          label="Fleet Reference Number"
          placeholder="Optionally provide a fleet reference number"
          variant="outlined"
          fullWidth
          value={formState.fleetReferenceNumber}
          error={!!errors.fleetReferenceNumber}
          helperText={errors.fleetReferenceNumber}
          onChange={e =>
            setFormState(prev => ({
              ...prev,
              fleetReferenceNumber: e.target.value,
            }))
          }
        />
      </FormControl>
      {request.vehicleId && isDialog ? null : (
        <FormControl>
          <TextField
            label="Unit #"
            placeholder="Optionally provide a unit number"
            variant="outlined"
            fullWidth
            value={formState.tempVehicleUnitNumber}
            error={!!errors.tempVehicleUnitNumber}
            helperText={errors.tempVehicleUnitNumber}
            onChange={async e => {
              const value = e.target.value;
              setFormState(prev => ({
                ...prev,
                tempVehicleUnitNumber: value,
              }));
              await debouncedUpdateDraftRequest({
                requestId: request._id,
                tempVehicleUnitNumber: value ?? '',
              });
            }}
          />
        </FormControl>
      )}
      <FormControl>
        <ServiceSelection
          value={formState.servicesRequiredByFleet}
          onChange={async value => {
            setFormState(prev => ({
              ...prev,
              servicesRequiredByFleet: value,
            }));

            if (!isDialog) {
              await debouncedUpdateDraftRequest({
                requestId: request._id,
                servicesRequiredByFleet: value,
              });
            }
          }}
          required
          filterOptions={{ splitOutTireBrandSelection: true }}
          error={!!errors.servicesRequiredByFleet}
          helperText={errors.servicesRequiredByFleet ?? ''}
          nationalAccountNumber={formState.nationalAccountNumber}
          onNationalAccountNumberChange={async value => {
            setFormState(prev => ({
              ...prev,
              nationalAccountNumber: value,
            }));

            if (!isDialog) {
              await debouncedUpdateDraftRequest({
                requestId: request._id,
                nationalAccountNumber: value,
              });
            }
          }}
          tireSize={formState.tireSize}
          onTireSizeChange={value => {
            setFormState(prev => ({ ...prev, tireSize: value }));
          }}
        />
      </FormControl>
      <FormControl>
        <PaymentMethodField
          value={formState.paymentMethod}
          onChange={value =>
            setFormState(prev => ({ ...prev, paymentMethod: value }))
          }
        />
      </FormControl>
      <FormControl>
        <TextField
          label="Additional Notes"
          placeholder="Optionally provide additional information"
          variant="outlined"
          fullWidth
          multiline
          rows={3}
          value={formState.description}
          error={!!errors.description}
          helperText={errors.description}
          onChange={async e => {
            const description = e.target.value;
            setFormState(prev => ({ ...prev, description }));

            if (isDialog) {
              return;
            }
            await debouncedUpdateDraftRequest({
              requestId: request._id,
              description,
            });
          }}
        />
      </FormControl>
    </Box>
  );

  const addressField =
    request.vehicleId && isDialog ? null : (
      <FormControl fullWidth>
        <AddressInput
          value={formState.address ?? ''}
          onChange={value => {
            setFormState(prev => ({ ...prev, address: value || '' }));
          }}
          onChangeFullAddressInfo={setFullAddressInfo}
          error={!!errors.address}
          helperText={errors.address}
          searchResults={searchResults}
          searchAddress={searchAddress}
          onCoordinatesChange={coords => {
            setCoordinates(
              coords ? { lat: coords.latitude, long: coords.longitude } : null
            );
            // TODO: Should set this async, but need to think of a better pattern for clearing values on the backend
          }}
        />
      </FormControl>
    );

  const locationContent = (
    <Stack spacing={2}>
      {coordinates && (
        <RequestMapDisplay
          coordinatesOverride={{
            latitude: coordinates.lat,
            longitude: coordinates.long,
          }}
        />
      )}
    </Stack>
  );

  const formValid =
    Object.values(errors).filter(e => e !== undefined).length === 0;

  if (isDialog) {
    return (
      <>
        <DialogContent>
          <Box sx={{ mt: 2 }} columnGap={2} rowGap={3} display="grid">
            {companyNameField}
            {complaintContent}
            {addressField}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton
            variant="contained"
            loading={isSubmitting}
            disabled={!formValid || !servicesValid || isSubmitting}
            onClick={() => handleSubmit()}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </>
    );
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <PreviousCustomerSelection
        disableFleetDispatcherSelection={formState.isDriverCalling}
        disableDriverSelection={formState.isNoActiveDriver}
        onSelectFleetDispatcher={async id => {
          await debouncedUpdateDraftRequest({
            requestId: request._id,
            activeFleetDispatcherId: id,
            tempFleetDispatchFirstName: null,
            tempFleetDispatchLastName: null,
            tempFleetDispatchEmail: null,
            tempFleetDispatchPhone: null,
          });

          setFormState(prev => ({
            ...prev,
            tempFleetDispatchPhone: undefined,
            tempFleetDispatchFirstName: undefined,
            tempFleetDispatchLastName: undefined,
            tempFleetDispatchEmail: undefined,
          }));
        }}
        onSelectDriver={async id => {
          await debouncedUpdateDraftRequest({
            requestId: request._id,
            activeDriverId: id,
            tempDriverFirstName: null,
            tempDriverLastName: null,
            tempDriverEmail: null,
            tempDriverPhone: null,
          });

          setFormState(prev => ({
            ...prev,
            tempDriverPhone: undefined,
            tempDriverFirstName: undefined,
            tempDriverLastName: undefined,
            tempDriverEmail: undefined,
          }));
        }}
        selectedDriverId={request.activeDriverId}
        selectedDispatcherId={request.activeFleetDispatcherId}
      />
      <RequestDetailsCard>
        {detailsContent} {complaintContent}
      </RequestDetailsCard>
      <Box sx={{ mt: 3 }}>
        <RequestLocationCard
          subheader={`Your selected vehicle's location information`}
        >
          <Stack spacing={2}>
            {addressField}
            {!isNoActiveDriver && (
              <Alert severity={'info'}>
                Driver will be able to provide more accurate location
                information once the request is submitted.
              </Alert>
            )}
            {locationContent}
          </Stack>
        </RequestLocationCard>
      </Box>
      {request.status === 'DRAFT' && (
        <Box width="100%" display="flex" justifyContent="center">
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            size="large"
            sx={{ mt: 3 }}
            loading={isSubmitting}
            fullWidth
            disabled={!formValid || isSubmitting || !servicesValid}
            onClick={() => handleSubmit()}
          >
            {submitButtonText}
          </LoadingButton>
        </Box>
      )}
    </Box>
  );
};

const SelectedUserCard = ({
  user,
  onClear,
}: {
  user: ConvexUser;
  onClear: () => void;
}) => {
  if (!user?._id) return null;

  const { fullName, primaryEmailAddress, primaryPhoneNumber } = user.clerkUser;

  return (
    <Box
      sx={{
        borderRadius: 1,
        p: 2,
        mb: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        bgcolor: 'background.neutral',
      }}
    >
      <Box>
        <Typography variant="subtitle2">{fullName}</Typography>
        <Typography variant="body2" color="text.secondary">
          {primaryPhoneNumber.phoneNumber}
        </Typography>
        {primaryEmailAddress && primaryEmailAddress.emailAddress && (
          <Typography variant="body2" color="text.secondary">
            {primaryEmailAddress.emailAddress}
          </Typography>
        )}
      </Box>
      <Button
        startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
        color="error"
        onClick={onClear}
      >
        Clear
      </Button>
    </Box>
  );
};

const validateRequired = (value?: string): string | undefined => {
  if (!value || value.length < 1) return 'This field is required';
};

const validatePhone = (value?: string): string | undefined => {
  if (!value) return 'Phone number is required';
  return phoneSchema.safeParse(value).success
    ? undefined
    : 'Valid phone number is required';
};

const validateEmail = (value?: string): string | undefined => {
  if (!value) return undefined;
  return z.string().email().safeParse(value).success
    ? undefined
    : 'Valid email is required';
};

const ExistingUserLoader = () => {
  return (
    <Box
      sx={{
        borderRadius: 1,
        p: 2,
        mb: 2,
        bgcolor: 'background.neutral',
      }}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <Skeleton variant="rounded" width={'100%'} height={60} />
      </Stack>
    </Box>
  );
};
