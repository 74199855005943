import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import {
  PaymentMethods,
  getFriendlyPaymentMethodName,
  paymentMethod,
} from 'src/convex/schema/enums/paymentMethod';

interface PaymentMethodFieldProps {
  value?: PaymentMethods;
  onChange: (value: PaymentMethods) => void;
  error?: boolean;
  helperText?: string;
  label?: string;
  required?: boolean;
}

export const PaymentMethodField = ({
  value,
  onChange,
  error,
  helperText,
  label = 'Payment Method',
  required,
}: PaymentMethodFieldProps) => {
  return (
    <FormControl fullWidth error={error} required={required}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value || ''}
        onChange={e => onChange(e.target.value as PaymentMethods)}
        label={label}
      >
        {paymentMethod.options.map(method => (
          <MenuItem key={method} value={method}>
            <ListItemText
              primary={getFriendlyPaymentMethodName(method)}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
