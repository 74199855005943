import { useUser } from '@clerk/clerk-react';
import { Stack, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { api } from '@cvx/api';
import { useConvexAuth, useMutation, useQuery } from 'convex/react';
import { useState } from 'react';
import { AnimateAvatar } from 'src/minimal-theme/components/animate';
import { Upload } from 'src/minimal-theme/components/upload';
import { varAlpha } from 'src/minimal-theme/theme/styles';
import { resizeImage } from 'src/utils/resizeImage';

type AvatarUploadDialogProps = {
  open: boolean;
  onClose: () => void;
};

export function AvatarUploadDialog({ open, onClose }: AvatarUploadDialogProps) {
  const [optimisticPreview, setOptimisticPreview] = useState<string | null>(
    null
  );
  const [image, setImage] = useState<Blob | null>(null);
  const [uploadingPhotos, setUploadingPhotos] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const theme = useTheme();

  const { user } = useUser();

  const { isAuthenticated } = useConvexAuth();

  const updateUser = useMutation(api.functions.users.updateUser);

  const me = useQuery(api.functions.users.getMe, isAuthenticated ? {} : 'skip');

  const onDrop = async (file: File) => {
    setUploadingPhotos(true);

    const preview = URL.createObjectURL(file);

    setOptimisticPreview(preview);

    const resized = await resizeImage(file, 1920);

    setImage(resized);
    setUploadingPhotos(false);
  };

  const confirmUpload = async () => {
    if (image && me && user) {
      setSaving(true);
      const img = await user.setProfileImage({ file: image });

      if (img.publicUrl) {
        await updateUser({
          userId: me._id,
          imageUrl: img.publicUrl,
          firstName: me.clerkUser.firstName,
          lastName: me.clerkUser.lastName,
          email: me.clerkUser.primaryEmailAddress.emailAddress,
          phone: me.clerkUser.primaryPhoneNumber.phoneNumber,
          primaryLocationGroupId: me.primaryLocationGroupId!,
        });
      }

      await user?.reload();

      onClose();
      setOptimisticPreview(null);
      setImage(null);
      setSaving(false);
    }
  };

  const handleCancel = async () => {
    setOptimisticPreview(null);
    setImage(null);
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle sx={{ pb: 2 }}>Upload New Profile Image</DialogTitle>

      <DialogContent sx={{ typography: 'body2' }}>
        {!optimisticPreview && (
          <Upload onDrop={(files: File[]) => onDrop(files[0])} sx={{ mb: 2 }} />
        )}

        {optimisticPreview && (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              transform: 'translateZ(0)',
              overflow: 'hidden',
              width: '100%',
            }}
          >
            <AnimateAvatar
              width={160}
              slotProps={{
                avatar: {
                  src: optimisticPreview,
                  alt: me?.clerkUser?.fullName,
                },
                overlay: {
                  border: 2,
                  spacing: 3,
                  color: `linear-gradient(135deg, ${varAlpha(theme.vars.palette.primary.mainChannel, 0)} 25%, ${theme.vars.palette.primary.main} 100%)`,
                },
              }}
            />
          </Stack>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          color="inherit"
          onClick={handleCancel}
          disabled={saving}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={confirmUpload}
          disabled={uploadingPhotos || !image || saving}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
